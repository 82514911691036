import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedPopupComponent } from './shared-popup.component';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../modules/material.module';

@NgModule({
    declarations: [SharedPopupComponent],
    imports: [CommonModule, FormsModule, MaterialModule],
    exports: [SharedPopupComponent],
})
export class SharedPopupModule {}
