<div id="home">
    <div class="section-1 light-blue-bk">
        <div class="inner-container">
            <div class="description-container">
                <h1 class="title">Matemáticas Financieras de {{schoolNameDisplay | titlecase }}</h1>
                <button class="download-btn tertiary mt-40"
                    [routerLink]="[productLink]">¡Comprar licencia!</button>
            </div>
            <img class="hero-img" src="assets/images/homepage/lic_escuela.png" alt="tarjeta">
        </div>
    </div>
</div>