<div id="home">
    <div class="section section-1 light-blue-bk">
        <div class="description-container">
            <h1>Premios y Reconocimientos</h1>
        </div>

        <div class="awards-container">
            <a href="https://jumpchile.com/ganador/inverkids/" target="_blank">
                <div class="award-container">
                    <img src="assets/images/homepage/logo_jumpchile.svg">
                    <h3>2018 - Jump Chile</h3>
                    <a href="https://jumpchile.com/ganador/inverkids/" target="_blank"><p>Leer más</p></a>
                </div>
            </a>

            <a href="https://www.finnovista.com/en/what-we-do/startup-programs/startupbootcamp-fintech/" target="_blank">
                <div class="award-container">
                    <img src="assets/images/homepage/logo_sbc.svg">
                    <h3>2019 - Startupbootcamp</h3>
                    <a href="https://www.finnovista.com/en/what-we-do/startup-programs/startupbootcamp-fintech/" target="_blank"><p>Leer más</p></a>
                </div>
            </a>

            <a href="https://contxto.com/es/eventos/masschallenge-mexico-finalistas/" target="_blank">
                <div class="award-container">
                    <img src="assets/images/homepage/logo_masschallenge.svg">
                    <h3>2020 - Mass Challenge</h3>
                    <a href="https://contxto.com/es/eventos/masschallenge-mexico-finalistas/" target="_blank"><p>Leer más</p></a>
                </div>
            </a>

            <a href="https://www.holoniq.com/notes/latam-edtech-100/" target="_blank">
                <div class="award-container">
                    <img src="assets/images/homepage/logo_holon.svg">
                    <h3>2020 - Holon IQ</h3>
                    <a href="https://www.holoniq.com/notes/latam-edtech-100/" target="_blank"><p>Leer más</p></a>
                </div>
            </a>

            <a href="https://www.mindcet.org/en" target="_blank">
                <div class="award-container">
                    <img src="assets/images/homepage/logo_mindcet.svg">
                    <h3>2021 - Mindcet</h3>
                    <a href="https://www.mindcet.org/en" target="_blank"><p>Leer más</p></a>
                </div>
            </a>

            <a href="https://www.pronetwork.mx/magazine/las-100-pro-2021/" target="_blank">
                <div class="award-container">
                    <img src="assets/images/homepage/logo_pro.svg">
                    <h3>2021 - PRO 100</h3>
                    <a href="https://www.pronetwork.mx/magazine/las-100-pro-2021/" target="_blank"><p>Leer más</p></a>
                </div>
            </a>

            <a href="https://www.holoniq.com/notes/2022-latin-america-edtech-100" target="_blank">
                <div class="award-container">
                    <img src="assets/images/homepage/logo_holon.svg">
                    <h3>2022 - Holon IQ</h3>
                    <a href="https://www.holoniq.com/notes/2022-latin-america-edtech-100" target="_blank"><p>Leer más</p></a>
                </div>
            </a>

            <a href="https://www.eleconomista.com.mx/el-empresario/ATT-Mexico-y-Endeavor-aceleraran-a-10-mujeres-emprendedoras-20230823-0116.html" target="_blank">
                <div class="award-container">
                    <img src="assets/images/homepage/logo_enovadoras.svg">
                    <h3>2023 - Enovadoras</h3>
                    <a href="https://www.eleconomista.com.mx/el-empresario/ATT-Mexico-y-Endeavor-aceleraran-a-10-mujeres-emprendedoras-20230823-0116.html" target="_blank"><p>Leer más</p></a>
                </div>
            </a>

            <a href="https://www.linkedin.com/posts/sances_de-viaje-con-startups-educativas-en-silicon-activity-7115367594605338625-_3DU?utm_source=share&utm_medium=member_desktop" target="_blank">
                <div class="award-container">
                    <img src="assets/images/homepage/logo_ife.svg">
                    <h3>2023 - IFE Launch</h3>
                    <a href="https://www.linkedin.com/posts/sances_de-viaje-con-startups-educativas-en-silicon-activity-7115367594605338625-_3DU?utm_source=share&utm_medium=member_desktop" target="_blank"><p>Leer más</p></a>
                </div>
            </a>
        </div>
    </div>

    <app-newsletter class="full-width"></app-newsletter>
    <app-social-footer class="full-width"></app-social-footer>
</div>