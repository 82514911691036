import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Payment } from '../../shared/models/payment.model';

@Injectable({
    providedIn: 'root',
})
export class PaymentsService {
    constructor(private http: HttpClient) {}

    createPayment(payment: Payment): Promise<any> {
        return this.http
            .post<any>(`${environment.apiUrl}/pay`, { payment })
            .toPromise();
    }

    getPayments(): Promise<any> {
        return this.http.get<any>(`${environment.apiUrl}/payments`).toPromise();
    }

    updatePayment(payment): Promise<any> {
        return this.http
            .put<any>(`${environment.apiUrl}/payments`, { payment })
            .toPromise();
    }

    createStorePayment(payment: Payment): Promise<any> {
        return this.http
            .post<any>(`${environment.apiUrl}/store_purchases`, { payment })
            .toPromise();
    }
}
