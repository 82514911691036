<div id="plans">
    <div class="main-container">
        <div class="section-1 light-blue-bk">
            <div class="inner-container">
                <div class="left-container">
                    <h1 class="title">Revolucionamos la manera de enseñar finanzas a tus hijos</h1>
                    <p class="description light mt-20" style="margin-top: 60px;">¡Próximamente su primera cuenta de ahorro!
                    </p>
                    <button class="tertiary large mt-40" [routerLink]="['/pago']" (click)="getPremium()">Unirme</button>
                </div>
                <div class="right-container">
                    <img class="hero-image" src="assets/images/plans/plans_hero.png" alt="plans-hero">
                </div>
            </div>
            <div class="green-circle-container">
                <img class="green-circle" src="assets/images/plans/green-half-circle.svg" alt="green-half-circle">
            </div>
        </div>

        <div class="section-2">
            <div class="blue-circle-container">
                <img class="blue-circle" src="assets/images/plans/blue-half-circle.svg" alt="blue-half-circle">
            </div>
            <p class="medium-title">Únete a la comunidad</p>
            <h2 class="mt-30">¿Por qué ser premium?</h2>
            <div class="images-list mt-40">
                <div class="image-container">
                    <img src="assets/images/plans/candado.svg" alt="candado">
                    <h3 class="image-title mt-40">Accede a todo el contenido ilimitado</h3>
                </div>
                <div class="image-container">
                    <img src="assets/images/plans/plantas.svg" alt="plantas">
                    <h3 class="image-title mt-40">Diviértete mientras aprenden jugando</h3>
                </div>
                <div class="image-container">
                    <img src="assets/images/plans/casa.svg" alt="casa">
                    <h3 class="image-title mt-40">Mejora las finanzas de toda la familia</h3>
                </div>
            </div>
        </div>

        <div class="section-3 blue-bk">
            <h2 class="title white">¡Confíanos tu educación financiera!</h2>

            <div class="cards-list mt-40">
                <div class="card small">
                    <p class="medium-title">Básico</p>
                    <h1 class="mt-30">Gratis</h1>
                    <p class="small mt-5">para siempre</p>
                    <button class="white border-blue mt-20" [routerLink]="['/register']">¡Empieza ya!</button>
                    <div class="divider"></div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Hijos ilimitados</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Vinculación de padres</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Acceso a asignación de tareas</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Establece metas financieras</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Registro de transacciones</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Deposita su mesada virtual</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Blog para padres</p>
                    </div>
                </div>
                <div class="card large">
                    <p class="medium-title">Premium</p>
                    <div class="title-container">
                        <h1 class="mt-30">$49</h1>
                        <p class="small">/mensual</p>
                    </div>
                    <p class="small mt-5">o $399 por año</p>
                    <button class="tertiary mt-20" [routerLink]="['/pago']" (click)="getPremium()">Suscribir</button>
                    <div class="divider"></div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">¡Tarjeta de ahorro para hijos!</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Plan básico +</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Cuentos y juegos para niños</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Contenido para adolescentes</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Contenido para padres</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Retos familiares</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Asesoría financiera</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Reporte de avances de hijos</p>
                    </div>
                </div>
                <div class="card small">
                    <p class="medium-title">Colegios</p>
                    <div class="title-container">
                        <h1 class="mt-30">$350</h1>
                        <p class="small">/anual</p>
                    </div>
                    <p class="small mt-5">*variable por total de usuarios</p>
                    <button class="white border-blue mt-20" [routerLink]="['/register/school']"
                        (click)="getPremium()">Cotizar</button>
                    <div class="divider"></div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Contenido curricular</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Perfil del administrador</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Eventos especiales</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Pláticas y conferencias</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Economía en el aula</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Elementos de gamificación</p>
                    </div>
                    <div class="description-container">
                        <span class="blue-circle"></span>
                        <p class="description gray">Próximamente: simuladores</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-4 dark-blue-bk">
            <h1 class="white">Tu decisión, su futuro.</h1>
        </div>

        <div class="section-5">
            <div class="first-circle-container">
                <img src="assets/images/plans/first-circle.svg" alt="first-circle">
            </div>
            <p class="medium-title">Instituciones</p>
            <h2 class="subtitle mt-30">Lleva educación financiera al colegio de tus hijos</h2>
            <button class="white border-blue mt-40" [routerLink]="['/register/school']"
                (click)="getPremium()">Contáctanos</button>
            <div class="second-circle-container">
                <img src="assets/images/plans/second-circle.svg" alt="second-circle">
            </div>
        </div>

        <div class="section-6 light-blue-bk">
            <h2 class="title mt-100 mb-60">Preguntas frequentes</h2>

            <div class="question-container">
                <h3>1. ¿Cómo agrego a mis hijos si ya tienen su propia cuenta?</h3>
                <img class="arrow" src="assets/images/icons/arrow_down.svg" alt="arrow-down"
                    (click)="selectQuestion(1)">
            </div>
            <div class="question-answer mt-20" *ngIf="selectedQuestion === 1">
                <p>En la sección usuarios, la primer vista después de iniciar sesión, se debe seleccionar el recuadro
                    con la opción de "Agregar usuario (+)", posteriormente hay que seleccionar la opción de "Cuenta
                    existente" y para finalizar, hay que ingresar los datos de la cuenta de tu hijo, correo y
                    contraseña, para vincular exitosamente la cuenta de tu hijo.</p>
            </div>
            <div class="divider"></div>
            <div class="question-container">
                <h3>2. ¿Qué puedo hacer si olvidé mi contraseña?</h3>
                <img class="arrow" src="assets/images/icons/arrow_down.svg" alt="arrow-down"
                    (click)="selectQuestion(2)">
            </div>
            <div class="question-answer mt-20" *ngIf="selectedQuestion === 2">
                <p>Al iniciar sesión, da click en "olvide mi contraseña", ingresa tu correo registrado en la aplicación
                    y ¡listo! En menos de lo que canta un gallo tendrás un correo en tu bandeja para restablecer la
                    contraseña de tu cuenta. </p>
            </div>
            <div class="divider"></div>
            <div class="question-container">
                <h3>3. ¿Cómo puedo agregar y eliminar tareas para mis hijos?</h3>
                <img class="arrow" src="assets/images/icons/arrow_down.svg" alt="arrow-down"
                    (click)="selectQuestion(3)">
            </div>
            <div class="question-answer mt-20" *ngIf="selectedQuestion === 3">
                <p>Dentro del perfil del papá, hay que ir a la sección de "Tareas" en donde podrás seleccionar al hijo a
                    quien le asignarás una tarea, después da click al botón de más (+) que encontrarás abajo de la
                    pantalla.</p>
                <p class="mt-10">Posterior llena la siguiente información: 1. Elige la tarea > 2. Elige los días que
                    debe realizar la tarea > 3. Ingresa el monto que le pagarás en total por cumplir la tarea de la
                    semana > 4. Da click en el botón "Asignar"</p>
            </div>
            <div class="divider"></div>
            <div class="question-container">
                <h3>4. ¿Cómo puedo establecer metas financieras de mi hijo?</h3>
                <img class="arrow" src="assets/images/icons/arrow_down.svg" alt="arrow-down"
                    (click)="selectQuestion(4)">
            </div>
            <div class="question-answer mt-20" *ngIf="selectedQuestion === 4">
                <p>Tu hijo debe ingresar a su perfil y en la sección de "Finanzas" dar click al recuadro de Metas y
                    después al botón de más (+) que encontrará abajo de la pantalla.</p>
                <p class="mt-10">Posterior se debe llenar las preguntas específicas de la meta y al dar click en el
                    botón"Finalizar"la meta se habrá creado exitosamente.</p>
            </div>
            <div class="divider"></div>
            <div class="question-container">
                <h3>5. ¿Cómo mando dinero a mi meta financiera?</h3>
                <img class="arrow" src="assets/images/icons/arrow_down.svg" alt="arrow-down"
                    (click)="selectQuestion(5)">
            </div>
            <div class="question-answer mt-20" *ngIf="selectedQuestion === 5">
                <p>En la sección de metas, se debe elegir una meta y dar click en el botón "Agregar dinero".
                    Posteriormente elige el monto a enviar a dicha cuenta y finaliza el proceso al dar click en "Guardar
                    meta".</p>
            </div>
            <div class="divider"></div>
            <div class="question-container">
                <h3>6. ¿Cómo se si mi hijo cumplió las tareas asignadas?</h3>
                <img class="arrow" src="assets/images/icons/arrow_down.svg" alt="arrow-down"
                    (click)="selectQuestion(6)">
            </div>
            <div class="question-answer mt-20" *ngIf="selectedQuestion === 6">
                <p>Dentro tareas podrás ver el perfil de tus hijos, cada uno con sus tareas asignadas. En cada tarea
                    aparecerán 7 círculos representando los días de la semana; los que estén rellenos de verde será los
                    días que tu hijo cumplió las tareas.</p>
            </div>
        </div>

        <app-blog-list class="full-width"></app-blog-list>

        <app-newsletter class="full-width"></app-newsletter>

        <app-social-footer class="full-width"></app-social-footer>
    </div>
</div>