import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { UserCredentials } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    requestInProgress = false;
    private authenticationUrl = `${environment.apiUrl}/login`;

    constructor(private http: HttpClient) {}

    authenticateUser(userCredentials: UserCredentials): Promise<any> {
        // userCredentials.enrollment_id = userCredentials.enrollment_id.toLowerCase();

        if (!this.requestInProgress) {
            this.requestInProgress = true;
            return this.http
                .post<any>(this.authenticationUrl, userCredentials)
                .pipe(
                    tap(
                        () => {
                            this.requestInProgress = false;
                        },
                        () => {
                            this.requestInProgress = false;
                        }
                    )
                )
                .toPromise();
        }
    }
}
