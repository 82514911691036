<div *ngIf='open_login' id="pin-login">
    <div class="main-container">
        <img src="assets/images/login/complete_bk.svg" class="complete_bk">
        <h1 class="white mb-60">Ingresar contraseña</h1>
        <div class="pin-background-img">
            <input #pinInput type="password" class="pin-input" [(ngModel)]="pin" maxlength="4"
                (keydown.enter)="login()" />
        </div>
        <div class="pin-pad">
            <div class="pin-button" (click)="addToPin('0')">0</div>
            <div class="pin-button" (click)="addToPin('1')">1</div>
            <div class="pin-button" (click)="addToPin('2')">2</div>
            <div class="pin-button" (click)="addToPin('3')">3</div>
            <div class="pin-button" (click)="addToPin('4')">4</div>
            <div class="pin-button" (click)="addToPin('5')">5</div>
            <div class="pin-button" (click)="addToPin('6')">6</div>
            <div class="pin-button" (click)="addToPin('7')">7</div>
            <div class="pin-button" (click)="addToPin('8')">8</div>
            <div class="pin-button" (click)="addToPin('9')">9</div>
            <div class="pin-button" (click)="removeFromPin()"><mat-icon class="delete-icon">close</mat-icon></div>
        </div>
        <button class="white mt-70" (click)="login()">Entrar</button>
        <a class="white mt-15" (click)="closeLogin()">cancelar</a>
    </div>
</div>

<div id="users" *ngIf="!open_login">
    <img src="assets/images/login/complete_bk.svg" class="complete_bk">
    <div class="header">
        <img src="assets/images/login/Inverkids_logo_white.svg" class="logo">
        <p class="teacher-id">{{ group_name ? group_name + ' | ' : '' }}{{ teacher_enrollment_id }}</p>
    </div>

    <div class="scroll-container">
        <div class="users-container" [ngClass]="{'large': users.length > 2}">
            <div class="cards-container mt-50" [ngClass]="{'small-container': users.length > 2}">
                <div class="large-card mb-50" *ngFor="let user of users" (click)="setUser(user)"
                    [ngClass]="{'small-large-card': users.length > 2}">
                    <img class="avatar" src="{{assetsUrl}}assets/images/avatars/{{user.avatar}}_sombra.svg" alt="avatar">
                    <h2 class="user-name">{{ user.name }}</h2>
                </div>
            </div>
        </div>
    </div>
</div>