import { Component, OnInit} from "@angular/core";
import * as anime from 'animejs/lib/anime.min';

import { HttpClient, HttpParams } from "@angular/common/http";
//import { environment } from "src/environments/environment";

@Component({
  selector: "app-section5",
  templateUrl: "./section5.component.html",
  styleUrls: ["./section5.component.scss"],
})
export class Section5Component implements OnInit{
  
  constructor(
  ) {
  }

  ngOnInit(): void {
      this.animarTarjetas();
    }
  
    animarTarjetas() {
      anime({
        targets: '.card',
        translateY: [
          { value: -10, duration: 1000 },
          { value: 0, duration: 1000 }
        ],
        loop: true,
        easing: 'easeInOutSine',
        delay: anime.stagger(200)
      });
    }
}
