<div id="product-detail">
    <div class="main-container">
        <div class="cart-container" (click)="goToCart()">
            <img class="cart clickable" src="assets/images/icons/carrito.svg" alt="carrito">
            <p class="caption ml-10 clickable">Mi carrito</p>
        </div>
        <div class="product-container">
            <img class="product-image" src="assets/store_files/{{products[productId].image}}" alt="product">
            <div class="product-details-container">
                <h2 class="title big">{{ products[productId].name }}</h2>
                <div class="price-container">
                    <h2>{{ products[productId].textPrice }}</h2>
                    <p class="green">Disponible</p>
                </div>
                <div class="divider"></div>
                <p class="quantity-title">Cantidad</p>
                <div class="quantity-buttons-container">
                    <input type="number" min="0" max="100" class="input-box quantity" [(ngModel)]="quantity">
                    <div class="buttons-container">
                        <button *ngIf="(products[productId].maxAmount == 0 || quantity <= products[productId].maxAmount) && (quantity > 0)" class="white border-blue large" (click)="addToCart()">Añadir al carrito</button>
                        <button *ngIf="((quantity > products[productId].maxAmount && products[productId].maxAmount > 0) || quantity <= 0)" disabled class="white border-blue large" (click)="addToCart()">Añadir al carrito</button>
                        <button *ngIf="products[productId].hasExtra" class="white border-blue large mt-15" (click)="selectProduct(products[productId].extraId)">{{products[productId].extraButtonPrompt}}</button>
                        <button *ngIf="(products[productId].maxAmount == 0 || quantity <= products[productId].maxAmount) && (quantity > 0)" class="tertiary large mt-15" (click)="payProduct()">Comprar ahora</button>
                        <button *ngIf="((quantity > products[productId].maxAmount && products[productId].maxAmount > 0) || quantity <= 0)" disabled class="tertiary large mt-15" (click)="payProduct()">Comprar ahora</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="details-container">
            <h2 class="details-title">Detalles</h2>
            <div class="divider"></div>
            <p class="detail mb-5" *ngFor="let description of products[productId].description">
                {{ description }}
            </p>
        </div>

        <app-social-footer class="full-width"></app-social-footer>
    </div>
</div>

<div itemscope itemtype="http://schema.org/Product">
    <meta itemprop="brand" content="Inverkids">
    <meta itemprop="name" [content]="products[productId].name">
    <meta itemprop="description" [content]="products[productId].category">
    <meta itemprop="productID" [content]="products[productId].id">
    <meta itemprop="url" content="https://inverkids.mx/tienda/producto/{{products[productId].id}}">
    <meta itemprop="image" content="https://inverkids.mx/{{products[productId].image}}">
    <meta itemprop="category" content="784">
    <div itemprop="value" itemscope itemtype="http://schema.org/PropertyValue">
        <span itemprop="name">{{productId}}</span>
        <meta itemprop="value" content="True">
    </div>
    <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
        <link itemprop="availability" href="http://schema.org/InStock">
        <link itemprop="itemCondition" href="http://schema.org/NewCondition">
        <meta itemprop="price" [content]="products[productId].price">
        <meta itemprop="priceCurrency" content="MXN">
    </div>
</div>