<div id="parent-registration">
    <form [formGroup]="parentForm" class="form-container light-blue-bk">
        <div class="step-container" *ngIf="step === 0">
            <h1 class="mb-60">¿Cuál es tu nombre completo?</h1>
            <input class="input-box" placeholder="Nombre y apellido" formControlName="name">
            <button (click)="step = 1" class="tertiary mt-70"
                [disabled]="!parentForm.get('name').value">Siguiente</button>
            <a class="mt-20" [routerLink]="['/register']">Atras</a>
        </div>

        <div class="step-container" *ngIf="step === 1">
            <h1 class="mb-60">¿Qué nombre o apodo te gustaría utilizar?</h1>
            <input class="input-box" placeholder="Apodo" formControlName="nickname">
            <button (click)="step = 2" class="tertiary mt-70"
                [disabled]="!parentForm.get('nickname').value">Siguiente</button>
            <a class="mt-20" (click)="step = 0">Atras</a>
        </div>

        <div class="step-container" *ngIf="step === 2">
            <h1 class="mb-60">¿Cuál es tu género?</h1>
            <div class="cards-container">
                <div class="small-card" [ngClass]="{'blue-border': parentForm.get('gender').value === 'Masculino'}"
                    (click)="setGender('Masculino')">
                    <img src="assets/images/icons/señor.svg" alt="hombre">
                </div>
                <div class="small-card" [ngClass]="{'blue-border': parentForm.get('gender').value === 'Femenino'}"
                    (click)="setGender('Femenino')">
                    <img src="assets/images/icons/señora.svg" alt="mujer">
                </div>
            </div>
            <button class="tertiary mt-70" [disabled]="!parentForm.get('gender').value"
                (click)="step = 3">Siguiente</button>
            <a class="mt-20" (click)="step = 1">Atras</a>
        </div>

        <div class="step-container" *ngIf="step === 3">
            <h1 class="mb-60">Elige tu avatar</h1>
            <div class="cards-container">
                <div class="small-card smaller" *ngFor="let color of colors"
                    [ngClass]="{ 'blue-border': parentForm.get('avatar').value === color }"
                    (click)="selectAvatar(color)">
                    <img src="{{assetsUrl}}assets/images/avatars/{{color}}.svg" alt="avatar-{{color}}">
                </div>
            </div>
            <button class="tertiary mt-60" [disabled]="!parentForm.get('avatar').value"
                (click)="step = 4">Siguiente</button>
            <a class="mt-20" (click)="step = 2">Atras</a>
        </div>

        <div class="step-container" *ngIf="step === 4">
            <h1 class="mb-60">¿Cuál es tu fecha de nacimiento?</h1>
            <mat-form-field class="date-picker">
                <input [max]="today" matInput [matDatepicker]="picker" formControlName="birthday">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <button class="tertiary mt-70" [disabled]="!parentForm.get('birthday').value"
                (click)="step = 5">Siguiente</button>
            <a class="mt-20" (click)="step = 3">Atras</a>
        </div>

        <div class="step-container" *ngIf="step === 5">
            <h1 class="mb-60">Ingresa tu correo y crea tu contraseña</h1>
            <input class="input-box mb-20" placeholder="Correo" type="email" formControlName="email">
            <input class="input-box mb-20" placeholder="Contraseña" type="password" formControlName="password">
            <input class="input-box" placeholder="Confirmar contraseña" type="password"
                formControlName="password_confirmation">
            <button class="tertiary mt-70" [disabled]="!isEmailAndPasswordValid()" (click)="step = 6">Siguiente</button>
            <a class="mt-20" (click)="step = 4">Atras</a>
        </div>

        <div class="step-container" *ngIf="step === 6">
            <h1 class="mb-60">Ingresa tu PIN de seguridad</h1>
            <input placeholder="PIN de segurdiad" type="password" class="input-box mb-20" formControlName="pin"
                maxlength="4">
            <input placeholder="Confirmar PIN" type="password" class="input-box" formControlName="pin_confirmation"
                maxlength="4">
            <button class="tertiary mt-70" [disabled]="!isPinValid()" (click)="submit()">Finalizar</button>
        </div>

        <div class="step-container" *ngIf="step === 7">
            <h1 class="mb-60">¡Cuenta creada exitosamente!</h1>
            <div class="small-card">
                <img src="{{assetsUrl}}assets/images/avatars/{{parentForm.get('avatar').value}}_sombra.svg" alt="user-avatar">
            </div>
            <button class="tertiary mt-60" [routerLink]="['/introduction']">Aceptar</button>
        </div>
    </form>
</div>