<div id="student-registration" class="light-blue-bk">
    <form [formGroup]="studentForm" class="form-container">
        <div class="step-container" *ngIf="step === 0">
            <h1 class="mb-60 mt-20">¿Cuál es tu nombre completo?</h1>
            <input class="input-box" placeholder="Nombre y apellido" formControlName="name">
            <button (click)="step = 1" class="tertiary mt-70"
                [disabled]="!studentForm.get('name').value">Siguiente</button>
            <a class="mt-20" [routerLink]="['/register']">Atras</a>
        </div>

        <div class="step-container" *ngIf="step === 1">
            <h1 class="mb-60 mt-20">¿Qué nombre o apodo te gustaría utilizar?</h1>
            <input class="input-box" placeholder="Apodo" formControlName="nickname">
            <button (click)="step = 2" class="tertiary mt-70"
                [disabled]="!studentForm.get('nickname').value">Siguiente</button>
            <a class="mt-20" (click)="step = 0">Atras</a>
        </div>

        <div class="step-container" *ngIf="step === 2">
            <h1 class="mb-60 mt-20">¿Cuál es tu género?</h1>
            <div class="cards-container">
                <div class="small-card" [ngClass]="{'blue-border': studentForm.get('gender').value === 'Masculino'}"
                    (click)="setGender('Masculino')">
                    <img src="assets/images/icons/niño.svg" alt="hombre">
                </div>
                <div class="small-card" [ngClass]="{'blue-border': studentForm.get('gender').value === 'Femenino'}"
                    (click)="setGender('Femenino')">
                    <img src="assets/images/icons/niña.svg" alt="mujer">
                </div>
            </div>
            <button class="tertiary mt-70" [disabled]="!studentForm.get('gender').value"
                (click)="step = 3">Siguiente</button>
            <a class="mt-20" (click)="step = 1">Atras</a>
        </div>

        <div class="step-container" *ngIf="step === 3">
            <h1 class="mb-60 mt-20">Elige tu avatar</h1>
            <div class="cards-container">
                <div class="small-card smaller" *ngFor="let color of colors"
                    [ngClass]="{ 'blue-border': studentForm.get('avatar').value === color }"
                    (click)="selectAvatar(color)">
                    <img src="{{assetsUrl}}assets/images/avatars/{{color}}.svg" alt="avatar-{{color}}">
                </div>
            </div>
            <button class="tertiary mt-60" [disabled]="!studentForm.get('avatar').value"
                (click)="step = 4">Siguiente</button>
            <a class="mt-20" (click)="step = 2">Atras</a>
        </div>

        <div class="step-container" *ngIf="step === 4">
            <h1 class="mb-60 mt-20">¿Cuál es tu fecha de nacimiento?</h1>
            <mat-form-field class="date-picker">
                <input [max]="today" matInput [matDatepicker]="picker" formControlName="birthday">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <button class="tertiary mt-70" [disabled]="!studentForm.get('birthday').value"
                (click)="step = 5">Siguiente</button>
            <a class="mt-20" (click)="step = 3">Atras</a>
        </div>

        <div class="step-container" *ngIf="step === 5">
            <h1 class="mb-60 mt-20">Ingresa tu correo y crea tu contraseña</h1>
            <input class="input-box mb-20" placeholder="Correo" type="email" formControlName="email">
            <input class="input-box mb-20" placeholder="Contraseña" type="password" formControlName="password">
            <input class="input-box" placeholder="Confirmar contraseña" type="password"
                formControlName="password_confirmation">
            <button class="tertiary mt-70" [disabled]="!isEmailAndPasswordValid()" (click)="step = 6">Siguiente</button>
            <a class="mt-20" (click)="step = 4">Atras</a>
        </div>

        <div class="step-container" *ngIf="step === 6">
            <h1 class="mb-60 mt-20">¿Tienes una cuenta de ahorro en el banco?</h1>
            <div class="cards-container">
                <div class="small-card" [ngClass]="{'blue-border': studentForm.get('savings_account').value === 'Si'}"
                    (click)="selectSavingsAccount('Si')">
                    <h2>Si</h2>
                </div>
                <div class="small-card" [ngClass]="{'blue-border': studentForm.get('savings_account').value === 'No'}"
                    (click)="selectSavingsAccount('No')">
                    <h2>No</h2>
                </div>
            </div>
            <button class="tertiary mt-60" [disabled]="!studentForm.get('savings_account').value"
                (click)="step = 7">Siguiente</button>
            <a class="mt-20" (click)="step = 5">Atras</a>
        </div>

        <div class="step-container" *ngIf="step === 7">
            <h1 class="mb-60 mt-20">¿Cuánto dinero tiene ahorrado?</h1>
            <input class="input-box" placeholder="Ahorros" currencyMask [options]="{align: 'left', precision: 0}"
                formControlName="earnings">
            <button class="tertiary mt-70" (click)="submit()">Finalizar</button>
            <a class="mt-20" (click)="step = 6">Atras</a>
        </div>

        <div class="step-container" *ngIf="step === 8">
            <h1 class="mb-60 mt-20">¡Cuenta creada exitosamente!</h1>
            <div class="small-card">
                <img src="{{assetsUrl}}assets/images/avatars/{{studentForm.get('avatar').value}}_sombra.svg" alt="user-avatar">
            </div>
            <button class="tertiary mt-60" [routerLink]="['/dashboard']">Aceptar</button>
        </div>
    </form>
</div>