<div id="cart">
    <div class="main-container light-blue-bk">
        <div class="cart-container">
            <h2 class="cart-title big">Carrito de compras</h2>
            <div class="divider"></div>
            <div class="cart-items-web">
                <div class="cart-item" *ngIf="cart.length === 0">
                    <h2 class="gray no-items">No tienes artículos en tu carrito.</h2>
                </div>

                <div *ngFor="let cartItem of cart">
                    <div class="cart-item">
                        <div class="left-container">
                            <img class="item-image" src="assets/store_files/{{cartItem.product.image}}" alt="cart_item">
                            <div class="item-details">
                                <h3 class="big">{{cartItem.product.name}}</h3>
                                <div class="quantity-container">
                                    <p>Cantidad</p>
                                    <input type="number" class="input-box quantity" (ngModelChange)="priceChange()"
                                        [(ngModel)]="cartItem.quantity">
                                </div>
                            </div>
                        </div>

                        <div class="right-container">
                            <h3 class="big product-price">${{ cartItem.product.price * cartItem.quantity | number }} mxn
                            </h3>
                            <p class="blue clickable" (click)="deleteCartItem(cartItem)">Eliminar</p>
                        </div>
                    </div>

                    <div class="cart-item" *ngIf="cartItem.product.extraInformation && cartItem.product.extraInformation.length > 0">
                        <div style="width: 100%;">
                            <h3 class="big">Completa los siguientes datos:</h3>

                            <div *ngFor="let detailList of cartItem.product.details; let detailIndex = index"
                                style="display: flex; justify-content: space-around;">

                                <div *ngFor="let detail of detailList" style="margin-top: 25px;"
                                    [style.width.%]="detail.fieldSize">

                                    <div
                                        *ngIf="detail.isVisible && (detail.fieldType == 'text' || detail.fieldType == 'number')">
                                        <p class="medium" style="margin-bottom: 10px;">{{loadFieldName(detail.fieldDisplay, detailIndex + 1)}}</p>
                                        <input class="input-box quantity" style="height: 40px; font-size: 18px;"
                                            type="detail.fieldType" [(ngModel)]="detail.value"
                                            [placeholder]="loadFieldName(detail.fieldPlaceholder, detailIndex + 1)">
                                    </div>

                                    <div *ngIf="detail.isVisible && (detail.fieldType == 'enum')">
                                        <p class="medium" style="margin-bottom: 10px; text-align: center;">{{loadFieldName(detail.fieldDisplay, detailIndex + 1)}}</p>
                                        <mat-select [(ngModel)]="detail.value" class="dropdown">
                                            <mat-option class="option" *ngFor="let option of detail.fieldOptions" value="{{option}}">
                                                {{ option }}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cart-items-mobile">
                <div class="cart-item" *ngIf="cart.length === 0">
                    <h2 class="gray no-items">No tienes artículos en tu carrito.</h2>
                </div>

                <div class="cart-item" *ngFor="let cartItem of cart">
                    <div class="top-container">
                        <img class="item-image" src="assets/store_files/{{cartItem.product.image}}" alt="cart_item">
                        <div class="item-name-price">
                            <h2 class="mt-10">{{ cartItem.product.name }}</h2>
                            <h2 class="product-price mt-20">${{ cartItem.product.price * cartItem.quantity | number }}
                                mxn</h2>
                        </div>
                    </div>
                    <div class="button-container">
                        <p>Cantidad</p>
                        <input type="number" class="input-box quantity" (ngModelChange)="priceChange()"
                            [(ngModel)]="cartItem.quantity">
                        <p class="blue clickable" (click)="deleteCartItem(cartItem)">Eliminar</p>
                    </div>
                    <div class="cart-item" *ngIf="cartItem.product.extraInformation && cartItem.product.extraInformation.length > 0">
                        <div style="width: 100%;">
                            <h3 class="big">Completa los siguientes datos:</h3>

                            <div *ngFor="let detailList of cartItem.product.details; let detailIndex = index"
                                style="display: flex; justify-content: space-around; flex-direction: column;">

                                <div *ngFor="let detail of detailList" style="margin-top: 25px; min-width: 5rem;">

                                    <div
                                        *ngIf="detail.isVisible && (detail.fieldType == 'text' || detail.fieldType == 'number')">
                                        <p class="medium" style="margin-bottom: 10px;">{{loadFieldName(detail.fieldDisplay, detailIndex + 1)}}</p>
                                        <input class="input-box quantity" style="height: 40px; font-size: 18px; width:100% !important;"
                                            type="detail.fieldType" [(ngModel)]="detail.value"
                                            [placeholder]="loadFieldName(detail.fieldPlaceholder, detailIndex + 1)">
                                    </div>

                                    <div *ngIf="detail.isVisible && (detail.fieldType == 'enum')">
                                        <p class="medium" style="margin-bottom: 10px; text-align: center;">{{loadFieldName(detail.fieldDisplay, detailIndex + 1)}}</p>
                                        <mat-select [(ngModel)]="detail.value" class="dropdown">
                                            <mat-option class="option" *ngFor="let option of detail.fieldOptions" value="{{option}}">
                                                {{ option }}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="cart-container-mobile">

        </div>

        <div class="cart-checkout">
            <h3 class="cart-checkout-title big">Subtotal ${{ total | number }} mxn</h3>
            <button *ngIf="!canPay()" disabled class="tertiary large checkout-btn">Finalizar
                pedido</button>
            <button *ngIf="canPay()" class="tertiary large checkout-btn" (click)="goToCheckout()">Finalizar
                pedido</button>
        </div>
    </div>
</div>