<div class="app-login">
    <div class="cross-container" (click)="close()" >
        <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" y="26.7487" width="35" height="2" rx="1" transform="rotate(-45 2 26.7487)" fill="currentColor"/>
            <rect x="3.41418" y="2" width="35" height="2" rx="1" transform="rotate(45 3.41418 2)" fill="currentColor"/>
        </svg>                    
    </div>
    <img src="assets/images/login/complete_bk.svg" class="complete_bk">
    <div class="form-container" *ngIf="!inside">
        <img src="assets/images/login/Inverkids_logo_white.svg" class="logo">
        <input class="code-input theme-text" placeholder="{{selected_lang == 'es'?'Codigo':'Code'}}" (change)="setCode($event)">
        <button class="enter-button subtitle" (click)="enter()">{{selected_lang == 'es'? 'Entrar':'Enter'}}</button>
        <!-- <p class="register-white-text">{{selected_lang == 'es'? 'Registrarme':'Register'}}</p> -->
    </div>

    <div class="users" *ngIf="inside">
        <div class="large-card mb-50" *ngFor="let user of users" (click)="login(user)"
            [ngClass]="{'small-large-card': users.length > 2}">
            <img class="avatar" src="{{assetsUrl}}assets/images/avatars/{{user.avatar}}.svg" alt="avatar">
            <h2 class="user-name">{{ user.name }}</h2>
        </div>
    </div>
</div>