<div id="blog-template">
    <div class="article-title">
        <h1>{{blog.title}}</h1>
    </div>

    <div class="article-image">
        <img [src]="blog.image">
    </div>

    <div class="article-content" [innerHTML]="blog.content"></div>

    <div class="go-back-share">
        <div class="go-back">
            <p class="blue" (click)="goBack()">Regresar</p>
        </div>
        <div class="share">
            <!-- <share-buttons style="position: relative; left: 10px;" [theme]="'default'"
                [include]="['facebook','twitter','whatsapp']" [show]="3" [showIcon]="false" [showText]="true"
                url="https://dev.inverkids.mx" [title]="'Hola Todos'" [description]="'Blog Inverkids'"
                [image]="'https://dev.inverkids.mx/assets/images/icons/Inverkids_social.png'"
                [tags]="'Inverkids, Finanzas, Educación Financiera'">
            </share-buttons> -->
        </div>
    </div>
</div>

<footer>
    <p class="legend">© 2020. Inverkids.</p>

    <div id="social-media">
        <p>Siguenos en:</p>

        <a href="https://www.facebook.com/InverkidsMX/" target="_blank" aria-label="facebook page">
            <img class="social-icon" src="assets/images/homepage/facebook.svg" alt="facebook">
        </a>

        <a href="https://www.instagram.com/inverkids" target="_blank" aria-label="instagram page">
            <img class="social-icon" src="assets/images/homepage/instagram.svg" alt="instagram">
        </a>
    </div>
</footer>