<div id="main-container">
  <div class="sidebar"
    *ngIf="!(router.url.includes('/users') || ['Teacher', 'Coordinator', 'Director'].includes(userType) || router.url.includes('/reader')) && userType">
    <div class="ik-logo" *ngIf="userType === 'Parent'" [routerLink]="['/dashboard']">
      <img class="logo mt-30" src="assets/images/menu/logo.svg" alt="logo">
    </div>
    <div class="sidebar-items pb-30" *ngIf="userType === 'Parent'" [ngClass]="{'center-icons': accountType === 'Free' && userType !== 'Parent'}">
      <ng-container *ngIf="userType === 'Parent'">
        <div class="sidebar-item" [routerLink]="['/dashboard']">
          <img class="icon" src="assets/images/menu/home.svg" alt="home">
          <p class="small">{{ content[1][userLang] }}</p>
        </div>
        <div class="sidebar-item" [routerLink]="['/school/grades']">
          <img class="icon" src="assets/images/icons/calificaciones.svg" alt="cuentas">
          <p class="small">{{ content[15][userLang] }}</p>
        </div>
        <div class="sidebar-item" [routerLink]="['/chores']">
          <img class="icon" src="assets/images/menu/tareas.svg" alt="tareas">
          <p class="small">{{ content[2][userLang] }}</p>
        </div>
        <div class="sidebar-item" [routerLink]="['/allowance']">
          <img class="icon" src="assets/images/menu/domingo.svg" alt="domingo">
          <p class="small">{{ content[3][userLang] }}</p>
        </div>
        <div class="sidebar-item" [routerLink]="['/goals']">
          <img class="icon" src="assets/images/menu/metas.svg" alt="metas">
          <p class="small">{{ content[4][userLang] }}</p>
        </div>
        <div class="sidebar-item" [routerLink]="['/accounts']">
          <img class="icon" src="assets/images/menu/cuentas.svg" alt="cuentas">
          <p class="small">{{ content[5][userLang] }}</p>
        </div>
        <div class="sidebar-item" [routerLink]="['/extras']">
          <img class="icon" src="assets/images/menu/extras.svg" alt="extras">
          <p class="small">{{ content[6][userLang] }}</p>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="main" [ngClass]="{'no-sidebar': (router.url.includes('/users') || ['Teacher', 'Coordinator', 'Director', 'Student'].includes(userType))}">
    <router-outlet></router-outlet>
  </div>
  <!-- <div class="no-display">
    <img class="phones" src="assets/images/homepage/phones.png" alt="phones">

    <h1 class="mt-40">¡Ups!</h1>

    <h2 class="description mt-20">{{ content[14][userLang] }}</h2>

    <a href="https://play.google.com/store/apps/details?id=inverkids.app" target="_blank">
      <img class="store mt-40 clickeable" src="assets/images/homepage/google_store.png" alt="google">
    </a>

    <a href="https://apps.apple.com/us/app/inverkids/id1505098586?ls=1" target="_blank">
      <img class="store mt-10 clickeable" src="assets/images/homepage/apple_store.png" alt="apple">
    </a>
  </div> -->
</div>
