    <div class="container">
        <h1><span class="normal-text">Todo listo para que </span>
            <span class="bold">enseñen y disfruten</span></h1>
        <div class="cards">
            <div class="card" style="z-index: 3;">
                <div class="card blue">
                    <img class="img1" src="assets/images/homepage/section5_card1.svg" alt="Clase de 40 minutos">
                </div>
            </div>
            <div class="card" style="z-index: 2;">
                <div class="card green">
                    <img class="img2" src="assets/images/homepage/section5_card2.svg" alt="Calificaciones automáticas">
                </div>
            </div>
            <div class="card">
                <div class="card purple">
                    <img class="img3" src="assets/images/homepage/section5_card3.svg" alt="Actividades didácticas">
                </div>
            </div>
            <div class="card">
                <div class="card orange">
                    <img class="img4" src="assets/images/homepage/section5_card4.svg" alt="Gamificación">
                </div>
            </div>
        </div>
    </div>

