<div id="school-games">
  <div class="main-container  light-blue-bk">
      <h1 class="dark-blue mb-40 mt-40">Descargas</h1>

      <div class="cards-container mb-40">
          <div class="game" *ngFor="let file of files; let index = index"  (click)="downloadPdf(index)">
              <img class="game-img"
                  src="assets/public_files/{{file.image_name}}.svg" alt="game">
          </div>
      </div>
  </div>
</div>