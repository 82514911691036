import { Router } from "@angular/router";
import { Component, Inject } from "@angular/core";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { environment } from "../../../environments/environment";
import { run_process } from "../../shared/utils/progressive_offline_utils";

import { Subscription } from "rxjs";
// import { AuthService, FacebookLoginProvider, SocialUser, GoogleLoginProvider } from 'angularx-social-login';

import { ResetPasswordService } from "../reset-password/reset-password.service";
import { UserProfileService } from "../../shared/services/userProfile.service";
import { AuthenticationService } from "../../shared/services/authentication.service";
import { UserCredentials } from "../../shared/models/user.model";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  tabSelected = "Login";
  subtabSelected: string;
  enrollmentId: string;
  password: string;
  enrollmentIdError = false;
  passwordError = false;
  userCredentials = new UserCredentials();
  socialSubscription: Subscription;
  // user: SocialUser;
  username: string;
  error: string;
  success: string;
  dialogRef;
  formSending = false;

  constructor(
    private userProfileService: UserProfileService,
    private router: Router,
    private authService: AuthenticationService,
    // private socialAuthService: AuthService,
    private dialog: MatDialog,
    private loginPopup: MatDialogRef<LoginComponent>,
    private resetPasswordService: ResetPasswordService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.register) {
      this.tabSelected = "Register";
    }
  }

  goToRegister(): void {
    this.dialog.closeAll();
    this.router.navigate(["/register"]);
  }

  goToLobby(): void {
    this.dialog.closeAll();
    this.router.navigate(["/app-lobby"]);
  }

  login(): void {
    const botonIniciarSesion = <HTMLButtonElement>(
      document.getElementById("botonIniciarSesion")
    );
    const _enrollmentId = (<HTMLInputElement>(
      document.getElementById("enrollmentId")
    )).value.trim();
    const _password = (<HTMLInputElement>(
      document.getElementById("password")
    )).value.trim();
    this.enrollmentIdError = false;
    this.passwordError = false;

    if (_enrollmentId === "") {
      this.enrollmentIdError = true;
    }

    if (_password === "") {
      this.passwordError = true;
    }
    if (this.password && this.enrollmentId) {
      botonIniciarSesion.innerHTML =
        '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="20px" width="20px" style="animation: rotate 2s infinite linear;" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.917 7A6.002 6.002 0 0 0 2.083 7H1.071a7.002 7.002 0 0 1 13.858 0h-1.012z"></path></svg>';
      botonIniciarSesion.style.opacity = "0.5";
      //console.log(this.userCredentials)
      this.userCredentials.enrollment_id = this.enrollmentId.trim();
      this.userCredentials.password = this.password.trim();
      // console.log(this.userCredentials)
      this.authenticateUser();
    }
  }

  authenticateUser(): void {
    const botonIniciarSesion = <HTMLButtonElement>(
      document.getElementById("botonIniciarSesion")
    );
    this.authService.authenticateUser(this.userCredentials).then(
      async (user) => {
        // here goes get catalog syncing
        if (navigator.onLine) {
          this.cargarCatalogoDesdeAPI();
          this.downloadGradesProgress();
        }

        this.userProfileService.setUserType(user.user_type);
        this.userProfileService.setUserRole(user.role);
        this.userProfileService.setUserId(user.id);
        this.userProfileService.setAuthToken(user.auth_token);
        this.userProfileService.setAccountType(user.account_type);
        this.userProfileService.removeParentToken();
        await this.userProfileService.getUserProfile();
        console.log(this.userProfileService.getLang());
        this.loginPopup.close();
        if (user.user_type === "Parent") {
          this.userProfileService.setParentToken(user.auth_token);
          this.router.navigate(["/users"]);
        } else if (
          ["Teacher", "Coordinator", "Director"].includes(user.user_type)
        ) {
          this.router.navigate(["/school"]);
        } else {
          this.router.navigate(["/dashboard"]);
        }
      },
      (error) => {
        if (error.status === 400 || error.status === 404) {
          this.error = error.error;
          botonIniciarSesion.innerHTML = "Iniciar Sesión";
          botonIniciarSesion.style.opacity = "1";
        }
      }
    );
  }

  async cargarCatalogoDesdeAPI() {
    if("serviceWorker" in navigator){
      try {
        console.log("Simulando carga de catalogo desde api");
        // QUESTION: deberiamos de gestionar la llamada a la api normal desde aqui o dejarlo tal cual esta y solo gestionar la llamada de SW?
        const registration: any = await navigator.serviceWorker.ready;
        try {
          console.log("Background Sync registered!");
          registration.active?.postMessage({
            type: "ENVIRONMENT",
            environment: environment,
            authToken: this.userProfileService.getAuthToken(),
            user_id: this.userProfileService.getUserId(),
            label: this.userProfileService.getWhiteLabel()
          });
          // await registration.sync.register("update-catalogo");
          registration.active?.postMessage({
            type: "update-catalog"
          });
        } catch (error){
          console.log("Background Sync could not be registered!", error);
        }
      } catch (error) {
        console.error("Error al cargar el catalogo desde la API:", error);
      }
    }else{
      console.log("Simulando carga de catalogo desde api, sin SW");
      run_process({
        data: {
          type: "ENVIRONMENT",
          environment: environment,
          authToken: this.userProfileService.getAuthToken(),
          user_id: this.userProfileService.getUserId(),
          label: this.userProfileService.getWhiteLabel()
        }
      })
      run_process({
        data: {
          type: "update-catalog"
        }
      })
    }
  }

  async downloadGradesProgress() {
    try {
      console.log("Simulando carga de grades progress desde api");
      // QUESTION: deberiamos de gestionar la llamada a la api normal desde aqui o dejarlo tal cual esta y solo gestionar la llamada de SW?
      const registration: any = await navigator.serviceWorker.ready;
      try {
        console.log("Background Sync registered!");
        registration.active?.postMessage({
          type: "ENVIRONMENT",
          environment: environment,
          authToken: this.userProfileService.getAuthToken(),
          user_id: this.userProfileService.getUserId(),
          label: this.userProfileService.getWhiteLabel()
        });
        // await registration.sync.register("update-grades-progress");
        registration.active?.postMessage({
          type: "update-grades-progress"
        });
      } catch(error) {
        console.log("Background Sync could not be registered!", error);
      }
    } catch (error) {
      console.error("Error al cargar el grades progress desde la API:", error);
    }
  }

  submitReset(): void {
    this.formSending = true;
    if (this.username.trim()) {
      this.resetPasswordService
        .resetPasswordMail(this.username.toLowerCase().trim())
        .then(
          (data) => {
            this.success =
              "Por favor revisa tu correo, hemos mandado las instrucciones para cambiar tu contraseña.";
            this.error = undefined;
          },
          (error) => {
            this.error = "Algo salió mal, por favor contacta a soporte";
            this.success = undefined;
          }
        );
    } else {
      this.error = "El usuario es obligatorio";
    }
  }
  ngOnInit(): void {
  }
  // ngOnInit(): void {
  //     this.socialSubscription = this.socialAuthService.authState.subscribe(user => {
  //         this.user = user;
  //         if (this.user && this.user.provider === 'FACEBOOK') {
  //             this.userCredentials.facebook_id = this.user.id;
  //         } else if (this.user && this.user.provider === 'GOOGLE') {
  //             this.userCredentials.google_id = this.user.id;
  //         }
  //     });
  // }

  // signInWithFB(): void {
  //     if (this.user && this.userCredentials.facebook_id) {
  //         this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  //         this.userCredentials.google_id = undefined;
  //         this.authenticateUser();
  //     } else {
  //         this.accountNotRegistred();
  //     }
  // }

  // signInWithGoogle(): void {
  //     if (this.user && this.userCredentials.google_id) {
  //         this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  //         this.userCredentials.facebook_id = undefined;
  //         this.authenticateUser();
  //     } else {
  //         this.accountNotRegistred();
  //     }
  // }

  // accountNotRegistred(): void {
  //     const options = {
  //         width: '400px',
  //         data: {
  //             title: 'Lo sentimos no pudimos encontrar tu cuenta.',
  //             subtitle: 'Te sugerimos ligar tu cuenta en tu perfil.',
  //             buttons: [
  //                 {
  //                     text: 'Ok',
  //                     action: () => this.dialogRef.close(),
  //                 },
  //             ],
  //         },
  //     };
  //     this.dialogRef = this.dialog.open(SharedPopupComponent, options);
  // }

  // ngOnDestroy(): void {
  //     if (this.socialSubscription) {
  //         this.socialSubscription.unsubscribe();
  //     }
  // }
}
