const CONTENT = {
  1: {
    en: "Let's Develop together the future.",
    es: "Desarrollemos juntos el futuro financiero de nuestros alumnos.",
  },
  2: {
    en: "Free Trial",
    es: "Prueba Gratis",
  },
  3: {
    en: "Teachers and Institutions",
    es: "Maestros e instituciones",
  },
  4: {
    en: "With Inverkids we develop the financial capacities of students in the whole of Latinamerica 100% online.",
    es:
      "Con Inverkids desarrollamos las capacidades financieras de alumnos de todo Latino América 100% en línea.",
  },
  5: {
    en: "Dynamic and simple academic content",
    es: "Contenido académico dinámico y sencillo",
  },
  6: {
    en: "Multiple activities in the form of challenges",
    es: "Múltiples actividades en forma de retos",
  },
  7: {
    en: "Games to reinforce their knowledge",
    es: "Juegos para reforzar sus conocimientos",
  },
  8: {
    en: "Completely customizable reports",
    es: "Informes totalmente personalizados",
  },
  9: {
    en: "Multiple gamification elements",
    es: "Múltiples elementos de gamificación",
  },
  10: {
    en: "Impulse their achievements",
    es: "Impulsa sus logros",
  },
  11: {
    en: "Develop their financial sense.",
    es: "Desarrollo de su criterio financiero.",
  },
  12: {
    en: "Increase their savings.",
    es: "Incremento de sus ahorros.",
  },
  13: {
    en: "Mental agility in mathematics.",
    es: "Agilidad mental en matemáticas.",
  },
  14: {
    en: "Achieving financial goals.",
    es: "Cumplimiento de metas financieras.",
  },
  15: {
    en: "With Inverkids learning finance is fun.",
    es: "Con Inverkids aprender finanzas es divertido.",
  },
  16: {
    en: "Register me",
    es: "Registrarme",
  },
  17: {
    en: "Elementary",
    es: "Primaria",
  },
  18: {
    en: "Books | Games | Activities",
    es: "Cuentos | Juegos | Actividades",
  },
  19: {
    en: "Middle School",
    es: "Secundaria",
  },
  20: {
    en: "Modules | Challenges | Games",
    es: "Módulos | Retos | Juegos",
  },
  21: {
    en: "Special Events",
    es: "Eventos especiales",
  },
  22: {
    en: "Finance Fair",
    es: "Finance Fair",
  },
  23: {
    en: "Become a financial expert investigatins and presenting your interests in monetary terms.",
    es:
      "Conviértete en experto financiero investigando y exponiendo tus intereses en términos monetarios.",
  },
  24: {
    en: "Place your finance in play against your friends and learn about investment, credits and contingencies of life.",
    es:
      "Pon tus finanzas en juego contra tus amigos y aprende sobre inversiones, créditos y los imprevistos de la vida.",
  },
  25: {
    en: "Simulators",
    es: "Simuladores",
  },
  26: {
    en: "There is no age for investment. The time has come to stop fearing them! Invest while learning.",
    es:
      "No hay edad para las inversiones. ¡Es momento de que les pierdan el miedo! Invierte mientras aprendes.",
  },
  27: {
    en: "More than 5,000 students share part in our journey.",
    es: "Más de 5,000 estudiantes son parte de nuestra historia.",
  },
  28: {
    en: "Inverkids in your school",
    es: "Inverkids en tu colegio",
  },
  29: {
    en: "They already trust us",
    es: "Ellos ya confían en nosotros",
  },
};

export default CONTENT;