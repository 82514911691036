<div id="social-footer" class="light-blue-bk">
    <div class="divider"></div>
    <div class="inner-container">
        <p class="tm">© 2020. Inverkids. All Rights Reserved.</p>
        <div class="social-media-container">
            <a href="https://www.facebook.com/InverkidsMX/" aria-label="facebook page">
                <img src="assets/images/homepage/fb_logo.svg" alt="facebook">
            </a>

            <a href="https://www.instagram.com/inverkids" aria-label="instagram page">
                <img src="assets/images/homepage/insta_logo.svg" alt="instagram">
            </a>

            <a href="https://wa.me/5218131300539/?text=¡Hola! Me gustaría saber más sobre Inverkids." aria-label="whatsapp link">
                <img src="assets/images/homepage/whats_logo.svg" alt="whatsapp">
            </a>
        </div>
    </div>
</div>