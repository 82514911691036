<div id="teachers-program">
    <div class="main-container">
        <div class="section-1 light-blue-bk">
            <div class="inner-container">
                <div class="description-container">
                    <h1 class="title">{{ content[1][userLang] }}</h1>
                    <button class="download-btn tertiary mt-40" [routerLink]="['/register/school']">{{ content[2][userLang] }}</button>
                </div>
                <img class="hero-img" src="assets/images/homepage/teachers_hero.png" alt="tarjeta">
            </div>
        </div>

        <div class="section-2">
            <div class="circle-container">
                <img src="assets/images/homepage/green-blue-circle.svg" alt="img">
            </div>
            <div class="left-container">
                <h3 class="subtitle">{{ content[3][userLang] }}</h3>
                <p class="description mt-20">{{ content[4][userLang] }}</p>
            </div>
            <div class="right-container">
                <div class="row">
                    <img src="assets/images/homepage/parents_2_1.svg" alt="img">
                    <h3 class="subtitle">{{ content[5][userLang] }}</h3>
                </div>
                <div class="row">
                    <img src="assets/images/homepage/parents_2_2.svg" alt="img">
                    <h3 class="subtitle">{{ content[6][userLang] }}</h3>
                </div>
                <div class="row">
                    <img src="assets/images/homepage/parents_2_3.svg" alt="img">
                    <h3 class="subtitle">{{ content[7][userLang] }}</h3>
                </div>
                <div class="row">
                    <img src="assets/images/homepage/parents_2_4.svg" alt="img">
                    <h3 class="subtitle">{{ content[8][userLang] }}</h3>
                </div>
                <div class="row">
                    <img src="assets/images/homepage/parents_2_5.svg" alt="img">
                    <h3 class="subtitle">{{ content[9][userLang] }}</h3>
                </div>
            </div>
        </div>

        <div class="section-3 light-blue-bk">
            <h2>{{ content[10][userLang] }}</h2>
            <div class="cards-list">
                <div class="card">
                    <img src="assets/images/homepage/parents_3_1.svg" alt="img">
                    <p class="card-description">{{ content[11][userLang] }}</p>
                </div>
                <div class="card">
                    <img src="assets/images/homepage/parents_3_2.svg" alt="img">
                    <p class="card-description">{{ content[12][userLang] }}</p>
                </div>
                <div class="card">
                    <img src="assets/images/homepage/parents_3_3.svg" alt="img">
                    <p class="card-description">{{ content[13][userLang] }}</p>
                </div>
                <div class="card">
                    <img src="assets/images/homepage/parents_3_4.svg" alt="img">
                    <p class="card-description">{{ content[14][userLang] }}</p>
                </div>
            </div>
        </div>

        <div class="section-4">
            <div class="first-circle-container">
                <img src="assets/images/plans/first-circle.svg" alt="first-circle">
            </div>
            <h2 class="subtitle">{{ content[15][userLang] }}</h2>
            <button class="tertiary mt-40" [routerLink]="['/register/school']">{{ content[16][userLang] }}</button>
            <div class="second-circle-container">
                <img src="assets/images/plans/second-circle.svg" alt="second-circle">
            </div>
        </div>

        <div class="section-5 dark-blue-bk">
            <div class="cards-container">
                <div class="card">
                    <img src="assets/images/homepage/parents_5_1.svg" alt="img">
                    <h1 class="white mt-60">{{ content[17][userLang] }}</h1>
                    <p class="white mt-20">{{ content[18][userLang] }}</p>
                </div>
                <div class="card">
                    <img src="assets/images/homepage/parents_5_2.svg" alt="img">
                    <h1 class="white mt-60">{{ content[19][userLang] }}</h1>
                    <p class="white mt-20">{{ content[20][userLang] }}</p>
                </div>
            </div>
        </div>

        <div class="section-6">
            <div class="full-width">
                <h2 class="title">{{ content[21][userLang] }}</h2>
            </div>
            <div class="inner-container">
                <div class="info-container right">
                    <h2 class="subtitle mt-20">{{ content[22][userLang] }}</h2>
                    <p class="description mt-20">{{ content[23][userLang] }}</p>
                </div>
                <div class="image-container">
                    <div class="image-inner-container">
                        <img class="image" src="assets/images/homepage/parents_6_1.png" alt="img">
                    </div>
                </div>
            </div>
            <div class="inner-container reversed">
                <div class="image-container right">
                    <div class="image-inner-container">
                        <img class="image" src="assets/images/homepage/parents_6_2.png" alt="img">
                    </div>
                </div>
                <div class="info-container">
                    <div class="image-inner-container">
                        <h2 class="subtitle mt-20">Waiz & Rich</h2>
                        <p class="description mt-20">{{ content[24][userLang] }}</p>
                    </div>
                </div>
            </div>
            <div class="inner-container">
                <div class="info-container right">
                    <h2 class="subtitle mt-20">{{ content[25][userLang] }}</h2>
                    <p class="description mt-20">{{ content[26][userLang] }}</p>
                </div>
                <div class="image-container">
                    <div class="image-inner-container">
                        <img class="image" src="assets/images/homepage/parents_6_3.png" alt="img">
                    </div>
                </div>
            </div>
        </div>

        <div class="section-7 dark-blue-bk">
            <h2 class="title white">{{ content[27][userLang] }}</h2>
        </div>

        <div class="section-8">
            <img class="image" src="assets/images/homepage/parents_8_1.png" alt="img">
        </div>

        <div class="section-9 light-blue-bk">
            <h2>{{ content[28][userLang] }}</h2>
            <p class="caption mt-30">{{ content[29][userLang] }}</p>
            <div class="slider-container mb-100">
                <!-- <swiper [config]="first_slider">
                    <img class="slider-image" src="assets/images/homepage/parents_9_1.svg" alt="img">
                    <img class="slider-image" src="assets/images/homepage/parents_9_2.svg" alt="img">
                    <img class="slider-image" src="assets/images/homepage/parents_9_3.svg" alt="img">
                    <img class="slider-image" src="assets/images/homepage/parents_9_4.svg" alt="img">
                    <img class="slider-image" src="assets/images/homepage/parents_9_5.svg" alt="img">
                    <img class="slider-image" src="assets/images/homepage/parents_9_6.svg" alt="img">
                    <img class="slider-image" src="assets/images/homepage/parents_9_7.svg" alt="img">
                    <img class="slider-image" src="assets/images/homepage/parents_9_8.svg" alt="img">
                </swiper> -->
            </div>
        </div>

        <app-blog-list class="full-width"></app-blog-list>

        <app-newsletter class="full-width"></app-newsletter>

        <app-social-footer class="full-width"></app-social-footer>
    </div>
</div>