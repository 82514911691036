import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

export const MODULES_ROUTES: Routes = [
    {
        path: 'chores',
        loadChildren: () => import('./chores/chores.module').then((m) => m.ChoresModule),
    },
    {
        path: 'goals',
        loadChildren: () => import('./goals/goals.module').then((m) => m.GoalsModule),
    },
    {
        path: 'accounts',
        loadChildren: () => import('./accounts/accounts.module').then((m) => m.AccountsModule),
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    {
        path: 'finances',
        loadChildren: () => import('./finances/finances.module').then((m) => m.FinancesModule),
    },
    {
        path: 'users',
        loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
    },
    {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    },
    {
        path: 'profile/:tab',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    },
    {
        path: 'school',
        loadChildren: () => import('./school/school.module').then((m) => m.SchoolModule),
    },
    {
        path: 'cms',
        loadChildren: () => import('./cms/cms.module').then((m) => m.CMSModule),
    },
    {
        path: 'extras',
        loadChildren: () => import('./extras/extras.module').then((m) => m.ExtrasModule),
    },
    {
        path: 'allowance',
        loadChildren: () => import('./allowance/allowance.module').then((m) => m.AllowanceModule),
    },
    {
        path: 'expenses',
        loadChildren: () => import('./expenses/expenses.module').then((m) => m.ExpensesModule),
    },
    {
        path: 'courses',
        loadChildren: () => import('./courses/courses.module').then((m) => m.CoursesModule),
    },

  {
    path: "jsonEdit",
    loadChildren: () =>
      import("./jsonEdit/jsonEdit.module").then((m) => m.CoursesModule),
  },

  {
    path: "achievements",
    loadChildren: () =>
      import("./achievements/achievements.module").then(
        (m) => m.AchievementsModule
      ),
  },
  {
    path: "misc",
    loadChildren: () => import("./misc/misc.module").then((m) => m.MiscModule),
  },
  {
    path: "leaderboards",
    loadChildren: () =>
      import("./leaderboards/leaderboards.module").then(
        (m) => m.LeaderboardsModule
      ),
  },
  {
    path: "kpis",
    loadChildren: () => import("./kpis/kpis.module").then((m) => m.KpisModule),
  },
  {
    path: "store",
    loadChildren: () =>
      import("./store/store.module").then((m) => m.StoreModule),
  },
  {
    path: "reports",
    loadChildren: () =>
      import("./reporting/reporting.module").then((m) => m.ReportingModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(MODULES_ROUTES)],
  exports: [RouterModule],
})
export class ModulesRoutingModule {}
