<div id="register" class="light-blue-bk">
    <div id="account-type" *ngIf="step === 0">
        <h1 class="mb-60">¿Qué tipo de cuenta te gustaría crear?</h1>
        <div class="cards-container">
            <div class="small-card" [routerLink]="['parent']">
                <h2>Padres</h2>
            </div>
            <div class="small-card" [routerLink]="['student']">
                <h2>Jóvenes</h2>
            </div>
            <div class="small-card" [routerLink]="['school']">
                <h2>Escuelas</h2>
            </div>
        </div>
    </div>
</div>