import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OptionsNavbarService {
    private optionSelectedSubject = new BehaviorSubject(null);
    optionSelected = this.optionSelectedSubject.asObservable();
    private userChangeSubject = new BehaviorSubject(null);
    userChanged = this.userChangeSubject.asObservable();

    setOptionSelected(option: string): void {
        console.log("option-navbar: ", this)
        this.optionSelectedSubject.next(option);
    }

    setUser(): void {
        this.userChangeSubject.next(null);
    }
}
