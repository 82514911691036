import { Component, OnInit} from "@angular/core";

import { HttpClient, HttpParams } from "@angular/common/http";
//import { environment } from "src/environments/environment";

@Component({
  selector: "app-section2",
  templateUrl: "./section2.component.html",
  styleUrls: ["./section2.component.scss"],
})
export class Section2Component implements OnInit{
  
  constructor(
  ) {
  }

  ngOnInit(): void {
    
  }
}
