import { Component, OnInit, Input, OnDestroy, OnChanges } from "@angular/core";
import { Router } from "@angular/router";
import { UserProfileService } from "../../services/userProfile.service";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";
import { OptionsNavbarService } from "./option-navbar.service";
import { environment } from '../../../../environments/environment';

import CONTENT from "./global-header-v2.content.js";
@Component({
  selector: "app-global-header-v2",
  templateUrl: "./global-header-v2.component.html",
  styleUrls: ["./global-header-v2.component.scss"],
})
export class GlobalHeaderV2Component implements OnInit, OnDestroy, OnChanges {
  content = CONTENT;
  assetsUrl = environment.assetsUrl;

  @Input() user;
  @Input() user_name;
  @Input() user_streak;
  @Input() user_exp;
  @Input() user_points;

  // Left container
  @Input() goBackArrow;
  @Input() backRoute;
  @Input() logo;

  // Midle container
  @Input() navbarTitle;

  // Right container
  @Input() exitRoute;
  @Input() closeFunc;
  @Input() showProfileButton = true;
  @Input() tabMenu;

  // Styles
  @Input() textColor = '#14004b';
  @Input() arrowColor = '#14004b';
  @Input() crossColor = '#14004b';

  // Bottom navbar
  @Input() bottomNavbarOptions;


  //Old
  @Input() showDropdown;
  @Input() showChildsNavbar;
  @Input() childs;

  @Input() showCurrency;
  @Input() showHitpoints;
  @Input() showEarnings;

  @Input() dropDownColor;
  @Input() whiteCross;
  @Input() whiteArrow;
  @Input() backgroundColor;

  optionSelected: string;
  tabSelected: number = 0;
  optionSelectedSubscription: Subscription;
  userChangedSubscription: Subscription;
  parentToken;
  dropdownToggled;
  userLang;

  constructor(
    private location: Location,
    private router: Router,
    private userProfileService: UserProfileService,
    private optionsNavbarService: OptionsNavbarService
  ) {}

  ngOnInit(): void {
    console.log("user: ", this.user);
    this.userLang = this.userProfileService.getLang();
    this.optionSelectedSubscription = this.optionsNavbarService.optionSelected.subscribe(
      (option) => {
        this.optionSelected = option;
      }
    );
  }

  ngOnChanges(changes) {
  }

  getUser(): void {
    this.userProfileService
      .getUserProfile()
      .then((data) => {
        this.user = data;
        this.parentToken = this.userProfileService.getParentToken();
      })
      .catch((error) => console.log(error));
  }

  goBack(route?): void {
    if (route) {
      this.router.navigate(route);
    } else {
      if(this.backRoute != null){
        this.router.navigate(this.backRoute);
      }else{
        this.location.back();
      }
    }
  }

  goTo(route): void {
    if (route === "back") {
      window.history.go(-1);
    } else {
      this.router.navigate(route);
    }
  }

  runCloseFunc() {
    console.log("close func: ", this.closeFunc)
    this.closeFunc()
  }

  logout(): void {
    this.userProfileService.removeAccountType();
    this.userProfileService.removeAuthToken();
    this.userProfileService.removeParentToken();
    this.userProfileService.removeUserType();
    this.router.navigate(["/"]);
  }

  selectOption(option: string) {
    this.optionsNavbarService.setOptionSelected(option);
  }

  ngOnDestroy(): void {
    if (this.optionSelectedSubscription) {
      this.optionSelectedSubscription.unsubscribe();
    }

    if (this.userChangedSubscription) {
      this.userChangedSubscription.unsubscribe();
    }
  }

  shortName(name) {
    console.log(name);
    const split_name = name.split(" ");
    return split_name[0];
  }

  selectTab(index) {
    this.tabSelected = index;
    this.tabMenu[index].func();
  }
}
