import { Component, OnInit } from '@angular/core';
// import { UserProfileService } from '../../../shared/services/userProfile.service';
import { SCHOOL_GAMES } from './school-games.data';

@Component({
    selector: 'app-games',
    templateUrl: './games.component.html',
    styleUrls: ['./games.component.scss'],
})
export class GamesComponent implements OnInit {
    user;
    games = SCHOOL_GAMES;

    constructor() { }

    ngOnInit() {

    }
}
