<div id="school-registration" class="light-blue-bk">
    <div class="main-container">
        <div class="left-container">
            <h2 class="mb-60 title"> Únete al programa de finanzas más divertido </h2>
            <img class="regist-img" src="assets/images/homepage/school_registration_hero.png">
        </div>

        <div class="right-container">
            <form [formGroup]="schoolForm" class="compForm">
                <h3 class="subtitle">Información de contacto</h3>
                <input class="input-box thin-input roboto mb-10" placeholder="Nombre de la escuela"
                    formControlName="school">
                <input class="input-box thin-input roboto mb-10" placeholder="Nombre del contacto"
                    formControlName="name">
                <input class="input-box thin-input roboto mb-10" placeholder="Correo del contacto"
                    formControlName="email">
                <input class="input-box thin-input roboto mb-10" placeholder="Teléfono del contacto"
                    formControlName="phone">
                <input class="input-box thin-input roboto mb-10" placeholder="Grados escolares"
                    formControlName="grades">
                <button (click)="submit()" class="btn tertiary mt-40">Enviar</button>
            </form>
        </div>
    </div>
</div>