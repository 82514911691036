import { Component, OnInit} from "@angular/core";
import * as anime from 'animejs/lib/anime.min';

import { HttpClient, HttpParams } from "@angular/common/http";
//import { environment } from "src/environments/environment";

@Component({
  selector: "app-section4",
  templateUrl: "./section4.component.html",
  styleUrls: ["./section4.component.scss"],
})
export class Section4Component implements OnInit{
  
  constructor(
  ) {
  }

  ngOnInit(): void {
  }
}
