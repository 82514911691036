import { Component, OnInit } from "@angular/core";
import '@lottiefiles/dotlottie-wc';
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-section1",
  templateUrl: "./section1.component.html",
  styleUrls: ["./section1.component.scss"],
})
export class Section1Component implements OnInit {
  
  assetsUrl = environment.assetsUrl;
  constructor(
  ) {
   
  }

  ngOnInit(): void {

  }
}
