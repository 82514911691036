<section class="hero">
    <div class="hero-content">
      <h1>CREEMOS EN <br>EL PODER DEL <br>CONOCIMIENTO<br>FINANCIERO</h1>
      <div class="lottie"><dotlottie-wc src="https://assets.inverkids.mx/assets/lottie_animations/lobby2.lottie" 
      background="transparent" 
      speed="0.8" 
      style="width: 152vw;
      height: 77vw;
      z-index: 1;
      left: -21%;
      top: -8%;
      position: absolute;"
      direction="1" 
      playMode="forward" 
      loop controls autoplay></dotlottie-wc></div>
      <div >
        <img class="img1" src="assets/images/homepage/section1_rayito.svg" alt="rayito" />
      </div>
    </div>
    <div style="position: relative;">
        <img class="img2" src="assets/images/homepage/section1_bluewaves.svg" alt="Ola" />
      </div>
</section>

  <section class="recognition">
    <div style="position: absolute;">
        <img class="orangecircle" src="assets/images/homepage/section1_orangebluecirlce.svg" alt="circulo" />
      </div>
    <div class="recognition-content">
      <div class="award-image">
        <img src="assets/images/homepage/section1_colibri.png" alt="Premio" />
      </div>
      <div class="award-text">
        <h2>
          Nos <span class="enorgullece">enorgullece</span> ser<br>
          reconocidos por<br>
          nuestra labor en la<br>
          <strong>educación financiera</strong>
        </h2>
        <a href="#" class="btn">Ver premios</a>
      </div>
    </div>
  </section>

  <section class="team">
    <h2>
      <span class="normal-text">Conoce</span> 
      <span class="bold"> al mejor team</span>
    </h2>
    <div class="team-grid">
        <div class="team-row team-row-1">
            <div class="member">
                <img src="assets/images/homepage/section2_mariana.svg" alt="Mariana Garza">
                <h3>Mariana Garza</h3>
                <p>CEO</p>
            </div>
            <div class="member">
                <img src="assets/images/homepage/section2_alex.svg" alt="Alexandro Guajardo">
                <h3>Alexandro Guajardo</h3>
                <p>Co-founder & CTO</p>
            </div>
        </div>

        <div class="team-row team-row-2">
            <div class="member">
                <img src="assets/images/homepage/section2_dannie.svg" alt="Daniela Aguirre">
                <h3>Daniela Aguirre</h3>
                <p>Designer Leader</p>
            </div>
            <div class="member">
                <img src="assets/images/homepage/section2_iris.svg" alt="Iris Alcorta">
                <h3>Iris Alcorta</h3>
                <p>Comercial Leader</p>
            </div>
            <div class="member">
                <img src="assets/images/homepage/section2_fabi.svg" alt="Fabiola Rodríguez">
                <h3>Fabiola Rodríguez</h3>
                <p>Education Leader</p>
            </div>
        </div>

        <div class="team-row team-row-3">
            <div class="member">
                <img src="assets/images/homepage/section2_melissa.svg" alt="Mely Saldaña">
                <h4>Mely Saldaña</h4>
                <p>Experimental <br>Learning Designer</p>
            </div>
            <div class="member">
                <img src="assets/images/homepage/section2_sebastian.svg" alt="Sebastián Vargas">
                <h4>Sebastián Vargas</h4>
                <p>Jr. Developer</p>
            </div>
            <div class="member">
                <img src="assets/images/homepage/section2_saul.svg" alt="Saúl Villalobos">
                <h4>Saúl Villalobos</h4>
                <p>Animator</p>
            </div>
            <div class="member">
                <img src="assets/images/homepage/section2_vicente.svg" alt="Vicente Contreras">
                <h4>Vicente Contreras</h4>
                <p>Games Developer</p>
            </div>
        </div>

        <div class="team-row team-row-4">
            <div class="member">
                <img src="assets/images/homepage/section2_jhon.svg" alt="Jhon Ramírez">
                <h3>Jhon Ramírez</h3>
                <p>Colombia CEO</p>
            </div>
            <div class="member">
                <img src="assets/images/homepage/section2_diego.svg" alt="Diego Montilla">
                <h3>Diego Montilla</h3>
                <p>Colombia CFO</p>
            </div>
        </div>
    </div>
</section>

<section class="work">
  <h1 style="font-family: Roboto">Nos encanta trabajar para ti</h1>
  <div class="work-container">
    <div class="work-carousel" (click)="prevCarousel()">
      <img class="arrow" src="assets/images/icons/arrow_down.svg" alt="arrow-down">
    </div>
    <div class="work-images">
      <img
        *ngFor="let img of allImages; let i = index"
        [ngStyle]="{'margin-left': i == 0 ? -100.3 * carouselFrame + 'vw' : '0'}"
        [src]="'https://assets.inverkids.mx/assets/images/nosotros/' + img"
        alt="trabajo"
      />
    </div>
    <div class="work-carousel2" (click)="nextCarousel()">
      <img class="arrow" src="assets/images/icons/arrow_down.svg" alt="arrow-down">
    </div>
  </div>
</section>