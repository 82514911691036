<div id="shared-popup">

    <div *ngIf="data.monster" id="monster">
        <img [src]="monster" alt="monster">
    </div>

    <div id="title-container" *ngIf="data.title">
        <h1>{{data.title}}</h1>
    </div>

    <div id="subtitle-container" *ngIf="data.subtitle">
        <h2>{{data.subtitle}}</h2>
    </div>

    <div id="content" *ngIf="data.content">
        <ng-container [ngSwitch]="data.content.type">
            <ng-container *ngSwitchCase="'text'">
                <p class="text" *ngFor="let text of data.content.text" [innerHTML]="text"></p>
            </ng-container>
        </ng-container>

        <ng-container [ngSwitch]="data.content.type">
            <ng-container *ngSwitchCase="'options-selection'">
                <div class="multiple-option-container">
                    <div class="multiple-option" [ngClass]="{'selected': option.selected}"
                        *ngFor="let option of data.content.options" (click)="option.action(option)">
                        <div class="image-container" *ngIf="option.image">
                            <img [src]="option.image" alt="option-image">
                        </div>
                        <div class="text-container" *ngIf="option.text">
                            <p class="text">{{ option.text }}</p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-container [ngSwitch]="data.content.type">
            <ng-container *ngSwitchCase="'input-option'">
                <div class="input-option-container">
                    <div class="input-option" *ngFor="let option of data.content.options">
                        <div class="input-text-container" *ngIf="option.text">
                            <p class="left">{{ option.text }}</p>
                        </div>
                        <div *ngIf="option.type === 'dropdown'; else textInput">
                            <mat-form-field>
                                <mat-select required [(ngModel)]="option.value">
                                    <mat-option [value]="value" *ngFor="let value of option.options">{{value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <ng-template #textInput>
                            <div class="input-text-container">
                                <mat-form-field class="full-width">
                                    <input matInput [type]="option.type" [disabled]="option.disabled" name="custom"
                                        [(ngModel)]="option.value" autocomplete="new-password">
                                </mat-form-field>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-container [ngSwitch]="data.content.type">
            <ng-container *ngSwitchCase="'slider'">
                <div *ngFor="let slider of [].constructor(data.content.slidersCount); let index = index">
                    <div class="slider-title" *ngIf="data.content.slidersTitles">
                        <p>{{ data.content.slidersTitles[index] }}</p>
                    </div>
                    <div class="slider-container">
                        <mat-slider thumbLabel (change)="data.content.setValue($event, index)"
                            [value]="data.content.values[index]" [displayWith]="value" [step]="data.content.step"
                            [min]="data.content.min" [max]="data.content.max"></mat-slider>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <div id="error-message" *ngIf="data.error">
        <p class="error">{{ data.error }}</p>
    </div>

    <div id="buttons-container" *ngIf="data.buttons">
        <button [ngClass]="{'large': button.size, 'hollow': data.buttons.length > 1 && index === 1 }"
            *ngFor="let button of data.buttons; let index = index;" (click)="button.action()">{{button.text}}</button>
    </div>
</div>