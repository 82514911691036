export const PRODUCTS = [
    {
        id: 0,
        name: 'App Premium + Welcome Kit',
        textPrice: '$439.00 MXN',
        price: 439,
        category: 'Kit',
        image: 'img_1.png',
        description: [
            'Enseña el valor del dinero a tus hijos desde temprana edad, para lograr desarrollar hábitos de vida en donde a través de esfuerzo y cumplimiento de retos consigan su dinero, el cual deberán aprender a administrar para ahorrar y lograr sus propias metas financieras. Además acorde a la edad todos los miembros de la familia podrán aprender temas financieros, desde dónde viene el dinero hasta cómo invertir y hacer crecer nuestro dinero o tener cuidado con el crédito y nuestro retiro.',
            'Vive una aventura llena de aprendizajes, retos, y juegos para reforzar conocimientos. ¡No olvides la recompensa! Esto ayudará a que nuestros hijos fortalezcan sus hábitos financieros, asegurando así su futuro. Además recibirán un Welcome Kit hasta la puerta de su hogar con divertidas actividades financieras a realizar en casa.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: false,
        extraButtonPrompt: "",
        extraId: 0,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 1,
        name: 'Pa que te animes',
        textPrice: '$999.00 MXN',
        price: 999,
        category: 'Kit',
        image: 'img_2.png',
        description: [
            '¡Llego el pa que te animes financiero! Con este Kit Premium obtendrás todos nuestros productos con tan solo un clic. Llena de diversión y aventuras financieras tu hogar. Con Waiz & Rich pondrás tus finanzas en juego contra tu familia o amigos, ¿quién ganará? Con nuestra colección de cuentos los niños de entre 5 a 10 años descubrirán todo lo que gira alrededor del dinero, en qué lo podemos usar, cuál es la historia detrás del dinero, de dónde viene, cómo lo podemos ganar y si todos usan el mismo alrededor del mundo.',
            'Finalmente cierre con broche de oro, nuestra aplicación de Inverkids Premium para toda la familia. Aquí tanto grandes, medianos como chiquitos aprenderán sobre finanzas de una manera diferente, divertida y a través de juegos y gamificación. ¡Qué estas esperando, anímate!',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: false,
        extraButtonPrompt: "",
        extraId: 0,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 2,
        name: 'Waiz & Rich',
        textPrice: '$499.00 MXN',
        price: 499,
        category: 'Juego de mesa',
        image: 'img_3.png',
        description: [
            '¡Con Waiz & Rich pondrás tus finanzas en juego! Domina el juego tomando las mejores decisiones financieras para hacer crecer tu patrimonio. Genera el equilibrio en tu vida con mayores ingresos sin dejar a un lado tu bienestar y felicidad. ¡Pero cuidado, sé precavido! Que las deudas y los imprevistos no terminen con tu riqueza.',
            'El juego es de 2 a 6 jugadores de 10 años en adelante. Incluye un tablero, 6 tarjetas para ganar, 13 fichas, 14 tarjetas de imprevistos, 18 tarjetas de compras, 24 tarjetas de educación, 34 tarjetas de inversión, 34 tarjetas de préstamo, 20 tarjetas de ahorro, un paquete de bienestar, un paquete de billetes y un instructivo.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: false,
        extraButtonPrompt: "",
        extraId: 0,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 3,
        name: 'Las monedas de oro',
        textPrice: '$150.00 MXN',
        price: 150,
        category: 'Cuento',
        image: 'img_4.png',
        description: [
            'En casa de los abuelos Nickel existe un árbol muy alto y hermoso. La leyenda cuenta, que de ahí caen monedas de oro.',
            'Un día, los primos Nickel deciden hacer un campamento en el jardín para investigar el secreto del árbol. Esa noche, Diego tiene un maravilloso sueño donde conoce a unos duendes que cuidan el árbol de las monedas de oro.',
            'En esta aventura descubrirás la importancia del ciclo del dinero. Aprenderán que con el dinero pueden ahorrar, gastar, donar e invertir, encontrando así el equilibrio financiero. ¿Estás listo para descubrir el mundo del dinero?',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: true,
        extraButtonPrompt: "Comprar digital",
        extraId: 17,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 17,
        name: 'Las monedas de oro DIGITAL',
        textPrice: '$79.00 MXN',
        price: 79,
        category: 'Cuento',
        image: 'img_4.png',
        description: [
            'En casa de los abuelos Nickel existe un árbol muy alto y hermoso. La leyenda cuenta, que de ahí caen monedas de oro.',
            'Un día, los primos Nickel deciden hacer un campamento en el jardín para investigar el secreto del árbol. Esa noche, Diego tiene un maravilloso sueño donde conoce a unos duendes que cuidan el árbol de las monedas de oro.',
            'En esta aventura descubrirás la importancia del ciclo del dinero. Aprenderán que con el dinero pueden ahorrar, gastar, donar e invertir, encontrando así el equilibrio financiero. ¿Estás listo para descubrir el mundo del dinero?',
        ],
        hasShipping: false,
        showOnStore: false,
        hasExtra: true,
        extraButtonPrompt: "Comprar físico",
        extraId: 3,
        maxAmount: 1,
    },
    {
        id: 4,
        name: 'La historia del dinero',
        textPrice: '$150.00 MXN',
        price: 150,
        category: 'Cuento',
        image: 'img_5.png',
        description: [
            'Un día lluvioso los primos Nickel se juntaron en casa de sus abuelos, la curiosidad los llevó a preguntarse ¿cómo es que hacían antes de que existiera el dinero para ahorrar, gastar, donar e invertir?',
            'En La historia del dinero, descubrirás lo que es el trueque, de dónde viene el primer billete y la primera moneda, así como el papel de la tecnología y el internet en la evolución del dinero y las nuevas formas de pago.',
            'Sorpréndete conociendo los distintos elementos de los billetes que los hacen únicos. Comprenderás, junto con los primos Nickel, lo que sucede cuando pagas con tarjeta o el celular.',
            'La historia del dinero, te contará el fascinante relato sobre todo lo que ha sucedido con el dinero a lo largo de los años. No te lo puedes perder.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: true,
        extraButtonPrompt: "Comprar digital",
        extraId: 18,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 18,
        name: 'La historia del dinero DIGITAL',
        textPrice: '$79.00 MXN',
        price: 79,
        category: 'Cuento',
        image: 'img_5.png',
        description: [
            'Un día lluvioso los primos Nickel se juntaron en casa de sus abuelos, la curiosidad los llevó a preguntarse ¿cómo es que hacían antes de que existiera el dinero para ahorrar, gastar, donar e invertir?',
            'En La historia del dinero, descubrirás lo que es el trueque, de dónde viene el primer billete y la primera moneda, así como el papel de la tecnología y el internet en la evolución del dinero y las nuevas formas de pago.',
            'Sorpréndete conociendo los distintos elementos de los billetes que los hacen únicos. Comprenderás, junto con los primos Nickel, lo que sucede cuando pagas con tarjeta o el celular.',
            'La historia del dinero, te contará el fascinante relato sobre todo lo que ha sucedido con el dinero a lo largo de los años. No te lo puedes perder.',
        ],
        hasShipping: false,
        showOnStore: false,
        hasExtra: true,
        extraButtonPrompt: "Comprar físico",
        extraId: 4,
        maxAmount: 1,
    },
    {
        id: 5,
        name: '¿De dónde viene el dinero?',
        textPrice: '$150.00 MXN',
        price: 150,
        category: 'Cuento',
        image: 'img_6.png',
        description: [
            'En una nueva aventura la familia Nickel va de paseo al campo. En el camino, Mateo se preguntó ¿de dónde venía el dinero?',
            'Los primos Nickel se preparan para un nuevo aprendizaje. ¿El dinero viene de las billeteras de los padres? ¿De una fábrica detrás de los cajeros? ¿De una fábrica manejada por duendes? ¿De los árboles con hojas de colores?',
            'Déjate sorprender por la increíble historia sobre el banco central. En ¿De dónde viene el dinero? aprenderás el proceso para la creación de billetes y monedas y la distribución del dinero a todos los bancos.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: true,
        extraButtonPrompt: "Comprar digital",
        extraId: 19,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 19,
        name: '¿De dónde viene el dinero? DIGITAL',
        textPrice: '$79.00 MXN',
        price: 79,
        category: 'Cuento',
        image: 'img_6.png',
        description: [
            'En una nueva aventura la familia Nickel va de paseo al campo. En el camino, Mateo se preguntó ¿de dónde venía el dinero?',
            'Los primos Nickel se preparan para un nuevo aprendizaje. ¿El dinero viene de las billeteras de los padres? ¿De una fábrica detrás de los cajeros? ¿De una fábrica manejada por duendes? ¿De los árboles con hojas de colores?',
            'Déjate sorprender por la increíble historia sobre el banco central. En ¿De dónde viene el dinero? aprenderás el proceso para la creación de billetes y monedas y la distribución del dinero a todos los bancos.',
        ],
        hasShipping: false,
        showOnStore: false,
        hasExtra: true,
        extraButtonPrompt: "Comprar físico",
        extraId: 5,
        maxAmount: 1,
    },
    {
        id: 6,
        name: 'Dinero en el mundo',
        textPrice: '$150.00 MXN',
        price: 150,
        category: 'Cuento',
        image: 'img_7.png',
        description: [
            'Las clases terminaron y la familia Nickel disfrutará de las vacaciones con un viaje a un destino desconocido para Mariana, Miguel, Diego, María y Mateo. La única pista que tienen los primos es que en el lugar que visitaran no se usan Inverpesos, sino otra moneda. ¿Tu sabías que cada país tiene su propio dinero o divisa? ¿No?, los primos Nickel tampoco.',
            'En la sala de espera descubrirán que así cómo cada país tiene su comida típica y lenguaje, también tienen su propia moneda. En Dinero en el mundo, aprenderás sobre las distintas divisas del mundo, cuáles son las monedas y billetes más usados y las características que tiene el dinero de cada país. Te asombraras con los diferentes diseños de las monedas en el mundo.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: true,
        extraButtonPrompt: "Comprar digital",
        extraId: 20,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 20,
        name: 'Dinero en el mundo DIGITAL',
        textPrice: '$79.00 MXN',
        price: 79,
        category: 'Cuento',
        image: 'img_7.png',
        description: [
            'Las clases terminaron y la familia Nickel disfrutará de las vacaciones con un viaje a un destino desconocido para Mariana, Miguel, Diego, María y Mateo. La única pista que tienen los primos es que en el lugar que visitaran no se usan Inverpesos, sino otra moneda. ¿Tu sabías que cada país tiene su propio dinero o divisa? ¿No?, los primos Nickel tampoco.',
            'En la sala de espera descubrirán que así cómo cada país tiene su comida típica y lenguaje, también tienen su propia moneda. En Dinero en el mundo, aprenderás sobre las distintas divisas del mundo, cuáles son las monedas y billetes más usados y las características que tiene el dinero de cada país. Te asombraras con los diferentes diseños de las monedas en el mundo.',
        ],
        hasShipping: false,
        showOnStore: false,
        hasExtra: true,
        extraButtonPrompt: "Comprar físico",
        extraId: 6,
        maxAmount: 1,
    },
    {
        id: 7,
        name: '¿Cómo ganamos dinero?',
        textPrice: '$150.00 MXN',
        price: 150,
        category: 'Cuento',
        image: 'img_8.png',
        description: [
            'Es el día de los abuelos, y la familia Nickel irá al parque para festejar a sus abuelos. Después de que el abuelo le da la mesada a los primos, Diego se pregunta ¿cómo se puede ganar dinero?',
            'En esta nueva historia descubrirás que es necesario trabajar para ganar dinero o tener ingresos. Además conocerás distintas profesiones que pueden dar o servicios o productos y lo más importante, aprenderás distintas formas en las que TÚ puedes ganar dinero haciendo tareas de la casa y con la aplicación de Inverkids.',
            '¿Cómo ganamos dinero? te ayudará a entender cómo es que podemos tener dinero para luego ahorrarlo, invertirlo, donarlo o gastarlo. Aprendé junto con  Mariana, Miguel, Diego, María y Mateo las distintas actividades que tu puedes hacer para ganar dinero.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: true,
        extraButtonPrompt: "Comprar digital",
        extraId: 21,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 21,
        name: '¿Cómo ganamos dinero? DIGITAL',
        textPrice: '$79.00 MXN',
        price: 79,
        category: 'Cuento',
        image: 'img_8.png',
        description: [
            'Es el día de los abuelos, y la familia Nickel irá al parque para festejar a sus abuelos. Después de que el abuelo le da la mesada a los primos, Diego se pregunta ¿cómo se puede ganar dinero?',
            'En esta nueva historia descubrirás que es necesario trabajar para ganar dinero o tener ingresos. Además conocerás distintas profesiones que pueden dar o servicios o productos y lo más importante, aprenderás distintas formas en las que TÚ puedes ganar dinero haciendo tareas de la casa y con la aplicación de Inverkids.',
            '¿Cómo ganamos dinero? te ayudará a entender cómo es que podemos tener dinero para luego ahorrarlo, invertirlo, donarlo o gastarlo. Aprendé junto con  Mariana, Miguel, Diego, María y Mateo las distintas actividades que tu puedes hacer para ganar dinero.',
        ],
        hasShipping: false,
        showOnStore: false,
        hasExtra: true,
        extraButtonPrompt: "Comprar físico",
        extraId: 7,
        maxAmount: 1,
    },
    {
        id: 8,
        name: 'El secreto del ahorro',
        textPrice: '$150.00 MXN',
        price: 150,
        category: 'Cuento',
        image: 'img_10.png',
        description: [
            'La familia Nickel irá al cine a ver una película, al terminar los primos recibirán  $20 inverpesos, con ello podrían comprarse lo que les alcance en la plaza comercial. Todos los primos, menos Mariana, comprarán  algo en alguna de las tiendas. Al verlos el abuelo decide darles una sorpresa.',
            'En esta ocasión los primos Nickel conocerán el secreto del ahorro. Podrás descubrir la importancia de guardar dinero hasta tener lo suficiente para comprar las cosas que tú quieras. Miguel, María, Diego, Mateo y Mariana aprovecharán esta oportunidad para poner en práctica el ahorro.',
            'El secreto del ahorro será tu oportunidad para adentrarte en las enormes posibilidades del ahorro.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: true,
        extraButtonPrompt: "Comprar digital",
        extraId: 22,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 22,
        name: 'El secreto del ahorro DIGITAL',
        textPrice: '$79.00 MXN',
        price: 79,
        category: 'Cuento',
        image: 'img_10.png',
        description: [
            'La familia Nickel irá al cine a ver una película, al terminar los primos recibirán  $20 inverpesos, con ello podrían comprarse lo que les alcance en la plaza comercial. Todos los primos, menos Mariana, comprarán  algo en alguna de las tiendas. Al verlos el abuelo decide darles una sorpresa.',
            'En esta ocasión los primos Nickel conocerán el secreto del ahorro. Podrás descubrir la importancia de guardar dinero hasta tener lo suficiente para comprar las cosas que tú quieras. Miguel, María, Diego, Mateo y Mariana aprovecharán esta oportunidad para poner en práctica el ahorro.',
            'El secreto del ahorro será tu oportunidad para adentrarte en las enormes posibilidades del ahorro.',
        ],
        hasShipping: false,
        showOnStore: false,
        hasExtra: true,
        extraButtonPrompt: "Comprar físico",
        extraId: 8,
        maxAmount: 1,
    },
    {
        id: 9,
        name: 'Metas financieras',
        textPrice: '$150.00 MXN',
        price: 150,
        category: 'Cuento',
        image: 'img_11.png',
        description: [
            'Un viernes como cualquier otro Mariana se prepara para ir a la escuela. Durante el recreo Mariana nota que sus amigas tenían muñecas, celulares y audífonos nuevos. Esto la sorprendió mucho. Al salir de clases acompañó a su madre al supermercado. En uno de los pasillos encontró audífonos, muñecas y celulares y le pidió a su madre que se los comprara. Su madre le recordó la importancia del ahorro, ella podría obtener cualquier meta financiera siempre y cuando ahorrara.            ',
            'Durante esta nueva historia descubrirás los secretos para poder lograr tú meta financiera. Mariana nos explicará las acciones necesarias para obtener y comprar lo que más desea. Aprovecha ésta oportunidad para conocer como lograr cualquier meta que te propongas.',
            'Mis metas financieras, te contará el fascinante secreto de como lograr todo aquello que te propongas a través del ahorro.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: true,
        extraButtonPrompt: "Comprar digital",
        extraId: 23,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 23,
        name: 'Metas financieras DIGITAL',
        textPrice: '$79.00 MXN',
        price: 79,
        category: 'Cuento',
        image: 'img_11.png',
        description: [
            'Un viernes como cualquier otro Mariana se prepara para ir a la escuela. Durante el recreo Mariana nota que sus amigas tenían muñecas, celulares y audífonos nuevos. Esto la sorprendió mucho. Al salir de clases acompañó a su madre al supermercado. En uno de los pasillos encontró audífonos, muñecas y celulares y le pidió a su madre que se los comprara. Su madre le recordó la importancia del ahorro, ella podría obtener cualquier meta financiera siempre y cuando ahorrara.            ',
            'Durante esta nueva historia descubrirás los secretos para poder lograr tú meta financiera. Mariana nos explicará las acciones necesarias para obtener y comprar lo que más desea. Aprovecha ésta oportunidad para conocer como lograr cualquier meta que te propongas.',
            'Mis metas financieras, te contará el fascinante secreto de como lograr todo aquello que te propongas a través del ahorro.',
        ],
        hasShipping: false,
        showOnStore: false,
        hasExtra: true,
        extraButtonPrompt: "Comprar físico",
        extraId: 9,
        maxAmount: 1,
    },
    {
        id: 10,
        name: 'Deseos y necesidades',
        textPrice: '$150.00 MXN',
        price: 150,
        category: 'Cuento',
        image: 'img_12.png',
        description: [
            'En una tarde soleada los primos Nickel aprovechan para pasear por la calle en sus bicicletas, todos a excepción de Diego que andaba en sus patines. Él le dice a su madre que necesita una bicicleta para estar con sus primos. Su madre aprovecha ésta valiosa oportunidad para explicarle a Diego la diferencias entre deseos y necesidades.            ',
            'Durante ésta nueva historia acompañaremos a Diego en una díficil decisión: ¿cuál bicicleta comprar? Aprenderás junto con nuestro personaje la diferencia entre un deseo y una necesidad al leer los valiosos consejos de sus abuelos.',
            'Después de leer Deseos y Necesidades podrás distinguir entre algo que quieres y algo que necesitas; así lograrás tener una meta financiera real.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: true,
        extraButtonPrompt: "Comprar digital",
        extraId: 0,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 24,
        name: 'Deseos y necesidades DIGITAL',
        textPrice: '$79.00 MXN',
        price: 79,
        category: 'Cuento',
        image: 'img_12.png',
        description: [
            'En una tarde soleada los primos Nickel aprovechan para pasear por la calle en sus bicicletas, todos a excepción de Diego que andaba en sus patines. Él le dice a su madre que necesita una bicicleta para estar con sus primos. Su madre aprovecha ésta valiosa oportunidad para explicarle a Diego la diferencias entre deseos y necesidades.            ',
            'Durante ésta nueva historia acompañaremos a Diego en una díficil decisión: ¿cuál bicicleta comprar? Aprenderás junto con nuestro personaje la diferencia entre un deseo y una necesidad al leer los valiosos consejos de sus abuelos.',
            'Después de leer Deseos y Necesidades podrás distinguir entre algo que quieres y algo que necesitas; así lograrás tener una meta financiera real.',
        ],
        hasShipping: false,
        showOnStore: false,
        hasExtra: true,
        extraButtonPrompt: "Comprar físico",
        extraId: 10,
        maxAmount: 1,
    },
    {
        id: 11,
        name: 'Mi primer cuenta bancaria',
        textPrice: '$150.00 MXN',
        price: 150,
        category: 'Cuento',
        image: 'img_13.png',
        description: [
            'Un domingo por la tarde Mariana se da cuenta de que en su alcancía ya no cabe más dinero. Su mamá piensa en darle una alcancía más grande, pero su abuela tiene una gran idea: ¡Enseñarle a sus nietos a ahorrar formalmente! ',
            'Cada uno de nuestros personajes se hace una idea sobre lo que esto podría significar. Su abuela llega al rescate para explicarles lo que en realidad es ahorrar formalmente.',
            'En Mi primera cuenta bancaria, descubrirás la importancia de acudir al banco y ahorrar dinero ahí. Acompaña a los primos Nickel en ésta aventura, aprenderás lo que significa ahorrar formalmente además de los distintos beneficios de hacerlo.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: true,
        extraButtonPrompt: "Comprar digital",
        extraId: 25,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 25,
        name: 'Mi primer cuenta bancaria DIGITAL',
        textPrice: '$79.00 MXN',
        price: 79,
        category: 'Cuento',
        image: 'img_13.png',
        description: [
            'Un domingo por la tarde Mariana se da cuenta de que en su alcancía ya no cabe más dinero. Su mamá piensa en darle una alcancía más grande, pero su abuela tiene una gran idea: ¡Enseñarle a sus nietos a ahorrar formalmente! ',
            'Cada uno de nuestros personajes se hace una idea sobre lo que esto podría significar. Su abuela llega al rescate para explicarles lo que en realidad es ahorrar formalmente.',
            'En Mi primera cuenta bancaria, descubrirás la importancia de acudir al banco y ahorrar dinero ahí. Acompaña a los primos Nickel en ésta aventura, aprenderás lo que significa ahorrar formalmente además de los distintos beneficios de hacerlo.',
        ],
        hasShipping: false,
        showOnStore: false,
        hasExtra: true,
        extraButtonPrompt: "Comprar físico",
        extraId: 11,
        maxAmount: 1,
    },
    {
        id: 12,
        name: 'Finanzas de terror',
        textPrice: '$150.00 MXN',
        price: 150,
        category: 'Cuento',
        image: 'img_14.png',
        description: [
            'Llegó el mes de octubre, el de Halloween y la Noche de Brujas. En ésta ocasión los primos Nickel se enfrentan a un gran reto: ¡Planear la fiesta de Halloween! Los primos utilizaran todo lo que han aprendido para lograr hacer un presupuesto y cumplir con el equilibrio en el dinero.',
            'Finanzas de miedo es un cuento como ningún otro. Los niños pondrán en acción todos sus conocimientos, dejando a un lado el miedo que puede provocar las finanzas y la planeación de una fiesta con presupuesto. ',
            'Durante esta aventura verás las grandes posibilidades en el mundo del dinero cuando se conocen los conceptos financieros. Acompaña a la familia Nickel en la planeación de una increíble fiesta de Halloween llena de sorpresas.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: true,
        extraButtonPrompt: "Comprar digital",
        extraId: 26,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 26,
        name: 'Finanzas de terror DIGITAL',
        textPrice: '$79.00 MXN',
        price: 79,
        category: 'Cuento',
        image: 'img_14.png',
        description: [
            'Llegó el mes de octubre, el de Halloween y la Noche de Brujas. En ésta ocasión los primos Nickel se enfrentan a un gran reto: ¡Planear la fiesta de Halloween! Los primos utilizaran todo lo que han aprendido para lograr hacer un presupuesto y cumplir con el equilibrio en el dinero.',
            'Finanzas de miedo es un cuento como ningún otro. Los niños pondrán en acción todos sus conocimientos, dejando a un lado el miedo que puede provocar las finanzas y la planeación de una fiesta con presupuesto. ',
            'Durante esta aventura verás las grandes posibilidades en el mundo del dinero cuando se conocen los conceptos financieros. Acompaña a la familia Nickel en la planeación de una increíble fiesta de Halloween llena de sorpresas.',
        ],
        hasShipping: false,
        showOnStore: false,
        hasExtra: true,
        extraButtonPrompt: "Comprar físico",
        extraId: 12,
        maxAmount: 1,
    },
    {
        id: 13,
        name: 'Mi primer mascota',
        textPrice: '$150.00 MXN',
        price: 150,
        category: 'Cuento',
        image: 'img_15.png',
        description: [
            'Un domingo por la tarde los primos Nickel descubren el parque de mascotas. Todos se preguntan por qué ellos no tienen mascotas y se dan cuenta que para tener una mascota es necesario tener dinero, además de darles cariño. Mateo está listo para tener una mascota. Para elegir una Mateo analizará, junto con sus padres, los gastos mensuales y anuales para cada uno de los diferentes animalitos.',
            'En esta nueva aventura, aprenderás la qué es un gasto y cuál es la importancia de organizar tus ingresos y gastos. Además, junto con Mateo, podrás darte cuenta de que toda meta es posible siempre y cuando los gastos de ajusten a tus ingresos. ',
            'Déjate sorprender por la historia de Mi primer mascota en donde aprenderás a analizar tus gastos de acuerdo a tus ingresos y con ello y un presupuesto, lograr cualquier meta financiera.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: true,
        extraButtonPrompt: "Comprar digital",
        extraId: 27,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 27,
        name: 'Mi primer mascota DIGITAL',
        textPrice: '$79.00 MXN',
        price: 79,
        category: 'Cuento',
        image: 'img_15.png',
        description: [
            'Un domingo por la tarde los primos Nickel descubren el parque de mascotas. Todos se preguntan por qué ellos no tienen mascotas y se dan cuenta que para tener una mascota es necesario tener dinero, además de darles cariño. Mateo está listo para tener una mascota. Para elegir una Mateo analizará, junto con sus padres, los gastos mensuales y anuales para cada uno de los diferentes animalitos.',
            'En esta nueva aventura, aprenderás la qué es un gasto y cuál es la importancia de organizar tus ingresos y gastos. Además, junto con Mateo, podrás darte cuenta de que toda meta es posible siempre y cuando los gastos de ajusten a tus ingresos. ',
            'Déjate sorprender por la historia de Mi primer mascota en donde aprenderás a analizar tus gastos de acuerdo a tus ingresos y con ello y un presupuesto, lograr cualquier meta financiera.',
        ],
        hasShipping: false,
        showOnStore: false,
        hasExtra: true,
        extraButtonPrompt: "Comprar físico",
        extraId: 13,
        maxAmount: 1,
    },
    {
        id: 14,
        name: 'Cuentos de dinero + Premium',
        textPrice: '$650.00 MXN',
        price: 650,
        category: 'Kit',
        image: 'img_9.png',
        description: [
            'A través de las cinco aventuras financieras de la familia Nickel descubrirás y aprenderás conceptos básicos sobre el dinero. Comenzando por la leyenda de las monedas de oro que caían de un árbol en donde descubrieron la importancia de ahorrar, gastar, donar e invertir. Después con curiosidad conocerán como es que hacían antes de que existiera el dinero como lo conocemos hoy en día. Se sorprenderán por la increíble historia sobre el banco central y aprenderán sobre las distintas divisas del mundo, cuáles son las monedas y billetes más usados. Finalmente en cómo ganamos dinero descubrirán junto a la familia Nickel, que es necesario trabajar para ganar dinero o tener ingresos a través de distintas profesiones que pueden brindar servicios o productos.',
            'Además recibe 2 meses Premium de nuestra app Inverkids en la cual tus hijos podrán tener los 5 cuentos animados, además de jugar los 11 divertidos juegos con los cuales seguirán aprendiendo y reforzando conocimientos. Podrán disfrutar de la app en Android, tu computadora y muy pronto en Apple.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: false,
        extraButtonPrompt: "",
        extraId: 0,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 15,
        name: 'Cuentos de ahorro + Premium',
        textPrice: '$520.00 MXN',
        price: 520,
        category: 'Kit',
        image: 'img_16.png',
        description: [
            'A través de las cuatro aventuras financieras de la familia Nickel descubrirás y aprenderás conceptos básicos sobre el ahorra. Comenzando por El secreto del ahorro en donde descubrirás la importancia de guardar dinero hasta tener lo suficiente para comprar las cosas que tú quieras. Después con curiosidad conocerán cómo lograr las metas financieras que te propongas. Se sorprenderán por la increíble historia de Deseos y Necesidades en donde podrás distinguir entre algo que quieres y algo que necesitas; así lograrás tener una meta financiera real. Finalmente en Mi primera cuenta bancaria, descubrirás la importancia de acudir al banco y ahorrar dinero ahí.',
            'Además recibe 2 meses Premium de nuestra app Inverkids en la cual tus hijos podrán tener los 4 cuentos animados, además de jugar los divertidos juegos con los cuales seguirán aprendiendo y reforzando conocimientos. Podrán disfrutar de la app en Android, tu computadora y muy pronto en Apple.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: false,
        extraButtonPrompt: "",
        extraId: 0,
        maxAmount: 0,
        extraInformation: [],
    },
    {
        id: 16,
        name: 'Pa que te espantes',
        textPrice: '$299.00 MXN',
        price: 299,
        category: 'Kit',
        image: 'img_17.png',
        description: [
            '¡Sorprende a tus hijos esta Noche de Brujas con su Kit Financiero de temporada! En donde los primos Nickel se enfrentan a un gran reto: ¡Planear la fiesta de Halloween! Los primos utilizaran todo lo que han aprendido para lograr hacer un presupuesto y cumplir con el equilibrio en el dinero.',
            'Recibe una caja llena de diversión con el cuento “Finanzas de terror”, 4 actividades de refuerzo, 6 actividades para colorear, 20 calcamonías de temporada y una bolsita de dulces de Halloween.',
        ],
        hasShipping: true,
        showOnStore: true,
        hasExtra: false,
        extraButtonPrompt: "",
        extraId: 0,
        maxAmount: 0,
        extraInformation: [],
    },
];

export const SCHOOL_PRODUCTS = [
    {
        id: 0,
        cartId: '',
        name: 'Licencia matemáticas financieras $SCHOOL_NAME',
        textPrice: '$450.00 MXN',
        price: 450,
        category: 'Licencia',
        image: 'img_esc_1.png',
        description: [
            'Inverkids es un programa de educación que prepara a nuestros hijos para su vida financiera. Una vez a la semana, nuestros alumnos reciben su clase de matemáticas financieras de manera digital a través de inverkids.mx',
            'Además, durante el ciclo tienen múltiples proyectos y eventos para desarrollar nuevas capacidades financieras que los motiven a ahorrar, emprender y cumplir sus metas financieras.',
        ],
        hasShipping: false,
        showOnStore: false,
        hasExtra: false,
        extraButtonPrompt: "",
        extraId: 0,
        maxAmount: 0,
        extraInformation: [
            {
                fieldName: "schoolName",
                fieldDisplay: "",
                fieldType: "default",
                fieldPlaceholder: "",
                fieldSize: 0,
                isVisible: false,
            },
            {
                fieldName: "childName",
                fieldDisplay: "Nombre completo del estudiante #$INDEX",
                fieldType: "text",
                fieldPlaceholder: "Escribe el nombre de tu hijo",
                fieldSize: 60,
                isVisible: true,
            },
            {
                fieldName: "group",
                fieldDisplay: "Grado",
                fieldType: "enum",
                fieldPlaceholder: "",
                fieldOptions: ["1ro" ,"2do" ,"3ro" ,"4to" ,"5to" ,"6to" ,"7mo" ,"8vo" ,"9no"],
                fieldSize: 15,
                isVisible: true,
            }
        ],
    },
];