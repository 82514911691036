export function FORM_ERROR(context) {
    const options = {
        width: '450px',
        data: {
            title: 'Por favor valida que todos los campos sean correctos.',
            buttons: [
                {
                    text: 'Ok',
                    action: () => context.dialog.closeAll(),
                },
            ],
        },
    };
    return options;
}

export function EMAIL_MISSING(context) {
    const options = {
        width: '350px',
        data: {
            title: 'Por favor introduce ambos correos.',
            buttons: [
                {
                    text: 'Ok',
                    action: () => context.dialog.closeAll(),
                },
            ],
        },
    };
    return options;
}

export function EMAIL_ERROR(context) {
    const options = {
        width: '450px',
        data: {
            title: 'Por favor valida que tu correo sea válido en ambos campos.',
            buttons: [
                {
                    text: 'Ok',
                    action: () => context.dialog.closeAll(),
                },
            ],
        },
    };
    return options;
}

export function USER_NOT_FOUND(context) {
    const options = {
        width: '400px',
        data: {
            title: 'No pudimos encontrar una cuenta con ese correo.',
            subtitle: 'Si no estas registrado, te invitamos a que lo hagas.',
            buttons: [
                {
                    text: 'Registrarme',
                    action: () => {
                        context.router.navigate(['/register']);
                        context.dialog.closeAll();
                    },
                },
            ],
        },
    };
    return options;
}

export function USER_NOT_VERIFIED(context) {
    const options = {
        width: '400px',
        data: {
            title: 'Tu cuenta no está verificada.',
            subtitle: 'Haz clic en el botón para enviar el correo de verificación.',
            buttons: [
                {
                    text: 'Enviar',
                    action: () => {
                        context.sendEmailVerification();
                        context.dialog.closeAll();
                    },
                },
            ],
        },
    };
    return options;
}

export function EMAIL_VERIFICATION_SENT(context) {
    const options = {
        width: '400px',
        data: {
            title: 'Hemos enviado el correo de validación.',
            subtitle: 'Por favor verifica tu correo.',
            buttons: [
                {
                    text: 'Ok',
                    action: () => {
                        context.dialog.closeAll();
                    },
                },
            ],
        },
    };
    return options;
}

export function SERVER_ERROR(context) {
    const options = {
        width: '400px',
        data: {
            title: 'Lo sentimos, hubo un problema al procesar la información.',
            subtitle: 'Si el problema persiste contacta al equipo de soporte por medio del chat.',
            buttons: [
                {
                    text: 'Ok',
                    action: () => {
                        context.dialog.closeAll();
                    },
                },
            ],
        },
    };
    return options;
}

export function PAYMENT_CREATION_ERROR(context) {
    const options = {
        width: '450px',
        data: {
            title: 'Lo sentimos, hubo un error al procesar su pago',
            subtitle: 'Favor de verificar la información de su tarjeta o contactar a su banco para la aprobación de la transación.',
            buttons: [
                {
                    text: 'Ok',
                    action: () => context.dialog.closeAll(),
                },
            ],
        },
    };
    return options;
}

export function PAYMENT_SERVER_ERROR(context) {
    const options = {
        width: '450px',
        data: {
            title: 'Lo sentimos, hubo un error con el procesador de pagos',
            subtitle: 'Por favor contacta al equipo de soporte',
            buttons: [
                {
                    text: 'Ok',
                    action: () => context.dialog.closeAll(),
                },
            ],
        },
    };
    return options;
}

export function PAYMENT_PROGRESS(context) {
    const options = {
        width: '450px',
        data: {
            title: 'Espere un momento mientras procesamos su pago.',
        },
    };
    return options;
}
