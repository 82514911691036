import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MailerService {
    requestInProgress = false;
    private newsletterUrl = `${environment.apiUrl}/subscribe`;

    constructor(private http: HttpClient) {}

    schoolRegistration(school): Promise<any> {
        if (!this.requestInProgress) {
            this.requestInProgress = true;
            return this.http
                .post<any>(`${environment.apiUrl}/school_registration`, { school })
                .pipe(
                    tap(
                        () => {
                            this.requestInProgress = false;
                        },
                        () => {
                            this.requestInProgress = false;
                        }
                    )
                )
                .toPromise();
        }
    }

    cardRegistration(card): Promise<any> {
        if (!this.requestInProgress) {
            this.requestInProgress = true;
            return this.http
                .post<any>(`${environment.apiUrl}/card_registration`, { card })
                .pipe(
                    tap(
                        () => {
                            this.requestInProgress = false;
                        },
                        () => {
                            this.requestInProgress = false;
                        }
                    )
                )
                .toPromise();
        }
    }

    createNewsletterSubscription(email: string): Promise<any> {
        if (!this.requestInProgress) {
            this.requestInProgress = true;
            return this.http
                .post(this.newsletterUrl, { subscriber: { email: email } })
                .pipe(
                    tap(
                        () => {
                            this.requestInProgress = false;
                        },
                        () => {
                            this.requestInProgress = false;
                        }
                    )
                )
                .toPromise();
        }
    }
}
