let environment;
let authToken;
let apiUrl;
let downloading_catalog = false;
let downloading_grades_progress = false;
let level;
let label;
let module;
let topic;
let user_id;

export const run_process = (event:any) => {
    console.log("message type -> ", event.data.type === "ENVIRONMENT");
    if (event.data && event.data.type === "ENVIRONMENT") {
      environment = event.data.environment;
      authToken = event.data.authToken;
      apiUrl = event.data.environment.apiUrl;
      user_id = event.data.user_id;
      label = event.data.label;
      console.log(
        "Environment data received in Service Worker:",
        environment,
        authToken
      );
    }
  
    if (event.data && event.data.type === "download-catalog") {
      console.log("Evento de descarga de catalogo en login escuchado");
      async function descargaCatalogo() {
        await downloadCatalog();
      }
      descargaCatalogo();
    }
  
    if (event.data && event.data.type === "update-catalog") {
      console.log("Evento de actualización de catalogo escuchado");
      async function run_actualizarCatalogo() {
        await actualizarCatalogo();
      }
      run_actualizarCatalogo()
    }
  
    if (event.data && event.data.type === "download-grades-progress") {
      // Definir variable desde env
      console.log("Evento de descarga de grades y progress escuchado");
      async function run_downloadGradesProgress() {
        await downloadGradesProgress();
      }
      run_downloadGradesProgress();
    }
  
    if (event.data && event.data.type === "update-grades-progress") {
      // Definir variable desde env
      console.log("Evento de descarga de grades y progress escuchado");
      async function run_updateGradesProgress() {
        await updateGradesProgress();
      }
      run_updateGradesProgress();
    }
  }
  
  // Funcion para descargar catalogo en login
  async function downloadCatalog() {
    if (!downloading_catalog){
      downloading_catalog = true
      fetch(apiUrl + `/sync/get_catalog?label=${label}`, {
        headers: {
          Authorization: authToken,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          saveCatalogOnDB(data);
          downloading_catalog = false
        })
        .catch((error) => {
          console.error("Error fetching or processing data:", error);
          downloading_catalog = false
        });
    }
  }
  
  async function downloadGradesProgress() {
    if (!downloading_grades_progress){
      downloading_grades_progress = true
      fetch(apiUrl + "/sync/get_user_grades_progress", {
        headers: {
          Authorization: authToken,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          saveGradesProgressOnDB(data);
          downloading_grades_progress = false
        })
        .catch((error) => {
          console.error("Error fetching or processing data:", error);
          downloading_grades_progress = false
        });
    }
  }
  
  async function updateGradesProgress() {
    if (!downloading_grades_progress){
      downloading_grades_progress = true
  
      let sync_progress:any = []
      let sync_grades:any = []
      let has_grades_progress:any = false
      
      const request:any = indexedDB.open("grades_progress");
  
      request.onupgradeneeded = () => {
        // This event only fires if the database doesn't exist or needs to be upgraded.
        request.transaction.abort(); // Cancel the upgrade to avoid creating the database
        
        if (navigator.onLine) {
            console.log("downloading grades progress navigator online")
            downloading_grades_progress = false
            downloadGradesProgress();
        }
  
        console.log("downloading grades progress")
      };
  
      request.onerror = function (event) {
        console.log("database_error", event)
      };
  
      request.onsuccess = (event) => {
        const db = event.target.result;
        
        try{
          const transaction = db.transaction("progress", "readwrite");
  
          const store = transaction.objectStore("progress");
          const cursorRequest = store.openCursor();
  
          cursorRequest.onsuccess = (event ) => {
            const cursor:any = event.target.result;
            
            if (cursor) {
              if (cursor.value.student_id == user_id){
                has_grades_progress = true
              }
              if (
                cursor.value.sync_status == "pending"
                && cursor.value.student_id == user_id
              ){
                  sync_progress.push(cursor.value)
                  cursor.update({...cursor.value, sync_status:"done"})
              }
              cursor.continue();
            } else {
              try{
                const transaction_grades = db.transaction("grades", "readwrite");
        
                const store_grades = transaction_grades.objectStore("grades");
                const cursorRequest = store_grades.openCursor();
        
                cursorRequest.onsuccess = (event ) => {
                  const cursor = event.target.result;
                  if (cursor) {
                    if(cursor.value.student_id == user_id){
                      has_grades_progress = true
                    }
                    if (
                      cursor.value.sync_status == "pending"
                      && cursor.value.student_id == user_id
                    ){
                        sync_grades.push(cursor.value)
                        cursor.update({...cursor.value, sync_status:"done"})
                    }
                    cursor.continue();
                  } else {
                    if(sync_grades.length > 0 || sync_progress.length > 0){
                      fetch(apiUrl + '/sync/update_user_grades_progress', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': authToken
                        },
                        body: JSON.stringify({grades: sync_grades, progress: sync_progress}),
                      }).then((resp)=>{
                        console.log("sync grades progress", resp)
                        if (!resp.ok) {
                          throw new Error(`HTTP error! Status: ${resp.status}`);
                        }
                        return resp.json(); // Parse the JSON body
                      }).then((resp)=>{
                        console.log("sync grades progress", resp)
                        if(resp.reset_grades_progress){
                          downloading_grades_progress = false
                          downloadGradesProgress().then(()=>{
                            window.dispatchEvent(new Event("SW_RELOAD"))
                          })
                        }
                      })
                    }else{
                      if(!has_grades_progress){
                        console.log("downloading grades cause none were found")
                        downloading_grades_progress = false
                        downloadGradesProgress()
                      }
                    }
                  }
                  downloading_grades_progress = false
                };
        
                cursorRequest.onerror = function () {
                  console.log("cursor_error")
                };
              }catch(error){
                console.log("database_error", error)
                return;
              }
            }
          };
  
          cursorRequest.onerror = function () {
            console.log("cursor_error")
          };
        }catch(error){
          console.log("database_error", error)
          return;
        }
      };
  
    }else{
      console.log("already downloading grades and progress")
    }
  }
  
  async function isVersionUpdated() {
    const versionActual = await getCatalogVersion();
    const response = await fetch(apiUrl + "/sync/get_catalog_version", {
      headers: {
        Authorization: authToken,
      },
    });
    const data = await response.json();
    const ultimaVersion = data.result;
  
    if (ultimaVersion !== versionActual) {
      //agregar funcion aqui para actualizar version en db
      return ultimaVersion;
    } else {
      return null; // estan actualizadas y no hay que hacer nada
    }
  }
  
  // Función para verificar versiónes y actualizar catalogo
  async function actualizarCatalogo() {
    let latestCatalogVersion = await isVersionUpdated();
    if (latestCatalogVersion != null) {
      await saveCatalogVersion(latestCatalogVersion); // Actualizando a ultima version en indexDB
      console.log("Versiones distintinas, simulando actualizar catalogo");
      await downloadCatalog();
    }
  }
  
  function saveCatalogOnDB(catalogData) {
    const request = indexedDB.open("catalog", 12); // IMPORTANTE: INCREMENTAR EN CADA CAMBIO ESTRUCTURAL DE LA BD
  
    request.onupgradeneeded = function (event:any) {
      const db = event.target.result;
  
      if (!db.objectStoreNames.contains("modules")) {
        db.createObjectStore("modules", { keyPath: "module_id" });
      }
      if (!db.objectStoreNames.contains("activities")) {
        db.createObjectStore("activities", { keyPath: "activity_id" });
      }
      if (!db.objectStoreNames.contains("books")) {
        db.createObjectStore("books", { keyPath: "book_id" });
      }
      if (!db.objectStoreNames.contains("games")) {
        db.createObjectStore("games", { keyPath: "game_id" });
      }
      if (!db.objectStoreNames.contains("glossary")) {
        db.createObjectStore("glossary", { keyPath: "id" });
      }
      if (!db.objectStoreNames.contains("texts")) {
        db.createObjectStore("texts", { keyPath: "text_id" });
      }
      if (!db.objectStoreNames.contains("topics")) {
        db.createObjectStore("topics", { keyPath: "topic_id" });
      }
      if (!db.objectStoreNames.contains("videos")) {
        db.createObjectStore("videos", { keyPath: "video_id" });
      }
      if (!db.objectStoreNames.contains("skills")) {
        db.createObjectStore("skills", { keyPath: "skill_id" });
      }
      if (!db.objectStoreNames.contains("catalog_data")) {
        db.createObjectStore("catalog_data", { keyPath: "key" });
      }
    };
  
    request.onsuccess = function (event:any) {
      const db = event.target.result;
  
      function saveObjects(storeName, objects) {
        if (objects && objects.length > 0) {
          const transaction = db.transaction([storeName], "readwrite");
          const objectStore = transaction.objectStore(storeName);
  
          objects.forEach((object) => {
            const key = object[objectStore.keyPath];
            const getRequest = objectStore.get(key);
  
            getRequest.onsuccess = function (event) {
              if (event.target.result) {
                const updateRequest = objectStore.put(object);
                updateRequest.onsuccess = function () {};
                updateRequest.onerror = function (event) {
                  console.error(
                    `Error updating ${storeName}`,
                    event.target.error
                  );
                };
              } else {
                const addRequest = objectStore.add(object);
                addRequest.onsuccess = function () {};
                addRequest.onerror = function (event) {
                  console.error(`Error adding ${storeName}`, event.target.error);
                };
              }
            };
  
            getRequest.onerror = function (event) {
              console.error(
                `Error checking for key in ${storeName}`,
                event.target.error
              );
            };
          });
        }
      }
  
      saveObjects("modules", catalogData.modules);
      saveObjects("activities", catalogData.activities);
      saveObjects("books", catalogData.books);
      saveObjects("games", catalogData.games);
      saveObjects("glossary", catalogData.glossary);
      saveObjects("texts", catalogData.texts);
      saveObjects("topics", catalogData.topics);
      saveObjects("videos", catalogData.videos);
      saveObjects("skills", catalogData.skills);
      saveObjects("catalog_data", [
        { key: "catalog_version", version: catalogData.catalog_version },
      ]);
    };
  
    request.onerror = function (event:any) {
      console.error("Error opening database", event.target.error);
    };
  }
  
  function saveGradesProgressOnDB(catalogData) {
    const request = indexedDB.open("grades_progress", 12); // IMPORTANTE: INCREMENTAR EN CADA CAMBIO ESTRUCTURAL DE LA BD
    console.log("saveGradesProgressOnDB: ", request)
    request.onupgradeneeded = function (event:any) {
      const db = event.target.result;
  
      if (!db.objectStoreNames.contains("grades")) {
        db.createObjectStore("grades", { keyPath: "grade_id" });
      }
      if (!db.objectStoreNames.contains("progress")) {
        db.createObjectStore("progress", { keyPath: "progress_id" });
      }
    };
  
    request.onsuccess = function (event:any) {
      const db = event.target.result;
      let progress_storeName = "progress"
      let grades_storeName = "grades"
  
      function saveObjects(storeName, objects) {
        if (objects && objects.length > 0) {
          const transaction = db.transaction([storeName], "readwrite");
          const objectStore = transaction.objectStore(storeName);
  
          objects.forEach((object) => {
            const key = object[objectStore.keyPath];
            const getRequest = objectStore.get(key);
  
            getRequest.onsuccess = function (event) {
              if (event.target.result) {
                const updateRequest = objectStore.put(object);
                updateRequest.onsuccess = function () {};
                updateRequest.onerror = function (event) {
                  console.error(
                    `Error updating ${storeName}`,
                    event.target.error
                  );
                };
              } else {
                const addRequest = objectStore.add(object);
                addRequest.onsuccess = function () {};
                addRequest.onerror = function (event) {
                  console.error(`Error adding ${storeName}`, event.target.error);
                };
              }
            };
  
            getRequest.onerror = function (event) {
              console.error(
                `Error checking for key in ${storeName}`,
                event.target.error
              );
            };
          });
        }
      }
  
      function deleteUserProgress() {
        try{
          const transaction = db.transaction(progress_storeName, "readwrite");
  
          const store = transaction.objectStore(progress_storeName);
          const cursorRequest = store.openCursor();
  
          cursorRequest.onsuccess = function (event) {
            const cursor = event.target.result;
            if (cursor) {
              if (
                user_id != null 
                && cursor.value.student_id == user_id
              ){  
                  cursor.delete()
              }
              cursor.continue();
            } else {
              return;
            }
          };
  
          cursorRequest.onerror = function () {
            console.log("clear_user_progress_error")
          };
        }catch(error){
          console.log("database_error", error)
          return;
        }
      }
  
      function deleteUserGrades() {
        try{
          const transaction = db.transaction(grades_storeName, "readwrite");
  
          const store = transaction.objectStore(grades_storeName);
          const cursorRequest = store.openCursor();
  
          cursorRequest.onsuccess = function (event ) {
            const cursor = event.target.result;
            if (cursor) {
              if (
                user_id != null 
                && cursor.value.student_id == user_id
              ){  
                  cursor.delete()
              }
              cursor.continue();
            } else {
              return;
            }
          };
  
          cursorRequest.onerror = function () {
            console.log("clear_user_grades_error")
          };
        }catch(error){
          console.log("database_error", error)
          return;
        }
      }
  
      deleteUserProgress()
      deleteUserGrades()
      saveObjects("grades", catalogData.grades);
      saveObjects("progress", catalogData.progress);
    };
  
    request.onerror = function (event:any) {
      console.error("Error opening database", event.target.error);
    };
  }
  
  async function saveCatalogVersion(version) {
    return new Promise(async (resolve, reject) => {
      // Wrap in a promise
      const request:any = indexedDB.open("catalog", 12);
  
      request.onupgradeneeded = () => {
        // This event only fires if the database doesn't exist or needs to be upgraded.
        request.transaction.abort(); // Cancel the upgrade to avoid creating the database
  
        if (navigator.onLine) {
          downloadCatalog();
        }
        console.log("database_not_found");
        resolve(0); // Database does not exist
      };
  
      request.onsuccess = async function (event) {
        // Make onsuccess async
        const db = event.target.result;
        const transaction = db.transaction("catalog_data", "readwrite");
        const objectStore = transaction.objectStore("catalog_data");
  
        try {
          const getRequest = objectStore.get("catalog_version");
          const result = await getRequest; // Await the get request
  
          if (result) {
            const updateRequest = objectStore.put({
              key: "catalog_version",
              version: version,
            });
            await updateRequest; // Await the put request
          } else {
            const addRequest = objectStore.add({
              key: "catalog_version",
              version: version,
            });
            await addRequest; // Await the add request
          }
  
          resolve(1); // Resolve the promise
        } catch (error) {
          console.error(`Error:`, error);
          reject(error); // Reject the promise on error
        }
      };
  
      request.onerror = function (event) {
        reject(event.target.error); // Reject the promise
      };
    });
    // return new Promise(async (resolve, reject) => {
    //   // Wrap in a promise
    //   const request = indexedDB.open("versions", 12);
  
    //   request.onupgradeneeded = function (event) {
    //     const db = event.target.result;
    //     if (!db.objectStoreNames.contains(url)) {
    //       db.createObjectStore(url, { keyPath: "url" });
    //     }
    //   };
  
    //   request.onsuccess = async function (event) {
    //     // Make onsuccess async
    //     const db = event.target.result;
    //     const transaction = db.transaction(url, "readwrite");
    //     const objectStore = transaction.objectStore(url);
  
    //     try {
    //       const getRequest = objectStore.get(url);
    //       const result = await getRequest; // Await the get request
  
    //       if (result) {
    //         const updateRequest = objectStore.put({ url: url, version: version });
    //         await updateRequest; // Await the put request
    //       } else {
    //         const addRequest = objectStore.add({ url: url, version: version });
    //         await addRequest; // Await the add request
    //       }
  
    //       resolve(); // Resolve the promise
    //     } catch (error) {
    //       console.error(`Error:`, error);
    //       reject(error); // Reject the promise on error
    //     }
    //   };
  
    //   request.onerror = function (event) {
    //     reject(event.target.error); // Reject the promise
    //   };
    // });
  }
  
  async function getCatalogVersion() {
    return new Promise((resolve, reject) => {
      const request:any = indexedDB.open("catalog", 12);
  
      request.onupgradeneeded = () => {
        // This event only fires if the database doesn't exist or needs to be upgraded.
        request.transaction.abort(); // Cancel the upgrade to avoid creating the database
  
        if (navigator.onLine) {
          downloadCatalog();
        }
        console.log("database_not_found");
        resolve(0); // Database does not exist
      };
  
      request.onerror = (event) => {
        console.error("Error opening IndexedDB:", event.target.error);
        reject(event.target.error);
      };
  
      request.onsuccess = async (event) => {
        const db = event.target.result;
        try {
          const transaction = db.transaction("catalog_data", "readonly");
          const store = transaction.objectStore("catalog_data");
          const getRequest = store.get("catalog_version");
  
          getRequest.onsuccess = (event) => {
            const data = event.target.result;
            if (data) {
              console.log("Version retrieved from IndexedDB:", data.version);
              resolve(data.version);
            } else {
              console.log("version_not_found");
              resolve(0);
            }
          };
  
          getRequest.onerror = (event) => {
            console.error(`get_version_error`, event.target.error);
            resolve(0);
          };
        } catch (error) {
          console.error("database_error", error);
          resolve(0);
        }
      };
    });
  }