<app-global-header-v2 *ngIf="user" [user]="user" [logo]="true" [user_exp]="user_exp" [user_streak]="user_streak" [user_points]="user_points" ></app-global-header-v2>

<div id="student-dashboard" *ngIf="user">
    <div class="navigation-tray light-blue-bk">
        <div class="left">
            <div class="history-card" *ngIf="continue_module != null && continue_topic != null" (click)="goToContinue()">
                <img class="continue-card-bkg" src="{{assetsUrl}}assets/images/modules/covers/{{continue_module['cover']}}.svg" alt="continue-card">
                <div class="title-subtitle">
                    <p class="title" #historyCardTitle>
                        {{userLang == 'en' ? 'History Mode' : 'Modo Historia'}}
                    </p>
                    <p class="subtitle" [ngStyle]="{width: subtitle_width+'px'}">
                        {{continue_topic['name'][userLang]}}
                    </p>
                </div>
                <div *ngIf="continue_button" class="continue-button">
                    <p class="button-text subtitle">
                        {{userLang == 'en' ? 'Continue' : 'Continuar'}}
                    </p>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="options">
                <div class="option-card" (click)="goTo(['/courses'])">
                    <img class="option-bkg" src="assets/images/icons/modules_option_card.svg" alt="option">
                    <p class="option-label title modules">{{userLang=='en'?'Modules':'Módulos'}}</p>
                </div>
                <div class="option-card" (click)="goTo(['/leaderboards'])">
                    <img class="option-bkg" src="assets/images/icons/ranking_option_card.svg" alt="option">
                    <p class="option-label title challenges">{{userLang=='en'?'Ranking':'Ranking'}}</p>
                </div>
            </div>
            <div class="options">
                <div class="option-card" (click)="goTo(['/store'])">
                    <img class="option-bkg" src="assets/images/icons/store_option_card.svg" alt="option">
                    <p class="option-label title store">{{userLang=='en'?'Store':'Tienda'}}</p>
                </div>
                <div class="option-card" (click)="goTo(['/achievements'])">
                    <img class="option-bkg" src="assets/images/icons/retos_option_card.svg" alt="option">
                    <p class="option-label title ranking">{{userLang=='en'?'Challenges':'Retos'}}</p>
                </div>
                <!-- <div class="option-card" (click)="goTo(['/extras', 'financial-health'])">
                    <img class="option-bkg" src="assets/images/icons/retos_option_card.svg" alt="option">
                    <p class="option-label title ranking">{{userLang=='en'?'Challenges':'Retos'}}</p>
                </div> -->
            </div>
            <div class="checkin-card" (click)="toggleStreak()">
                <p class="title">
                    {{userLang == 'en' ? 'Daily check-in' : 'Check-in diario'}}
                </p>
            </div>
        </div>
    </div>

    <!-- Show streak POPUP -->
    <div class="streak-container" *ngIf="show_streak">
        <div class="streak-window">
            <img class="streak-close" src="assets/images/icons/close.svg" (click)="toggleStreak()">
            <img class="streak-avatar" src="{{assetsUrl}}assets/images/avatars/happy/{{user.avatar}}.svg">
            <div class="streak-week-row">
                <ng-container *ngFor="let letter of day_letters; let index = index">
                    <div [ngClass]="{
                        'streak-day-highlight': dayInStreak(index+1),
                        'streak-day': !dayInStreak(index+1)
                        }"
                        [ngStyle]="{'background-color': dayInStreak(index+1) ? getDayColor() : null}"
                        >
                        <p class="subtitle streak-day-letter" [ngClass]="{'gray': !dayInStreak(index+1)}">{{letter[userLang]}}</p>
                    </div>
                </ng-container>
            </div>
            <div class="text-container">
                <p class="title">{{userLang == 'en'? user.streak + ' days of streak!' : '¡' + user.streak + ' días de racha!'}}</p>
                <p class="">{{userLang == 'en'? 'You got ' : 'Has obtenido '}}<span class="orange theme-text">+{{reward}}</span>{{userLang == 'en'? ' as a reward' : ' como recompensa'}}</p>
            </div>
        </div>
    </div>

    <!-- POPUP para publicar mensajes de Inverkids -->
    <div class="ad-container" *ngIf="show_ad">
        <div class="ad-window">
            <img class="ad-close" src="assets/images/icons/close.svg" (click)="toggleAd()">
            <img class="ad-banner" (click)="goToAdRoute()" src="{{assetsUrl}}{{ label ? label+'/' : ''}}{{ userLang === 'en' ? 'en/' : ''}}assets/images/ads/{{found_ad}}.svg">
        </div>
    </div>

    <!-- POPUP para vincular la cuenta del padre  -->
    <div class="parent-onboarding-container" *ngIf="show_parent_onboarding">
      <div class="po-window">
        <img class="po-close" src="assets/images/icons/close.svg" (click)="closeParentOnboarding()">
        <img class="po-avatar" src="{{assetsUrl}}assets/images/avatars/happy/{{user.avatar}}.svg">

        <div class="inner-container">
          <h1 class="title">{{userLang == 'en'? "Register your parent's account and win rewards!" : "¡Registra la cuenta de tus papás y gana recompensas!" }}</h1>
          <button [routerLink]="['/profile', 'parent']" class="tertiary">
            {{userLang == 'en' ? 'Continue' : 'Continuar'}}
          </button>
        </div>
      </div>
    </div>

    <!-- POPUP para mostrar las `pending Attitude Questions -->
    <app-attitude-questions-popup></app-attitude-questions-popup>
</div>
