import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SharedPopupComponent } from '../../../shared/components/shared-popup/shared-popup.component';

declare var UnityLoader: any;
declare var UnityProgress: any;

@Component({
    selector: 'app-game',
    templateUrl: './game.component.html',
    styleUrls: ['./game.component.scss'],
})
export class GameComponent implements OnInit, OnDestroy {
    gameName;
    gameInstance;
    freeGame;
    user;
    gameType;

    constructor(
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private router: Router,
    ) { }

    ngOnInit() {
        this.gameName = this.activatedRoute.snapshot.paramMap.get('game');

        window['fromUnity'] = this.emptyVoid;
        window['goToGames'] = this.emptyVoid;
        window['closeUnity'] = this.goToGames;

        const that = this;
        this.dialog.open(SharedPopupComponent, {
            width: '350px',
            data: {
                title: `¡Estás en la sección gratuita, tu progreso no se guardará!`,
                buttons: [
                    {
                        text: 'Aceptar',
                        action: () => {
                            if (this.gameName === 'Waiz') {
                                this.gameInstance = UnityLoader.instantiate('unityContainer', `assets/unity/Waiz/Unity.json`, {
                                    onProgress: UnityProgress,
                                    Module: {
                                        onRuntimeInitialized: function () {
                                            setTimeout(() => {
                                                this.SendMessage('MainManager', 'SetPlayerName', "FreePlayer");
                                                this.SendMessage('MainManager', 'ToggleNameAsk');
                                            });
                                        },
                                    },
                                });
                            } else {
                                const gameName = this.gameName;
                                this.gameInstance = UnityLoader.instantiate('unityContainer', `assets/unity/Minigames/Unity.json`, {
                                    onProgress: UnityProgress,
                                    Module: {
                                        onRuntimeInitialized: function () {
                                            setTimeout(() => {
                                                this.SendMessage(
                                                    'gameState',
                                                    'loadJSON',
                                                    JSON.stringify({ error: 'error', user: "FreePlayer", name: gameName })
                                                );
                                            });
                                        },
                                    },
                                });
                            }
                            this.dialog.closeAll();
                        },
                    },
                    {
                        text: 'Regresar',
                        action: () => {
                            this.dialog.closeAll();
                            that.router.navigate([`/games`]);
                        },
                    },
                ],
            },
        });
    }

    emptyVoid(): void {
        console.log("Ended a game!");
    }

    goToGames(): void {
        const event = new CustomEvent('goToGames');
        window.dispatchEvent(event);
    }

    @HostListener('window:goToGames')
    getGames(detail): void {
        if (this.gameInstance != null) { this.gameInstance.Quit(); }
        this.router.navigate([`/games`]);
    }

    ngOnDestroy(): void {
        if (this.gameInstance != null) { this.gameInstance.Quit(); }
    }
}
