import { Component, OnInit} from "@angular/core";

import { HttpClient, HttpParams } from "@angular/common/http";
//import { environment } from "src/environments/environment";

@Component({
    selector: 'app-team1',
    templateUrl: './team1.component.html',
    styleUrls: ['./team1.component.scss']
})
export class Team1Component implements OnInit{
    allImages = [
        "nosotros_carousel1.jpeg",
        "nosotros_carousel2.jpeg",
        "nosotros_carousel3.jpeg",
        "nosotros_carousel4.jpeg",
        "nosotros_carousel5.jpeg",
        "nosotros_carousel6.jpeg",
        "nosotros_carousel7.jpeg",
        "nosotros_carousel8.jpeg",
        "nosotros_carousel9.jpeg",
        "nosotros_carousel10.jpeg",
        "nosotros_carousel11.jpeg",
        "nosotros_carousel12.jpeg"
      ];
    
      imagesPerPage = 3;
    
      carouselFrame = 0;
    
      constructor() {}
    
      ngOnInit(): void {

      }
    
      nextCarousel() {
        this.carouselFrame++;
        if (this.carouselFrame >= this.totalPages) {
          this.carouselFrame = 0;
        }
      }
    
      prevCarousel() {
        this.carouselFrame--;
        if (this.carouselFrame < 0) {
          this.carouselFrame = this.totalPages - 1;
        }
      }
    
      get totalPages(): number {
        return Math.ceil(this.allImages.length / this.imagesPerPage);
      }
    
      get imagesToShow() {
        const startIndex = this.carouselFrame * this.imagesPerPage;
        return this.allImages.slice(startIndex, startIndex + this.imagesPerPage);
      }
  }

