<div id="inverlingo">
    <div class="main-container">
        <div id="first-frame" class="light-blue-bk">
            <div class="first-frame-container">
                <div class="title-container">
                    <p class="bold">Próximamente</p>
                    <h1 class="title mt-40">Si la vida te da naranjas, aprende sobre finanzas.</h1>
                    <a href="https://glxsj1kx.paperform.co/" target="_blank">
                        <button class="tertiary mt-40">Registrarme</button>
                    </a>
                </div>
                <div class="image-container">
                    <img class="inverlingo-web" src="assets/images/homepage/inverlingo_web.gif" alt="inverlingo">
                    <img class="inverlingo-mobile" src="assets/images/homepage/inverlingo_mobile.gif" alt="inverlingo">
                </div>
            </div>
        </div>

        <div id="second-frame">
            <h2 class="title big">Sólo te tomará 5 minutos al día</h2>

            <div class="card-list">
                <div class="card">
                    <img src="assets/images/homepage/inversiones.svg" alt="inversiones">
                    <p class="caption mt-30">Aprender sobre</p>
                    <h3 class="card-title mt-10">Inversiones</h3>
                </div>
                <div class="card">
                    <img src="assets/images/homepage/creditos.svg" alt="creditos">
                    <p class="caption mt-30">Aprender sobre</p>
                    <h3 class="card-title mt-10">Créditos</h3>
                </div>
                <div class="card">
                    <img src="assets/images/homepage/impuestos.svg" alt="impuestos">
                    <p class="caption mt-30">Aprender sobre</p>
                    <h3 class="card-title mt-10">Impuestos</h3>
                </div>
                <div class="card">
                    <img src="assets/images/homepage/ahorros.svg" alt="ahorros">
                    <p class="caption mt-30">Aprender sobre</p>
                    <h3 class="card-title mt-10">Ahorros</h3>
                </div>
                <div class="card">
                    <img src="assets/images/homepage/presupuesto.svg" alt="presupuesto">
                    <p class="caption mt-30">Aprender sobre</p>
                    <h3 class="card-title mt-10">Presupuesto</h3>
                </div>
                <div class="card">
                    <img src="assets/images/homepage/retiro.svg" alt="retiro">
                    <p class="caption mt-30">Aprender sobre</p>
                    <h3 class="card-title mt-10">Retiro</h3>
                </div>
            </div>
        </div>

        <div id="third-frame" class="dark-blue-bk">
            <h1 class="title white">La app que te paga por aprender sobre finanzas.</h1>
            <!-- <a href="https://apps.apple.com/us/app/inverkids/id1505098586?ls=1" target="_blank">
                <button *ngIf="OS === 'iOS'" class="download-btn tertiary mt-40">¡Descarga ya!</button>
            </a>
            <a href="https://play.google.com/store/apps/details?id=inverkids.app" target="_blank">
                <button *ngIf="OS === 'Android'" class="download-btn tertiary mt-40">¡Descarga ya!</button>
            </a> -->
            <a href="https://glxsj1kx.paperform.co/" target="_blank">
                <button class="download-btn tertiary mt-40">¡La quiero!</button>
            </a>
        </div>

        <div id="fourth-frame" class="blue-bk">
            <p class="white">¿Cuánto cuesta?</p>
            <h2 class="title big white mt-40">Para ti es gratis, nos asociamos con instituciones financieras y compañías
                para acercarnos a jóvenes y brindarles educación financiera de calidad.</h2>
            <a href="https://glxsj1kx.paperform.co/" target="_blank">
                <button class="white mt-40">Registrarme</button>
            </a>
        </div>

        <app-newsletter class="full-width"></app-newsletter>

        <app-social-footer class="full-width"></app-social-footer>
    </div>
</div>