import { Component, OnInit } from '@angular/core';

import { UserProfileService } from '../shared/services/userProfile.service';
import { Router } from '@angular/router';

import CONTENT from "./modules.content.js";
@Component({
    selector: 'app-modules',
    templateUrl: './modules.component.html',
    styleUrls: ['./modules.component.scss'],
})
export class ModulesComponent implements OnInit {
    content = CONTENT;
    userType;
    userTypeSubscription;
    accountType;
    userLevel;
    userLang;

    constructor(private userProfileService: UserProfileService, public router: Router) {}

    ngOnInit() {
        this.userProfileService.getUserProfile();
        this.userLang = this.userProfileService.getLang();
        this.userTypeSubscription = this.userProfileService.currentType.subscribe((type) => {
            this.userType = type;
            this.accountType = this.userProfileService.getAccountType();
        });
        this.userProfileService.changeUserType(this.userProfileService.getUserType());
        this.userLevel = this.userProfileService.getUserLevel();
    }
}
