export const ACHIEVEMENTS = [
  {
    title: "Ingresos",
    achievements: [
      {
        id: 0,
        type: "Ingresos",
        name: "Ingresos variables",
        points: 100,
        image: "achievement_0",
        unlocked: false,
        description:
          "Para realizar un presupuesto es necesario que tengas en cuenta tus ingresos, pueden existir fijos o variables. Los ingresos variables son el dinero que recibimos, el cual cambia de un periodo a otro. No son ingresos constantes o regulares mes con mes y se suelen recibir en base a nuestro desempeño laboral como bonos o comisiones.",
      },
      {
        id: 1,
        type: "Ingresos",
        name: "Ingreso fijo",
        points: 200,
        image: "achievement_1",
        unlocked: false,
        description:
          "Para realizar un presupuesto es necesario que tengas en cuenta tus ingresos, pueden existir fijos o variables. Los ingresos fijos son el dinero que recibimos, el cual no cambia de un periodo a otro. Son ingresos constantes mes con mes y se suelen recibir en base a nuestro sueldo laboral.",
      },
      {
        id: 2,
        type: "Ingresos",
        name: "Múltiples ingresos fijos",
        points: 300,
        image: "achievement_2",
        unlocked: false,
        description:
          "Que tu familia tenga más de una fuente de ingresos fijos ya sean personales o familiares.",
      },
      {
        id: 3,
        type: "Ingresos",
        name: "Ingresos pasivos",
        points: 400,
        image: "achievement_3",
        unlocked: false,
        description:
          "Un ingreso pasivo aquel en el cual no tenemos que estar activos trabajando para recibir dinero, al contrario, justo no ocupamos estar presentes para recibir nuestro ingreso. Algunos ejemplos son cuando tenemos una propiedad en renta o por los rendimientos que recibimos de nuestra inversiones o negocios.",
      },
    ],
  },
  {
    title: "Planeación",
    achievements: [
      {
        id: 4,
        type: "Planeación",
        name: "Presupuesto activo",
        points: 100,
        image: "achievement_4",
        unlocked: false,
        description:
          "Un presupuesto es el conjunto de ingresos y gastos previstos para un determinado periodo de tiempo con el objetivo de tener control sobre nuestro dinero. El estar activo significa que tienes un presupuesto y lo sigues mes con mes, además de que esta en constante revisión.",
      },
      {
        id: 5,
        type: "Planeación",
        name: "Educación financiera hijos",
        points: 100,
        image: "achievement_5",
        unlocked: false,
        description:
          "La educación financiera en nuestros hijos y sobrinos es vital para desarrollar sus capacidades financieras desde temprana edad. Enseñas a tus hijos la importancia del ahorro, de establecer metas y de ser consumidores inteligentes al momento de comprar.",
      },
      {
        id: 6,
        type: "Planeación",
        name: "Metas financieras ",
        points: 200,
        image: "achievement_6",
        unlocked: false,
        description:
          "Las metas financieras son objetivos que requieren dinero para alcanzarlas. Como comprar ropa, un viaje, la universidad, un carro o una casa. Debes tener tus metas financieras de corto, mediano y largo plazo escritas con la siguiente información: ¿Cuál es mi meta financiera ¿Cuándo la quiero obtener? ¿Cuánto debo ahorrar en total y por mes? y ¿Cómo le haré para alcanzarla?",
      },
      {
        id: 7,
        type: "Planeación",
        name: "Finanzas en pareja",
        points: 200,
        image: "achievement_7",
        unlocked: false,
        description:
          "Las finanzas en pareja son de alta importancia para la formación de un patrimonio. Hay que tener una cita financiera cada mes para ver la situación en la que nos encontramos. Algunas de los temas a tratar son: ¿Cuál es el ingreso de cada uno? ¿Cuánto se destinará en conjunto para el ahorro? ¿Tenemos nuestro documentos en orden? ¿Cuál es nuestro presupuesto familiar e individual?",
      },
      {
        id: 8,
        type: "Planeación",
        name: "Salud fiscal",
        points: 200,
        image: "achievement_8",
        unlocked: false,
        description:
          "Cumplir con tus obligaciones fiscales en tiempo y forma cada periodo.",
      },
      {
        id: 9,
        type: "Planeación",
        name: "Fondo de emergencias",
        points: 500,
        image: "achievement_9",
        unlocked: false,
        description:
          "Un fondo de emergencia es un ahorro líquido con un mínimo de 6 meses de nuestros ingresos o gastos fijos, con el objetivo de que en cualquier imprevisto, accidente o desempleo podamos pagar los siguientes 6 meses nuestros compromisos financieros; evitando un desequilibrio financiero o endeudamiento.",
      },
    ],
  },
  {
    title: "Inversiones",
    achievements: [
      {
        id: 10,
        type: "Inversiones",
        name: "Págate a ti primero",
        points: 100,
        image: "achievement_10",
        unlocked: false,
        description:
          "Una de las principales claves para llegar a formar riqueza es pagarnos a nosotros primero. Esto significa ajustar nuestro presupuesto para que cada mes ahorremos un porcentaje determinado al recibir nuestros ingresos y no ahorrar solo lo que nos sobre. Después de ahorrar entonces si le pagamos al de la gasolinera, al super, al colegio, a los servicios, entre otros.",
      },
      {
        id: 11,
        type: "Inversiones",
        name: "Pagarés o bonos",
        points: 200,
        image: "achievement_11",
        unlocked: false,
        description:
          "Los bonos, certificados de depósito y pagarés son instrumentos de deuda que una empresa o gobierno emiten para financiarse. Es el primer paso dentro de las inversiones y el riesgo así como el rendimiento suelen ser bajos. En vez de tener tus ahorros debajo del colchón o en una cuenta bancaria, tu dinero esta creciendo en dicho instrumento financiero.",
      },
      {
        id: 12,
        type: "Inversiones",
        name: "Negocio personal",
        points: 300,
        image: "achievement_12",
        unlocked: false,
        description:
          "Un negocio es cualquier actividad, ocupación o quehacer que tiene como fin obtener una ganancia. Podemos obtener ingresos activos o pasivos dependiendo del tiempo que nos consuma. En vez de tener tus ahorros debajo del colchón o en una cuenta bancaria, tu dinero esta creciendo en dicho negocio y te esta generando un retorno sobre la inversión.",
      },
      {
        id: 13,
        type: "Inversiones",
        name: "Fondo de inversión",
        points: 300,
        image: "achievement_13",
        unlocked: false,
        description:
          "Los fondos de inversión son un conjunto de instrumentos financieros dentro de una 'canasta' y son administrados por expertos; es el paso que sigue al incrementar nuestros ahorros. El riesgo así como el rendimiento suelen ser medios. En vez de tener tus ahorros debajo del colchón o en una cuenta bancaria, tu dinero esta creciendo en dicho instrumento financiero.",
      },
      {
        id: 14,
        type: "Inversiones",
        name: "Bienes raíces",
        points: 300,
        image: "achievement_14",
        unlocked: false,
        description:
          "Los bienes raíces son todos los bienes inmuebles como edificios, terrenos y cualquier derivado de éstos que están fijados a la tierra. El riesgo suele ser medio y el rendimiento alto. Para contarlo como una inversión activa debe ser una propiedad que te genere rendimientos, a través de rentas o de la plusvalía al venderla.",
      },
    ],
  },
  {
    title: "Seguridad",
    achievements: [
      {
        id: 15,
        type: "Seguridad",
        name: "Seguro de automóvil",
        points: 100,
        image: "achievement_15",
        unlocked: false,
        description:
          "Un seguro de automóvil es un contrato que cubre los riesgos creados por la conducción de automóviles en caso de causar un accidente. Todos los autos de la familia deben tener por lo menos el seguro contra terceros, el cual es el seguro mínimo que por ley debemos tener para poder circular. Lo ideal es tener todos los automóviles asegurados con cobertura amplia.",
      },
      {
        id: 16,
        type: "Seguridad",
        name: "Seguro de hogar",
        points: 200,
        image: "achievement_16",
        unlocked: false,
        description:
          "Un seguro de hogar es un contrato que cubre los riesgos que una vivienda o inmueble sufra. Estos pueden ser derivados de cortocircuitos eléctricos, de daños en la plomería, accidentes domésticos, robos, averías en electrodomésticos, inundaciones, así como por responsabilidad civil originada por daños o lesiones causados en la vivienda.",
      },
      {
        id: 17,
        type: "Seguridad",
        name: "Seguro de Vida",
        points: 300,
        image: "achievement_17",
        unlocked: false,
        description:
          "Un seguro de vida es un contrato que cubre los riesgos de muerte e incapacidad, se garantiza el pago de un capital inmediatamente después de que fallece o se incapacita el asegurado. El seguro de vida es una forma de proteger el futuro de tu familia, se recomienda tener un seguro de vida al tener dependientes.",
      },
      {
        id: 18,
        type: "Seguridad",
        name: "Seguro Médico Familiar",
        points: 500,
        image: "achievement_18",
        unlocked: false,
        description:
          "Un seguro de gastos médicos es un contrato que cubre los riesgos de accidentes o enfermedades, la aseguradora cubre los gastos hospitalarios y médicos a cambio de una prima. Toda la familia debe estar asegurada para prevalecer su salud y protección en todo momento.",
      },
    ],
  },
  {
    title: "Futuro",
    achievements: [
      {
        id: 19,
        type: "Futuro",
        name: "Testamento",
        points: 100,
        image: "achievement_19",
        unlocked: false,
        description:
          "El testamento es el acto jurídico por el cual una persona dispone de todos sus bienes o parte de ellos para ser distribuidos después de su muerte. El testamento de la familia debe estar actualizado.",
      },
      {
        id: 20,
        type: "Futuro",
        name: "Ahorro educativo",
        points: 200,
        image: "achievement_20",
        unlocked: false,
        description:
          "Ahorro formado con el objetivo de pagar la universidad de los hijos sin descapitalizarse en un futuro. Puede ahorrarse e invertirse de manera privada o a través de un seguro para la educación, en donde el ahorro es obligatorio por un periodo de tiempo. Se debe estar ahorrando para todos los hijos de la familia.",
      },
      {
        id: 21,
        type: "Futuro",
        name: "Ahorro para el retiro",
        points: 300,
        image: "achievement_21",
        unlocked: false,
        description:
          "El ahorro para el retiro es un fondo que todos los empleados formales tienen como una prestación laboral. Dicho dinero es administrado por instituciones financieras especializadas las cuales brindan seguridad y rendimientos a nuestras inversiones las cuales podremos obtener al retirarnos.",
      },
      {
        id: 22,
        type: "Futuro",
        name: "Aportaciones voluntarias",
        points: 400,
        image: "achievement_22",
        unlocked: false,
        description:
          "El ahorro voluntario son aportaciones adicionales que se hacen como complemento a nuestro ahorro para el retiro. Asegúrate de contribuir de manera voluntaria a tu ahorro para el retiro cada mes con el objetivo de fortalecer tu futuro financiero.",
      },
      {
        id: 23,
        type: "Futuro",
        name: "Plan Personal de Retiro",
        points: 500,
        image: "achievement_23",
        unlocked: false,
        description:
          "El Plan Personal de Retiro (PPR) es un producto de inversión que complementa el retiro que deseas, gozando de importantes beneficios fiscales y con la posibilidad de generar atractivos rendimientos desde hoy. Las aportaciones al PPR pueden ser deducibles de impuestos.",
      },
      {
        id: 24,
        type: "Futuro",
        name: "Evaluación constante",
        points: 500,
        image: "achievement_24",
        unlocked: false,
        description:
          "Evaluar por lo menos cada mes tu desempeño financiero de ese periodo y analizar el resultado planeado contra el real. Además de manera más profunda cada seis meses evaluar tu situación financiera, revisando a detalle los avances en tus ingresos, ahorros, inversiones, metas financieras, seguridad y ahorro para tu futuro.",
      },
    ],
  },
  {
    title: "Crédito",
    achievements: [
      {
        id: 25,
        type: "Crédito",
        name: "Deudas al corriente",
        points: 200,
        image: "achievement_25",
        unlocked: false,
        description:
          "El crédito es una cantidad de dinero que recibimos con la obligación de pagarla en un plazo determinado más una cantidad adicional por concepto de intereses. Todas nuestras deudas, incluyendo las tarjetas de crédito deben estar al corriente, es decir debemos de pagar cada mes el total establecido de ese periodo.",
      },
      {
        id: 26,
        type: "Crédito",
        name: "Buen historial crediticio",
        points: 200,
        image: "achievement_26",
        unlocked: false,
        description:
          "El historial crediticio es el registro de todas las operaciones relacionadas al crédito que hayamos solicitado. La forma en que paguemos nuestros créditos siempre quedará registrada; si lo hacemos puntualmente, si nos retrasamos o si no pagamos. Tu historial debe ser bueno o excelente.",
      },
      {
        id: 27,
        type: "Crédito",
        name: "Cero deudas",
        points: 300,
        image: "achievement_27",
        unlocked: false,
        description:
          "El crédito es una cantidad de dinero que recibimos con la obligación de pagarla en un plazo determinado más una cantidad adicional por concepto de intereses. Aunque parezca que pedir dinero prestado es malo, no siempre es así, pero siempre es bueno no tener pasivos o deudas en nuestro mes a mes.",
      },
      {
        id: 28,
        type: "Crédito",
        name: "Sano endeudamiento",
        points: 500,
        image: "achievement_28",
        unlocked: false,
        description:
          "Un sano endeudamiento se considera cuando dicho crédito nos ayudará a incrementar nuestro patrimonio, por ejemplo para iniciar un negocio, invertir en bienes raíces o comprar bienes duraderos, los cuales son productos o servicios que pueden ser utilizados un gran número de veces a lo largo de un gran periodo de tiempo, no son compras de un uso o de corto plazo.",
      },
    ],
  },
];

export const ACHIEVEMENTS_EN = [
  {
    title: "Income",
    achievements: [
      {
        id: 0,
        type: "Income",
        name: "Variable Income",
        points: 100,
        image: "achievement_0",
        unlocked: false,
        description:
          "To make a budget you need to take into account your income, which can be fixed or variable. Variable income is the money we receive, which changes from one period to another. It is not a constant or regular income from month to month and is usually received based on our work performance, such as bonuses or commissions.",
      },
      {
        id: 1,
        type: "Income",
        name: "Fixed Income",
        points: 200,
        image: "achievement_1",
        unlocked: false,
        description:
          "Para realizar un presupuesto es necesario que tengas en cuenta tus ingresos, pueden existir fijos o variables. Los ingresos fijos son el dinero que recibimos, el cual no cambia de un periodo a otro. Son ingresos constantes mes con mes y se suelen recibir en base a nuestro sueldo laboral.",
      },
      {
        id: 2,
        type: "Income",
        name: "Multiple Fixed Income",
        points: 300,
        image: "achievement_2",
        unlocked: false,
        description:
          "That your family has more than one source of fixed income, either personal or family.",
      },
      {
        id: 3,
        type: "Income",
        name: "Passive Income",
        points: 400,
        image: "achievement_3",
        unlocked: false,
        description:
          "A passive income is one in which we do not have to be actively working to receive money, on the contrary, we just do not need to be present to receive our income. Some examples are when we have a rental property or for the returns we receive from our investments or businesses.",
      },
    ],
  },
  {
    title: "Planning",
    achievements: [
      {
        id: 4,
        type: "Planning",
        name: "Active Budget",
        points: 100,
        image: "achievement_4",
        unlocked: false,
        description:
          "A budget is the set of income and expenses planned for a certain period of time with the objective of having control over our money. Being active means that you have a budget and you follow it month by month, and it is constantly under review.",
      },
      {
        id: 5,
        type: "Planning",
        name: "Children Financial Education",
        points: 100,
        image: "achievement_5",
        unlocked: false,
        description:
          "Financial education for our children and nieces and nephews is vital to develop their financial skills from an early age. You teach your children the importance of saving, setting goals and being smart consumers when shopping.",
      },
      {
        id: 6,
        type: "Planning",
        name: "Financial Goals",
        points: 200,
        image: "achievement_6",
        unlocked: false,
        description:
          "Financial goals are objectives that require money to achieve. Such as buying clothes, a trip, college, a car or a house. You should have your short, medium and long term financial goals written down with the following information: What is my financial goal, when do I want to achieve it, how much do I need to save in total and per month, and how will I achieve it?",
      },
      {
        id: 7,
        type: "Planning",
        name: "Finance in Relationships",
        points: 200,
        image: "achievement_7",
        unlocked: false,
        description:
          "The finances in couple are of high importance for the formation of a patrimony. It is necessary to have a financial appointment every month to see the situation in which we are. Some of the topics to be discussed are: What is the income of each one? How much will be destined jointly for savings? Do we have our documents in order? What is our family and individual budget?",
      },
      {
        id: 8,
        type: "Planning",
        name: "Financial Health",
        points: 200,
        image: "achievement_8",
        unlocked: false,
        description:
          "Comply with your tax obligations in due time and form each period.",
      },
      {
        id: 9,
        type: "Planning",
        name: "Emergency Fund",
        points: 500,
        image: "achievement_9",
        unlocked: false,
        description:
          "An emergency fund is a liquid savings with a minimum of 6 months of our income or fixed expenses, with the objective that in any unforeseen event, accident or unemployment we can pay the following 6 months our financial commitments; avoiding a financial imbalance or indebtedness.",
      },
    ],
  },
  {
    title: "Investment",
    achievements: [
      {
        id: 10,
        type: "Investment",
        name: "Pay Yourself First",
        points: 100,
        image: "achievement_10",
        unlocked: false,
        description:
          "One of the main keys to building wealth is to pay ourselves first. This means adjusting our budget so that each month we save a certain percentage when we receive our income and not save only what we have left over. After saving then if we pay the gas station, supermarket, school, utilities, among others.",
      },
      {
        id: 11,
        type: "Investment",
        name: "Notes or Bonds",
        points: 200,
        image: "achievement_11",
        unlocked: false,
        description:
          "Bonds, certificates of deposit and promissory notes are debt instruments that a company or government issues to finance itself. They are the first step into investments and the risk and return are usually low. Instead of having your savings under the mattress or in a bank account, your money is growing in this financial instrument.",
      },
      {
        id: 12,
        type: "Investment",
        name: "Personal Business",
        points: 300,
        image: "achievement_12",
        unlocked: false,
        description:
          "A business is any activity, occupation or task that has the purpose of obtaining a profit. We can earn active or passive income depending on the time it consumes. Instead of having your savings under the mattress or in a bank account, your money is growing in this business and is generating a return on investment.",
      },
      {
        id: 13,
        type: "Investment",
        name: "Investment Fond",
        points: 300,
        image: "achievement_13",
        unlocked: false,
        description:
          "Mutual funds are a set of financial instruments within a 'basket' and are managed by experts; it is the next step in increasing our savings. The risk as well as the return is usually average. Instead of having your savings under the mattress or in a bank account, your money is growing in such a financial instrument.",
      },
      {
        id: 14,
        type: "Investment",
        name: "Real Estate",
        points: 300,
        image: "achievement_14",
        unlocked: false,
        description:
          "Real estate is all real property such as buildings, land and any derivatives thereof that are affixed to the land. The risk is usually medium and the return is high. To count as an active investment it must be a property that generates returns for you, through rents or capital gains when you sell it.",
      },
    ],
  },
  {
    title: "Security",
    achievements: [
      {
        id: 15,
        type: "Security",
        name: "Car Insurance",
        points: 100,
        image: "achievement_15",
        unlocked: false,
        description:
          "An automobile insurance is a contract that covers the risks created by the driving of automobiles in case of causing an accident. All cars in the family must have at least third party insurance, which is the minimum insurance required by law to be able to circulate. The ideal is to have all the automobiles insured with comprehensive coverage.",
      },
      {
        id: 16,
        type: "Security",
        name: "Home Insurance",
        points: 200,
        image: "achievement_16",
        unlocked: false,
        description:
          "A home insurance policy is a contract that covers the risks that a home or property suffers. These can be derived from electrical short circuits, plumbing damage, domestic accidents, theft, appliance breakdowns, floods, as well as civil liability arising from damages or injuries caused in the home.",
      },
      {
        id: 17,
        type: "Security",
        name: "Life Insurance",
        points: 300,
        image: "achievement_17",
        unlocked: false,
        description:
          "A life insurance policy is a contract that covers the risks of death and disability, it guarantees the payment of a capital sum immediately after the death or disability of the insured. Life insurance is a way to protect your family's future, it is recommended to have life insurance when you have dependents.",
      },
      {
        id: 18,
        type: "Security",
        name: "Family Health Insurance",
        points: 500,
        image: "achievement_18",
        unlocked: false,
        description:
          "A medical expense insurance is a contract that covers the risks of accidents or illnesses, the insurer covers hospital and medical expenses in exchange for a premium. The whole family should be insured to ensure their health and protection at all times.",
      },
    ],
  },
  {
    title: "Future",
    achievements: [
      {
        id: 19,
        type: "Future",
        name: "Last Will and Testament",
        points: 100,
        image: "achievement_19",
        unlocked: false,
        description:
          "A will is the legal act by which a person disposes of all or part of his or her assets to be distributed after death. The family will must be kept up to date.",
      },
      {
        id: 20,
        type: "Future",
        name: "Eduaction Savings",
        points: 200,
        image: "achievement_20",
        unlocked: false,
        description:
          "Savings formed for the purpose of paying for the children's college education without decapitalizing in the future. It can be saved and invested privately or through an education insurance, where saving is mandatory for a period of time. You should be saving for all the children in the family.",
      },
      {
        id: 21,
        type: "Future",
        name: "Retirement Savings",
        points: 300,
        image: "achievement_21",
        unlocked: false,
        description:
          "Retirement savings is a fund that all formal employees have as a labor benefit. This money is managed by specialized financial institutions which provide security and returns on our investments, which we can obtain upon retirement.",
      },
      {
        id: 22,
        type: "Future",
        name: "Voluntary Contributions",
        points: 400,
        image: "achievement_22",
        unlocked: false,
        description:
          "Voluntary savings are additional contributions made to complement our retirement savings. Be sure to voluntarily contribute to your retirement savings each month to strengthen your financial future.",
      },
      {
        id: 23,
        type: "Future",
        name: "Personal Retirement Plan",
        points: 500,
        image: "achievement_23",
        unlocked: false,
        description:
          "The Personal Retirement Plan (PRP) is an investment product that complements the retirement you desire, enjoying important tax benefits and with the possibility of generating attractive returns starting today. Contributions to the PPR may be tax deductible.",
      },
      {
        id: 24,
        type: "Future",
        name: "Constant Evaluation",
        points: 500,
        image: "achievement_24",
        unlocked: false,
        description:
          "Evaluate at least every month your financial performance for that period and analyze your planned versus actual results. In addition, every six months evaluate your financial situation, reviewing in detail the progress of your income, savings, investments, financial goals, security and savings for your future.",
      },
    ],
  },
  {
    title: "Credit",
    achievements: [
      {
        id: 25,
        type: "Credit",
        name: "Debts Up to Date",
        points: 200,
        image: "achievement_25",
        unlocked: false,
        description:
          "Credit is an amount of money that we receive with the obligation to pay it in a determined period of time plus an additional amount of interest. All our debts, including credit cards, must be current, i.e. we must pay each month the total amount due for that period.",
      },
      {
        id: 26,
        type: "Credit",
        name: "Good Credit History",
        points: 200,
        image: "achievement_26",
        unlocked: false,
        description:
          "The credit history is the record of all transactions related to the credit we have applied for. The way we pay our credits will always be recorded; if we pay on time, if we are late or if we do not pay at all. Your credit history should be good or excellent.",
      },
      {
        id: 27,
        type: "Credit",
        name: "Zero Debt",
        points: 300,
        image: "achievement_27",
        unlocked: false,
        description:
          "Credit is an amount of money that we receive with the obligation to pay it back within a certain period of time plus an additional amount of interest. Although it may seem that borrowing money is bad, it is not always so, but it is always good not to have liabilities or debts in our month to month.",
      },
      {
        id: 28,
        type: "Credit",
        name: "Healthy Debt",
        points: 500,
        image: "achievement_28",
        unlocked: false,
        description:
          "Healthy debt is considered when such credit will help us to increase our wealth, for example to start a business, invest in real estate or buy durable goods, which are products or services that can be used a large number of times over a long period of time, not one-time or short-term purchases.",
      },
    ],
  },
];

export const ACHIEVEMENTS_NO_SECTION = [
  {
    "id": 0,
    "type": "Meta financiera",
    "name": "Escribí mi meta financiera",
    "image": "reto_student_0",
    "unlocked": false,
    "description": "¡Acabas de dar el primer paso para lograr tu meta financiera! Primero hay que escribirla y proponernos ahorrar para ella. ¡Sin duda lo lograrás, esfuérzate mucho!"
  },
  {
    "id": 1,
    "type": "Meta financiera",
    "name": "Llevo ahorrado 10% de mi meta",
    "image": "reto_student_1",
    "unlocked": false,
    "description": "Ya has empezado tus ahorros, llevas 10%, recuerda paso a paso lograrás grandes cosas."
  },
  {
    "id": 2,
    "type": "Meta financiera",
    "name": "Llevo ahorrado 50% de mi meta",
    "image": "reto_student_2",
    "unlocked": false,
    "description": "¡Vas a la mitad, tú puedes lograrlo! Sigue ahorrando así, ya pronto estarás cerca de alcanzar tu meta financiera."
  },
  {
    "id": 3,
    "type": "Meta financiera",
    "name": "Llevo ahorrado 75% de mi meta",
    "image": "reto_student_3",
    "unlocked": false,
    "description": "¡Wow! Estas a nada de juntar los ahorros para alcanzar tu meta, sigue así. ¡Tu puedes!"
  },
  {
    "id": 4,
    "type": "Meta financiera",
    "name": "¡Logré mi meta financiera!",
    "image": "reto_student_4",
    "unlocked": false,
    "description": "¡Muchas felicidades! Has logrado ahorrar lo suficiente para alcanzar tu meta financiera, que gran disciplina tienes. ¿Comprarás y disfrutarás tu meta o seguirás ahorrando para algo diferente?"
  },
  {
    "id": 5,
    "type": "Ingresos",
    "name": "Recibí dinero",
    "image": "reto_student_5",
    "unlocked": false,
    "description": "¡Qué padre! Tus padres, tíos o abuelos te dieron dinero en la semana."
  },
  {
    "id": 6,
    "type": "Ingresos",
    "name": "Me asignaron tareas",
    "image": "reto_student_6",
    "unlocked": false,
    "description": "¡Bienvenido! Tus papás te asignaron tareas dentro de la sección de tus \"Finanzas\" por las cuales te pagarán una recompensa al cumplirlas."
  },
  {
    "id": 7,
    "type": "Ingresos",
    "name": "Cumplí 3 tareas en una semana",
    "image": "reto_student_7",
    "unlocked": false,
    "description": "¡Felicidades! Lograste realizar tus primeras 3 tareas que te asignaron tus papás en la sección de \"Finanzas\" y las palomeaste para que tus papás sean notificados de cumplidas."
  },
  {
    "id": 8,
    "type": "Ingresos",
    "name": "Cumplí 20 tareas en un mes",
    "image": "reto_student_8",
    "unlocked": false,
    "description": "¡Vas por excelente camino! Lograste realizar 20 tareas a lo largo del mes que tus papás te asignaron en la sección de \"Finanzas\" y las palomeaste para que tus papás fueran notificados de cumplidas."
  },
  {
    "id": 9,
    "type": "Ingresos",
    "name": "Cumplí las tareas de 3 meses",
    "image": "reto_student_9",
    "unlocked": false,
    "description": "¡Wow, cumpliste un super reto! Lograste realizar las tareas que tus papás te asignaron a lo largo de 3 meses y fuiste recompensado por cumplirlas."
  },
  {
    "id": 10,
    "type": "Ingresos",
    "name": "Cumplí las tareas de 6 meses",
    "image": "reto_student_10",
    "unlocked": false,
    "description": "¡Eres increíble y muy responsable! Lograste realizar las tareas que tus papás te asignaron a lo largo de 6 meses y fuiste recompensado por cumplirlas."
  },
  {
    "id": 11,
    "type": "Ahorro",
    "name": "Tengo una alcancía",
    "image": "reto_student_11",
    "unlocked": false,
    "description": "Tienes una alcancía para ahorrar tu dinero. ¡Puede ser grande, chica o de muchos o pocos colores! Lo importante es que tu alcancía quiere ser llenada de dinero."
  },
  {
    "id": 12,
    "type": "Ahorro",
    "name": "Ahorré $100",
    "image": "reto_student_12",
    "unlocked": false,
    "description": "¡Excelente primer paso! Ahorraste $100 pesos en tu alcancía. Recuerda no sacarlos aún."
  },
  {
    "id": 13,
    "type": "Ahorro",
    "name": "Ahorré $200",
    "image": "reto_student_13",
    "unlocked": false,
    "description": "¡Sigue avanzando! Ya llevas $250 pesos ahorrados en tu alcancía. Recuerda no sacarlos aún."
  },
  {
    "id": 14,
    "type": "Ahorro",
    "name": "Ahorré $400",
    "image": "reto_student_14",
    "unlocked": false,
    "description": "¡Vas increíble! Ya llevas $500 pesos ahorrados en tu alcancía. Recuerda no sacarlos aún, tu puedes."
  },
  {
    "id": 15,
    "type": "Ahorro",
    "name": "Ahorré $1,000",
    "image": "reto_student_15",
    "unlocked": false,
    "description": "¡Wow, muchas felicidades! Lograste ahorrar $1,000 pesos en tu alcancía. ¿Puedes seguir ahorrando más?"
  },
  {
    "id": 16,
    "type": "Ahorro",
    "name": "Ahorré $2,000",
    "image": "reto_student_16",
    "unlocked": false,
    "description": "¡Lograste seguir ahorrando más! No te detengas, si sigues así, ahora... ¿Estas listo para ahorrar formalmente en el banco?"
  },
  {
    "id": 17,
    "type": "Banco",
    "name": "Abrí mi cuenta bancaria",
    "image": "reto_student_17",
    "unlocked": false,
    "description": "¡Has dado un gran paso, muchas felicidades! Al abrir tu primer cuenta bancaria podrás ahorrar formalmente."
  },
  {
    "id": 18,
    "type": "Banco",
    "name": "Deposité mis ahorros en mi cuenta ",
    "image": "reto_student_18",
    "unlocked": false,
    "description": "¡Excelente! El uso principal de las cuentas bancarias es depositar nuestros ahorros para mantenerlos seguros."
  },
  {
    "id": 19,
    "type": "Banco",
    "name": "Tengo $500 en mi cuenta bancaria",
    "image": "reto_student_19",
    "unlocked": false,
    "description": "¡Vas muy bien! Tienes ahorrados $500 pesos en tu cuenta bancaria. ¡Sigue haciendo crecer tu cuenta bancaria!"
  },
  {
    "id": 20,
    "type": "Banco",
    "name": "Tengo $1,000 en mi cuenta bancaria",
    "image": "reto_student_20",
    "unlocked": false,
    "description": "¡Felicidades! Tienes ahorrados $1,000 pesos en tu cuenta bancaria. ¿Estas listo para seguir así y duplicarla?"
  },
  {
    "id": 21,
    "type": "Banco",
    "name": "Tengo $2,000 en mi cuenta bancaria",
    "image": "reto_student_21",
    "unlocked": false,
    "description": " ¡Wow, tienes gran disciplina! Lograste ahorrar y duplicar tu dinero a $2,000 pesos en tu cuenta bancaria. ¿Estas listo para invertir?"
  },
  {
    "id": 22,
    "type": "Inversión",
    "name": "Abrí mi cuenta de inversión",
    "image": "reto_student_22",
    "unlocked": false,
    "description": "¡Estas avanzando muy rápido, muchas felicidades! Antes ahorrabas en una alcancía y ahora haces crecer tu dinero en una inversión, sigue así."
  },
  {
    "id": 23,
    "type": "Inversión",
    "name": "Invertí $100",
    "image": "reto_student_23",
    "unlocked": false,
    "description": "¡Wow! Has logrado tu primer inversión, tus primeros $100 pesos que trabajarán para ti y crecerán poco a poco."
  },
  {
    "id": 24,
    "type": "Inversión",
    "name": "Invertí $500",
    "image": "reto_student_24",
    "unlocked": false,
    "description": "¡Excelente decisión seguir invirtiendo! Ya llegaste a $500 pesos invertidos... ¿listo para duplicarlos?"
  },
  {
    "id": 25,
    "type": "Inversión",
    "name": "Invertí $1,000",
    "image": "reto_student_25",
    "unlocked": false,
    "description": "¡Lo lograste! Antes tenías $1,000 pesos ahorrar y ahora los invertiste y están creciendo, sigue así. ¿Qué te han parecido las inversiones, te gustan?"
  },
  {
    "id": 26,
    "type": "Inversión",
    "name": "Invertí $2,000",
    "image": "achievement_26",
    "unlocked": false,
    "description": "$2,000 pesos invertidos y trabajando para ti... ¡¿Puedes creerlo?!"
  },
  {
    "id": 27,
    "type": "Inversión",
    "name": "¡Mi inversión creció!",
    "image": "reto_student_27",
    "unlocked": false,
    "description": "¡Increíble, tu inversión creció! Esperemos siga así, creciendo y trabajando para ti."
  },
  {
    "id": 28,
    "type": "Inversión",
    "name": "Mi inversión bajo",
    "image": "reto_student_28",
    "unlocked": false,
    "description": "No te preocupes, recuerda podemos perder dinero en una inversión, pero espera, pronto tu inversión crecerá."
  },
  {
    "id": 29,
    "type": "Emprender",
    "name": "Abrí mi negocio",
    "image": "reto_student_29",
    "unlocked": false,
    "description": "Gran paso, has descubierto una nueva forma de invertir el dinero y hacerlo crecer."
  },
  {
    "id": 30,
    "type": "Emprender",
    "name": "Gané $100 en mi negocio",
    "image": "reto_student_30",
    "unlocked": false,
    "description": "¡Felicidades! Tus primeros $100 pesos ganados en tu negocio, sigue así."
  },
  {
    "id": 31,
    "type": "Emprender",
    "name": "Gané $500 en mi negocio",
    "image": "reto_student_31",
    "unlocked": false,
    "description": "¡Excelente! Ya llevas $500 pesos ganados en tu negocio, ¿qué sientes? Sigue así."
  },
  {
    "id": 32,
    "type": "Emprender",
    "name": "Gané $1,000 en mi negocio",
    "image": "reto_student_32",
    "unlocked": false,
    "description": "¡Has logrado una gran inversión! Tu dinero y esfuerzo te hicieron ganar $1,000 pesos."
  },
  {
    "id": 33,
    "type": "Donación",
    "name": "Mi primer donación",
    "image": "reto_student_33",
    "unlocked": false,
    "description": "Qué gran gesto, siempre es bueno ayudar a otras personas."
  },
  {
    "id": 34,
    "type": "Donación",
    "name": "Doné ropa",
    "image": "reto_student_34",
    "unlocked": false,
    "description": "¡Felicidades! Le has dado una segunda vida a tu ropa, estoy segura hiciste a otras personas muy felices."
  },
  {
    "id": 35,
    "type": "Donación",
    "name": "Doné juguetes",
    "image": "reto_student_35",
    "unlocked": false,
    "description": "Seguro tienes grandes recuerdos con tus juguetes; ahora otros niños y niñas se divertirán mucho gracias a tu donación."
  },
  {
    "id": 36,
    "type": "Donación",
    "name": "Doné a 2 asociaciones",
    "points": 500,
    "image": "reto_student_36",
    "unlocked": false,
    "description": "Ayudar a través de asociaciones es increíble, así podemos llegar a muchas más causas que apoyen ya sea a personas o animales. ¿Qué sentiste al donar?"
  },
  {
    "id": 37,
    "type": "Donación",
    "name": "Campaña de donación",
    "image": "reto_student_37",
    "unlocked": false,
    "description": "¡Unidos sumamos más! Qué increíble que pudiste participar u organizar una campaña de donación para ayudar a los que lo necesitan."
  },
  {
    "id": 38,
    "type": "Familia",
    "name": "Ayudé a mis papás con sus metas",
    "image": "reto_student_38",
    "unlocked": false,
    "description": "¡Qué gusto que estas compartiendo tus nuevas habilidades de escribir las metas financieras para alcanzarlas!"
  },
  {
    "id": 39,
    "type": "Familia",
    "name": "Ayudé a mis papás a ahorrar",
    "image": "reto_student_39",
    "unlocked": false,
    "description": "¡Qué gusto que estas compartiendo tus nuevas habilidades de ahorro! Es escencial que más personas conozcan la importancia del ahorro a lo largo de nuestra vida. "
  },
  {
    "id": 40,
    "type": "Familia",
    "name": "Ayudé a mis papás a invertir",
    "image": "reto_student_40",
    "unlocked": false,
    "description": "¡Qué gusto que estas compartiendo tus nuevas habilidades de inversión! Es importante que más personas conozcan el beneficio de invertir nuestro dinero y hacerlo crecer."
  },
  {
    "id": 41,
    "type": "Familia",
    "name": "Ayudé a mis papás a donar",
    "image": "reto_student_41",
    "unlocked": false,
    "description": "¡Qué gusto que estas compartiendo tus nuevas habilidades de donación! Es importante que más personas se sumen a donar y ayudar a los que lo necesiten."
  }
];

export const ACHIEVEMENTS_NO_SECTION_EN = [
  {
    "id": 0,
    "type": "Financial Goal",
    "name": "Wrote My Financial Goal",
    "image": "reto_student_0",
    "unlocked": false,
    "description": "You have just taken the first step towards achieving your financial goal! First you have to write it down and set out to save for it, you will definitely achieve it, try hard!"
  },
  {
    "id": 1,
    "type": "Financial Goal",
    "name": "Saved 10% of My Goal",
    "image": "reto_student_1",
    "unlocked": false,
    "description": "You have already started your savings, you have 10%, remember step by step you will achieve great things."
  },
  {
    "id": 2,
    "type": "Financial Goal",
    "name": "Saved 50% of My Goal",
    "image": "reto_student_2",
    "unlocked": false,
    "description": "You are halfway there, you can do it! Keep saving like this, you will soon be close to reaching your financial goal."
  },
  {
    "id": 3,
    "type": "Financial Goal",
    "name": "Saved 75% of My Goal",
    "image": "reto_student_3",
    "unlocked": false,
    "description": "Wow! You are just a few minutes away from having the savings to reach your goal, keep it up, you can do it!"
  },
  {
    "id": 4,
    "type": "Financial Goal",
    "name": "Achived My Financial Goal!",
    "image": "reto_student_4",
    "unlocked": false,
    "description": "Congratulations! You have managed to save enough to reach your financial goal, what great discipline you have, will you buy and enjoy your goal or will you keep saving for something else?"
  },
  {
    "id": 5,
    "type": "Income",
    "name": "Got Money",
    "image": "reto_student_5",
    "unlocked": false,
    "description": "How cool! Your parents, aunts, uncles or grandparents gave you money during the week."
  },
  {
    "id": 6,
    "type": "Income",
    "name": "I Got a Task Assigned",
    "image": "reto_student_6",
    "unlocked": false,
    "description": "Welcome! Your parents have assigned you tasks within your \"Finances\" section for which they will pay you a reward upon completion."
  },
  {
    "id": 7,
    "type": "Income",
    "name": "Completed 3 Tasks in 1 Week",
    "image": "reto_student_7",
    "unlocked": false,
    "description": "Congratulations! You managed to complete your first 3 tasks assigned to you by your parents in the \"Finances\" section and you have checked them off so that your parents can be notified that you have completed them."
  },
  {
    "id": 8,
    "type": "Income",
    "name": "Completed 20 Tasks in 1 Month",
    "image": "reto_student_8",
    "unlocked": false,
    "description": "You are on the right track! You managed to complete 20 tasks throughout the month that your parents assigned to you in the \"Finances\" section and you submitted them so that your parents could be notified that you had completed them."
  },
  {
    "id": 9,
    "type": "Income",
    "name": "Completed the Tasks of 3 Months",
    "image": "reto_student_9",
    "unlocked": false,
    "description": "Wow, you accomplished a super challenge! You managed to complete the tasks your parents assigned you over the course of 3 months and you were rewarded for accomplishing them."
  },
  {
    "id": 10,
    "type": "Income",
    "name": "Completed the Tasks of 6 Months",
    "image": "reto_student_10",
    "unlocked": false,
    "description": "You are amazing and very responsible! You managed to accomplish the tasks your parents assigned you over the course of 6 months and were rewarded for accomplishing them."
  },
  {
    "id": 11,
    "type": "Savings",
    "name": "I Have a Piggy Bank",
    "image": "reto_student_11",
    "unlocked": false,
    "description": "You have a piggy bank to save your money. It can be big, small or many or few colors! The important thing is that your piggy bank wants to be filled with money."
  },
  {
    "id": 12,
    "type": "Savings",
    "name": "Saved $100",
    "image": "reto_student_12",
    "unlocked": false,
    "description": "Excellent first step! You saved $100 pesos in your piggy bank. Remember not to take it out yet."
  },
  {
    "id": 13,
    "type": "Savings",
    "name": "Saved $200",
    "image": "reto_student_13",
    "unlocked": false,
    "description": "Keep going! You already have $250 pesos saved in your piggy bank. Remember not to take it out yet."
  },
  {
    "id": 14,
    "type": "Savings",
    "name": "Saved $400",
    "image": "reto_student_14",
    "unlocked": false,
    "description": "¡Vas increíble! Ya llevas $500 pesos ahorrados en tu alcancía. Recuerda no sacarlos aún, tu puedes."
  },
  {
    "id": 15,
    "type": "Savings",
    "name": "Saved $1,000",
    "image": "reto_student_15",
    "unlocked": false,
    "description": "Wow, congratulations! You managed to save $1,000 pesos in your piggy bank. Can you keep saving more?"
  },
  {
    "id": 16,
    "type": "Savings",
    "name": "Saved $2,000",
    "image": "reto_student_16",
    "unlocked": false,
    "description": "You managed to keep saving more! Do not stop, if you keep it up, now.... Are you ready to save formally in the bank?"
  },
  {
    "id": 17,
    "type": "Bank",
    "name": "Opened a Bank Account",
    "image": "reto_student_17",
    "unlocked": false,
    "description": "You have taken a big step, congratulations! By opening your first bank account you will be able to save formally."
  },
  {
    "id": 18,
    "type": "Bank",
    "name": "Deposited My Savings Into My Account",
    "image": "reto_student_18",
    "unlocked": false,
    "description": "Excellent! The main use of bank accounts is to deposit our savings to keep them safe."
  },
  {
    "id": 19,
    "type": "Bank",
    "name": "I Have $500 in My Bank Account",
    "image": "reto_student_19",
    "unlocked": false,
    "description": "You are doing great! You have $500 pesos saved in your bank account, keep growing your bank account!"
  },
  {
    "id": 20,
    "type": "Bank",
    "name": "I Have $1,000 in My Bank Account",
    "image": "reto_student_20",
    "unlocked": false,
    "description": "Congratulations! You have $1,000 pesos saved in your bank account, are you ready to keep it up and double it?"
  },
  {
    "id": 21,
    "type": "Bank",
    "name": "I Have $2,000 in My Bank Account",
    "image": "reto_student_21",
    "unlocked": false,
    "description": "Wow, you have great discipline! You managed to save and double your money to $2,000 pesos in your bank account. Are you ready to invest?"
  },
  {
    "id": 22,
    "type": "Investment",
    "name": "Opened An Investment Account",
    "image": "reto_student_22",
    "unlocked": false,
    "description": "You are advancing very fast, congratulations! Before you were saving in a piggy bank and now you are making your money grow in an investment, keep it up."
  },
  {
    "id": 23,
    "type": "Investment",
    "name": "Invested $100",
    "image": "reto_student_23",
    "unlocked": false,
    "description": "Wow! You have made your first investment, your first $100 pesos that will work for you and grow little by little."
  },
  {
    "id": 24,
    "type": "Investment",
    "name": "Invested $500",
    "image": "reto_student_24",
    "unlocked": false,
    "description": "Excellent decision to keep investing! You have already reached $500 pesos invested... ready to double it?"
  },
  {
    "id": 25,
    "type": "Investment",
    "name": "Invested $1,000",
    "image": "reto_student_25",
    "unlocked": false,
    "description": "You did it! Before you had $1,000 pesos to save and now you invested it and it is growing, keep it up. What did you think of the investments, do you like them?"
  },
  {
    "id": 26,
    "type": "Investment",
    "name": "Invested $2,000",
    "image": "achievement_26",
    "unlocked": false,
    "description": "$2,000 pesos invested and working for you... Can you believe it!"
  },
  {
    "id": 27,
    "type": "Investment",
    "name": "My Investment Has Grown!",
    "image": "reto_student_27",
    "unlocked": false,
    "description": "Incredible, your investment has grown! Let us hope it keeps growing and working for you."
  },
  {
    "id": 28,
    "type": "Investment",
    "name": "My Investment Has Shrunk!",
    "image": "reto_student_28",
    "unlocked": false,
    "description": "Do not worry, remember we can lose money on an investment, but wait, soon your investment will grow."
  },
  {
    "id": 29,
    "type": "Start a Business",
    "name": "Started a Business",
    "image": "reto_student_29",
    "unlocked": false,
    "description": "Great step, you have discovered a new way to invest money and make it grow."
  },
  {
    "id": 30,
    "type": "Start a Business",
    "name": "Earned $100 With My Business",
    "image": "reto_student_30",
    "unlocked": false,
    "description": "¡Felicidades! Tus primeros $100 pesos ganados en tu negocio, sigue así."
  },
  {
    "id": 31,
    "type": "Start a Business",
    "name": "Earned $500 With My Business",
    "image": "reto_student_31",
    "unlocked": false,
    "description": "¡Excelente! Ya llevas $500 pesos ganados en tu negocio, ¿qué sientes? Sigue así."
  },
  {
    "id": 32,
    "type": "Start a Business",
    "name": "Earned $1,000 With My Business",
    "image": "reto_student_32",
    "unlocked": false,
    "description": "You have made a great investment! Your money and effort earned you $1,000 pesos."
  },
  {
    "id": 33,
    "type": "Donation",
    "name": "My First Donation",
    "image": "reto_student_33",
    "unlocked": false,
    "description": "What a great gesture, it is always good to help other people."
  },
  {
    "id": 34,
    "type": "Donation",
    "name": "Donated Clothes",
    "image": "reto_student_34",
    "unlocked": false,
    "description": "Congratulations! You have given a second life to your clothes, I am sure you made other people very happy."
  },
  {
    "id": 35,
    "type": "Donation",
    "name": "Donated Toys",
    "image": "reto_student_35",
    "unlocked": false,
    "description": "I am sure you have great memories with your toys; now other children will have a lot of fun thanks to your donation."
  },
  {
    "id": 36,
    "type": "Donation",
    "name": "Donated To 2 Associations",
    "points": 500,
    "image": "reto_student_36",
    "unlocked": false,
    "description": "Helping through associations is amazing, so we can reach many more causes that support either people or animals. How did you feel when you donated?"
  },
  {
    "id": 37,
    "type": "Donation",
    "name": "Donation Campaign",
    "image": "reto_student_37",
    "unlocked": false,
    "description": "Together we add up to more! How amazing that you were able to participate or organize a donation campaign to help those in need."
  },
  {
    "id": 38,
    "type": "Family",
    "name": "Helped My Parents With Their Goals",
    "image": "reto_student_38",
    "unlocked": false,
    "description": "So glad you are sharing your new skills of writing down financial goals to achieve them!"
  },
  {
    "id": 39,
    "type": "Family",
    "name": "Helped My Parents Save",
    "image": "reto_student_39",
    "unlocked": false,
    "description": "How nice that you are sharing your new savings skills! It is essential that more people know the importance of saving throughout our lives."
  },
  {
    "id": 40,
    "type": "Family",
    "name": "Helped My Parents Invest",
    "image": "reto_student_40",
    "unlocked": false,
    "description": "How nice that you are sharing your new investment skills! It is important that more people know the benefit of investing our money and making it grow."
  },
  {
    "id": 41,
    "type": "Family",
    "name": "Helped My Parents Donate",
    "image": "reto_student_41",
    "unlocked": false,
    "description": "How nice that you are sharing your new donation skills! It is important that more people join in to donate and help those in need."
  }
];