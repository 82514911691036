import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxCurrencyModule } from 'ngx-currency';

import { HomeComponent } from './home/home.component';
import { TeamComponent } from './team/team.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPinComponent } from './reset-pin/reset-pin.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-terms/privacy-policy.component';
import { MaterialModule } from '../shared/modules/material.module';
import { RegisterComponent } from './login/register/register.component';
import { GlobalHeaderModule } from '../shared/components/global-header/global-header.module';
import { RouterModule } from '@angular/router';
import { StudentComponent } from './login/register/student/student.component';
import { ParentComponent } from './login/register/parent/parent.component';
import { SchoolComponent } from './login/register/school/school.component';
import { CardComponent } from './login/register/debitCard/card.component';
import { SharedPopupModule } from '../shared/components/shared-popup/shared-popup.module';
import { BlogModule } from '../modules/extras/blog/blog.module';
import { BlogComponent } from './blog/blog.component';
import { ArticleComponent } from './blog/article.component';
import { PlansComponent } from './plans/plans.component';
import { PaymentsComponent } from './payments/payments.component';
import { IntroductionComponent } from './login/introduction/introduction.component';
import { ValidateAccountComponent } from './validate-account/validate-account.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { StoreComponent } from './store/store.component';
import { ProductDetailComponent } from './store/product-detail/product-detail.component';
import { CartComponent } from './store/cart/cart.component';
import { PaymentComponent } from './store/payment/payment.component';
import { PaymentCompleteComponent } from './store/payment-complete/payment-complete.component';
import { InverlingoComponent } from './inverlingo/inverlingo.component';
import { SocialFooterComponent } from '../shared/components/social-footer/social-footer.component';
import { NewsletterComponent } from '../shared/components/newsletter/newsletter.component';
import { BlogListComponent } from '../shared/components/blog-list/blog-list.component';
import { ParentsComponent } from './parents/parents.component';
import { TeachersComponent } from './teachers/teachers.component';
import { GamesComponent } from './games/games.component';
import { GameComponent } from './games/game/game.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { SchoolHubComponent } from './school-hub/school-hub.component';
import { SchoolProductComponent } from './school-product/school-product.component';

import { Section1Component } from './home/section1/section1.component';
import { Section3Component } from './home/Section3/section3.component';
import { Section4Component } from './home/Section4/section4.component';

import { VcardComponent } from './vcard/vcard.component';
import { VcardModule } from './vcard/vcard.module';
// import { FunnelsComponent } from './funnels/funnels.component';
// import { FunnelsModule } from './funnels/funnels.module';

import { PremiosComponent } from './premios/premios.component';
import { Section2Component } from './home/Section2/section2.component';
import { Section5Component } from './home/Section5/section5.component';
import { Section6Component } from './home/Section6/section6.component';
import { Section7Component } from './home/Section7/section7.component';

import { Team1Component } from './team/Team1/team1.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
    return player;
}
@NgModule({
    declarations: [
        Team1Component,
        Section7Component,
        Section6Component,
        Section5Component,
        Section4Component,
        Section3Component,
        Section2Component,
        Section1Component,
        VcardComponent,
        // FunnelsComponent,
        HomeComponent,
        TeamComponent,
        LoginComponent,
        ResetPasswordComponent,
        ResetPinComponent,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        RegisterComponent,
        StudentComponent,
        ParentComponent,
        SchoolComponent,
        CardComponent,
        BlogComponent,
        ArticleComponent,
        PlansComponent,
        PaymentsComponent,
        IntroductionComponent,
        ValidateAccountComponent,
        PaymentSuccessComponent,
        StoreComponent,
        ProductDetailComponent,
        CartComponent,
        PaymentComponent,
        PaymentCompleteComponent,
        InverlingoComponent,
        SocialFooterComponent,
        NewsletterComponent,
        BlogListComponent,
        ParentsComponent,
        TeachersComponent,
        GamesComponent,
        GameComponent,
        DownloadsComponent,
        SchoolHubComponent,
        SchoolProductComponent,
        PremiosComponent
    ],
    imports: [
        LottieModule.forRoot({ player: playerFactory }),
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        GlobalHeaderModule,
        SharedPopupModule,
        NgxCurrencyModule,
        BlogModule,
        VcardModule,
        LottieModule
        // FunnelsModule
    ],
    exports: [LoginComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],  
})
export class HomePageModule { }
