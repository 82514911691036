<div id="school-games">
    <div class="main-container light-blue-bk">
        <h1 class="dark-blue mb-40 mt-40">Juegos</h1>

        <div class="cards-container mb-40">
            <!-- <div class="game" [routerLink]="['/games/Waiz']">
                <img class="game-img" src="assets/images/games/waiz.svg" alt="game">
            </div> -->
            <div class="game" *ngFor="let game of games"
                [routerLink]="['/games', game.game_name]">
                <img class="game-img"
                    src="assets/images/games/module_{{game.module}}_{{game.topic}}_{{game.game_index}}.svg" alt="game">
            </div>
        </div>
    </div>
</div>