import { Component, OnInit} from "@angular/core";
import { Router } from "@angular/router";

import { HttpClient, HttpParams } from "@angular/common/http";
//import { environment } from "src/environments/environment";

@Component({
  selector: "app-section7",
  templateUrl: "./section7.component.html",
  styleUrls: ["./section7.component.scss"],
})
export class Section7Component implements OnInit{
  
  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
    
  }

  goToAgendar(): void {
    this.router.navigate(["/register/school"]);
  }
}
