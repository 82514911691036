<section class="info-section">
    <h1>
        <span class="normal-text">Pequeños pasos,</span>
        <span class="bold"> grandes decisiones</span>
    </h1>
    <img src="assets/images/homepage/section2_bluecircle.svg" alt="blue-circle" class="blue-circle" data-index="4">
    <div class="info-container">
        <dotlottie-wc src="https://assets.inverkids.mx/assets/lottie_animations/cohete.lottie" 
        speed="1" 
        style="width: 21vw;
        height: auto;
        z-index: 2;
        left: 11%;
        top: -10%;
        position: absolute;" 
        mode="forward" 
        loop autoplay></dotlottie-wc>

        <dotlottie-wc src="https://assets.inverkids.mx/assets/lottie_animations/libro.lottie" 
        speed="1" 
        style="width: 24vw;
        height: auto;
        z-index: 2;
        left: 38%;
        top: -15%;
        position: absolute;" 
        mode="forward" 
        loop autoplay></dotlottie-wc>

        <dotlottie-wc src="https://assets.inverkids.mx/assets/lottie_animations/lapiz.lottie" 
        speed="1" 
        style="width: 23vw;
        height: auto;
        z-index: 2;
        left: 67%;
        top: -16%;
        position: absolute;" 
        mode="forward" 
        loop autoplay></dotlottie-wc>

        <div class="info-box green">
            <h2>+8 años</h2>
            <p>impulsando la educación financiera y el emprendimiento</p>
        </div>
        <div class="info-box purple">
            <h2>+15,000</h2>
            <p>alumnos activos de 7 a 15 años en México y Colombia</p>
        </div>
        <div class="info-box orange">
            <h2>Único</h2>
            <p>programa basado en el desarrollo de competencias</p>
        </div>
    </div>
</section>