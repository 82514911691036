import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserProfileService } from '../../shared/services/userProfile.service';

@Component({
    selector: 'app-validate-account',
    templateUrl: './validate-account.component.html',
    styleUrls: ['./validate-account.component.scss'],
})
export class ValidateAccountComponent implements OnInit {
    email;
    validated;

    constructor(private activatedRoute: ActivatedRoute, private userProfileService: UserProfileService) {}

    ngOnInit() {
        this.email = this.activatedRoute.snapshot.paramMap.get('email');
        this.userProfileService.validateAccount(this.email).then((_) => (this.validated = true));
    }
}
