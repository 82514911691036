import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxCurrencyModule } from 'ngx-currency';
import { MaterialModule } from '../../shared/modules/material.module';
import { GlobalHeaderModule } from '../../shared/components/global-header/global-header.module';
import { RouterModule } from '@angular/router';
import { SharedPopupModule } from '../../shared/components/shared-popup/shared-popup.module';

@NgModule({
    declarations: [
       
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        GlobalHeaderModule,
        SharedPopupModule,
        NgxCurrencyModule,
    ],
    exports: []
})
export class VcardModule { }
