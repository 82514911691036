import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StoreService {
    requestInProgress = false;
    cart = [];

    constructor(private http: HttpClient) {}
}
