<div id="introduction">
    <div class="main-container">
        <div class="exit-container">
            <p class="exit caption gray" [routerLink]="['/users/options']">Saltar</p>
        </div>
        <div class="slides">
            <ng-container *ngIf="slide === 0">
                <div class="slide-container">
                    <img src="assets/images/intro/slide_1.svg" alt="slide">
                    <h2 class="mt-40 mb-15">¡Bienvenido!</h2>
                    <p>Inverkids es una manera divertida de enseñar finanzas a tus hijos, logrando que desarrollen
                        capacidades financieras para su futuro.</p>
                </div>
            </ng-container>
            <ng-container *ngIf="slide === 1">
                <div class="slide-container">
                    <img src="assets/images/intro/slide_2.svg" alt="slide">
                    <h2 class="mt-40 mb-15">Agrega a tu familia</h2>
                    <p>Comenzarás dando de alta a tus hijos, desde los 2 hasta los 17 años, todos son bienvenidos. Si
                        tus hijos ya cuentan con un correo, pueden crear su propia cuenta ellos mismos.</p>
                </div>
            </ng-container>
            <ng-container *ngIf="slide === 2">
                <div class="slide-container">
                    <img src="assets/images/intro/slide_3.svg" alt="slide">
                    <h2 class="mt-40 mb-15">Enseña finanzas a tus hijos</h2>
                    <p>Como en la vida real, tus hijos aprenderán a trabajar para generar ingresos y con ellos
                        administrar su dinero en su alcancía o cuenta bancaria para cumplir sus metas financieras.</p>
                </div>
            </ng-container>
            <ng-container *ngIf="slide === 3">
                <div class="slide-container">
                    <img src="assets/images/intro/slide_4.svg" alt="slide">
                    <h2 class="mt-40 mb-15">Asigna tareas del hogar</h2>
                    <p>Podrás asignar múltiples tareas a cada uno de tus hijos. ¡Lo mejor es que cada edad tiene
                        recomendaciones de tareas! Siempre podrás agregar actividades nuevas si así lo deseas.</p>
                </div>
            </ng-container>
            <ng-container *ngIf="slide === 4">
                <div class="slide-container">
                    <img src="assets/images/intro/slide_5.svg" alt="slide">
                    <h2 class="mt-40 mb-15">Recompensa su esfuerzo</h2>
                    <p>Cuando tus hijos cumplan sus actividades se te avisará para que les recompenses su esfuerzo con
                        el monto pactado. ¡Puedes darle efectivo o transferirle a su cuenta!</p>
                </div>
            </ng-container>
            <ng-container *ngIf="slide === 5">
                <div class="slide-container">
                    <img src="assets/images/intro/slide_6.svg" alt="slide">
                    <h2 class="mt-40 mb-15">Motivalos a cumplir sus metas</h2>
                    <p>¡Tus hijos podrán comprar sus propias metas financieras! Cada semana se esforzarán para
                        conseguirlo, mantenlos motivados, el apoyo familiar es clave en la educación financiera.</p>
                </div>
            </ng-container>
            <ng-container *ngIf="slide === 6">
                <div class="slide-container">
                    <img src="assets/images/intro/slide_7.svg" alt="slide">
                    <h2 class="mt-40 mb-15">Su primer cuenta bancaria</h2>
                    <p>Próximamente podrán solicitar su primer cuenta bancaria de Inverkids para que ahorre
                        formalmente y compre sus metas financieras. ¡Ganarás control y ellos libertad!</p>
                </div>
            </ng-container>
        </div>
        <div class="button-container">
            <button *ngIf="slide !== 6" class="tertiary" (click)="nextSlide()">Siguiente</button>
            <button *ngIf="slide === 6" class="tertiary" [routerLink]="['/users/options']">Comenzar</button>
        </div>
    </div>
</div>