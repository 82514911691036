import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatDialog } from "@angular/material/dialog";
import { ServiceWorkerModule } from "@angular/service-worker";
import { registerLocaleData } from "@angular/common";
import localeEsMx from "@angular/common/locales/es-MX";
import { CommonModule } from "@angular/common";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomePageModule } from "./homepage/homepage.module";
import { HomePageComponent } from "./homepage/homepage.component";
import { environment } from "../environments/environment";
import { RequestInterceptorService } from "./shared/services/RequestInterceptor.service";
import { HomepageNavbarModule } from "./shared/components/homepage-navbar/homepage-navbar.module";
import { ModulesModule } from "./modules/modules.module";
import { ToolsModule } from "./tools/tools.module";
import { ToolsComponent } from "./tools/tools.component";

import { NgxEchartsModule } from 'ngx-echarts';
import player from 'lottie-web';

// const config = new AuthServiceConfig([
//     {
//         id: GoogleLoginProvider.PROVIDER_ID,
//         provider: new GoogleLoginProvider('1052319816258-1vrkt6qu0q96kc648ikt7cg1t8f1uj2d.apps.googleusercontent.com')
//     },
//     {
//         id: FacebookLoginProvider.PROVIDER_ID,
//         provider: new FacebookLoginProvider('605508226880093')
//     }
// ]);

// export function provideConfig() {
//     return config;
// }

registerLocaleData(localeEsMx);

@NgModule({
  declarations: [AppComponent, HomePageComponent, ToolsComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HomepageNavbarModule,
    HomePageModule,
    ModulesModule,
    CommonModule,
    ToolsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    // SocialLoginModule
    ServiceWorkerModule.register("custom-ngsw-worker.js", {
      scope: "/",
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [
    MatDialog,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "es-Mx" },
    // {
    //     provide: AuthServiceConfig,
    //     useFactory: provideConfig
    // }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
