import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogTemplateComponent } from './pages/blog_template.component';
import { BlogComponent } from './blog.component';

const routes: Routes = [
    { path: '', component: BlogComponent },
    { path: ':blog_name', component: BlogTemplateComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class BlogRoutingModule {}
