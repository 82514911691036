import { Component, OnInit, OnDestroy } from "@angular/core";
/** 
import { OptionsNavbarService } from "../../shared/components/global-header/option-navbar.service";
import { Subscription } from "rxjs";
*/
import { Router } from "@angular/router";


@Component({
  selector: "app-premios",
  templateUrl: "./premios.component.html",
  styleUrls: ["./premios.component.scss"],
})
export class PremiosComponent implements OnInit, OnDestroy {
  OS: string;

  constructor(
    private router: Router
  ) {
    
  }

  ngOnInit(): void {
    
  }

  openPage(url): void {
    window.open(url, "_blank");
  }

  ngOnDestroy(): void {
    
  }
}
