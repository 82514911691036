<div id="login">
    <div class="login-logo">
        <img src="assets/images/icons/inverkids_logo_nav.png" alt="inverkids logo">
    </div>

    <ng-container *ngIf="tabSelected === 'Login'">
        <!-- <div class="social-buttons-container">
            <button id="facebook-btn" (click)="signInWithFB()"><i class="fab fa-facebook-square"></i>Iniciar sesión con
                Facebook</button>
            <button id="google-btn" (click)="signInWithGoogle()"><i class="fab fa-google"></i>Iniciar sesión con
                Google</button>
        </div> -->

        <div mat-dialog-content>
            <input class="main-text-input" placeholder="Usuario" [(ngModel)]="enrollmentId" id="enrollmentId" maxlength="100" autocomplete="false"  (ngModelChange)="enrollmentId = $event.toLowerCase()">
            
            <p class="error-message" *ngIf="enrollmentIdError">El usuario es obligatorio.</p>

            <input class="main-text-input" (keydown.enter)="login()" matInput placeholder="Contraseña" id="password" type="password"
                [(ngModel)]="password" maxlength="60">

            <p class="forgot-password" (click)="tabSelected = 'Reset'">Olvidé mi contraseña</p>
            <p class="error-message" *ngIf="passwordError">La contraseña es obligatoria.</p>
            <p class="error-message" *ngIf="error">{{ error }}</p>
        </div>

        <button id="botonIniciarSesion" class="primary full-width" color="primary" (click)="login()">Iniciar Sesión</button>
        <button id="botonLobbySesion" class="tertiary full-width" color="primary" (click)="goToLobby()">Lobby</button>
        <p class="registerCta" (click)="goToRegister()">Registrarme</p>
    </ng-container>

    <ng-container *ngIf="tabSelected === 'Reset'">
        <div id="reset">
            <mat-form-field>
                <input matInput placeholder="Correo" [(ngModel)]="username" maxlength="100" autocomplete="false">
            </mat-form-field>
            <p *ngIf="success" class="success-message">{{success}}</p>
            <p *ngIf="error" class="error-message">{{error}}</p>
            <button [disabled]="formSending" class="submit-btn" (click)="submitReset()">Enviar</button>
        </div>
    </ng-container>
</div>