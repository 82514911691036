import { Component, OnInit } from '@angular/core';
import { FILE_INFO } from '../../../assets/public_files/downloads.data';

declare var require: any;
const FileSaver = require('file-saver');

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {
  files = FILE_INFO;

  constructor() { }

  ngOnInit() { }

  downloadPdf(activityIndex): void {
    console.log("Downloading file: " + activityIndex);
    console.log(this.files[activityIndex]);
    const pdfName = `${this.files[activityIndex].file_name}.pdf`;
    const pdfUrl = `./assets/public_files/${pdfName}`;
    FileSaver.saveAs(pdfUrl, pdfName);
  }
}
