import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { environment } from '../../../../environments/environment';
import * as FormValidator from '../../../shared/services/formValidaton.service';
import { SharedPopupComponent } from '../../../shared/components/shared-popup/shared-popup.component';
import { PaymentsService } from '../../payments/payments.service';
import {
    EMAIL_ERROR,
    PAYMENT_PROGRESS,
    FORM_ERROR,
    PAYMENT_SERVER_ERROR,
    PAYMENT_CREATION_ERROR,
    USER_NOT_FOUND,
    SERVER_ERROR,
} from '../../payments/payments.dialogs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

declare var Conekta: any;
declare let fbq: Function;

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
    paymentForm: FormGroup;
    userForm: FormGroup;
    conektaSuccessResponseHandler;
    conektaErrorResponseHandler;
    payment;
    planSelected = 'mensual';
    @ViewChild('conektaForm', { static: false }) conektaForm;
    isSubmitted = false;
    cart;
    total;
    shipping = 0;
    isAllDigital;

    constructor(
        private paymentService: PaymentsService,
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
        private router: Router // se usa en el dialog, no lo borres
    ) {
        this.paymentForm = this.formBuilder.group({
            cardOwner: ['', FormValidator.FullNameValidator],
            cardNumber: ['', FormValidator.CardNumberValidator], // 4242424242424242
            cvcNumber: ['', FormValidator.CVCValidator], // 123
            expirationMonth: ['', FormValidator.ExpirationMonthValidator], // 12
            expirationYear: ['', FormValidator.ExpirationYearValidator], // 2020
        });
    }

    ngOnInit() {
        this.conektaSuccessResponseHandler = (token) => {
            this.payment = { ...this.userForm.value, token: token.id };
            fbq('track', 'Purchase', { value: this.total, currency: 'MXN' });
            this.paymentService.createStorePayment(this.payment).then(
                () => {
                    this.dialog.closeAll();
                    localStorage.removeItem('Cart');
                    this.router.navigate(['/payment-complete']);
                },
                (error) => {
                    console.log(error);
                    this.dialog.closeAll();
                    if (error.status === 400) {
                        this.dialog.open(SharedPopupComponent, PAYMENT_CREATION_ERROR(this));
                    } else if (error.status === 404) {
                        this.dialog.open(SharedPopupComponent, USER_NOT_FOUND(this));
                    } else {
                        this.dialog.open(SharedPopupComponent, SERVER_ERROR(this));
                    }
                }
            );
        };

        this.conektaErrorResponseHandler = (error) => {
            console.log(error);
            this.dialog.open(SharedPopupComponent, PAYMENT_SERVER_ERROR(this));
        };

        this.cart = JSON.parse(localStorage.getItem('Cart'));
        this.getCartTotal();

        setTimeout(() => {
            Conekta.setPublicKey(environment.conektaKey);
            Conekta.setLanguage('es');
        });

        this.isAllDigital = this.allDigital();

        if(this.isAllDigital){
            this.userForm = this.formBuilder.group({
                email: ['', FormValidator.EmailValidator],
                email_confirmation: ['', FormValidator.EmailValidator],
                items: [''],
                name: ['', FormValidator.FullNameValidator]
            });
        }else{
            this.userForm = this.formBuilder.group({
                email: ['', FormValidator.EmailValidator],
                email_confirmation: ['', FormValidator.EmailValidator],
                items: [''],
                full_name: ['', FormValidator.FullNameValidator],
                address: ['', FormValidator.AddressValidator],
                zip_code: ['', FormValidator.ZipCodeValidator],
                city: ['', FormValidator.CityValidator],
                state: ['', FormValidator.StateValidator],
                country: ['', FormValidator.CountryValidator],
                phone: ['+52', Validators.minLength(10)],
                shipping: [this.shipping, Validators.required],
            });
        }

        this.onChanges();
    }

    onChanges(): void {
        if(!this.isAllDigital){
            this.userForm
                .get('city')
                .valueChanges.pipe(debounceTime(800), distinctUntilChanged())
                .subscribe((val) => {
                    this.shipping = this.isAllDigital ? 0 :
                        (val.toLowerCase() === "monterrey" || val.toLowerCase() === "mty" || val.toLowerCase() === "monterey") ? 50 : 100;
                });
        }
    }

    allDigital(): boolean {
        let allDigital = true;
        this.cart.forEach(cartItem => {
            if (allDigital) {
                allDigital = !cartItem.product.hasShipping;
            }
        });
        return allDigital;
    }

    pay(): void {
        this.isSubmitted = true;
        this.validateUserForm();
    }

    setItems(): void {
        const items = this.cart.map((cartItem) => ({
            name: cartItem.product.name,
            unit_price: Number(cartItem.product.price + '00'),
            details: this.getDetails(cartItem.product.details),
            quantity: cartItem.quantity,
        }));
        this.userForm.patchValue({ items: items });
        this.userForm.patchValue({ shipping: Number(this.shipping + '00') });
    }

    getDetails(_productDetails) {
        if (!_productDetails) {return null; }

        let dets = [];

        for (let itemIndex = 0; itemIndex < _productDetails.length; itemIndex++) {
            const detailList = _productDetails[itemIndex];
            let det = {}
            for (let detailIndex = 0; detailIndex < detailList.length; detailIndex++) {
                const detail = detailList[detailIndex];
                det[detail.fieldName] = detail.value
            }

            dets.push(det);
        }
        return dets
    }

    validateCard(): void {
        if (this.paymentForm.valid) {
            this.setItems();
            this.dialog.open(SharedPopupComponent, PAYMENT_PROGRESS(this));
            Conekta.Token.create(this.conektaForm.nativeElement, this.conektaSuccessResponseHandler, this.conektaErrorResponseHandler);
        } else {
            this.dialog.open(SharedPopupComponent, FORM_ERROR(this));
        }
    }

    validateUserForm(): void {
        if (this.userForm.valid) {
            if (!(this.userForm.get('email').value === this.userForm.get('email_confirmation').value)) {
                this.dialog.open(SharedPopupComponent, EMAIL_ERROR(this));
            } else {
                this.validateCard();
            }
        }
    }

    goToCart(): void {
        this.router.navigate(['carrito']);
    }

    getCartTotal(): void {
        this.total = 0;
        this.cart.forEach((cartItem) => {
            this.total += cartItem.product.price * cartItem.quantity;
        });
    }
}
