import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-school-hub',
  templateUrl: './school-hub.component.html',
  styleUrls: ['./school-hub.component.scss']
})
export class SchoolHubComponent implements OnInit {

  schoolId
  schoolNameDisplay
  productLink
  constructor(
    private activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.schoolId = this.activatedRoute.snapshot.paramMap.get('school');

    this.schoolNameDisplay = this.schoolId.replaceAll("_", " ");

    this.productLink = `/escuela/${this.schoolId}/producto/0`
  }

}
