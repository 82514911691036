<div class="container">
    <div class="header">
        <h1>ENSEÑA <span class="highlight">FINANZAS</span> CON</h1>
        <h1 class="big-text">INVERKIDS</h1>
        <dotlottie-wc 
        src="https://assets.inverkids.mx/assets/lottie_animations/HappyAvatars.lottie"
            autoplay
            loop
            speed="2"
            background="transparent">
        </dotlottie-wc>
    </div>
    <div class="video-container">
        <video width="500" height="400" controls poster="https://assets.inverkids.mx/assets/images/homepage/section1_videoposter.webp">
            <source src="https://assets.inverkids.mx/assets/videos/homepage_section1.mp4" type="video/mp4">
            Tu navegador no soporta videos.
        </video>
        <img class="green-circle" src="assets/images/homepage/section1_greencircle.svg" alt="Green Circle">
        <img class="wave" src="assets/images/homepage/section1_wave.svg" alt="Wave">
    </div>
</div>