import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedPopupComponent } from '../../shared/components/shared-popup/shared-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MailerService } from '../../shared/services/mailer.service';
import { SUBSCRIPTION_SUCCESS, WRONG_EMAIL } from '../home/home.dialogs';

declare let fbq: Function;
@Component({
    selector: 'app-plans',
    templateUrl: './plans.component.html',
    styleUrls: ['./plans.component.scss'],
})
export class PlansComponent implements OnInit {
    email;
    selectedQuestion;

    constructor(private router: Router, private mailerService: MailerService, private dialog: MatDialog) {}

    ngOnInit() {}

    subscribe(): void {
        if (this.email && this.email.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')) {
            this.mailerService.createNewsletterSubscription(this.email).then(
                () => this.dialog.open(SharedPopupComponent, SUBSCRIPTION_SUCCESS(this)),
                (error) => console.log(error)
            );
        } else {
            this.dialog.open(SharedPopupComponent, WRONG_EMAIL(this));
        }
    }

    selectQuestion(index): void {
        if (index === this.selectedQuestion) {
            this.selectedQuestion = null;
        } else {
            this.selectedQuestion = index;
        }
    }

    getPremium(): void {
        fbq('track', 'Lead');
    }
}
