import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SCHOOL_PRODUCTS } from '../../../assets/store_files/products.data';
import { SharedPopupComponent } from '../../shared/components/shared-popup/shared-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { StoreService } from '../store/product.service';

declare let fbq: Function;
@Component({
  selector: 'app-school-product',
  templateUrl: './school-product.component.html',
  styleUrls: ['./school-product.component.scss']
})
export class SchoolProductComponent implements OnInit {
    schoolId;
    productId;
    products = SCHOOL_PRODUCTS.sort((n1, n2) => n1.id - n2.id);
    quantity = 1;

    constructor(
        private storeService: StoreService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.loadProduct();
    }

    loadProduct(): void {
        this.schoolId = this.activatedRoute.snapshot.paramMap.get('school');
        this.productId = this.activatedRoute.snapshot.paramMap.get('product_id');

        const cartItems = JSON.parse(localStorage.getItem('Cart'));
        if (cartItems && cartItems.length > 0) {
            this.storeService.cart = cartItems;
        }
        fbq('track', 'ViewContent');

        this.products[this.productId].name = this.loadProductName()
        this.products[this.productId].cartId = this.getCartId(this.productId)
    }

    loadProductName(){
      let productName = this.products[this.productId].name
      let schoolName = this.schoolId.replaceAll("_", " ");

      productName = productName.replace("$SCHOOL_NAME", schoolName)

      return productName
    }

    getCartId(id){
        let cartId = this.schoolId + "_" + id
        console.log("formatted id for cart as: ", cartId);
        return cartId
    }

    addToCart(): void {
        console.log("Logging cart as: ", this.storeService.cart);
        const productIndex = this.storeService.cart.findIndex((cartItem) => {
            if(cartItem.product.cartId) return cartItem.product.cartId === this.getCartId(this.productId)
            return false
        });

        if (productIndex >= 0) {
            console.log("Found same index item at: ", productIndex);
            this.storeService.cart.splice(productIndex, 1);
        }

        let cartItem = {
            product: this.products[this.productId],
            quantity: this.quantity,
        }

        cartItem.product.extraInformation[0]["value"] = this.schoolId

        console.log("Ading school product of: ", cartItem);

        this.storeService.cart.push(cartItem);
        localStorage.setItem('Cart', JSON.stringify(this.storeService.cart));

        this.dialog.open(SharedPopupComponent, {
            width: '400px',
            data: {
                title: 'Se ha agregado el producto a tu carrito',
                buttons: [
                    {
                        text: 'Ok',
                        action: () => {
                            this.dialog.closeAll();
                        },
                    },
                ],
            },
        });
        fbq('track', 'AddToCart', {
            value: this.products[this.productId].price,
            currency: 'MXN',
            content_type: 'product',
            content_ids: this.productId,
        });
    }

    payProduct(): void {
        this.addToCart();
        this.router.navigate(['carrito']);
    }

    goToCart(): void {
        this.router.navigate(['carrito']);
    }

    selectProduct(productIndex): void {
        this.router.navigate([`tienda/producto/${productIndex}`]).then(() => this.loadProduct());
    }
}
