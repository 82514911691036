import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { tap } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

import { environment } from "../../../environments/environment";
import { run_process } from "../utils/progressive_offline_utils";
import { ACHIEVEMENTS_NO_SECTION, ACHIEVEMENTS_NO_SECTION_EN } from "../../modules/extras/financial-health/achievements/achievements.data";

// import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UserProfileService {
  requestInProgress;
  userProfile;
  private childSubject = new BehaviorSubject(null);
  childSelected = this.childSubject.asObservable();
  private userType = new BehaviorSubject(null);
  currentType = this.userType.asObservable();
  lastChildSelected;
  module;
  topic;

  constructor(private http: HttpClient) {}

  getName(user_id) {
    const params = { user_id };

    return this.http
      .post<any>(`${environment.apiUrl}/profile/get_name`, params)
      .toPromise();
  }

  getRetos(user_lang){
    return user_lang == "en" ? ACHIEVEMENTS_NO_SECTION_EN : ACHIEVEMENTS_NO_SECTION
  }

  getUserProfile(): Promise<any> {
    return this.http
        .get<any>(`${environment.apiUrl}/profile`)
        .pipe(
            tap((user) => {
                this.userProfile = user;
                this.setAccountType(user["account_type"]);
                this.setUserType(user["user_type"]);
                this.setUserRole(user["role"]);
                this.setUserLevel(user["level"]);
                this.setLang(user["lang"]);
                this.setWhiteLabel(user["white_label"]);

                window.localStorage.setItem("progress", JSON.stringify(user["progress"]));

                this.update_sw()
            })
        )
        .toPromise();
  }

  async update_sw() {
    if("serviceWorker" in navigator){
      try {
        console.log("update_sw");
        // QUESTION: deberiamos de gestionar la llamada a la api normal desde aqui o dejarlo tal cual esta y solo gestionar la llamada de SW?
        const registration: any = await navigator.serviceWorker.ready;
        try {
          console.log("update_sw: ready");
          registration.active?.postMessage({
            type: "ENVIRONMENT",
            environment: environment,
            authToken: this.getAuthToken(),
            user_id: this.getUserId(),
            label: this.getWhiteLabel()
          });
        } catch {
          console.log("update_sw: failed");
        }
      } catch (error) {
        console.error("update_sw: failed - ", error);
      }
    }else{
      console.log("Simulando carga de catalogo desde api, sin SW");
      run_process({
      data: {
          type: "ENVIRONMENT",
          environment: environment,
          authToken: this.getAuthToken(),
          user_id: this.getUserId(),
          label: this.getWhiteLabel()
      }
      })
      run_process({
      data: {
          type: "update-catalog"
      }
      })
    }
  }

  getParentProfile(params: {
    enrollment_id: string;
    password: string;
  }): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/profile/get_parent_user`, params)
      .toPromise();
  }

  update_a_parents_child_accounts(params: {
    enrollment_id: string;
    password: string;
    child_accounts: string[];
  }): Promise<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/profile/update_a_parents_child_accounts`,
        params
      )
      .toPromise();
  }

  get_current_parent() {
    return this.http
      .get<any>(`${environment.apiUrl}/profile/get_current_parent`)
      .toPromise();
  }

  setLangRemote(lang): Promise<any> {
    this.setLang(lang);
    return this.http
      .post<any>(`${environment.apiUrl}/profile/set_lang`, { lang: lang })
      .toPromise();
  }

  setProgress(progress): Promise<any> {
    window.localStorage.setItem("progress", JSON.stringify(progress));
    return this.http
      .post<any>(`${environment.apiUrl}/profile/set_progress`, {
        progress: progress,
      })
      .toPromise();
  }

  getProgress(): any {
    const progress = window.localStorage.getItem("progress");
    if (progress == "undefined" || progress === null) {
      return null;
    } else {
      return JSON.parse(progress);
    }
  }

  setLang(lang): void {
    console.log("setLang:", lang);
    window.localStorage.setItem("lang", lang);
  }

  getLang(): string | null {
    return window.localStorage.getItem("lang");
  }

  setWhiteLabel(white_label): void {
    console.log("setLabel:", white_label);
    window.localStorage.setItem("white_label", white_label);
  }

  getWhiteLabel(): string | null {
    return window.localStorage.getItem("white_label");
  }

  setNickname(nickname): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/profile/set_nickname`, {
        nickname: nickname,
      })
      .toPromise();
  }

  validateAccount(email): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/profile/validate_account`, {
        user: { email },
      })
      .toPromise();
  }

  checkEmailVerification(email): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/profile/verify_account`, {
        user: { email },
      })
      .toPromise();
  }

  sendEmailVerification(email): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/profile/send_email_verification`, {
        user: { email },
      })
      .toPromise();
  }

  setUserProfile(profile): void {
    this.userProfile = profile;
  }

  getUserType(): string | null {
    return window.localStorage.getItem("UserType");
  }

  getUserLevel(): string | null {
    return window.localStorage.getItem("UserLevel");
  }

  getUserRole(): string | null {
    return window.localStorage.getItem("UserRole");
  }

  setUserType(userType: string): void {
    window.localStorage.setItem("UserType", userType);
  }

  setUserLevel(userLevel: string): void {
    window.localStorage.setItem("UserLevel", userLevel);
  }

  setUserRole(userRole: string): void {
    window.localStorage.setItem("UserRole", userRole);
  }

  removeUserType(): void {
    window.localStorage.removeItem("UserType");
  }

  getAuthToken(): string | null {
    return window.localStorage.getItem("AuthToken");
  }

  getUserId(): string | null {
    return window.localStorage.getItem("UserId");
  }

  setUserId(user_id: string): void {
    window.localStorage.setItem("UserId", user_id);
  }

  setAuthToken(authToken: string): void {
    window.localStorage.setItem("AuthToken", authToken);
  }

  removeAuthToken(): void {
    window.localStorage.removeItem("AuthToken");
  }

  getAccountType(): string | null {
    return window.localStorage.getItem("AccountType");
  }

  setAccountType(accountType: string): void {
    window.localStorage.setItem("AccountType", accountType);
  }

  removeAccountType(): void {
    window.localStorage.removeItem("AccountType");
  }

  getParentToken(): string | null {
    return window.localStorage.getItem("ParentToken");
  }

  setParentToken(parentToken: string): void {
    window.localStorage.setItem("ParentToken", parentToken);
  }

  removeParentToken(): void {
    window.localStorage.removeItem("ParentToken");
  }

  setChild(child: any): void {
    this.childSubject.next(child);
    this.lastChildSelected = child;
  }

  changeUserType(type: string) {
    this.userType.next(type);
  }

  updateProfile(user): Promise<any> | undefined {
    if (!this.requestInProgress) {
        this.requestInProgress = true;
        return this.http
            .put(`${environment.apiUrl}/profile`, { user })
            .pipe(
                tap(
                    () => (this.requestInProgress = false),
                    () => (this.requestInProgress = false)
                )
            )
            .toPromise();
    }
  }    

  updateWhatsapp(body: {
      enrollment_id?: string,
      password?: string,
      whatsapp: string
    }) {
      if (!this.requestInProgress) {
        this.requestInProgress = true;
        return this.http
          .post(`${environment.apiUrl}/profile/update_whatsapp`, body)
          .pipe(
              tap(
                () => (this.requestInProgress = false),
                () => (this.requestInProgress = false)
              )
          )
          .toPromise();
      }
  }


  verifyWhatsapp(body: { verification_code: string }) {
    if (!this.requestInProgress) {
      this.requestInProgress = true;
      return this.http
        .post(`${environment.apiUrl}/profile/verify_whatsapp`, body)
        .pipe(
          tap(
            () => (this.requestInProgress = false),
            () => (this.requestInProgress = false)
          )
        )
        .toPromise();
    }
  }

  updateChildAccount(child): Promise<any> | undefined {
    if (!this.requestInProgress) {
        this.requestInProgress = true;
        return this.http
            .put(`${environment.apiUrl}/childs/${child.id}`, { child })
            .pipe(
                tap(
                    () => (this.requestInProgress = false),
                    () => (this.requestInProgress = false)
                )
            )
            .toPromise();
    }
  }


  setNavbarChild(): void {
    if (!this.lastChildSelected) {
      this.setChild(this.userProfile.child_accounts[0]);
    } else {
      this.setChild(
        this.userProfile.child_accounts.find(
          (child) => child.id === this.lastChildSelected.id
        )
      );
    }
  }

    getNotionSite(): Promise<any> | null{
        const user_type = this.getUserType() || ""
        if (["Teacher", "Coordinator", "Director"].includes(user_type)){
            return this.http
                .get(`${environment.apiUrl}/app_data/notion_site`)
                .toPromise();
        }else{
            return null
        }
    }

    getGroups(): Promise<any> | null{
        const user_type = this.getUserType() || ""
        if (["Teacher", "Coordinator", "Director"].includes(user_type)){
            return this.http
                .get(`${environment.apiUrl}/groups`)
                .toPromise();
        }else{
            return null
        }
    }
}
