<div id="card-registration" class="light-blue-bk">
    <div class="main-container">
        <div class="left-container">
            <h2 class="title"> ¡Únete gratis! </h2>
            <p class="description"> Regístrate en nuestra lista de espera y se una de las primeras familias en disfrutar de la tarjeta de Inverkids.
            </p>
            <img class="regist-img" src="assets/images/homepage/unetegratis.png">
        </div>

        <div class="right-container">
            <form [formGroup]="cardForm" class="compForm">
                <h3 class="subtitle">Compártenos tus datos</h3>
                <input class="input-box thin-input roboto mb-10" placeholder="Nombre"
                    formControlName="name">
                <input class="input-box thin-input roboto mb-10" placeholder="Correo electrónico"
                    formControlName="email">
                <input class="input-box thin-input roboto mb-10" placeholder="Número de hijos"
                    formControlName="children">
                <input class="input-box thin-input roboto mb-10" placeholder="Ciudad"
                    formControlName="city">
                <button type="button" (click)="submitCard()" class="btn tertiary mt-40">Enviar</button>
            </form>
        </div>
    </div>
</div>