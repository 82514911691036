import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../environments/environment";
import { run_process } from "../utils/progressive_offline_utils";

import { Observable, throwError, timer, from, of } from "rxjs";
import {
  catchError,
  retryWhen,
  mergeMap,
  concatMap,
  switchMap,
  delay,
  take,
} from "rxjs/operators";

import { UserProfileService } from "./userProfile.service";

import { SharedPopupComponent } from "../components/shared-popup/shared-popup.component";

function throttleDebounce(func, throttleDelay, debounceDelay) {
  let debounceTimeout: any = null;
  let lastExecution = 0;

  return (...args) => {
    const now = Date.now();

    // Throttle: Execute if enough time has passed since the last execution
    if (now - lastExecution >= throttleDelay) {
      console.log("running function throttle allowed", now - lastExecution, throttleDelay, now, lastExecution)
      lastExecution = now;
      func.apply(this, args); // Execute the function immediately
      if(debounceTimeout!=null){
        clearTimeout(debounceTimeout); // Clear the debounce timeout if it's set
      }
    } else {
      // Debounce: Reset the timeout to execute the function after debounceDelay
      if(debounceTimeout!=null){
        clearTimeout(debounceTimeout);
      }
      debounceTimeout = setTimeout(() => {
        lastExecution = Date.now();
        console.log("running function debounce allowed")
        func.apply(this, args); // Execute the function
      }, debounceDelay);
    }
  };
}

function getFormattedTimestamp() {
  const now = new Date();
  
  // Extract components
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(now.getUTCDate()).padStart(2, '0');
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  const seconds = String(now.getUTCSeconds()).padStart(2, '0');
  const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0');
  
  // Generate microseconds by padding milliseconds
  const microseconds = milliseconds + '000';

  // Combine into the desired format
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${microseconds}+00`;
}

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  private readonly retryCount = 5;
  private readonly retryDelay = 1000; // 1 second
  private readonly progressiveOfflineURLs = [
    "https://prod.inverkids.mx/api/teachers/get_levels",
  ];

  private readonly catalogDB = 'catalog'
  private readonly grades_progress_DB = 'grades_progress'

  constructor(
    private userProfileService: UserProfileService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  getPathFromUrl(url: string): string {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.pathname; // returns the path between the domain and the query params
    } catch (error) {
      console.error("Invalid URL provided:", error);
      return '';
    }
  }

  cargarCatalogoDesdeAPI = async () => {
    if("serviceWorker" in navigator){
      try {
        console.log("Simulando carga de catalogo desde api");
        // QUESTION: deberiamos de gestionar la llamada a la api normal desde aqui o dejarlo tal cual esta y solo gestionar la llamada de SW?
        const registration: any = await navigator.serviceWorker.ready;
        try {
          console.log("Background Sync registered!");
          registration.active?.postMessage({
            type: "ENVIRONMENT",
            environment: environment,
            authToken: this.userProfileService.getAuthToken(),
            user_id: this.userProfileService.getUserId(),
            label: this.userProfileService.getWhiteLabel()
          });
          // await registration.sync.register("download-catalog");
          registration.active?.postMessage({
            type: "download-catalog"
          });
        } catch(error) {
          console.log("Background Sync could not be registered!", error);
        }
      } catch (error) {
        console.error("Error al cargar el catalogo desde la API:", error);
      }
    }else{
      run_process({
        data: {
          type: "ENVIRONMENT",
          environment: environment,
          authToken: this.userProfileService.getAuthToken(),
          user_id: this.userProfileService.getUserId(),
          label: this.userProfileService.getWhiteLabel()
        }
      })
      run_process({
        data: {
          type: "download-catalog"
        }
      })
    }
  }

   actualizarCatalogoDesdeAPI = async() => {
    if("serviceWorker" in navigator){
      try {
        console.log("Simulando carga de catalogo desde api");
        // QUESTION: deberiamos de gestionar la llamada a la api normal desde aqui o dejarlo tal cual esta y solo gestionar la llamada de SW?
        const registration: any = await navigator.serviceWorker.ready;
        try {
          console.log("Background Sync registered!");
          registration.active?.postMessage({
            type: "ENVIRONMENT",
            environment: environment,
            authToken: this.userProfileService.getAuthToken(),
            user_id: this.userProfileService.getUserId(),
            label: this.userProfileService.getWhiteLabel()
          });
          // await registration.sync.register("update-catalog");
          registration.active?.postMessage({
            type: "update-catalog"
          });
        } catch(error) {
          console.log("Background Sync could not be registered!", error);
        }
      } catch (error) {
        console.error("Error al cargar el catalogo desde la API:", error);
      }
    }else{
      console.log("Simulando carga de catalogo desde api, sin SW");
      run_process({
        data: {
          type: "ENVIRONMENT",
          environment: environment,
          authToken: this.userProfileService.getAuthToken(),
          user_id: this.userProfileService.getUserId(),
          label: this.userProfileService.getWhiteLabel()
        }
      })
      run_process({
        data: {
          type: "update-catalog"
        }
      })
    }
  }

  updateGradesProgress = async () => {
    if("serviceWorker" in navigator){
      try {
        console.log("Simulando carga de grades progress desde api");
        // QUESTION: deberiamos de gestionar la llamada a la api normal desde aqui o dejarlo tal cual esta y solo gestionar la llamada de SW?
        const registration: any = await navigator.serviceWorker.ready;
        try {
          console.log("Background Sync registered!");
          registration.active?.postMessage({
            type: "ENVIRONMENT",
            environment: environment,
            authToken: this.userProfileService.getAuthToken(),
            user_id: this.userProfileService.getUserId(),
            label: this.userProfileService.getWhiteLabel()
          });
          // await registration.sync.register("update-grades-progress");
          registration.active?.postMessage({
            type: "update-grades-progress"
          });
        } catch (error) {
          console.log("Background Sync could not be registered!", error);
        }
      } catch (error) {
        console.error("Error al cargar el grades progress desde la API:", error);
      }
    }else{
      console.log("Simulando carga de grades progress desde api, sin SW");
      run_process({
        data: {
          type: "ENVIRONMENT",
          environment: environment,
          authToken: this.userProfileService.getAuthToken(),
          user_id: this.userProfileService.getUserId(),
          label: this.userProfileService.getWhiteLabel()
        }
      })
      run_process({
        data: {
          type: "update-grades-progress"
        }
      })
    }
  }

  throttledUpdateGradesProgress = throttleDebounce(this.updateGradesProgress, 2000, 2000)

  courses_get_courses_data(req): Observable<any> {
    const label = req.params.get('label');
    const level = req.params.get('level');

    if (level != null && label != null) {
      const storeName = "modules";
      let modules:any = [];
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.catalogDB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.cargarCatalogoDesdeAPI();
            }
            console.log("database_not_found")
            resolve([]); // Database does not exist
          };
  
          request.onerror = (event: any) => {
            console.log("database_error")
            resolve([])
          };

          request.onsuccess = (event: any) => {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(storeName, "readonly");

              const store = transaction.objectStore(storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = (event:any ) => {
                const cursor = event.target.result;
                if (cursor) {
                  console.log("Cursor entry:", cursor.value);
                  if (cursor.value.white_label === label && cursor.value.level === level) {
                    modules.push(cursor.value);
                  }
                  cursor.continue();
                } else {
                  modules = modules.sort((a, b) => a.module_number - b.module_number);
                  resolve(modules); // Resolve with the data when done
                }
              };
    
              cursorRequest.onerror = () => {
                reject(new Error("Error opening cursor"));
              };
            }catch(error){
              console.log("database_error")
              resolve([])
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve([]));
    }
  }
  
  topics_get_topic_names_progress(req): Observable<any> {
    const label = req.params.get('white_label');
    const level = req.params.get('level');
    const module_number = req.params.get('module_number');
    const user_id = req.params.get('user_id');

    console.log('topics_get_topic_names_progress: ', label, level, module_number, user_id)

    if (level != null && label != null && module_number != null && user_id != null) {
      const module_storeName = "modules";
      const topics_storeName = "topics";
      const activities_storeName = "activities";
      const grades_storeName = "grades";
      let modules:any = [];
      let topics:any = [];
      let activities:any = [];
      let finished:any = [];
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.catalogDB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.cargarCatalogoDesdeAPI();
            }
            console.log("database_not_found")
            resolve([]); // Database does not exist
          };
  
          request.onerror = function (event: any) {
            console.log("database_error")
            resolve([])
          };

          request.onsuccess = (event: any) => {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(module_storeName, "readonly");

              const store = transaction.objectStore(module_storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = (event:any ) => {
                const cursor = event.target.result;
                if (cursor) {
                  if (cursor.value.white_label == label && cursor.value.level == level && cursor.value.module_number == module_number) {
                    console.log("Cursor entry:", cursor.value);
                    modules.push(cursor.value);
                  }
                  cursor.continue();
                } else {
                  modules = modules.sort((a, b) => a.module_number - b.module_number);
                  const module_ids = modules.map((module_obj)=>{
                    return module_obj.module_id
                  })

                  const transaction = db.transaction(topics_storeName, "readonly");

                  const store = transaction.objectStore(topics_storeName);
                  const cursorRequest = store.openCursor();
        
                  cursorRequest.onsuccess = (event:any ) => {
                    const cursor = event.target.result;
                    if (cursor) {
                      if (module_ids.includes(cursor.value.module_id)) {
                        console.log("Cursor entry:", cursor.value);
                        topics.push(cursor.value);
                      }
                      cursor.continue();
                    } else {
                      const topics_processed = topics.map((topic)=>{return [topic.name, topic.topic_number]}).sort((a, b) => a[1] - b[1]);
                      const topic_ids = topics.map((topic)=>{return topic.topic_id})

                      const transaction = db.transaction(activities_storeName, "readonly");

                      const store = transaction.objectStore(activities_storeName);
                      const cursorRequest = store.openCursor();
                      const found_activities = {}
            
                      cursorRequest.onsuccess = (event:any ) => {
                        const cursor = event.target.result;
                        if (cursor) {
                          if (topic_ids.includes(cursor.value.topic_id) && !cursor.value.home) {
                            console.log("Cursor entry:", cursor.value);
                            activities.push(cursor.value);
                            const found_topic = topics.filter((topic)=>{return topic.topic_id == cursor.value.topic_id})
                            if (found_topic.length > 0){
                              if(found_activities[found_topic[0].topic_number] == null){
                                found_activities[found_topic[0].topic_number] = 0
                              }
                              found_activities[found_topic[0].topic_number] += 1
                            }
                          }
                          cursor.continue();
                        } else {
                          const activity_ids = activities.map((activity)=>{return activity.activity_id})
                          
                          const request = indexedDB.open(this.grades_progress_DB);

                          request.onupgradeneeded = () => {
                            // This event only fires if the database doesn't exist or needs to be upgraded.
                            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
                            
                            if (navigator.onLine) {
                              this.updateGradesProgress();
                            }
                            console.log("database_not_found")
                            resolve({topics: topics_processed, finished: []}); // Database does not exist
                          };
                  
                          request.onerror = function (event: any) {
                            console.log("database_error")
                            resolve({topics: topics_processed, finished: []})
                          };

                          request.onsuccess = function (event: any) {
                            const db = event.target.result;
                            
                            try{
                              const transaction = db.transaction(grades_storeName, "readonly");

                              const store = transaction.objectStore(grades_storeName);
                              const cursorRequest = store.openCursor();
                              const found_grades = {}
                    
                              cursorRequest.onsuccess = function (event:any ) {
                                const cursor = event.target.result;
                                if (cursor) {
                                  if (
                                    activity_ids.length > 0 
                                    && cursor.value.student_id == user_id 
                                    && activity_ids.includes(cursor.value.activity_id)
                                  ){
                                      let found_topic = topics.filter((topic)=>{return topic.topic_id == cursor.value.topic_id})
                                      if (found_topic.length > 0){
                                        if(found_grades[found_topic[0].topic_number] == null){
                                          found_grades[found_topic[0].topic_number] = 0
                                        }
                                        found_grades[found_topic[0].topic_number] += 1
                                      }
                                  }
                                  cursor.continue();
                                } else {
                                  Object.entries(found_grades).forEach(([grade_topic_number, grade_topic_count]:any)=>{
                                    console.log('found_grade: ', found_activities, found_grades, grade_topic_number, grade_topic_count)
                                    if(found_activities[grade_topic_number] <= grade_topic_count){
                                      finished.push(parseInt(grade_topic_number))
                                    }
                                  })
                                  console.log('finished with: ', finished)
                                  resolve({topics: topics_processed, finished: finished})
                                }
                              };
                              cursorRequest.onerror = function () {
                                console.log('did not finish')
                                resolve({topics: topics_processed, finished:[]});
                              };
                            }catch(error){
                              console.log("database_error")
                              resolve({topics: topics_processed, finished:[]})
                              return;
                            }
                          };
                        }
                      };
                    }
                  };
                }
              };
    
              cursorRequest.onerror = function () {
                reject(new Error("Error opening cursor"));
              };
            }catch(error){
              console.log("database_error")
              resolve([])
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve([]));
    }
  }

  modules_get_continue_module(req): Observable<any> {
    
    const user_id = this.userProfileService.getUserId()
    const level = this.userProfileService.getUserLevel()
    const label = this.userProfileService.getWhiteLabel()

    console.log('modules_get_continue_module: ', label, level, user_id)

    if (level != null && label != null && user_id != null) {
      const module_storeName = "modules";
      const topics_storeName = "topics";
      const activities_storeName = "activities";
      const grades_storeName = "grades";
      let modules:any = [];
      let topics:any = [];
      let activities:any = [];
      let finished:any = [];
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.catalogDB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.cargarCatalogoDesdeAPI();
            }
            console.log("database_not_found")
            resolve({}); // Database does not exist
          };
  
          request.onerror = function (event: any) {
            console.log("database_error")
            resolve({})
          };

          request.onsuccess = (event: any) => {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(module_storeName, "readonly");

              const store = transaction.objectStore(module_storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = (event:any ) => {
                const cursor = event.target.result;
                if (cursor) {
                  if (cursor.value.white_label == label && cursor.value.level == level) {
                    console.log("Cursor entry:", cursor.value);
                    modules.push(cursor.value);
                  }
                  cursor.continue();
                } else {
                  modules = modules.sort((a, b) => a.module_number - b.module_number);
                  const module_ids = modules.map((module_obj)=>{
                    return module_obj.module_id
                  })

                  const transaction = db.transaction(topics_storeName, "readonly");

                  const store = transaction.objectStore(topics_storeName);
                  const cursorRequest = store.openCursor();
        
                  cursorRequest.onsuccess = (event:any ) => {
                    const cursor = event.target.result;
                    if (cursor) {
                      if (module_ids.includes(cursor.value.module_id)) {
                        console.log("Cursor entry:", cursor.value);
                        topics.push(cursor.value);
                      }
                      cursor.continue();
                    } else {
                      const topics_processed = topics.map((topic)=>{return [topic.name, topic.topic_number]}).sort((a, b) => a[1] - b[1]);
                      const topic_ids = topics.map((topic)=>{return topic.topic_id})

                      const transaction = db.transaction(activities_storeName, "readonly");

                      const store = transaction.objectStore(activities_storeName);
                      const cursorRequest = store.openCursor();
                      const found_activities = {}
            
                      cursorRequest.onsuccess = (event:any ) => {
                        const cursor = event.target.result;
                        if (cursor) {
                          if (topic_ids.includes(cursor.value.topic_id) && !cursor.value.home) {
                            console.log("Cursor entry:", cursor.value);
                            activities.push(cursor.value);

                            const found_module = modules.filter((module)=>{return module.module_id == cursor.value.module_id})
                            const found_topic = topics.filter((topic)=>{return topic.topic_id == cursor.value.topic_id})
                            if (found_module.length > 0){
                              if (found_topic.length > 0){
                                if(found_activities[found_module[0].module_number] == null){
                                  found_activities[found_module[0].module_number] = {}
                                }
                                if(found_activities[found_module[0].module_number][found_topic[0].topic_number] == null){
                                  found_activities[found_module[0].module_number][found_topic[0].topic_number] = 0
                                }
                                found_activities[found_module[0].module_number][found_topic[0].topic_number] += 1
                              }
                            }
                          }
                          cursor.continue();
                        } else {
                          const activity_ids = activities.map((activity)=>{return activity.activity_id})
                          
                          const request = indexedDB.open(this.grades_progress_DB);

                          request.onupgradeneeded = () => {
                            // This event only fires if the database doesn't exist or needs to be upgraded.
                            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
                            
                            if (navigator.onLine) {
                              this.updateGradesProgress();
                            }
                            console.log("database_not_found")
                            resolve({}); // Database does not exist
                          };
                  
                          request.onerror = function (event: any) {
                            console.log("database_error")
                            resolve({})
                          };

                          request.onsuccess = function (event: any) {
                            const db = event.target.result;
                            
                            try{
                              const transaction = db.transaction(grades_storeName, "readonly");

                              const store = transaction.objectStore(grades_storeName);
                              const cursorRequest = store.openCursor();
                              const found_grades = {}
                    
                              cursorRequest.onsuccess = function (event:any ) {
                                const cursor = event.target.result;
                                if (cursor) {
                                  if (
                                    activity_ids.length > 0 
                                    && cursor.value.student_id == user_id 
                                    && activity_ids.includes(cursor.value.activity_id)
                                  ){
                                    const found_module = modules.filter((module)=>{return module.module_id == cursor.value.module_id})
                                    const found_topic = topics.filter((topic)=>{return topic.topic_id == cursor.value.topic_id})
                                    if (found_module.length > 0){
                                      if (found_topic.length > 0){
                                        if(found_grades[found_module[0].module_number] == null){
                                          found_grades[found_module[0].module_number] = {}
                                        }
                                        if(found_grades[found_module[0].module_number][found_topic[0].topic_number] == null){
                                          found_grades[found_module[0].module_number][found_topic[0].topic_number] = 0
                                        }
                                        found_grades[found_module[0].module_number][found_topic[0].topic_number] += 1
                                      }
                                    }
                                  }
                                  cursor.continue();
                                } else {
                                  const get_next_module_topic = (found_activities, found_grades) => {
                                    let result;
                                    Object.keys(found_activities).sort((a, b)=>{return parseInt(a) - parseInt(b)}).forEach((module_number)=>{
                                      if (result == null){
                                        Object.keys(found_activities[module_number]).sort((a, b)=>{return parseInt(a) - parseInt(b)}).forEach((topic_number)=>{
                                          if(result == null){
                                            try{
                                              console.log('found_grade: ', module_number, topic_number, found_activities[module_number][topic_number], found_grades[module_number][topic_number])
                                              if(found_grades[module_number][topic_number] == null || found_activities[module_number][topic_number] > found_grades[module_number][topic_number]){
                                                result = [module_number, topic_number]
                                              }
                                            }catch(error){
                                              console.log("continue_module_error: ", error)
                                              result = [module_number, topic_number]
                                            }
                                          }
                                        })
                                      }
                                    })
                                    return result
                                  }
                                  const next_module_topic = get_next_module_topic(found_activities, found_grades)
                                  const next_module = modules.filter((module)=>{return module.module_number == next_module_topic[0]})
                                  const next_topic = topics.filter((topic)=>{return topic.topic_number == next_module_topic[1] && topic.module_id == next_module[0].module_id})
                                  console.log('next_module_topic: ', found_activities, found_grades, next_module_topic, next_module, next_topic)
                                  resolve({module: next_module[0], topic: next_topic[0]})
                                }
                              };
                              cursorRequest.onerror = function () {
                                console.log('did not finish')
                                resolve({});
                              };
                            }catch(error){
                              console.log("database_error")
                              resolve({})
                              return;
                            }
                          };
                        }
                      };
                    }
                  };
                }
              };
    
              cursorRequest.onerror = function () {
                reject(new Error("Error opening cursor"));
              };
            }catch(error){
              console.log("database_error")
              resolve({})
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve({}));
    }
  }

  topics_get_topic_content(req): Observable<any> {
    const label = req.params.get('label');
    const level = req.params.get('level');
    const module_number = req.params.get('module_number');
    const topic_number = req.params.get('topic_number');

    if (level != null && label != null && module_number != null && topic_number != null) {
      const module_storeName = "modules";
      const topics_storeName = "topics";
      const activities_storeName = "activities";
      const texts_storeName = "texts";
      const books_storeName = "books";
      const games_storeName = "games";
      const videos_storeName = "videos";
      let modules:any = [];
      let topic:any = {};
      let activities:any = [];
      let texts:any = [];
      let books:any = [];
      let games:any = [];
      let videos:any = [];
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.catalogDB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.cargarCatalogoDesdeAPI();
            }
            console.log("database_not_found")
            resolve([]); // Database does not exist
          };
  
          request.onerror = function (event: any) {
            console.log("database_error")
            resolve([])
          };

          request.onsuccess = function (event: any) {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(module_storeName, "readonly");

              const store = transaction.objectStore(module_storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = function (event:any ) {
                const cursor = event.target.result;
                if (cursor) {
                  if (cursor.value.white_label == label && cursor.value.level == level && cursor.value.module_number == module_number) {
                    console.log("Cursor entry:", cursor.value);
                    modules.push(cursor.value);
                  }
                  cursor.continue();
                } else {
                  modules = modules.sort((a, b) => a.module_number - b.module_number);
                  const module_ids = modules.map((module_obj)=>{
                    return module_obj.module_id
                  })

                  const transaction = db.transaction(topics_storeName, "readonly");

                  const store = transaction.objectStore(topics_storeName);
                  const cursorRequest = store.openCursor();
        
                  cursorRequest.onsuccess = function (event:any ) {
                    const cursor = event.target.result;
                    if (cursor) {
                      if (module_ids.includes(cursor.value.module_id) && cursor.value.topic_number == topic_number) {
                        console.log("Cursor entry:", cursor.value, module_ids, topic_number);
                        topic = cursor.value
                      }
                      cursor.continue();
                    } else {
                      const topic_id = topic.topic_id
                      const transaction = db.transaction(activities_storeName, "readonly");

                      const store = transaction.objectStore(activities_storeName);
                      const cursorRequest = store.openCursor();
            
                      cursorRequest.onsuccess = function (event:any ) {
                        const cursor = event.target.result;
                        if (cursor) {
                          if (cursor.value.topic_id == topic_id) {
                            console.log("Cursor entry:", cursor.value);
                            activities.push(cursor.value)
                          }
                          cursor.continue();
                        } else {
                          activities = activities.sort((a, b) => a.activity_number - b.activity_number);
                          
                          const transaction = db.transaction(texts_storeName, "readonly");

                          const store = transaction.objectStore(texts_storeName);
                          const cursorRequest = store.openCursor();
                
                          cursorRequest.onsuccess = function (event:any ) {
                            const cursor = event.target.result;
                            if (cursor) {
                              if (cursor.value.topic_id == topic_id) {
                                console.log("Cursor entry:", cursor.value);
                                texts.push(cursor.value)
                              }
                              cursor.continue();
                            } else {
                              texts = texts.sort((a, b) => a.text_number - b.text_number);
                              const transaction = db.transaction(books_storeName, "readonly");

                              const store = transaction.objectStore(books_storeName);
                              const cursorRequest = store.openCursor();
                    
                              cursorRequest.onsuccess = function (event:any ) {
                                const cursor = event.target.result;
                                if (cursor) {
                                  if (cursor.value.topic_id == topic_id) {
                                    console.log("Cursor entry:", cursor.value);
                                    books.push(cursor.value)
                                  }
                                  cursor.continue();
                                } else {
                                  books = books.sort((a, b) => a.book_number - b.book_number);
                                  const transaction = db.transaction(games_storeName, "readonly");

                                  const store = transaction.objectStore(games_storeName);
                                  const cursorRequest = store.openCursor();
                        
                                  cursorRequest.onsuccess = function (event:any ) {
                                    const cursor = event.target.result;
                                    if (cursor) {
                                      if (cursor.value.topic_id == topic_id) {
                                        console.log("Cursor entry:", cursor.value);
                                        games.push(cursor.value)
                                      }
                                      cursor.continue();
                                    } else {
                                      games = games.sort((a, b) => a.game_number - b.game_number);
                                      const transaction = db.transaction(videos_storeName, "readonly");

                                      const store = transaction.objectStore(videos_storeName);
                                      const cursorRequest = store.openCursor();
                            
                                      cursorRequest.onsuccess = function (event:any ) {
                                        const cursor = event.target.result;
                                        if (cursor) {
                                          if (cursor.value.topic_id == topic_id) {
                                            console.log("Cursor entry:", cursor.value);
                                            videos.push(cursor.value)
                                          }
                                          cursor.continue();
                                        } else {
                                          videos = videos.sort((a, b) => a.video_number - b.video_number);
                                          resolve({topic, activities, texts, books, games, videos}); // Resolve with the data when done
                                        }
                                      };
                                    }
                                  };
                                }
                              };
                            }
                          };
                        }
                      };
                    }
                  };
                }
              };
    
              cursorRequest.onerror = function () {
                reject(new Error("Error opening cursor"));
              };
            }catch(error){
              console.log("database_error")
              resolve([])
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve([]));
    }
  }

  topics_get_initial_resource(req): Observable<any> {
    const label = req.params.get('white_label');
    const level = req.params.get('level');
    const module_number = req.params.get('module_number');
    const topic_number = req.params.get('topic_number');
    console.log("initial_resource: ", label, level, module_number, topic_number)

    if (level != null && label != null && module_number != null && topic_number != null) {
      const module_storeName = "modules";
      const topics_storeName = "topics";
      const activities_storeName = "activities";
      const texts_storeName = "texts";
      const books_storeName = "books";
      const games_storeName = "games";
      const videos_storeName = "videos";
      let modules:any = [];
      let topic:any = {};
      let activities:any = [];
      let texts:any = [];
      let books:any = [];
      let games:any = [];
      let videos:any = [];
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.catalogDB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.cargarCatalogoDesdeAPI();
            }
            console.log("database_not_found")
            resolve([]); // Database does not exist
          };
  
          request.onerror = function (event: any) {
            console.log("database_error")
            resolve([])
          };

          request.onsuccess = function (event: any) {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(module_storeName, "readonly");

              const store = transaction.objectStore(module_storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = function (event:any ) {
                const cursor = event.target.result;
                if (cursor) {
                  if (cursor.value.white_label == label && cursor.value.level == level && cursor.value.module_number == module_number) {
                    console.log("Cursor entry:", cursor.value);
                    modules.push(cursor.value);
                  }
                  cursor.continue();
                } else {
                  modules = modules.sort((a, b) => a.module_number - b.module_number);
                  const module_ids = modules.map((module_obj)=>{
                    return module_obj.module_id
                  })

                  const transaction = db.transaction(topics_storeName, "readonly");

                  const store = transaction.objectStore(topics_storeName);
                  const cursorRequest = store.openCursor();
        
                  cursorRequest.onsuccess = function (event:any ) {
                    const cursor = event.target.result;
                    if (cursor) {
                      if (module_ids.includes(cursor.value.module_id) && cursor.value.topic_number == topic_number) {
                        console.log("Cursor entry:", cursor.value, module_ids, topic_number);
                        topic = cursor.value
                      }
                      cursor.continue();
                    } else {
                      const topic_id = topic.topic_id
                      const transaction = db.transaction(activities_storeName, "readonly");

                      const store = transaction.objectStore(activities_storeName);
                      const cursorRequest = store.openCursor();
            
                      cursorRequest.onsuccess = function (event:any ) {
                        const cursor = event.target.result;
                        if (cursor) {
                          if (cursor.value.topic_id == topic_id && cursor.value.first) {
                            console.log("Cursor entry:", cursor.value);
                            resolve([{...cursor.value, resource_type: 'activity'}])
                          }
                          cursor.continue();
                        } else {
                          activities = activities.sort((a, b) => a.activity_number - b.activity_number);
                          
                          const transaction = db.transaction(texts_storeName, "readonly");

                          const store = transaction.objectStore(texts_storeName);
                          const cursorRequest = store.openCursor();
                
                          cursorRequest.onsuccess = function (event:any ) {
                            const cursor = event.target.result;
                            if (cursor) {
                              if (cursor.value.topic_id == topic_id && cursor.value.first) {
                                console.log("Cursor entry:", cursor.value);
                                resolve([{...cursor.value, resource_type: 'text'}])
                              }
                              cursor.continue();
                            } else {
                              texts = texts.sort((a, b) => a.text_number - b.text_number);
                              const transaction = db.transaction(books_storeName, "readonly");

                              const store = transaction.objectStore(books_storeName);
                              const cursorRequest = store.openCursor();
                    
                              cursorRequest.onsuccess = function (event:any ) {
                                const cursor = event.target.result;
                                if (cursor) {
                                  if (cursor.value.topic_id == topic_id && cursor.value.first) {
                                    console.log("Cursor entry:", cursor.value);
                                    resolve([{...cursor.value, resource_type: 'book'}])
                                  }
                                  cursor.continue();
                                } else {
                                  books = books.sort((a, b) => a.book_number - b.book_number);
                                  const transaction = db.transaction(games_storeName, "readonly");

                                  const store = transaction.objectStore(games_storeName);
                                  const cursorRequest = store.openCursor();
                        
                                  cursorRequest.onsuccess = function (event:any ) {
                                    const cursor = event.target.result;
                                    if (cursor) {
                                      if (cursor.value.topic_id == topic_id && cursor.value.first) {
                                        console.log("Cursor entry:", cursor.value);
                                        resolve([{...cursor.value, resource_type: 'game'}])
                                      }
                                      cursor.continue();
                                    } else {
                                      games = games.sort((a, b) => a.game_number - b.game_number);
                                      const transaction = db.transaction(videos_storeName, "readonly");

                                      const store = transaction.objectStore(videos_storeName);
                                      const cursorRequest = store.openCursor();
                            
                                      cursorRequest.onsuccess = function (event:any ) {
                                        const cursor = event.target.result;
                                        if (cursor) {
                                          if (cursor.value.topic_id == topic_id) {
                                            console.log("Cursor entry:", cursor.value);
                                            resolve([{...cursor.value, resource_type: 'video'}])
                                          }
                                          cursor.continue();
                                        } else {
                                          videos = videos.sort((a, b) => a.video_number - b.video_number);
                                          resolve([]); // Resolve with the data when done
                                        }
                                      };
                                    }
                                  };
                                }
                              };
                            }
                          };
                        }
                      };
                    }
                  };
                }
              };
    
              cursorRequest.onerror = function () {
                reject(new Error("Error opening cursor"));
              };
            }catch(error){
              console.log("database_error")
              resolve([])
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve([]));
    }
  }
  
  topics_get_activity_content(req): Observable<any> {
    let label = req.params.get('white_label');
    let level = req.params.get('level');
    const module_number = req.params.get('module_number');
    const topic_number = req.params.get('topic_number');
    const activity_number = req.params.get('activity_number');

    console.log("params: ", label, level, module_number, topic_number, activity_number)

    if(label == null){
      label = this.userProfileService.getWhiteLabel()
    }

    if(level == null){
      level = this.userProfileService.getUserLevel()
    }

    if (level != null && label != null && module_number != null && topic_number != null && activity_number != null) {
      const module_storeName = "modules";
      const topics_storeName = "topics";
      const activities_storeName = "activities";
      let modules:any = [];
      let topic:any = {};
      let activities:any = [];
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.catalogDB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.cargarCatalogoDesdeAPI();
            }
            console.log("database_not_found")
            resolve([]); // Database does not exist
          };
  
          request.onerror = function (event: any) {
            console.log("database_error")
            resolve([])
          };

          request.onsuccess = function (event: any) {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(module_storeName, "readonly");

              const store = transaction.objectStore(module_storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = function (event:any ) {
                const cursor = event.target.result;
                if (cursor) {
                  if (cursor.value.white_label == label && cursor.value.level == level && cursor.value.module_number == module_number) {
                    console.log("Cursor entry:", cursor.value);
                    modules.push(cursor.value);
                  }
                  cursor.continue();
                } else {
                  modules = modules.sort((a, b) => a.module_number - b.module_number);
                  const module_ids = modules.map((module_obj)=>{
                    return module_obj.module_id
                  })

                  const transaction = db.transaction(topics_storeName, "readonly");

                  const store = transaction.objectStore(topics_storeName);
                  const cursorRequest = store.openCursor();
        
                  cursorRequest.onsuccess = function (event:any ) {
                    const cursor = event.target.result;
                    if (cursor) {
                      if (module_ids.includes(cursor.value.module_id) && cursor.value.topic_number == topic_number) {
                        console.log("Cursor entry:", cursor.value, module_ids, topic_number);
                        topic = cursor.value
                      }
                      cursor.continue();
                    } else {
                      const topic_id = topic.topic_id
                      const transaction = db.transaction(activities_storeName, "readonly");

                      const store = transaction.objectStore(activities_storeName);
                      const cursorRequest = store.openCursor();
            
                      cursorRequest.onsuccess = function (event:any ) {
                        const cursor = event.target.result;
                        if (cursor) {
                          if (cursor.value.topic_id == topic_id && cursor.value.activity_number == activity_number) {
                            console.log("Cursor entry:", cursor.value, activity_number);
                            activities.push(cursor.value)
                          }
                          cursor.continue();
                        } else {
                          const resource_skills = activities[0].skills
                          const found_skills:any = []
                          const skill_transaction = db.transaction("skills", "readonly");
                          const skill_store = skill_transaction.objectStore("skills");

                          const skill_cursorRequest = skill_store.openCursor();

                          skill_cursorRequest.onsuccess = function (event:any ) {
                            const cursor = event.target.result;
                            if (cursor) {
                              if (resource_skills.includes(cursor.value.skill_id)) {
                                found_skills.push(cursor.value)
                              }
                              cursor.continue();
                            } else {
                              activities[0].skills = found_skills

                              const next_activities = activities[0].next.filter((resource_loc)=>{return resource_loc[1] == "activity"}).map((activity_loc)=>{return activity_loc[0]})                      
                              const next_texts = activities[0].next.filter((resource_loc)=>{return resource_loc[1] == "text"}).map((text_loc)=>{return text_loc[0]})                      
                              const next_books = activities[0].next.filter((resource_loc)=>{return resource_loc[1] == "book"}).map((book_loc)=>{return book_loc[0]})                      
                              const next_games = activities[0].next.filter((resource_loc)=>{return resource_loc[1] == "game"}).map((game_loc)=>{return game_loc[0]})                      
                              const next_videos = activities[0].next.filter((resource_loc)=>{return resource_loc[1] == "video"}).map((video_loc)=>{return video_loc[0]})                      
                              
                              const transaction = db.transaction("activities", "readonly");
                              const store = transaction.objectStore("activities");
    
                              const cursorRequest = store.openCursor();
                
                              cursorRequest.onsuccess = function (event:any ) {
                                const cursor = event.target.result;
                                if (cursor) {
                                  if (next_activities.includes(cursor.value.activity_id)) {
                                    const resource_record = cursor.value
                                    console.log(next_activities, cursor.value.activity_id, activities, resource_record)
    
                                    const transaction = db.transaction(module_storeName, "readonly");
                                    const store = transaction.objectStore(module_storeName);
    
                                    // Get the object with the specified key
                                    const getRequest = store.get(resource_record.module_id);
    
                                    getRequest.onerror = (event) => {
                                        resolve(activities[0])
                                    };
    
                                    getRequest.onsuccess = (event) => {
                                      const module_record = event.target.result
                                      
                                      const transaction = db.transaction(topics_storeName, "readonly");
                                      const store = transaction.objectStore(topics_storeName);
      
                                      // Get the object with the specified key
                                      const getRequest = store.get(resource_record.topic_id);
      
                                      getRequest.onerror = (event) => {
                                          resolve(activities[0])
                                      };
      
                                      getRequest.onsuccess = (event) => {
                                          const topic_record = event.target.result
      
                                          activities[0].next = [{
                                            type: "activity",
                                            name: resource_record.name,
                                            number: resource_record.activity_number,
                                            topic_number: topic_record.topic_number,
                                            module_number: module_record.module_number
                                          }]
    
                                          resolve(activities[0])
                                      };
                                    };
                                  }
                                  cursor.continue();
                                } else {                                  
                                  const transaction = db.transaction("texts", "readonly");
                                  const store = transaction.objectStore("texts");
    
                                  const cursorRequest = store.openCursor();
                
                                  cursorRequest.onsuccess = function (event:any ) {
                                    const cursor = event.target.result;
                                    if (cursor) {
                                      if (next_texts.includes(cursor.value.text_id)) {
                                        const resource_record = cursor.value
    
                                        const transaction = db.transaction(module_storeName, "readonly");
                                        const store = transaction.objectStore(module_storeName);
    
                                        // Get the object with the specified key
                                        const getRequest = store.get(resource_record.module_id);
    
                                        getRequest.onerror = (event) => {
                                            resolve(activities[0])
                                        };
    
                                        getRequest.onsuccess = (event) => {
                                          const module_record = event.target.result
                                          
                                          const transaction = db.transaction(topics_storeName, "readonly");
                                          const store = transaction.objectStore(topics_storeName);
          
                                          // Get the object with the specified key
                                          const getRequest = store.get(resource_record.topic_id);
          
                                          getRequest.onerror = (event) => {
                                              resolve(activities[0])
                                          };
          
                                          getRequest.onsuccess = (event) => {
                                              const topic_record = event.target.result
          
                                              activities[0].next = [{
                                                type: "text",
                                                name: resource_record.name,
                                                number: resource_record.text_number,
                                                topic_number: topic_record.topic_number,
                                                module_number: module_record.module_number
                                              }]
    
                                              resolve(activities[0])
                                          };
                                        };
                                      }
                                      cursor.continue();
                                    } else {                                  
                                      const transaction = db.transaction("books", "readonly");
                                      const store = transaction.objectStore("books");
    
                                      const cursorRequest = store.openCursor();
                
                                      cursorRequest.onsuccess = function (event:any ) {
                                        const cursor = event.target.result;
                                        if (cursor) {
                                          if (next_books.includes(cursor.value.book_id)) {
                                            const resource_record = cursor.value
    
                                            const transaction = db.transaction(module_storeName, "readonly");
                                            const store = transaction.objectStore(module_storeName);
    
                                            // Get the object with the specified key
                                            const getRequest = store.get(resource_record.module_id);
    
                                            getRequest.onerror = (event) => {
                                                resolve(activities[0])
                                            };
    
                                            getRequest.onsuccess = (event) => {
                                              const module_record = event.target.result
                                              
                                              const transaction = db.transaction(topics_storeName, "readonly");
                                              const store = transaction.objectStore(topics_storeName);
              
                                              // Get the object with the specified key
                                              const getRequest = store.get(resource_record.topic_id);
              
                                              getRequest.onerror = (event) => {
                                                  resolve(activities[0])
                                              };
              
                                              getRequest.onsuccess = (event) => {
                                                  const topic_record = event.target.result
              
                                                  activities[0].next = [{
                                                    type: "book",
                                                    name: resource_record.name,
                                                    number: resource_record.book_number,
                                                    topic_number: topic_record.topic_number,
                                                    module_number: module_record.module_number
                                                  }]
    
                                                  resolve(activities[0])
                                              };
                                            };
                                          }
                                          cursor.continue();
                                        } else {                                  
                                          const transaction = db.transaction("games", "readonly");
                                          const store = transaction.objectStore("games");
    
                                          const cursorRequest = store.openCursor();
                
                                          cursorRequest.onsuccess = function (event:any ) {
                                            const cursor = event.target.result;
                                            if (cursor) {
                                              if (next_games.includes(cursor.value.game_id)) {
                                                const resource_record = cursor.value
    
                                                const transaction = db.transaction(module_storeName, "readonly");
                                                const store = transaction.objectStore(module_storeName);
    
                                                // Get the object with the specified key
                                                const getRequest = store.get(resource_record.module_id);
    
                                                getRequest.onerror = (event) => {
                                                    resolve(activities[0])
                                                };
    
                                                getRequest.onsuccess = (event) => {
                                                  const module_record = event.target.result
                                                  
                                                  const transaction = db.transaction(topics_storeName, "readonly");
                                                  const store = transaction.objectStore(topics_storeName);
                  
                                                  // Get the object with the specified key
                                                  const getRequest = store.get(resource_record.topic_id);
                  
                                                  getRequest.onerror = (event) => {
                                                      resolve(activities[0])
                                                  };
                  
                                                  getRequest.onsuccess = (event) => {
                                                      const topic_record = event.target.result
                  
                                                      activities[0].next = [{
                                                        type: "game",
                                                        name: resource_record.name,
                                                        number: resource_record.game_number,
                                                        topic_number: topic_record.topic_number,
                                                        module_number: module_record.module_number
                                                      }]
    
                                                      resolve(activities[0])
                                                  };
                                                };
                                              }
                                              cursor.continue();
                                            } else {                                  
                                              const transaction = db.transaction("videos", "readonly");
                                              const store = transaction.objectStore("videos");
    
                                              const cursorRequest = store.openCursor();
                
                                              cursorRequest.onsuccess = function (event:any ) {
                                                const cursor = event.target.result;
                                                if (cursor) {
                                                  if (next_videos.includes(cursor.value.video_id)) {
                                                    const resource_record = cursor.value
    
                                                    const transaction = db.transaction(module_storeName, "readonly");
                                                    const store = transaction.objectStore(module_storeName);
    
                                                    // Get the object with the specified key
                                                    const getRequest = store.get(resource_record.module_id);
    
                                                    getRequest.onerror = (event) => {
                                                        resolve(activities[0])
                                                    };
    
                                                    getRequest.onsuccess = (event) => {
                                                      const module_record = event.target.result
                                                      
                                                      const transaction = db.transaction(topics_storeName, "readonly");
                                                      const store = transaction.objectStore(topics_storeName);
                      
                                                      // Get the object with the specified key
                                                      const getRequest = store.get(resource_record.topic_id);
                      
                                                      getRequest.onerror = (event) => {
                                                          resolve(activities[0])
                                                      };
                      
                                                      getRequest.onsuccess = (event) => {
                                                          const topic_record = event.target.result
                      
                                                          activities[0].next = [{
                                                            type: "video",
                                                            name: resource_record.name,
                                                            number: resource_record.video_number,
                                                            topic_number: topic_record.topic_number,
                                                            module_number: module_record.module_number
                                                          }]
    
                                                          resolve(activities[0])
                                                      };
                                                    };
                                                  }
                                                  cursor.continue();
                                                } else {                                  
                                                  resolve(activities[0])
                                                }
                                              };
                                            }
                                          };
                                        }
                                      };
                                    }
                                  };
                                }
                              };
                            }
                          };
                        }
                      };
                    }
                  };
                }
              };
    
              cursorRequest.onerror = function () {
                reject(new Error("Error opening cursor"));
              };
            }catch(error){
              console.log("database_error")
              resolve([])
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve([]));
    }
  }

  topics_get_book_content(req): Observable<any> {
    let label = req.params.get('white_label');
    let level = req.params.get('level');
    const module_number = req.params.get('module_number');
    const topic_number = req.params.get('topic_number');
    const book_number = req.params.get('book_number');

    if(label == null){
      label = this.userProfileService.getWhiteLabel()
    }

    if(level == null){
      level = this.userProfileService.getUserLevel()
    }

    if (level != null && label != null && module_number != null && topic_number != null && book_number != null) {
      const module_storeName = "modules";
      const topics_storeName = "topics";
      const books_storeName = "books";
      let modules:any = [];
      let topic:any = {};
      let books:any = [];
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.catalogDB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.cargarCatalogoDesdeAPI();
            }
            console.log("database_not_found")
            resolve([]); // Database does not exist
          };
  
          request.onerror = function (event: any) {
            console.log("database_error")
            resolve([])
          };

          request.onsuccess = function (event: any) {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(module_storeName, "readonly");

              const store = transaction.objectStore(module_storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = function (event:any ) {
                const cursor = event.target.result;
                if (cursor) {
                  if (cursor.value.white_label == label && cursor.value.level == level && cursor.value.module_number == module_number) {
                    console.log("Cursor entry:", cursor.value);
                    modules.push(cursor.value);
                  }
                  cursor.continue();
                } else {
                  modules = modules.sort((a, b) => a.module_number - b.module_number);
                  const module_ids = modules.map((module_obj)=>{
                    return module_obj.module_id
                  })

                  const transaction = db.transaction(topics_storeName, "readonly");

                  const store = transaction.objectStore(topics_storeName);
                  const cursorRequest = store.openCursor();
        
                  cursorRequest.onsuccess = function (event:any ) {
                    const cursor = event.target.result;
                    if (cursor) {
                      if (module_ids.includes(cursor.value.module_id) && cursor.value.topic_number == topic_number) {
                        console.log("Cursor entry:", cursor.value, module_ids, topic_number);
                        topic = cursor.value
                      }
                      cursor.continue();
                    } else {
                      const topic_id = topic.topic_id
                      const transaction = db.transaction(books_storeName, "readonly");

                      const store = transaction.objectStore(books_storeName);
                      const cursorRequest = store.openCursor();
            
                      cursorRequest.onsuccess = function (event:any ) {
                        const cursor = event.target.result;
                        if (cursor) {
                          if (cursor.value.topic_id == topic_id && cursor.value.book_number == book_number) {
                            console.log("Cursor entry:", cursor.value);
                            books.push(cursor.value)
                          }
                          cursor.continue();
                        } else {
                          const resource_skills = books[0].skills
                          const found_skills:any = []
                          const skill_transaction = db.transaction("skills", "readonly");
                          const skill_store = skill_transaction.objectStore("skills");

                          const skill_cursorRequest = skill_store.openCursor();

                          skill_cursorRequest.onsuccess = function (event:any ) {
                            const cursor = event.target.result;
                            if (cursor) {
                              if (resource_skills.includes(cursor.value.skill_id)) {
                                found_skills.push(cursor.value)
                              }
                              cursor.continue();
                            } else {
                              books[0].skills = found_skills

                              const next_activities = books[0].next.filter((resource_loc)=>{return resource_loc[1] == "activity"}).map((activity_loc)=>{return activity_loc[0]})                      
                              const next_texts = books[0].next.filter((resource_loc)=>{return resource_loc[1] == "text"}).map((text_loc)=>{return text_loc[0]})                      
                              const next_books = books[0].next.filter((resource_loc)=>{return resource_loc[1] == "book"}).map((book_loc)=>{return book_loc[0]})                      
                              const next_games = books[0].next.filter((resource_loc)=>{return resource_loc[1] == "game"}).map((game_loc)=>{return game_loc[0]})                      
                              const next_videos = books[0].next.filter((resource_loc)=>{return resource_loc[1] == "video"}).map((video_loc)=>{return video_loc[0]})                      
                              
                              const transaction = db.transaction("activities", "readonly");
                              const store = transaction.objectStore("activities");

                              const cursorRequest = store.openCursor();
                
                              cursorRequest.onsuccess = function (event:any ) {
                                const cursor = event.target.result;
                                if (cursor) {
                                  if (next_activities.includes(cursor.value.activity_id)) {
                                    const resource_record = cursor.value

                                    const transaction = db.transaction(module_storeName, "readonly");
                                    const store = transaction.objectStore(module_storeName);

                                    // Get the object with the specified key
                                    const getRequest = store.get(resource_record.module_id);

                                    getRequest.onerror = (event) => {
                                        resolve(books[0])
                                    };

                                    getRequest.onsuccess = (event) => {
                                      const module_record = event.target.result
                                      
                                      const transaction = db.transaction(topics_storeName, "readonly");
                                      const store = transaction.objectStore(topics_storeName);
      
                                      // Get the object with the specified key
                                      const getRequest = store.get(resource_record.topic_id);
      
                                      getRequest.onerror = (event) => {
                                          resolve(books[0])
                                      };
      
                                      getRequest.onsuccess = (event) => {
                                          const topic_record = event.target.result
      
                                          books[0].next = [{
                                            type: "activity",
                                            name: resource_record.name,
                                            number: resource_record.activity_number,
                                            topic_number: topic_record.topic_number,
                                            module_number: module_record.module_number
                                          }]

                                          resolve(books[0])
                                      };
                                    };
                                  }
                                  cursor.continue();
                                } else {                                  
                                  const transaction = db.transaction("texts", "readonly");
                                  const store = transaction.objectStore("texts");

                                  const cursorRequest = store.openCursor();
                
                                  cursorRequest.onsuccess = function (event:any ) {
                                    const cursor = event.target.result;
                                    if (cursor) {
                                      if (next_texts.includes(cursor.value.text_id)) {
                                        const resource_record = cursor.value

                                        const transaction = db.transaction(module_storeName, "readonly");
                                        const store = transaction.objectStore(module_storeName);

                                        // Get the object with the specified key
                                        const getRequest = store.get(resource_record.module_id);

                                        getRequest.onerror = (event) => {
                                            resolve(books[0])
                                        };

                                        getRequest.onsuccess = (event) => {
                                          const module_record = event.target.result
                                          
                                          const transaction = db.transaction(topics_storeName, "readonly");
                                          const store = transaction.objectStore(topics_storeName);
          
                                          // Get the object with the specified key
                                          const getRequest = store.get(resource_record.topic_id);
          
                                          getRequest.onerror = (event) => {
                                              resolve(books[0])
                                          };
          
                                          getRequest.onsuccess = (event) => {
                                              const topic_record = event.target.result
          
                                              books[0].next = [{
                                                type: "text",
                                                name: resource_record.name,
                                                number: resource_record.text_number,
                                                topic_number: topic_record.topic_number,
                                                module_number: module_record.module_number
                                              }]

                                              resolve(books[0])
                                          };
                                        };
                                      }
                                      cursor.continue();
                                    } else {                                  
                                      const transaction = db.transaction("books", "readonly");
                                      const store = transaction.objectStore("books");

                                      const cursorRequest = store.openCursor();
                
                                      cursorRequest.onsuccess = function (event:any ) {
                                        const cursor = event.target.result;
                                        if (cursor) {
                                          if (next_books.includes(cursor.value.book_id)) {
                                            const resource_record = cursor.value

                                            const transaction = db.transaction(module_storeName, "readonly");
                                            const store = transaction.objectStore(module_storeName);

                                            // Get the object with the specified key
                                            const getRequest = store.get(resource_record.module_id);

                                            getRequest.onerror = (event) => {
                                                resolve(books[0])
                                            };

                                            getRequest.onsuccess = (event) => {
                                              const module_record = event.target.result
                                              
                                              const transaction = db.transaction(topics_storeName, "readonly");
                                              const store = transaction.objectStore(topics_storeName);
              
                                              // Get the object with the specified key
                                              const getRequest = store.get(resource_record.topic_id);
              
                                              getRequest.onerror = (event) => {
                                                  resolve(books[0])
                                              };
              
                                              getRequest.onsuccess = (event) => {
                                                  const topic_record = event.target.result
              
                                                  books[0].next = [{
                                                    type: "book",
                                                    name: resource_record.name,
                                                    number: resource_record.book_number,
                                                    topic_number: topic_record.topic_number,
                                                    module_number: module_record.module_number
                                                  }]

                                                  resolve(books[0])
                                              };
                                            };
                                          }
                                          cursor.continue();
                                        } else {                                  
                                          const transaction = db.transaction("games", "readonly");
                                          const store = transaction.objectStore("games");

                                          const cursorRequest = store.openCursor();
                
                                          cursorRequest.onsuccess = function (event:any ) {
                                            const cursor = event.target.result;
                                            if (cursor) {
                                              if (next_games.includes(cursor.value.game_id)) {
                                                const resource_record = cursor.value

                                                const transaction = db.transaction(module_storeName, "readonly");
                                                const store = transaction.objectStore(module_storeName);

                                                // Get the object with the specified key
                                                const getRequest = store.get(resource_record.module_id);

                                                getRequest.onerror = (event) => {
                                                    resolve(books[0])
                                                };

                                                getRequest.onsuccess = (event) => {
                                                  const module_record = event.target.result
                                                  
                                                  const transaction = db.transaction(topics_storeName, "readonly");
                                                  const store = transaction.objectStore(topics_storeName);
                  
                                                  // Get the object with the specified key
                                                  const getRequest = store.get(resource_record.topic_id);
                  
                                                  getRequest.onerror = (event) => {
                                                      resolve(books[0])
                                                  };
                  
                                                  getRequest.onsuccess = (event) => {
                                                      const topic_record = event.target.result
                  
                                                      books[0].next = [{
                                                        type: "game",
                                                        name: resource_record.name,
                                                        number: resource_record.game_number,
                                                        topic_number: topic_record.topic_number,
                                                        module_number: module_record.module_number
                                                      }]

                                                      resolve(books[0])
                                                  };
                                                };
                                              }
                                              cursor.continue();
                                            } else {                                  
                                              const transaction = db.transaction("videos", "readonly");
                                              const store = transaction.objectStore("videos");

                                              const cursorRequest = store.openCursor();
                
                                              cursorRequest.onsuccess = function (event:any ) {
                                                const cursor = event.target.result;
                                                if (cursor) {
                                                  if (next_videos.includes(cursor.value.video_id)) {
                                                    const resource_record = cursor.value

                                                    const transaction = db.transaction(module_storeName, "readonly");
                                                    const store = transaction.objectStore(module_storeName);

                                                    // Get the object with the specified key
                                                    const getRequest = store.get(resource_record.module_id);

                                                    getRequest.onerror = (event) => {
                                                        resolve(books[0])
                                                    };

                                                    getRequest.onsuccess = (event) => {
                                                      const module_record = event.target.result
                                                      
                                                      const transaction = db.transaction(topics_storeName, "readonly");
                                                      const store = transaction.objectStore(topics_storeName);
                      
                                                      // Get the object with the specified key
                                                      const getRequest = store.get(resource_record.topic_id);
                      
                                                      getRequest.onerror = (event) => {
                                                          resolve(books[0])
                                                      };
                      
                                                      getRequest.onsuccess = (event) => {
                                                          const topic_record = event.target.result
                      
                                                          books[0].next = [{
                                                            type: "video",
                                                            name: resource_record.name,
                                                            number: resource_record.video_number,
                                                            topic_number: topic_record.topic_number,
                                                            module_number: module_record.module_number
                                                          }]

                                                          resolve(books[0])
                                                      };
                                                    };
                                                  }
                                                  cursor.continue();
                                                } else {                                  
                                                  resolve(books[0])
                                                }
                                              };
                                            }
                                          };
                                        }
                                      };
                                    }
                                  };
                                }
                              };
                            }
                          };
                        }
                      };
                    }
                  };
                }
              };
    
              cursorRequest.onerror = function () {
                reject(new Error("Error opening cursor"));
              };
            }catch(error){
              console.log("database_error")
              resolve([])
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve([]));
    }
  }

  topics_get_text_content(req): Observable<any> {
    let label = req.params.get('white_label');
    let level = req.params.get('level');
    const module_number = req.params.get('module_number');
    const topic_number = req.params.get('topic_number');
    const text_number = req.params.get('text_number');

    if(label == null){
      label = this.userProfileService.getWhiteLabel()
    }

    if(level == null){
      level = this.userProfileService.getUserLevel()
    }

    if (level != null && label != null && module_number != null && topic_number != null && text_number != null) {
      const module_storeName = "modules";
      const topics_storeName = "topics";
      const texts_storeName = "texts";
      let modules:any = [];
      let topic:any = {};
      let texts:any = [];
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.catalogDB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.cargarCatalogoDesdeAPI();
            }
            console.log("database_not_found")
            resolve([]); // Database does not exist
          };
  
          request.onerror = function (event: any) {
            console.log("database_error")
            resolve([])
          };

          request.onsuccess = function (event: any) {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(module_storeName, "readonly");

              const store = transaction.objectStore(module_storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = function (event:any ) {
                const cursor = event.target.result;
                if (cursor) {
                  if (cursor.value.white_label == label && cursor.value.level == level && cursor.value.module_number == module_number) {
                    console.log("Cursor entry:", cursor.value);
                    modules.push(cursor.value);
                  }
                  cursor.continue();
                } else {
                  modules = modules.sort((a, b) => a.module_number - b.module_number);
                  const module_ids = modules.map((module_obj)=>{
                    return module_obj.module_id
                  })

                  const transaction = db.transaction(topics_storeName, "readonly");

                  const store = transaction.objectStore(topics_storeName);
                  const cursorRequest = store.openCursor();
        
                  cursorRequest.onsuccess = function (event:any ) {
                    const cursor = event.target.result;
                    if (cursor) {
                      if (module_ids.includes(cursor.value.module_id) && cursor.value.topic_number == topic_number) {
                        console.log("Cursor entry:", cursor.value, module_ids, topic_number);
                        topic = cursor.value
                      }
                      cursor.continue();
                    } else {
                      const topic_id = topic.topic_id
                      const transaction = db.transaction(texts_storeName, "readonly");

                      const store = transaction.objectStore(texts_storeName);
                      const cursorRequest = store.openCursor();
            
                      cursorRequest.onsuccess = function (event:any ) {
                        const cursor = event.target.result;
                        if (cursor) {
                          if (cursor.value.topic_id == topic_id && cursor.value.text_number == text_number) {
                            console.log("Cursor entry:", cursor.value);
                            texts.push(cursor.value)
                          }
                          cursor.continue();
                        } else {  
                          const resource_skills = texts[0].skills
                          const found_skills:any = []
                          const skill_transaction = db.transaction("skills", "readonly");
                          const skill_store = skill_transaction.objectStore("skills");

                          const skill_cursorRequest = skill_store.openCursor();

                          skill_cursorRequest.onsuccess = function (event:any ) {
                            const cursor = event.target.result;
                            if (cursor) {
                              if (resource_skills.includes(cursor.value.skill_id)) {
                                found_skills.push(cursor.value)
                              }
                              cursor.continue();
                            } else {
                              texts[0].skills = found_skills

                              const next_activities = texts[0].next.filter((resource_loc)=>{return resource_loc[1] == "activity"}).map((activity_loc)=>{return activity_loc[0]})                      
                              const next_texts = texts[0].next.filter((resource_loc)=>{return resource_loc[1] == "text"}).map((text_loc)=>{return text_loc[0]})                      
                              const next_books = texts[0].next.filter((resource_loc)=>{return resource_loc[1] == "book"}).map((book_loc)=>{return book_loc[0]})                      
                              const next_games = texts[0].next.filter((resource_loc)=>{return resource_loc[1] == "game"}).map((game_loc)=>{return game_loc[0]})                      
                              const next_videos = texts[0].next.filter((resource_loc)=>{return resource_loc[1] == "video"}).map((video_loc)=>{return video_loc[0]})                      
                              
                              const transaction = db.transaction("activities", "readonly");
                              const store = transaction.objectStore("activities");
    
                              const cursorRequest = store.openCursor();
                
                              cursorRequest.onsuccess = function (event:any ) {
                                const cursor = event.target.result;
                                if (cursor) {
                                  if (next_activities.includes(cursor.value.activity_id)) {
                                    const resource_record = cursor.value
    
                                    const transaction = db.transaction(module_storeName, "readonly");
                                    const store = transaction.objectStore(module_storeName);
    
                                    // Get the object with the specified key
                                    const getRequest = store.get(resource_record.module_id);
    
                                    getRequest.onerror = (event) => {
                                        resolve(texts[0])
                                    };
    
                                    getRequest.onsuccess = (event) => {
                                      const module_record = event.target.result
                                      
                                      const transaction = db.transaction(topics_storeName, "readonly");
                                      const store = transaction.objectStore(topics_storeName);
      
                                      // Get the object with the specified key
                                      const getRequest = store.get(resource_record.topic_id);
      
                                      getRequest.onerror = (event) => {
                                          resolve(texts[0])
                                      };
      
                                      getRequest.onsuccess = (event) => {
                                          const topic_record = event.target.result
      
                                          texts[0].next = [{
                                            type: "activity",
                                            name: resource_record.name,
                                            number: resource_record.activity_number,
                                            topic_number: topic_record.topic_number,
                                            module_number: module_record.module_number
                                          }]
    
                                          resolve(texts[0])
                                      };
                                    };
                                  }
                                  cursor.continue();
                                } else {                                  
                                  const transaction = db.transaction("texts", "readonly");
                                  const store = transaction.objectStore("texts");
    
                                  const cursorRequest = store.openCursor();
                
                                  cursorRequest.onsuccess = function (event:any ) {
                                    const cursor = event.target.result;
                                    if (cursor) {
                                      if (next_texts.includes(cursor.value.text_id)) {
                                        const resource_record = cursor.value
    
                                        const transaction = db.transaction(module_storeName, "readonly");
                                        const store = transaction.objectStore(module_storeName);
    
                                        // Get the object with the specified key
                                        const getRequest = store.get(resource_record.module_id);
    
                                        getRequest.onerror = (event) => {
                                            resolve(texts[0])
                                        };
    
                                        getRequest.onsuccess = (event) => {
                                          const module_record = event.target.result
                                          
                                          const transaction = db.transaction(topics_storeName, "readonly");
                                          const store = transaction.objectStore(topics_storeName);
          
                                          // Get the object with the specified key
                                          const getRequest = store.get(resource_record.topic_id);
          
                                          getRequest.onerror = (event) => {
                                              resolve(texts[0])
                                          };
          
                                          getRequest.onsuccess = (event) => {
                                              const topic_record = event.target.result
          
                                              texts[0].next = [{
                                                type: "text",
                                                name: resource_record.name,
                                                number: resource_record.text_number,
                                                topic_number: topic_record.topic_number,
                                                module_number: module_record.module_number
                                              }]
    
                                              resolve(texts[0])
                                          };
                                        };
                                      }
                                      cursor.continue();
                                    } else {                                  
                                      const transaction = db.transaction("books", "readonly");
                                      const store = transaction.objectStore("books");
    
                                      const cursorRequest = store.openCursor();
                
                                      cursorRequest.onsuccess = function (event:any ) {
                                        const cursor = event.target.result;
                                        if (cursor) {
                                          if (next_books.includes(cursor.value.book_id)) {
                                            const resource_record = cursor.value
    
                                            const transaction = db.transaction(module_storeName, "readonly");
                                            const store = transaction.objectStore(module_storeName);
    
                                            // Get the object with the specified key
                                            const getRequest = store.get(resource_record.module_id);
    
                                            getRequest.onerror = (event) => {
                                                resolve(texts[0])
                                            };
    
                                            getRequest.onsuccess = (event) => {
                                              const module_record = event.target.result
                                              
                                              const transaction = db.transaction(topics_storeName, "readonly");
                                              const store = transaction.objectStore(topics_storeName);
              
                                              // Get the object with the specified key
                                              const getRequest = store.get(resource_record.topic_id);
              
                                              getRequest.onerror = (event) => {
                                                  resolve(texts[0])
                                              };
              
                                              getRequest.onsuccess = (event) => {
                                                  const topic_record = event.target.result
              
                                                  texts[0].next = [{
                                                    type: "book",
                                                    name: resource_record.name,
                                                    number: resource_record.book_number,
                                                    topic_number: topic_record.topic_number,
                                                    module_number: module_record.module_number
                                                  }]
    
                                                  resolve(texts[0])
                                              };
                                            };
                                          }
                                          cursor.continue();
                                        } else {                                  
                                          const transaction = db.transaction("games", "readonly");
                                          const store = transaction.objectStore("games");
    
                                          const cursorRequest = store.openCursor();
                
                                          cursorRequest.onsuccess = function (event:any ) {
                                            const cursor = event.target.result;
                                            if (cursor) {
                                              if (next_games.includes(cursor.value.game_id)) {
                                                const resource_record = cursor.value
    
                                                const transaction = db.transaction(module_storeName, "readonly");
                                                const store = transaction.objectStore(module_storeName);
    
                                                // Get the object with the specified key
                                                const getRequest = store.get(resource_record.module_id);
    
                                                getRequest.onerror = (event) => {
                                                    resolve(texts[0])
                                                };
    
                                                getRequest.onsuccess = (event) => {
                                                  const module_record = event.target.result
                                                  
                                                  const transaction = db.transaction(topics_storeName, "readonly");
                                                  const store = transaction.objectStore(topics_storeName);
                  
                                                  // Get the object with the specified key
                                                  const getRequest = store.get(resource_record.topic_id);
                  
                                                  getRequest.onerror = (event) => {
                                                      resolve(texts[0])
                                                  };
                  
                                                  getRequest.onsuccess = (event) => {
                                                      const topic_record = event.target.result
                  
                                                      texts[0].next = [{
                                                        type: "game",
                                                        name: resource_record.name,
                                                        number: resource_record.game_number,
                                                        topic_number: topic_record.topic_number,
                                                        module_number: module_record.module_number
                                                      }]
    
                                                      resolve(texts[0])
                                                  };
                                                };
                                              }
                                              cursor.continue();
                                            } else {                                  
                                              const transaction = db.transaction("videos", "readonly");
                                              const store = transaction.objectStore("videos");
    
                                              const cursorRequest = store.openCursor();
                
                                              cursorRequest.onsuccess = function (event:any ) {
                                                const cursor = event.target.result;
                                                if (cursor) {
                                                  if (next_videos.includes(cursor.value.video_id)) {
                                                    const resource_record = cursor.value
    
                                                    const transaction = db.transaction(module_storeName, "readonly");
                                                    const store = transaction.objectStore(module_storeName);
    
                                                    // Get the object with the specified key
                                                    const getRequest = store.get(resource_record.module_id);
    
                                                    getRequest.onerror = (event) => {
                                                        resolve(texts[0])
                                                    };
    
                                                    getRequest.onsuccess = (event) => {
                                                      const module_record = event.target.result
                                                      
                                                      const transaction = db.transaction(topics_storeName, "readonly");
                                                      const store = transaction.objectStore(topics_storeName);
                      
                                                      // Get the object with the specified key
                                                      const getRequest = store.get(resource_record.topic_id);
                      
                                                      getRequest.onerror = (event) => {
                                                          resolve(texts[0])
                                                      };
                      
                                                      getRequest.onsuccess = (event) => {
                                                          const topic_record = event.target.result
                      
                                                          texts[0].next = [{
                                                            type: "video",
                                                            name: resource_record.name,
                                                            number: resource_record.video_number,
                                                            topic_number: topic_record.topic_number,
                                                            module_number: module_record.module_number
                                                          }]
    
                                                          resolve(texts[0])
                                                      };
                                                    };
                                                  }
                                                  cursor.continue();
                                                } else {                                  
                                                  resolve(texts[0])
                                                }
                                              };
                                            }
                                          };
                                        }
                                      };
                                    }
                                  };
                                }
                              };
                            }
                          };
                        }
                      };
                    }
                  };
                }
              };
    
              cursorRequest.onerror = function () {
                resolve([]);
              };
            }catch(error){
              console.log("database_error")
              resolve([])
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve([]));
    }
  }

  topics_get_game_content(req): Observable<any> {
    let label = req.params.get('white_label');
    let level = req.params.get('level');
    const module_number = req.params.get('module_number');
    const topic_number = req.params.get('topic_number');
    const game_number = req.params.get('game_number');

    if(label == null){
      label = this.userProfileService.getWhiteLabel()
    }

    if(level == null){
      level = this.userProfileService.getUserLevel()
    }

    if (level != null && label != null && module_number != null && topic_number != null && game_number != null) {
      const module_storeName = "modules";
      const topics_storeName = "topics";
      const games_storeName = "games";
      let modules:any = [];
      let topic:any = {};
      let games:any = [];
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.catalogDB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.cargarCatalogoDesdeAPI();
            }
            console.log("database_not_found")
            resolve([]); // Database does not exist
          };
  
          request.onerror = function (event: any) {
            console.log("database_error")
            resolve([])
          };

          request.onsuccess = function (event: any) {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(module_storeName, "readonly");

              const store = transaction.objectStore(module_storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = function (event:any ) {
                const cursor = event.target.result;
                if (cursor) {
                  if (cursor.value.white_label == label && cursor.value.level == level && cursor.value.module_number == module_number) {
                    console.log("Cursor entry:", cursor.value);
                    modules.push(cursor.value);
                  }
                  cursor.continue();
                } else {
                  modules = modules.sort((a, b) => a.module_number - b.module_number);
                  const module_ids = modules.map((module_obj)=>{
                    return module_obj.module_id
                  })

                  const transaction = db.transaction(topics_storeName, "readonly");

                  const store = transaction.objectStore(topics_storeName);
                  const cursorRequest = store.openCursor();
        
                  cursorRequest.onsuccess = function (event:any ) {
                    const cursor = event.target.result;
                    if (cursor) {
                      if (module_ids.includes(cursor.value.module_id) && cursor.value.topic_number == topic_number) {
                        console.log("Cursor entry:", cursor.value, module_ids, topic_number);
                        topic = cursor.value
                      }
                      cursor.continue();
                    } else {
                      const topic_id = topic.topic_id
                      const transaction = db.transaction(games_storeName, "readonly");

                      const store = transaction.objectStore(games_storeName);
                      const cursorRequest = store.openCursor();
            
                      cursorRequest.onsuccess = function (event:any ) {
                        const cursor = event.target.result;
                        if (cursor) {
                          if (cursor.value.topic_id == topic_id && cursor.value.game_number == game_number) {
                            console.log("Cursor entry:", cursor.value);
                            games.push(cursor.value)
                          }
                          cursor.continue();
                        } else {
                          const resource_skills = games[0].skills
                          const found_skills:any = []
                          const skill_transaction = db.transaction("skills", "readonly");
                          const skill_store = skill_transaction.objectStore("skills");

                          const skill_cursorRequest = skill_store.openCursor();

                          skill_cursorRequest.onsuccess = function (event:any ) {
                            const cursor = event.target.result;
                            if (cursor) {
                              if (resource_skills.includes(cursor.value.skill_id)) {
                                found_skills.push(cursor.value)
                              }
                              cursor.continue();
                            } else {
                              games[0].skills = found_skills

                              const next_activities = games[0].next.filter((resource_loc)=>{return resource_loc[1] == "activity"}).map((activity_loc)=>{return activity_loc[0]})                      
                              const next_texts = games[0].next.filter((resource_loc)=>{return resource_loc[1] == "text"}).map((text_loc)=>{return text_loc[0]})                      
                              const next_books = games[0].next.filter((resource_loc)=>{return resource_loc[1] == "book"}).map((book_loc)=>{return book_loc[0]})                      
                              const next_games = games[0].next.filter((resource_loc)=>{return resource_loc[1] == "game"}).map((game_loc)=>{return game_loc[0]})                      
                              const next_videos = games[0].next.filter((resource_loc)=>{return resource_loc[1] == "video"}).map((video_loc)=>{return video_loc[0]})                      
                              
                              const transaction = db.transaction("activities", "readonly");
                              const store = transaction.objectStore("activities");
    
                              const cursorRequest = store.openCursor();
                
                              cursorRequest.onsuccess = function (event:any ) {
                                const cursor = event.target.result;
                                if (cursor) {
                                  if (next_activities.includes(cursor.value.activity_id)) {
                                    const resource_record = cursor.value
    
                                    const transaction = db.transaction(module_storeName, "readonly");
                                    const store = transaction.objectStore(module_storeName);
    
                                    // Get the object with the specified key
                                    const getRequest = store.get(resource_record.module_id);
    
                                    getRequest.onerror = (event) => {
                                        resolve(games[0])
                                    };
    
                                    getRequest.onsuccess = (event) => {
                                      const module_record = event.target.result
                                      
                                      const transaction = db.transaction(topics_storeName, "readonly");
                                      const store = transaction.objectStore(topics_storeName);
      
                                      // Get the object with the specified key
                                      const getRequest = store.get(resource_record.topic_id);
      
                                      getRequest.onerror = (event) => {
                                          resolve(games[0])
                                      };
      
                                      getRequest.onsuccess = (event) => {
                                          const topic_record = event.target.result
      
                                          games[0].next = [{
                                            type: "activity",
                                            name: resource_record.name,
                                            number: resource_record.activity_number,
                                            topic_number: topic_record.topic_number,
                                            module_number: module_record.module_number
                                          }]
    
                                          resolve(games[0])
                                      };
                                    };
                                  }
                                  cursor.continue();
                                } else {                                  
                                  const transaction = db.transaction("texts", "readonly");
                                  const store = transaction.objectStore("texts");
    
                                  const cursorRequest = store.openCursor();
                
                                  cursorRequest.onsuccess = function (event:any ) {
                                    const cursor = event.target.result;
                                    if (cursor) {
                                      if (next_texts.includes(cursor.value.text_id)) {
                                        const resource_record = cursor.value
    
                                        const transaction = db.transaction(module_storeName, "readonly");
                                        const store = transaction.objectStore(module_storeName);
    
                                        // Get the object with the specified key
                                        const getRequest = store.get(resource_record.module_id);
    
                                        getRequest.onerror = (event) => {
                                            resolve(games[0])
                                        };
    
                                        getRequest.onsuccess = (event) => {
                                          const module_record = event.target.result
                                          
                                          const transaction = db.transaction(topics_storeName, "readonly");
                                          const store = transaction.objectStore(topics_storeName);
          
                                          // Get the object with the specified key
                                          const getRequest = store.get(resource_record.topic_id);
          
                                          getRequest.onerror = (event) => {
                                              resolve(games[0])
                                          };
          
                                          getRequest.onsuccess = (event) => {
                                              const topic_record = event.target.result
          
                                              games[0].next = [{
                                                type: "text",
                                                name: resource_record.name,
                                                number: resource_record.text_number,
                                                topic_number: topic_record.topic_number,
                                                module_number: module_record.module_number
                                              }]
    
                                              resolve(games[0])
                                          };
                                        };
                                      }
                                      cursor.continue();
                                    } else {                                  
                                      const transaction = db.transaction("books", "readonly");
                                      const store = transaction.objectStore("books");
    
                                      const cursorRequest = store.openCursor();
                
                                      cursorRequest.onsuccess = function (event:any ) {
                                        const cursor = event.target.result;
                                        if (cursor) {
                                          if (next_books.includes(cursor.value.book_id)) {
                                            const resource_record = cursor.value
    
                                            const transaction = db.transaction(module_storeName, "readonly");
                                            const store = transaction.objectStore(module_storeName);
    
                                            // Get the object with the specified key
                                            const getRequest = store.get(resource_record.module_id);
    
                                            getRequest.onerror = (event) => {
                                                resolve(games[0])
                                            };
    
                                            getRequest.onsuccess = (event) => {
                                              const module_record = event.target.result
                                              
                                              const transaction = db.transaction(topics_storeName, "readonly");
                                              const store = transaction.objectStore(topics_storeName);
              
                                              // Get the object with the specified key
                                              const getRequest = store.get(resource_record.topic_id);
              
                                              getRequest.onerror = (event) => {
                                                  resolve(games[0])
                                              };
              
                                              getRequest.onsuccess = (event) => {
                                                  const topic_record = event.target.result
              
                                                  games[0].next = [{
                                                    type: "book",
                                                    name: resource_record.name,
                                                    number: resource_record.book_number,
                                                    topic_number: topic_record.topic_number,
                                                    module_number: module_record.module_number
                                                  }]
    
                                                  resolve(games[0])
                                              };
                                            };
                                          }
                                          cursor.continue();
                                        } else {                                  
                                          const transaction = db.transaction("games", "readonly");
                                          const store = transaction.objectStore("games");
    
                                          const cursorRequest = store.openCursor();
                
                                          cursorRequest.onsuccess = function (event:any ) {
                                            const cursor = event.target.result;
                                            if (cursor) {
                                              if (next_games.includes(cursor.value.game_id)) {
                                                const resource_record = cursor.value
    
                                                const transaction = db.transaction(module_storeName, "readonly");
                                                const store = transaction.objectStore(module_storeName);
    
                                                // Get the object with the specified key
                                                const getRequest = store.get(resource_record.module_id);
    
                                                getRequest.onerror = (event) => {
                                                    resolve(games[0])
                                                };
    
                                                getRequest.onsuccess = (event) => {
                                                  const module_record = event.target.result
                                                  
                                                  const transaction = db.transaction(topics_storeName, "readonly");
                                                  const store = transaction.objectStore(topics_storeName);
                  
                                                  // Get the object with the specified key
                                                  const getRequest = store.get(resource_record.topic_id);
                  
                                                  getRequest.onerror = (event) => {
                                                      resolve(games[0])
                                                  };
                  
                                                  getRequest.onsuccess = (event) => {
                                                      const topic_record = event.target.result
                  
                                                      games[0].next = [{
                                                        type: "game",
                                                        name: resource_record.name,
                                                        number: resource_record.game_number,
                                                        topic_number: topic_record.topic_number,
                                                        module_number: module_record.module_number
                                                      }]
    
                                                      resolve(games[0])
                                                  };
                                                };
                                              }
                                              cursor.continue();
                                            } else {                                  
                                              const transaction = db.transaction("videos", "readonly");
                                              const store = transaction.objectStore("videos");
    
                                              const cursorRequest = store.openCursor();
                
                                              cursorRequest.onsuccess = function (event:any ) {
                                                const cursor = event.target.result;
                                                if (cursor) {
                                                  if (next_videos.includes(cursor.value.video_id)) {
                                                    const resource_record = cursor.value
    
                                                    const transaction = db.transaction(module_storeName, "readonly");
                                                    const store = transaction.objectStore(module_storeName);
    
                                                    // Get the object with the specified key
                                                    const getRequest = store.get(resource_record.module_id);
    
                                                    getRequest.onerror = (event) => {
                                                        resolve(games[0])
                                                    };
    
                                                    getRequest.onsuccess = (event) => {
                                                      const module_record = event.target.result
                                                      
                                                      const transaction = db.transaction(topics_storeName, "readonly");
                                                      const store = transaction.objectStore(topics_storeName);
                      
                                                      // Get the object with the specified key
                                                      const getRequest = store.get(resource_record.topic_id);
                      
                                                      getRequest.onerror = (event) => {
                                                          resolve(games[0])
                                                      };
                      
                                                      getRequest.onsuccess = (event) => {
                                                          const topic_record = event.target.result
                      
                                                          games[0].next = [{
                                                            type: "video",
                                                            name: resource_record.name,
                                                            number: resource_record.video_number,
                                                            topic_number: topic_record.topic_number,
                                                            module_number: module_record.module_number
                                                          }]
    
                                                          resolve(games[0])
                                                      };
                                                    };
                                                  }
                                                  cursor.continue();
                                                } else {                                  
                                                  resolve(games[0])
                                                }
                                              };
                                            }
                                          };
                                        }
                                      };
                                    }
                                  };
                                }
                              };
                            }
                          };
                        }
                      };
                    }
                  };
                }
              };
    
              cursorRequest.onerror = function () {
                reject(new Error("Error opening cursor"));
              };
            }catch(error){
              console.log("database_error")
              resolve([])
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve([]));
    }
  }

  topics_get_video_content(req): Observable<any> {
    let label = req.params.get('white_label');
    let level = req.params.get('level');
    const module_number = req.params.get('module_number');
    const topic_number = req.params.get('topic_number');
    const video_number = req.params.get('video_number');

    if(label == null){
      label = this.userProfileService.getWhiteLabel()
    }

    if(level == null){
      level = this.userProfileService.getUserLevel()
    }

    if (level != null && label != null && module_number != null && topic_number != null && video_number != null) {
      const module_storeName = "modules";
      const topics_storeName = "topics";
      const videos_storeName = "videos";
      let modules:any = [];
      let topic:any = {};
      let videos:any = [];
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.catalogDB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.cargarCatalogoDesdeAPI();
            }
            console.log("database_not_found")
            resolve([]); // Database does not exist
          };
  
          request.onerror = function (event: any) {
            console.log("database_error")
            resolve([])
          };

          request.onsuccess = function (event: any) {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(module_storeName, "readonly");

              const store = transaction.objectStore(module_storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = function (event:any ) {
                const cursor = event.target.result;
                if (cursor) {
                  if (cursor.value.white_label == label && cursor.value.level == level && cursor.value.module_number == module_number) {
                    console.log("Cursor entry:", cursor.value);
                    modules.push(cursor.value);
                  }
                  cursor.continue();
                } else {
                  modules = modules.sort((a, b) => a.module_number - b.module_number);
                  const module_ids = modules.map((module_obj)=>{
                    return module_obj.module_id
                  })

                  const transaction = db.transaction(topics_storeName, "readonly");

                  const store = transaction.objectStore(topics_storeName);
                  const cursorRequest = store.openCursor();
        
                  cursorRequest.onsuccess = function (event:any ) {
                    const cursor = event.target.result;
                    if (cursor) {
                      if (module_ids.includes(cursor.value.module_id) && cursor.value.topic_number == topic_number) {
                        console.log("Cursor entry:", cursor.value, module_ids, topic_number);
                        topic = cursor.value
                      }
                      cursor.continue();
                    } else {
                      const topic_id = topic.topic_id
                      const transaction = db.transaction(videos_storeName, "readonly");

                      const store = transaction.objectStore(videos_storeName);
                      const cursorRequest = store.openCursor();
            
                      cursorRequest.onsuccess = function (event:any ) {
                        const cursor = event.target.result;
                        if (cursor) {
                          if (cursor.value.topic_id == topic_id && cursor.value.video_number == video_number) {
                            console.log("Cursor entry:", cursor.value);
                            videos.push(cursor.value)
                          }
                          cursor.continue();
                        } else {
                          const resource_skills = videos[0].skills
                          const found_skills:any = []
                          const skill_transaction = db.transaction("skills", "readonly");
                          const skill_store = skill_transaction.objectStore("skills");

                          const skill_cursorRequest = skill_store.openCursor();

                          skill_cursorRequest.onsuccess = function (event:any ) {
                            const cursor = event.target.result;
                            if (cursor) {
                              if (resource_skills.includes(cursor.value.skill_id)) {
                                found_skills.push(cursor.value)
                              }
                              cursor.continue();
                            } else {
                              videos[0].skills = found_skills

                              const next_activities = videos[0].next.filter((resource_loc)=>{return resource_loc[1] == "activity"}).map((activity_loc)=>{return activity_loc[0]})                      
                              const next_texts = videos[0].next.filter((resource_loc)=>{return resource_loc[1] == "text"}).map((text_loc)=>{return text_loc[0]})                      
                              const next_books = videos[0].next.filter((resource_loc)=>{return resource_loc[1] == "book"}).map((book_loc)=>{return book_loc[0]})                      
                              const next_games = videos[0].next.filter((resource_loc)=>{return resource_loc[1] == "game"}).map((game_loc)=>{return game_loc[0]})                      
                              const next_videos = videos[0].next.filter((resource_loc)=>{return resource_loc[1] == "video"}).map((video_loc)=>{return video_loc[0]})                      
                              
                              const transaction = db.transaction("activities", "readonly");
                              const store = transaction.objectStore("activities");
    
                              const cursorRequest = store.openCursor();
                
                              cursorRequest.onsuccess = function (event:any ) {
                                const cursor = event.target.result;
                                if (cursor) {
                                  if (next_activities.includes(cursor.value.activity_id)) {
                                    const resource_record = cursor.value
    
                                    const transaction = db.transaction(module_storeName, "readonly");
                                    const store = transaction.objectStore(module_storeName);
    
                                    // Get the object with the specified key
                                    const getRequest = store.get(resource_record.module_id);
    
                                    getRequest.onerror = (event) => {
                                        resolve(videos[0])
                                    };
    
                                    getRequest.onsuccess = (event) => {
                                      const module_record = event.target.result
                                      
                                      const transaction = db.transaction(topics_storeName, "readonly");
                                      const store = transaction.objectStore(topics_storeName);
      
                                      // Get the object with the specified key
                                      const getRequest = store.get(resource_record.topic_id);
      
                                      getRequest.onerror = (event) => {
                                          resolve(videos[0])
                                      };
      
                                      getRequest.onsuccess = (event) => {
                                          const topic_record = event.target.result
      
                                          videos[0].next = [{
                                            type: "activity",
                                            name: resource_record.name,
                                            number: resource_record.activity_number,
                                            topic_number: topic_record.topic_number,
                                            module_number: module_record.module_number
                                          }]
    
                                          resolve(videos[0])
                                      };
                                    };
                                  }
                                  cursor.continue();
                                } else {                                  
                                  const transaction = db.transaction("texts", "readonly");
                                  const store = transaction.objectStore("texts");
    
                                  const cursorRequest = store.openCursor();
                
                                  cursorRequest.onsuccess = function (event:any ) {
                                    const cursor = event.target.result;
                                    if (cursor) {
                                      if (next_texts.includes(cursor.value.text_id)) {
                                        const resource_record = cursor.value
    
                                        const transaction = db.transaction(module_storeName, "readonly");
                                        const store = transaction.objectStore(module_storeName);
    
                                        // Get the object with the specified key
                                        const getRequest = store.get(resource_record.module_id);
    
                                        getRequest.onerror = (event) => {
                                            resolve(videos[0])
                                        };
    
                                        getRequest.onsuccess = (event) => {
                                          const module_record = event.target.result
                                          
                                          const transaction = db.transaction(topics_storeName, "readonly");
                                          const store = transaction.objectStore(topics_storeName);
          
                                          // Get the object with the specified key
                                          const getRequest = store.get(resource_record.topic_id);
          
                                          getRequest.onerror = (event) => {
                                              resolve(videos[0])
                                          };
          
                                          getRequest.onsuccess = (event) => {
                                              const topic_record = event.target.result
          
                                              videos[0].next = [{
                                                type: "text",
                                                name: resource_record.name,
                                                number: resource_record.text_number,
                                                topic_number: topic_record.topic_number,
                                                module_number: module_record.module_number
                                              }]
    
                                              resolve(videos[0])
                                          };
                                        };
                                      }
                                      cursor.continue();
                                    } else {                                  
                                      const transaction = db.transaction("books", "readonly");
                                      const store = transaction.objectStore("books");
    
                                      const cursorRequest = store.openCursor();
                
                                      cursorRequest.onsuccess = function (event:any ) {
                                        const cursor = event.target.result;
                                        if (cursor) {
                                          if (next_books.includes(cursor.value.book_id)) {
                                            const resource_record = cursor.value
    
                                            const transaction = db.transaction(module_storeName, "readonly");
                                            const store = transaction.objectStore(module_storeName);
    
                                            // Get the object with the specified key
                                            const getRequest = store.get(resource_record.module_id);
    
                                            getRequest.onerror = (event) => {
                                                resolve(videos[0])
                                            };
    
                                            getRequest.onsuccess = (event) => {
                                              const module_record = event.target.result
                                              
                                              const transaction = db.transaction(topics_storeName, "readonly");
                                              const store = transaction.objectStore(topics_storeName);
              
                                              // Get the object with the specified key
                                              const getRequest = store.get(resource_record.topic_id);
              
                                              getRequest.onerror = (event) => {
                                                  resolve(videos[0])
                                              };
              
                                              getRequest.onsuccess = (event) => {
                                                  const topic_record = event.target.result
              
                                                  videos[0].next = [{
                                                    type: "book",
                                                    name: resource_record.name,
                                                    number: resource_record.book_number,
                                                    topic_number: topic_record.topic_number,
                                                    module_number: module_record.module_number
                                                  }]
    
                                                  resolve(videos[0])
                                              };
                                            };
                                          }
                                          cursor.continue();
                                        } else {                                  
                                          const transaction = db.transaction("games", "readonly");
                                          const store = transaction.objectStore("games");
    
                                          const cursorRequest = store.openCursor();
                
                                          cursorRequest.onsuccess = function (event:any ) {
                                            const cursor = event.target.result;
                                            if (cursor) {
                                              if (next_games.includes(cursor.value.game_id)) {
                                                const resource_record = cursor.value
    
                                                const transaction = db.transaction(module_storeName, "readonly");
                                                const store = transaction.objectStore(module_storeName);
    
                                                // Get the object with the specified key
                                                const getRequest = store.get(resource_record.module_id);
    
                                                getRequest.onerror = (event) => {
                                                    resolve(videos[0])
                                                };
    
                                                getRequest.onsuccess = (event) => {
                                                  const module_record = event.target.result
                                                  
                                                  const transaction = db.transaction(topics_storeName, "readonly");
                                                  const store = transaction.objectStore(topics_storeName);
                  
                                                  // Get the object with the specified key
                                                  const getRequest = store.get(resource_record.topic_id);
                  
                                                  getRequest.onerror = (event) => {
                                                      resolve(videos[0])
                                                  };
                  
                                                  getRequest.onsuccess = (event) => {
                                                      const topic_record = event.target.result
                  
                                                      videos[0].next = [{
                                                        type: "game",
                                                        name: resource_record.name,
                                                        number: resource_record.game_number,
                                                        topic_number: topic_record.topic_number,
                                                        module_number: module_record.module_number
                                                      }]
    
                                                      resolve(videos[0])
                                                  };
                                                };
                                              }
                                              cursor.continue();
                                            } else {                                  
                                              const transaction = db.transaction("videos", "readonly");
                                              const store = transaction.objectStore("videos");
    
                                              const cursorRequest = store.openCursor();
                
                                              cursorRequest.onsuccess = function (event:any ) {
                                                const cursor = event.target.result;
                                                if (cursor) {
                                                  if (next_videos.includes(cursor.value.video_id)) {
                                                    const resource_record = cursor.value
    
                                                    const transaction = db.transaction(module_storeName, "readonly");
                                                    const store = transaction.objectStore(module_storeName);
    
                                                    // Get the object with the specified key
                                                    const getRequest = store.get(resource_record.module_id);
    
                                                    getRequest.onerror = (event) => {
                                                        resolve(videos[0])
                                                    };
    
                                                    getRequest.onsuccess = (event) => {
                                                      const module_record = event.target.result
                                                      
                                                      const transaction = db.transaction(topics_storeName, "readonly");
                                                      const store = transaction.objectStore(topics_storeName);
                      
                                                      // Get the object with the specified key
                                                      const getRequest = store.get(resource_record.topic_id);
                      
                                                      getRequest.onerror = (event) => {
                                                          resolve(videos[0])
                                                      };
                      
                                                      getRequest.onsuccess = (event) => {
                                                          const topic_record = event.target.result
                      
                                                          videos[0].next = [{
                                                            type: "video",
                                                            name: resource_record.name,
                                                            number: resource_record.video_number,
                                                            topic_number: topic_record.topic_number,
                                                            module_number: module_record.module_number
                                                          }]
    
                                                          resolve(videos[0])
                                                      };
                                                    };
                                                  }
                                                  cursor.continue();
                                                } else {                                  
                                                  resolve(videos[0])
                                                }
                                              };
                                            }
                                          };
                                        }
                                      };
                                    }
                                  };
                                }
                              };
                            }
                          };
                        }
                      };
                    }
                  };
                }
              };
    
              cursorRequest.onerror = function () {
                reject(new Error("Error opening cursor"));
              };
            }catch(error){
              console.log("database_error")
              resolve([])
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve([]));
    }
  }

  glosario_palabras(req): Observable<any> {
    let lang = req.params.get('lang');
    let level = req.params.get('grado');
    const module_number = req.params.get('modulo');
    const topic_number = req.params.get('tema');

    if(level == null){
      level = this.userProfileService.getUserLevel()
    }

    lang = lang == 'es' ? 'Español' : 'Inglés'

    console.log('glosario_palabras: ', lang, level, module_number, topic_number, req.params.keys() )

    if (level != null && lang != null && module_number != null && topic_number != null) {
      const glossary_storeName = "glossary";
      let palabras:any = [];
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.catalogDB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.cargarCatalogoDesdeAPI();
            }
            console.log("database_not_found")
            resolve([]); // Database does not exist
          };
  
          request.onerror = function (event: any) {
            console.log("database_error")
            resolve([])
          };

          request.onsuccess = function (event: any) {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(glossary_storeName, "readonly");

              const store = transaction.objectStore(glossary_storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = function (event:any ) {
                const cursor = event.target.result;
                if (cursor) {
                  if (cursor.value.grado == level 
                    && cursor.value.modulo == module_number 
                    && cursor.value.tema == topic_number 
                    && cursor.value.idioma == lang) {
                    console.log("Cursor entry:", cursor.value);
                    palabras.push(cursor.value);
                  }
                  cursor.continue();
                } else {
                  palabras = palabras.sort((a, b) => a.palabra - b.palabra);
                  
                  resolve(palabras)
                }
              };
    
              cursorRequest.onerror = function () {
                reject(new Error("Error opening cursor"));
              };
            }catch(error){
              console.log("database_error")
              resolve([])
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve([]));
    }
  }

  progress_get_progress(req): Observable<any> {
    const user_id = req.params.get('user_id');
    const activity_id = req.params.get('activity_id');
    const text_id = req.params.get('text_id');
    const book_id = req.params.get('book_id');
    const game_id = req.params.get('game_id');
    const video_id = req.params.get('video_id');

    console.log('progress_get_progress: ', user_id, activity_id, req.params.keys() )

    if (user_id != null && (activity_id != null || text_id != null || book_id != null || game_id != null || video_id != null)) {
      const progress_storeName = "progress";
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.grades_progress_DB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.updateGradesProgress();
            }
            console.log("database_not_found")
            resolve([]); // Database does not exist
          };
  
          request.onerror = function (event: any) {
            console.log("database_error")
            resolve([])
          };

          request.onsuccess = function (event: any) {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(progress_storeName, "readonly");

              const store = transaction.objectStore(progress_storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = function (event:any ) {
                const cursor = event.target.result;
                if (cursor) {
                  if (
                    activity_id != null 
                    && cursor.value.student_id == user_id 
                    && cursor.value.activity_id == activity_id
                  ){
                      resolve([cursor.value])
                  }
                  if (
                    text_id != null 
                    && cursor.value.student_id == user_id 
                    && cursor.value.text_id == text_id
                  ){
                      resolve([cursor.value])
                  }
                  if (
                    book_id != null 
                    && cursor.value.student_id == user_id 
                    && cursor.value.book_id == book_id
                  ){
                      resolve([cursor.value])
                  }
                  if (
                    game_id != null 
                    && cursor.value.student_id == user_id 
                    && cursor.value.game_id == game_id
                  ){
                      resolve([cursor.value])
                  }
                  if (
                    video_id != null 
                    && cursor.value.student_id == user_id 
                    && cursor.value.video_id == video_id
                  ){
                      resolve([cursor.value])
                  }
                  cursor.continue();
                } else {                  
                  resolve([])
                }
              };
    
              cursorRequest.onerror = function () {
                resolve([]);
              };
            }catch(error){
              console.log("database_error")
              resolve([])
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve([]));
    }
  }

  grades_activity_answers_id(req): Observable<any> {
    const user_id = req.params.get('user_id');
    const activity_id = req.params.get('activity_id');

    console.log('grades_activity_answers_id: ', user_id, activity_id, req.params.keys() )

    if (user_id != null && activity_id != null) {
      const grades_storeName = "grades";
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.grades_progress_DB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.updateGradesProgress();
            }
            console.log("database_not_found")
            resolve([]); // Database does not exist
          };
  
          request.onerror = function (event: any) {
            console.log("database_error")
            resolve([])
          };

          request.onsuccess = function (event: any) {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(grades_storeName, "readonly");

              const store = transaction.objectStore(grades_storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = function (event:any ) {
                const cursor = event.target.result;
                if (cursor) {
                  if (
                    activity_id != null 
                    && cursor.value.student_id == user_id 
                    && cursor.value.activity_id == activity_id
                  ){
                      resolve([cursor.value])
                  }
                  cursor.continue();
                } else {                  
                  resolve([])
                }
              };
    
              cursorRequest.onerror = function () {
                resolve([]);
              };
            }catch(error){
              console.log("database_error")
              resolve([])
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve([]));
    }
  }

  progress_set_progress(req): Observable<any> {
    let progress_update
    let user_id
    let module_id
    let topic_id
    let activity_id
    let text_id
    let book_id
    let game_id
    let video_id
    try{
      progress_update = req.body.params
      user_id = progress_update["user_id"]
      module_id = progress_update["module_id"]
      topic_id = progress_update["topic_id"]
      activity_id = progress_update["activity_id"]
      text_id = progress_update["text_id"]
      book_id = progress_update["book_id"]
      game_id = progress_update["game_id"]
      video_id = progress_update["video_id"]
    }catch(error){
      console.log("set_progress_error")
    }

    console.log('progress_set_progress: ', progress_update, user_id, activity_id, module_id, topic_id, req.params.keys() )

    if (user_id != null && module_id != null && topic_id != null && (activity_id != null || text_id != null || book_id != null || game_id != null || video_id != null)) {
      const progress_storeName = "progress";
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.grades_progress_DB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.updateGradesProgress();
            }
            console.log("database_not_found")
            resolve([]); // Database does not exist
          };
  
          request.onerror = function (event: any) {
            console.log("database_error")
            resolve([])
          };

          request.onsuccess = function (event: any) {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(progress_storeName, "readwrite");

              const store = transaction.objectStore(progress_storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = function (event:any ) {
                const cursor = event.target.result;
                if (cursor) {
                  if (
                    activity_id != null 
                    && cursor.value.student_id == user_id 
                    && cursor.value.activity_id == activity_id
                  ){  
                      
                      cursor.update({...cursor.value, ...progress_update, updated_at:getFormattedTimestamp(), sync_status: 'pending', progress_id: cursor.value.progress_id})
                      resolve([cursor.value])
                      return
                  }
                  if (
                    text_id != null 
                    && cursor.value.student_id == user_id 
                    && cursor.value.text_id == text_id
                  ){
                      cursor.update({...cursor.value, ...progress_update, updated_at:getFormattedTimestamp(), sync_status: 'pending', progress_id: cursor.value.progress_id})
                      resolve([cursor.value])
                      return
                  }
                  if (
                    book_id != null 
                    && cursor.value.student_id == user_id 
                    && cursor.value.book_id == book_id
                  ){
                      cursor.update({...cursor.value, ...progress_update, updated_at:getFormattedTimestamp(), sync_status: 'pending', progress_id: cursor.value.progress_id})
                      resolve([cursor.value])
                      return
                  }
                  if (
                    game_id != null 
                    && cursor.value.student_id == user_id 
                    && cursor.value.game_id == game_id
                  ){
                      cursor.update({...cursor.value, ...progress_update, updated_at:getFormattedTimestamp(), sync_status: 'pending', progress_id: cursor.value.progress_id})
                      resolve([cursor.value])
                      return
                  }
                  if (
                    video_id != null 
                    && cursor.value.student_id == user_id 
                    && cursor.value.video_id == video_id
                  ){
                      cursor.update({...cursor.value, ...progress_update, updated_at:getFormattedTimestamp(), sync_status: 'pending', progress_id: cursor.value.progress_id})
                      resolve([cursor.value])
                      return
                  }
                  cursor.continue();
                } else {
                  const newValue = {
                    "progress_id": crypto.randomUUID(),
                    "created_at": getFormattedTimestamp(),
                    "student_id": user_id,
                    "module_id": module_id,
                    "topic_id": topic_id,
                    "activity_id": activity_id,
                    "text_id": text_id,
                    "book_id": book_id,
                    "game_id": game_id,
                    "video_id": video_id,
                    "data": progress_update.data,
                    "finished": true,
                    "variant": 0,
                    "updated_at": getFormattedTimestamp(),
                    "sync_status": "pending"
                  }

                  const addRequest = store.add(newValue);

                  addRequest.onsuccess = function () {
                      console.log("Value added successfully:", newValue);
                      resolve([newValue])
                      return
                  };

                  addRequest.onerror = function (err) {
                      console.error("Error adding value:", err);
                      resolve([])
                  };
                }
              };
    
              cursorRequest.onerror = function () {
                resolve([]);
              };
            }catch(error){
              console.log("database_error")
              resolve([])
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve([]));
    }
  }

  grades_activity_update(req): Observable<any> {
    let grade_update
    let answers
    let grade
    let user_id
    let group_id
    let activity_number
    let module_number
    let topic_number
    let label
    let level
    try{
      grade_update = req.body.paramsData
      user_id = grade_update["user_id"]
      group_id = this.userProfileService.userProfile.group_id
      answers = grade_update["answers"]
      grade = grade_update["grade"]
      activity_number = grade_update["activity"]
      module_number = grade_update["module"]
      topic_number = grade_update["topic"]
      label = grade_update["white_label"]
      level = grade_update["level"]
    }catch(error){
      console.log("activity_update_error")
    }

    const module_storeName = "modules";
    const topics_storeName = "topics";
    const activities_storeName = "activities";
    let modules:any = [];
    let topic:any = {};
    let activities:any = [];

    console.log('grades_activity_update: ', grade_update, user_id, this.userProfileService.userProfile, user_id, activity_number, level, label, answers, grade, module_number, topic_number)

    if (user_id != null && activity_number != null && level != null && label != null && answers != null && grade != null && module_number != null && topic_number != null) {
      console.log("all params in activity_update")
      const grades_storeName = "grades";
  
      return from(
        new Promise((resolve, reject) => {
          const request = indexedDB.open(this.catalogDB);

          request.onupgradeneeded = () => {
            // This event only fires if the database doesn't exist or needs to be upgraded.
            request.transaction.abort(); // Cancel the upgrade to avoid creating the database
            
            if (navigator.onLine) {
              this.cargarCatalogoDesdeAPI();
            }
            console.log("database_not_found")
            resolve([]); // Database does not exist
          };
  
          request.onerror = function (event: any) {
            console.log("database_error")
            resolve([])
          };

          request.onsuccess = (event: any) => {
            const db = event.target.result;
            
            try{
              const transaction = db.transaction(module_storeName, "readonly");

              const store = transaction.objectStore(module_storeName);
              const cursorRequest = store.openCursor();
    
              cursorRequest.onsuccess = (event:any ) => {
                const cursor = event.target.result;
                if (cursor) {
                  if (cursor.value.white_label == label && cursor.value.level == level && cursor.value.module_number == module_number) {
                    console.log("Cursor entry:", cursor.value);
                    modules.push(cursor.value);
                  }
                  cursor.continue();
                } else {
                  modules = modules.sort((a, b) => a.module_number - b.module_number);
                  const module_ids = modules.map((module_obj)=>{
                    return module_obj.module_id
                  })

                  const transaction = db.transaction(topics_storeName, "readonly");

                  const store = transaction.objectStore(topics_storeName);
                  const cursorRequest = store.openCursor();
        
                  cursorRequest.onsuccess = (event:any ) => {
                    const cursor = event.target.result;
                    if (cursor) {
                      if (module_ids.includes(cursor.value.module_id) && cursor.value.topic_number == topic_number) {
                        console.log("Cursor entry:", cursor.value, module_ids, topic_number);
                        topic = cursor.value
                      }
                      cursor.continue();
                    } else {
                      const topic_id = topic.topic_id
                      const transaction = db.transaction(activities_storeName, "readonly");

                      const store = transaction.objectStore(activities_storeName);
                      const cursorRequest = store.openCursor();
            
                      cursorRequest.onsuccess = (event:any ) => {
                        const cursor = event.target.result;
                        if (cursor) {
                          if (cursor.value.topic_id == topic_id && cursor.value.activity_number == activity_number) {
                            console.log("Cursor entry:", cursor.value);
                            activities.push(cursor.value)

                            const request_grades = indexedDB.open(this.grades_progress_DB);

                            request_grades.onupgradeneeded = () => {
                              // This event only fires if the database doesn't exist or needs to be upgraded.
                              request_grades.transaction.abort(); // Cancel the upgrade to avoid creating the database
                              
                              if (navigator.onLine) {
                                this.updateGradesProgress();
                              }
                              console.log("database_not_found")
                              resolve([]); // Database does not exist
                            };
                    
                            request_grades.onerror = function (event: any) {
                              console.log("database_error")
                              resolve([])
                            };
  
                            request_grades.onsuccess = (event: any) => {
                              const db = event.target.result;
                              
                              try{
                                const transaction = db.transaction(grades_storeName, "readwrite");
  
                                const store = transaction.objectStore(grades_storeName);
                                const cursorRequest = store.openCursor();
                      
                                cursorRequest.onsuccess = function (event:any ) {
                                  const cursor = event.target.result;
                                  if (cursor) {
                                    if (
                                      activities.length > 0 
                                      && cursor.value.student_id == user_id 
                                      && cursor.value.activity_id == activities[0].activity_id
                                    ){
                                      cursor.update({...cursor.value, answers, grade, datetime: getFormattedTimestamp(), sync_status: 'pending'})
                                      resolve([cursor.value])
                                      return                                 
                                    }
                                    cursor.continue();
                                  } else {
                                    const newValue = {
                                      "student_id": user_id,
                                      "group_id": group_id,
                                      "grade": grade,
                                      "grade_type": "activity",
                                      "grade_id": crypto.randomUUID(),
                                      "module_id": module_ids[0],
                                      "topic_id": topic_id,
                                      "activity_id": activities[0].activity_id,
                                      "answers": answers,
                                      "datetime": getFormattedTimestamp(),
                                      "variant": 0,
                                      "sync_status": "pending"
                                    }
                                    const addRequest = store.add(newValue);

                                    addRequest.onsuccess = function () {
                                        console.log("Value added successfully:", newValue);
                                        resolve([newValue])
                                        return
                                    };

                                    addRequest.onerror = function (err) {
                                        console.error("Error adding value:", err);
                                        resolve([])
                                    };
                                  }
                                };
                                cursorRequest.onerror = function () {
                                  console.log('did not finish')
                                  resolve([]);
                                };
                              }catch(error){
                                console.log("database_error")
                                resolve([])
                                return;
                              }
                            }
                          }
                          cursor.continue();
                        } else {
                          resolve([])
                        }
                      };
                    }
                  };
                }
              };
    
              cursorRequest.onerror = function () {
                reject(new Error("Error opening cursor"));
              };
            }catch(error){
              console.log("database_error")
              resolve([])
              return;
            }
          };
        })
      );
    } else {
      return from(Promise.resolve([]));
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.userProfileService.getAuthToken();

    const lang = "es";
    let newHeaders = req.headers;
    let newParams = req.params;
    if (token) {
      newHeaders = newHeaders.set("Authorization", token);
    }
    if (lang) {
      newParams = newParams.set("locale", lang);
    }
    if (!(req.body && req.body instanceof FormData)) {
      newHeaders = newHeaders.set("Content-Type", "application/json");
    }
    // Finally we have to clone our request with our new headers
    // This is required because HttpRequests are immutable
    req = req.clone({ headers: newHeaders, params: newParams });
    
    console.log("request path: ", this.getPathFromUrl(req.url))
    switch(this.getPathFromUrl(req.url)){
      case '/api/courses/get_courses_data':
        console.log("intercepting /courses/get_courses_data")

        if (navigator.onLine) {
          this.actualizarCatalogoDesdeAPI();
        }

        // Use the IndexedDB method and map the result to an HttpEvent-like response
        return this.courses_get_courses_data(req).pipe(
          switchMap((modules) => {
            console.log("modules: ", modules)
            if (modules.length > 0){
              // Create a fake HTTP response to match HttpClient's expectation
              return of(new HttpResponse({ status: 200, body: modules }));
            }else{
              return next.handle(req);
            }
          }),
          catchError((error) => {
            console.error("IndexedDB error:", error);
            // In case of an IndexedDB error, fall back to making the HTTP request
            return next.handle(req);
          })
        );
        break;

      case '/api/topics/get_topic_names_progress':
        console.log("intercepting /topics/get_topic_names_progress")

        if (navigator.onLine) {
          this.actualizarCatalogoDesdeAPI();
        }

        // Use the IndexedDB method and map the result to an HttpEvent-like response
        return this.topics_get_topic_names_progress(req).pipe(
          switchMap((topics) => {
            console.log("topics: ", topics)
            if (topics.topics.length > 0){
              // Create a fake HTTP response to match HttpClient's expectation
              return of(new HttpResponse({ status: 200, body: topics }));
            }else{
              return next.handle(req);
            }
          }),
          catchError((error) => {
            console.error("IndexedDB error:", error);
            // In case of an IndexedDB error, fall back to making the HTTP request
            return next.handle(req);
          })
        );
        break;
      
      case '/api/topics/get_topic_content':
        if (window.location.href.includes('/cms')) {
          return next.handle(req);
        }

        console.log("intercepting /topics/get_topic_content")

        if (navigator.onLine) {
          this.actualizarCatalogoDesdeAPI();
        }

        // Use the IndexedDB method and map the result to an HttpEvent-like response
        return this.topics_get_topic_content(req).pipe(
          switchMap((topic_data: any) => {
            console.log("topic data: ", topic_data)
            if (Object.keys(topic_data.topic).length > 0){
              // Create a fake HTTP response to match HttpClient's expectation
              return of(new HttpResponse({ status: 200, body: topic_data }));
            }else{
              return next.handle(req);
            }
          }),
          catchError((error) => {
            console.error("IndexedDB error:", error);
            // In case of an IndexedDB error, fall back to making the HTTP request
            return next.handle(req);
          })
        );
        break;

      case '/api/topics/get_activity_content':
        console.log("intercepting /topics/get_activity_content")
        if (window.location.href.includes('/cms')) {
          return next.handle(req);
        }

        if (navigator.onLine) {
          this.actualizarCatalogoDesdeAPI();
        }

        // Use the IndexedDB method and map the result to an HttpEvent-like response
        return this.topics_get_activity_content(req).pipe(
          switchMap((activity_data: any) => {
            console.log("activity data: ", activity_data)
            if (Object.keys(activity_data).length > 0){
              // Create a fake HTTP response to match HttpClient's expectation
              return of(new HttpResponse({ status: 200, body: activity_data }));
            }else{
              return next.handle(req);
            }
          }),
          catchError((error) => {
            console.error("IndexedDB error:", error);
            // In case of an IndexedDB error, fall back to making the HTTP request
            return next.handle(req);
          })
        );
        break;

      case '/api/topics/get_text_content':
        if (window.location.href.includes('/cms')) {
          return next.handle(req);
        }
        console.log("intercepting /topics/get_text_content")

        if (navigator.onLine) {
          this.actualizarCatalogoDesdeAPI();
        }

        // Use the IndexedDB method and map the result to an HttpEvent-like response
        return this.topics_get_text_content(req).pipe(
          switchMap((text_data: any) => {
            console.log("text data: ", text_data)
            if (Object.keys(text_data).length > 0){
              // Create a fake HTTP response to match HttpClient's expectation
              return of(new HttpResponse({ status: 200, body: text_data }));
            }else{
              return next.handle(req);
            }
          }),
          catchError((error) => {
            console.error("IndexedDB error:", error);
            // In case of an IndexedDB error, fall back to making the HTTP request
            return next.handle(req);
          })
        );
        break;

      case '/api/topics/get_book_content':
        if (window.location.href.includes('/cms')) {
          return next.handle(req);
        }
        console.log("intercepting /topics/get_book_content")

        if (navigator.onLine) {
          this.actualizarCatalogoDesdeAPI();
        }

        // Use the IndexedDB method and map the result to an HttpEvent-like response
        return this.topics_get_book_content(req).pipe(
          switchMap((book_data: any) => {
            console.log("book data: ", book_data)
            if (Object.keys(book_data).length > 0){
              // Create a fake HTTP response to match HttpClient's expectation
              return of(new HttpResponse({ status: 200, body: book_data }));
            }else{
              return next.handle(req);
            }
          }),
          catchError((error) => {
            console.error("IndexedDB error:", error);
            // In case of an IndexedDB error, fall back to making the HTTP request
            return next.handle(req);
          })
        );
        break;

      case '/api/topics/get_game_content':
        if (window.location.href.includes('/cms')) {
          return next.handle(req);
        }
        console.log("intercepting /topics/get_game_content")

        if (navigator.onLine) {
          this.actualizarCatalogoDesdeAPI();
        }

        // Use the IndexedDB method and map the result to an HttpEvent-like response
        return this.topics_get_game_content(req).pipe(
          switchMap((game_data: any) => {
            console.log("game data: ", game_data)
            if (Object.keys(game_data).length > 0){
              // Create a fake HTTP response to match HttpClient's expectation
              return of(new HttpResponse({ status: 200, body: game_data }));
            }else{
              return next.handle(req);
            }
          }),
          catchError((error) => {
            console.error("IndexedDB error:", error);
            // In case of an IndexedDB error, fall back to making the HTTP request
            return next.handle(req);
          })
        );
        break;

      case '/api/topics/get_video_content':
        if (window.location.href.includes('/cms')) {
          return next.handle(req);
        }
        console.log("intercepting /topics/get_video_content")

        if (navigator.onLine) {
          this.actualizarCatalogoDesdeAPI();
        }

        // Use the IndexedDB method and map the result to an HttpEvent-like response
        return this.topics_get_video_content(req).pipe(
          switchMap((video_data: any) => {
            console.log("video data: ", video_data)
            if (Object.keys(video_data).length > 0){
              // Create a fake HTTP response to match HttpClient's expectation
              return of(new HttpResponse({ status: 200, body: video_data }));
            }else{
              return next.handle(req);
            }
          }),
          catchError((error) => {
            console.error("IndexedDB error:", error);
            // In case of an IndexedDB error, fall back to making the HTTP request
            return next.handle(req);
          })
        );
        break;
    
      case '/api/glosario/palabras':
        console.log("intercepting /glosario/palabras")

        if (navigator.onLine) {
          this.actualizarCatalogoDesdeAPI();
        }

        // Use the IndexedDB method and map the result to an HttpEvent-like response
        return this.glosario_palabras(req).pipe(
          switchMap((palabras: any) => {
            console.log("palabras data: ", palabras)
            if (palabras.length > 0){
              // Create a fake HTTP response to match HttpClient's expectation
              return of(new HttpResponse({ status: 200, body: palabras }));
            }else{
              return next.handle(req);
            }
          }),
          catchError((error) => {
            console.error("IndexedDB error:", error);
            // In case of an IndexedDB error, fall back to making the HTTP request
            return next.handle(req);
          })
        );
        break;
    
      case '/api/topics/get_initial_resource':
        console.log("intercepting /topics/get_initial_resource")

        if (navigator.onLine) {
          this.actualizarCatalogoDesdeAPI();
        }

        // Use the IndexedDB method and map the result to an HttpEvent-like response
        return this.topics_get_initial_resource(req).pipe(
          switchMap((resource: any) => {
            console.log("resource data: ", resource)
            if (resource.length > 0){
              // Create a fake HTTP response to match HttpClient's expectation
              return of(new HttpResponse({ status: 200, body: resource[0] }));
            }else{
              return next.handle(req);
            }
          }),
          catchError((error) => {
            console.error("IndexedDB error:", error);
            // In case of an IndexedDB error, fall back to making the HTTP request
            return next.handle(req);
          })
        );
        break; 

      case '/api/progress/get_progress':
        console.log("intercepting /progress/get_progress")

        if (navigator.onLine) {
          //here comes update grades and progress
        }

        // Use the IndexedDB method and map the result to an HttpEvent-like response
        return this.progress_get_progress(req).pipe(
          switchMap((progress: any) => {
            console.log("progress data: ", progress)
            if (progress.length > 0){
              // Create a fake HTTP response to match HttpClient's expectation
              return of(new HttpResponse({ status: 200, body: progress[0] }));
            }else{
              return next.handle(req);
            }
          }),
          catchError((error) => {
            console.error("IndexedDB error:", error);
            // In case of an IndexedDB error, fall back to making the HTTP request
            return next.handle(req);
          })
        );
        break; 

      case '/api/grades/activity_answers_id':
        console.log("intercepting /grades/activity_answers_id")

        if (navigator.onLine) {
          //here comes update grades and progress
        }

        // Use the IndexedDB method and map the result to an HttpEvent-like response
        return this.grades_activity_answers_id(req).pipe(
          switchMap((answers: any) => {
            console.log("answers data: ", answers)
            if (answers.length > 0){
              // Create a fake HTTP response to match HttpClient's expectation
              return of(new HttpResponse({ status: 200, body: answers[0] }));
            }else{
              return next.handle(req);
            }
          }),
          catchError((error) => {
            console.error("IndexedDB error:", error);
            // In case of an IndexedDB error, fall back to making the HTTP request
            return next.handle(req);
          })
        );
        break; 
    
      case '/api/modules/get_continue_module':
        console.log("intercepting /modules/get_continue_module")

        if (navigator.onLine) {
          //here comes update grades and progress
        }

        // Use the IndexedDB method and map the result to an HttpEvent-like response
        return this.modules_get_continue_module(req).pipe(
          switchMap((results: any) => {
            console.log("results data: ", results)
            if (Object.keys(results).length > 0){
              // Create a fake HTTP response to match HttpClient's expectation
              return of(new HttpResponse({ status: 200, body: results }));
            }else{
              return next.handle(req);
            }
          }),
          catchError((error) => {
            console.error("IndexedDB error:", error);
            // In case of an IndexedDB error, fall back to making the HTTP request
            return next.handle(req);
          })
        );
        break;

      case '/api/progress/set_progress':
          console.log("intercepting /progress/set_progress")
  
          if (navigator.onLine) {
            //here comes update grades and progress
            this.throttledUpdateGradesProgress()
          }
  
          // Use the IndexedDB method and map the result to an HttpEvent-like response
          return this.progress_set_progress(req).pipe(
            switchMap((progress: any) => {
              console.log("progress data: ", progress)
              if (progress.length > 0){
                // Create a fake HTTP response to match HttpClient's expectation
                return of(new HttpResponse({ status: 200, body: progress[0] }));
              }else{
                return next.handle(req);
              }
            }),
            catchError((error) => {
              console.error("IndexedDB error:", error);
              // In case of an IndexedDB error, fall back to making the HTTP request
              return next.handle(req);
            })
          );
          break;

      case '/api/grades/activity_update':
          console.log("intercepting /grades/activity_update")
  
          if (navigator.onLine) {
            if (navigator.onLine) {
              //here comes update grades and progress
              this.throttledUpdateGradesProgress()
            }
          }
  
          // Use the IndexedDB method and map the result to an HttpEvent-like response
          return this.grades_activity_update(req).pipe(
            switchMap((grades: any) => {
              console.log("grades data: ", grades)
              if (grades.length > 0){
                // Create a fake HTTP response to match HttpClient's expectation
                return of(new HttpResponse({ status: 200, body: grades[0] }));
              }else{
                return next.handle(req);
              }
            }),
            catchError((error) => {
              console.error("IndexedDB error:", error);
              // In case of an IndexedDB error, fall back to making the HTTP request
              return next.handle(req);
            })
          );
          break; 
      
      }
    // Then we return an Observable that will run the request
    // or pass it to the next interceptor if any
    return next.handle(req).pipe(
      retryWhen((errors) =>
        errors.pipe(
          mergeMap((error, count) => {
            if (
              (error.status === 0 ||
                error.status === 408 ||
                error.status === 502 ||
                error.status === 503 ||
                error.status === 504) &&
              count < this.retryCount
            ) {
              // Network error and retry count not exceeded
              return timer(this.retryDelay);
            }
            // If it's not a network error or retry count exceeded, throw error
            return throwError(error);
          })
        )
      ),
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 0 ||
          error.status === 408 ||
          error.status === 502 ||
          error.status === 503 ||
          error.status === 504
        ) {
          this.dialog.open(SharedPopupComponent, {
            width: "400px",
            disableClose: true,
            data: {
              title: "¡Ups!",
              subtitle: "Parece que hay un problema de conexión.",
              content: {
                type: "text",
                text: [
                  "Ayudanos verificando que tengas internet, " +
                    "si el problema persiste, porfavor contactanos por medio del chat de ayuda.",
                ],
              },
              buttons: [
                {
                  text: "Ok",
                  action: () => {
                    this.router.navigate(["./"]);
                    this.dialog.closeAll();
                  },
                },
              ],
            },
          });
        } else if (error.status === 401) {
          this.dialog.open(SharedPopupComponent, {
            width: "400px",
            disableClose: true,
            data: {
              title: "¡Alto ahí!",
              subtitle:
                "No cuentas con el permiso para acceder a esta sección.",
              content: {
                type: "text",
                text: [
                  "Si crees que esto es un error, porfavor contactanos por medio del chat de ayuda.",
                ],
              },
              buttons: [
                {
                  text: "Ok",
                  action: () => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                    this.dialog.closeAll();
                  },
                },
              ],
            },
          });
        } else if (error.status === 500) {
          this.dialog.open(SharedPopupComponent, {
            width: "400px",
            disableClose: true,
            data: {
              title: "¡Ups!",
              subtitle:
                "Parece que salió un error, porfavor vuelve a intentar.",
              content: {
                type: "text",
                text: [
                  "Si el problema persiste ayudanos reportando este problema, porfavor contactanos por medio del chat de ayuda.",
                ],
              },
              buttons: [
                {
                  text: "Ok",
                  action: () => {
                    this.dialog.closeAll();
                  },
                },
              ],
            },
          });
        }
        return throwError(error);
      })
    ) as any;
  }
}
