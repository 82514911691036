
<div class="main-container">
    <div class="section section-1">
        <div>
            <h1><span>De monedas a valores:</span><br /> Enseña el valor del dinero en tu hogar</h1>
            <button [routerLink]="['/register/parent']" class="tertiary">Me interesa</button>
        </div>
        <img src="assets/images/homepage/parents_hero.png" alt="tarjeta">
    </div>

    <div class="section section-2">
        <h2>Enseña <span>hábitos financieros</span> con Inverkids</h2>

        <div>
            <h3>Asigna tareas y recompensa a tus hijos</h3>
            <img class="image" src="assets/images/homepage/section_2_1.svg" alt="tareas">
        </div>

        <div class="reversed">
            <img class="image" src="assets/images/homepage/section_2_2.svg" alt="metas">
            <h3>Motívalos a cumplir sus metas financieras</h3>
        </div>

        <div>
            <h3>Enseña a través cuentos, videos y mucho contenido</h3>
            <img class="image" src="assets/images/homepage/section_2_3.svg" alt="cuentos">
        </div>

        <div class="reversed">
            <img class="image" src="assets/images/homepage/section_2_4.svg" alt="juegos">
            <h3>Goza verlos aprender finanzas jugando</h3>
        </div>
    </div>

    <div class="section section-3">
        <h2 class="white title">Haz de cada <span>momento</span> una oportunidad de enseñanza.</h2>
        <button [routerLink]="['/register/parent']" class="tertiary">Me interesa</button>
    </div>

    <div class="section section-4">
        <div class="leftPanel">
            <p><b>¡Próximamente!</b></p>
            <h1>La primera <span>tarjeta de débito</span> de tus hijos</h1>
            <p>Aprenderán a ahorrar, gastar e invertir desde un mismo lugar y <b>con dinero real</b></p>
            <button [routerLink]="['/register/parent']">Me interesa</button>
        </div>

        <img src="assets/images/homepage/tarjeta_hero.png">
    </div>

    <app-blog-list class="full-width"></app-blog-list>

    <app-newsletter class="full-width"></app-newsletter>

    <app-social-footer class="full-width"></app-social-footer>
</div>