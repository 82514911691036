<div class="spacer"></div>  
<div class="">
  <img class= "blue_wave" src="assets/images/homepage/section6_bluewave.svg" alt="Ola azul">
</div>
<section class="carousel">
    <div class="carousel-container">
      <div class="carousel-text">
            <p>Explorar, decidir <br>e invertir: <strong>así es <br> como enseñamos</strong></p>
          </div>
      <div class="carousel-item">
        <img src="assets/images/homepage/section6_card1.svg" alt="Competencias">
      </div>
      <div class="carousel-item">
        <img src="assets/images/homepage/section6_card2.svg" alt="Simuladores">
      </div>
      <div class="carousel-item">
        <img src="assets/images/homepage/section6_card3.svg" alt="Gamificacion">
      </div>
      <div class="carousel-item">
        <img src="assets/images/homepage/section6_card4.svg" alt="Evaluaciones">
      </div>
    </div>
  </section>

  <section class="events">
    <h2>Nuestros <strong>eventos</strong></h2>
    <div class="events-container">
      <div class="main-image">
        <img src="https://assets.inverkids.mx/assets/images/homepage/section6_carousel5.jpeg" alt="Evento principal">
      </div>
      <img src="assets/images/homepage/section6_bubble1.svg" class="floating-image finance">
      <img src="assets/images/homepage/section6_bubble2.svg" alt="Teens Teach" class="floating-image teens">
      <img src="assets/images/homepage/section6_bubble3.svg" alt="InverTank" class="floating-image tank">
      <img src="assets/images/homepage/section6_bubble4.svg" alt="InverChallenge" class="floating-image challenge">
    </div>
    <img class= "blue_wave2" src="assets/images/homepage/section6_bluewave.svg" alt="Ola azul 2">
  </section>

  <section class="future">
    <h2>Niños preparados <strong>para el futuro</strong></h2>
    <div class="future-container">
      <div class="future-images">
        <div class="future-carousel" (click)="prevCarousel()">
          <img class="arrow" src="assets/images/icons/arrow_down.svg" alt="arrow-down">
        </div>
        <div class="carousel-container-2">
          <img 
            class="future-img-2"
            *ngFor="let img of allImages; let i = index"
            [ngStyle]="{'margin-left': i == 0 ? -100.3 * carouselFrame + 'vw' : '0'}"
            [src]="'https://assets.inverkids.mx/assets/images/homepage/' + img"
            alt="Imagen Carrusel">
        </div>
        <div class="future-carousel2" (click)="nextCarousel()">
          <img class="arrow" src="assets/images/icons/arrow_down.svg" alt="arrow-down">
        </div>     
      </div>    
      
  </div>    
  </section>
