<div class="app-login">
    <img src="assets/images/login/complete_bk.svg" class="complete_bk">
    <div class="form-container" *ngIf="!forgot_pass">
        <img src="assets/images/login/Inverkids_logo_white.svg" class="logo">
        <input class="username-input theme-text" placeholder="{{selected_lang == 'es'?'Usuario':'Username'}}" (input)="setEnrollmentId($event)">
        <input (keydown.enter)="login()" type="password" class="password-input theme-text" placeholder="{{selected_lang == 'es'? 'Contraseña':'Password'}}" (input)="setPassword($event)">
        <p class="forgot-white-text" (click)="toggleForgot()">{{selected_lang == 'es'? 'Olvide mi contraseña':'Forgot my password'}}</p>
        <div [ngStyle]="{height: '0px', display: 'flex', 'flex-direction':'column', 'justify-content':'flex-end'}">
            <p class="error-message" *ngIf="error">{{ error }}</p>
        </div>
        <button id="botonIniciarSesion" class="login-button subtitle" (click)="login()">{{selected_lang == 'es'? 'Iniciar sesión':'Login'}}</button>
        <button class="lobby-button subtitle" (click)="goToLobby()">{{selected_lang == 'es'? 'Lobby':'Lobby'}}</button>
        <!-- <p class="register-white-text">{{selected_lang == 'es'? 'Registrarme':'Register'}}</p> -->
    </div>

    <div class="form-container" *ngIf="forgot_pass">
        <img src="assets/images/login/Inverkids_logo_white.svg" class="logo">
        <input class="username-input theme-text" placeholder="{{selected_lang == 'es'?'Usuario':'Username'}}" (input)="setForgotEnrollmentId($event)">
        <p class="forgot-white-text" (click)="toggleForgot()">{{selected_lang == 'es'? 'Regresar':'Go back'}}</p>
        <button class="login-button subtitle" (click)="sendEmail()">{{selected_lang == 'es'? 'Enviar':'Send'}}</button>
        <!-- <p class="register-white-text">{{selected_lang == 'es'? 'Registrarme':'Register'}}</p> -->
    </div>
</div>