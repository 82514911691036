<nav id="homepage-navbar">
    <ng-container *ngIf="visible">
        <div id="desktop-menu" [ngClass]="{'light-blue-bk': blueBackground}">
            <div class="inner-container">
                <img routerLink="/" id="navbar-desktop-inverkids-logo" src="assets/images/icons/inverkids_logo_nav.png"
                    alt="inverkids logo">
                <ul class="nav-items">
                    <li class="dark-blue" [routerLink]="['/']" (click)="resetOpion()">Inicio</li>
                    <!--<li class="dark-blue" [routerLink]="['/planes']" (click)="resetOpion()">Planes</li>-->
                    <li class="dark-blue" [routerLink]="['/padres']" (click)="resetOpion()">Familia</li>
                    <!--<li class="dark-blue" (click)="scrollToPrograms()">Programas</li>-->
                    <li class="dark-blue" [routerLink]="['/nosotros']" (click)="resetOpion()">Nosotros</li>
                    <!-- <li class="dark-blue" [routerLink]="['/tienda']" (click)="resetOpion()">Tienda</li> -->
                    <li class="dark-blue" [routerLink]="['/blog']" (click)="resetOpion()">Blog</li>
                    <!--<li *ngIf="showDownloads" class="dark-blue" [routerLink]="['/downloads']" (click)="resetOpion()">Descargas</li>-->
                    <li class="dark-blue" [routerLink]="['/games']" (click)="resetOpion()">Juegos</li>
                    <li class="blue" (click)="openDialog()">Iniciar Sesión</li>
                    <!--<li class="dark-blue" (click)="setLang()">{{ userLang }}</li>-->
                    <li>
                        <!-- La app está en pausa por ahorita 
                        <a href="https://apps.apple.com/us/app/inverkids/id1505098586?ls=1" target="_blank">
                            <button *ngIf="OS === 'iOS'" class="download-btn tertiary">Registrarme</button>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=inverkids.app" target="_blank">
                            <button *ngIf="OS === 'Android'" class="download-btn tertiary">Registrarme</button>
                        </a>
                        <button *ngIf="OS === 'Other'" class="download-btn tertiary"
                            [routerLink]="['/register']">¡Regístrate!</button>
                        -->
                        <button class="download-btn tertiary"
                            [routerLink]="['/register']">Registrarme</button>
                    </li>
                </ul>
            </div>
        </div>
        <div id="mobile-menu">
            <div id="hamburger-menu" (click)="openMenu()" [ngClass]="{ 'open' : toggled}">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div></div>
            <img [routerLink]="['/']" id="navbar-mobile-inverkids-logo" src="assets/images/icons/inverkids_logo_nav.png" alt="inverkids logo">
            <div></div>
            <div id="overlay" [ngClass]="{ 'overlay': toggled, 'hidden': !toggled }">
                <ul class="nav-items">
                    <li class="dark-blue" [routerLink]="['/']" (click)="resetOpion()">Inicio</li>
                    <!--<li class="dark-blue" [routerLink]="['/planes']" (click)="resetOpion()">Planes</li>-->
                    <li class="dark-blue" [routerLink]="['/padres']" (click)="resetOpion()">Familia</li>
                    <!--<li class="dark-blue" (click)="scrollToPrograms()">Programas</li>-->
                    <li class="dark-blue" [routerLink]="['/nosotros']" (click)="resetOpion()">Nosotros</li>
                    <!-- <li class="dark-blue" [routerLink]="['/tienda']" (click)="resetOpion()">Tienda</li> -->
                    <li class="dark-blue" [routerLink]="['/blog']" (click)="resetOpion()">Blog</li>
                    <li class="blue" (click)="openDialog()">Iniciar Sesión</li>
            
                    <li>
                        <!--
                        <a href="https://apps.apple.com/us/app/inverkids/id1505098586?ls=1" target="_blank">
                            <button *ngIf="OS === 'iOS'" class="download-btn tertiary">¡Descarga ya!</button>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=inverkids.app" target="_blank">
                            <button *ngIf="OS === 'Android'" class="download-btn tertiary">¡Descarga ya!</button>
                        </a>
                        <button *ngIf="OS === 'Other'" class="download-btn tertiary"
                            [routerLink]="['/register']">¡Regístrate!</button>
                        -->
                        <button class="download-btn tertiary"
                            [routerLink]="['/register']">Registrarme</button>
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>
</nav>