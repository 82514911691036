const CONTENT = {
  1: {
    en: "Start",
    es: "Inicio",
  },
  2: {
    en: "Tasks",
    es: "Tareas",
  },
  3: {
    en: "Allowance",
    es: "Domingo",
  },
  4: {
    en: "Goals",
    es: "Metas",
  },
  5: {
    en: "Accounts",
    es: "Cuentas",
  },
  6: {
    en: "Extras",
    es: "Extras"
  },
  7: {
    en: "Finance",
    es: "Finanzas"
  },
  8: {
    en: "School",
    es: "Escuela"
  },
  9: {
    en: "Achievements",
    es: "Logros"
  },
  10: {
    en: "Games",
    es: "Juegos"
  },
  11: {
    en: "Ranking",
    es: "Ranking"
  },
  12: {
    en: "Store",
    es: "Tienda"
  },
  13: {
    en: "Challenges",
    es: "Retos"
  },
  14: {
    en: "To enjoy the content in this resolution we invite you to download our Inverkids app.",
    es: "Para disfrutar del contenido en esta resolución te invitamos a descargar nuestra app Inverkids."
  },
  15: {
    en: "Reports",
    es: "Reportes"
  },
  16: {
    en: "Site",
    es: "Site"
  }
};

export default CONTENT;