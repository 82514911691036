<div id="home">
    <app-section1></app-section1>
    <app-section2></app-section2>
    <app-section3></app-section3>
    <app-section4></app-section4>
    <app-section5></app-section5>
    <app-section6></app-section6>
    <app-section7></app-section7>

    <app-social-footer class="full-width"></app-social-footer>
</div>