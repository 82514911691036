<div id="store">
    <div class="section section-1">
        <div>
            <h1>El mejor juego de mesa para aprender sobre finanzas</h1>
            <button (click)="selectProduct(2)" class="tertiary">Ver producto</button>
        </div>
        <img src="assets/images/homepage/tienda_hero.png" alt="tienda_hero">
    </div>

    <div class="section section-2 light-blue-bk">
        <h2 class="title big">Nuestros productos</h2>

        <div class="images-list">
            <div class="card" *ngFor="let product of storeProducts; let index = index;" (click)="selectProduct(index)">
                <img class="card-img" src="assets/store_files/{{product.image}}" alt="img_1">
                <div class="card-info">
                    <p class="small blue">{{product.category}}</p>
                    <p class="mt-10">{{product.name}}</p>
                    <p class="bold mt-10">{{product.textPrice}}</p>
                </div>
            </div>
        </div>
    </div>

    <app-blog-list class="full-width"></app-blog-list>

    <app-newsletter class="full-width"></app-newsletter>

    <app-social-footer class="full-width"></app-social-footer>
</div>