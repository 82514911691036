import { Component, OnDestroy } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Subscription, filter } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

declare let fbq: Function;

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnDestroy {
    versionSubscription: Subscription;
    title = 'http-client';

    constructor(private swUpdate: SwUpdate, private router: Router) {
        this.versionSubscription = this.swUpdate.available.subscribe((evt) => {
            console.warn('NUEVA VERSION');
            window.location.reload();
        });

        if (swUpdate.isEnabled) {
            this.swUpdate.versionUpdates
              .pipe(filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'))
              .subscribe(() => {
                if (confirm('A new version is available. Reload to update?')) {
                  window.location.reload();
                }
              });
          }

        router.events.subscribe((nav: NavigationEnd) => {
            if (nav instanceof NavigationEnd) {
                fbq('track', 'PageView');
            }
        });

        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.addEventListener("message", (event) => {
                if (event.data && event.data.type === "SW_RELOAD") {
                console.log("Model updated, reloading...");
                window.location.reload(); // Reloads the app when model updates
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.versionSubscription.unsubscribe();
    }
}
