<div id="payments">
    <div class="main-container light-blue-bk">
        <h2 class="title mt-50">¡Estás a un paso de ser parte de la familia Inverkids!</h2>

        <div class="payment-container mb-40">
            <div class="left-container">
                <p class="description bold mb-10">Información de contacto</p>
                <form class="payment-form" [formGroup]="userForm" autocomplete="off">
                    <input class="payment-input" [ngClass]="{'error': (!userForm.get('email').valid && isSubmitted) }"
                        formControlName="email" type="email" placeholder="Correo electrónico">
                    <input class="payment-input"
                        [ngClass]="{'error': (!userForm.get('email_confirmation').valid && isSubmitted) }"
                        formControlName="email_confirmation" type="email" placeholder="Confirmar correo electrónico">
                </form>
                <div class="divider"></div>
                <p class="bold mb-20">Tipo de plan</p>
                <mat-radio-group class="options" aria-label="Select an option" (change)="updateSelection($event)"
                    [(ngModel)]="planSelected">
                    <mat-radio-button class="option" value="mensual">Mensual $69/mes</mat-radio-button>
                    <mat-radio-button class="option" value="anual">Anual $439/año</mat-radio-button>
                </mat-radio-group>
                <div class="divider"></div>
                <p class="description bold mb-10">Detalles del pago</p>
                <form class="payment-form" [formGroup]="paymentForm" #conektaForm autocomplete="off">
                    <input class="payment-input"
                        [ngClass]="{'error': (!paymentForm.get('cardOwner').valid && isSubmitted) }"
                        placeholder="Nombre del titular" maxlength="60" formControlName="cardOwner"
                        data-conekta="card[name]" required>
                    <input class="payment-input"
                        [ngClass]="{'error': (!paymentForm.get('cardNumber').valid && isSubmitted) }"
                        placeholder="Número de la tarjeta" maxlength="60" formControlName="cardNumber"
                        data-conekta="card[number]" required>
                    <div class="inputs-row">
                        <input class="payment-input small"
                            [ngClass]="{'error': (!paymentForm.get('expirationMonth').valid && isSubmitted) }"
                            placeholder="MM" maxlength="2" formControlName="expirationMonth"
                            data-conekta="card[exp_month]" required>
                        <input class="payment-input small"
                            [ngClass]="{'error': (!paymentForm.get('expirationYear').valid && isSubmitted) }"
                            placeholder="AAAA" maxlength="4" formControlName="expirationYear"
                            data-conekta="card[exp_year]" required>
                        <input class="payment-input small"
                            [ngClass]="{'error': (!paymentForm.get('cvcNumber').valid && isSubmitted) }"
                            placeholder="CVC" maxlength="4" formControlName="cvcNumber" data-conekta="card[cvc]"
                            required>
                    </div>
                    <input type="hidden" name="conektaTokenId" id="conektaTokenId">
                    <div class="button-container mt-40">
                        <button class="tertiary" (click)="pay()">Pagar</button>
                    </div>
                </form>
            </div>
            <div class="right-container">
                <h3 class="mt-30">Resumen</h3>
                <div class="divider"></div>
                <div class="info-container mb-10">
                    <p class="description">Plan Premium</p>
                    <p *ngIf="planSelected === 'mensual'">$69</p>
                    <p *ngIf="planSelected === 'anual'">$439</p>
                </div>
                <div class="info-container mb-10">
                    <p class="description bold">Total a pagar</p>
                    <p *ngIf="planSelected === 'mensual'">$69</p>
                    <p *ngIf="planSelected === 'anual'">$439</p>
                </div>
                <div class="divider"></div>
                <p class="info caption small gray mb-20">El cobro se realiza en moneda nacional, ya incluye IVA. Se le
                    realizará un cargo por ${{ planSelected === 'mensual' ? '69' : '439' }} mxn cada año mientras la
                    suscripción se encuentre activa. Puede cancelar en cualquier
                    momento.</p>

                <p class="small caption gray">Aplican <a class="small"
                        [routerLink]="['/terminos-y-condiciones']">Terminos y condiciones</a>, y te invitamos a que
                    revises nuestras <a class="small" [routerLink]="['/politicas-de-privacidad']">Políticas de
                        Privacidad</a></p>
            </div>
        </div>
    </div>
</div>