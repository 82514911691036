<div id="blog">
    <div class="popular-aricles-container">
        <ng-container *ngFor="let blog of popularBlogs; let index = index;">
            <ng-container *ngIf="blogIndex === index">
                <div class="popular-aricles-image">
                    <img [src]="blog.image" alt="blog-image">
                </div>
                <div class="blog-details-container">
                    <div class="blog-details">
                        <div class="blog-title">
                            <h2>{{blog.title}}</h2>
                        </div>

                        <div class="blog-description mt-20">
                            <p class="description">{{blog.description}}</p>
                        </div>
                    </div>

                    <div class="read-more-button-container mt-20">
                        <button class="tertiary" (click)="getBlog(blog.id)">Leer más</button>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <div class="pagination">
        <p class="previous" (click)="getPreviousBlog()">Anterior</p>
        <div class="circle" [ngClass]="{ 'selected': blogIndex === 0 }"></div>
        <div class="circle" [ngClass]="{ 'selected': blogIndex === 1 }"></div>
        <div class="circle" [ngClass]="{ 'selected': blogIndex === 2 }"></div>
        <p class="next" (click)="getNextBlog()">Siguiente</p>
    </div>

    <div class="blog-list">
        <div class="blog" *ngFor="let blog of popularBlogs" (click)="getBlog(blog.id)">
            <img [src]="blog.image" alt="blog-image">

            <div class="blog-title mt-10">
                <h2>{{blog.title}}</h2>
            </div>

            <div class="blog-description">
                <p class="description">{{blog.description}}</p>
            </div>

            <a class="link">Leer más</a>
        </div>
    </div>
</div>