<div id="blog-list">
    <div class="inner-container">
        <h2 class="title big">Nuestro Blog</h2>

        <div class="blog-list">
            <div class="blog" *ngFor="let blog of popularBlogs" (click)="getBlog(blog.id)">
                <img [src]="blog.image" alt="blog-image">

                <div class="blog-title-container">
                    <h3 class="blog-title">{{blog.title}}</h3>
                </div>

                <div class="blog-description">
                    <p class="description">{{blog.description}}</p>
                </div>

                <p class="read-more">Leer más</p>
            </div>
        </div>
    </div>
</div>