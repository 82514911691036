import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegisterService } from '../../register.service';
import { UserProfileService } from '../../../../shared/services/userProfile.service';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedPopupComponent } from '../../../../shared/components/shared-popup/shared-popup.component';
import { EMAIL_TAKEN } from '../register.dialogs';
import { environment } from '../../../../../environments/environment';

declare let fbq: Function;

@Component({
    selector: 'app-student-registration',
    templateUrl: './student.component.html',
    styleUrls: ['./student.component.scss'],
})
export class StudentComponent {
    step = 0;
    studentForm = new FormGroup({
        name: new FormControl(''),
        nickname: new FormControl(''),
        birthday: new FormControl(''),
        gender: new FormControl(''),
        avatar: new FormControl(''),
        email: new FormControl('', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')]),
        password: new FormControl(''),
        password_confirmation: new FormControl(''),
        savings_account: new FormControl(''),
        earnings: new FormControl(0),
        user_type: new FormControl('Student'),
    });
    colors = ['azul', 'naranja', 'morado', 'verde', 'rosa'];
    today = new Date().toISOString().substring(0, 10);
    assetsUrl = environment.assetsUrl

    constructor(
        private registerService: RegisterService,
        private userProfileService: UserProfileService,
        private authenticationService: AuthenticationService,
        private dialog: MatDialog
    ) {}

    setGender(gender): void {
        this.studentForm.patchValue({ gender });
    }

    selectAvatar(avatar): void {
        this.studentForm.patchValue({ avatar });
    }

    isEmailAndPasswordValid(): boolean {
        if (
            this.studentForm.get('email'.trim()).dirty &&
            this.studentForm.get('password'. trim()).dirty &&
            this.studentForm.get('password_confirmation'.trim()).dirty
        ) {
            return (
                this.studentForm.get('password'.trim()).value === this.studentForm.get('password_confirmation').value &&
                this.studentForm.get('email'.trim()).valid &&
                this.studentForm.get('password'.trim()).value.length >= 5
            );
        } else {
            return false;
        }
    }

    selectSavingsAccount(savings_account): void {
        this.studentForm.patchValue({ savings_account });
    }

    submit(): void {
        this.registerService
            .registerStudent(this.studentForm.value)
            .then((data) => this.authenticateUser())
            .catch((error) => {
                if (error.status === 400) {
                    this.dialog.open(SharedPopupComponent, EMAIL_TAKEN(this));
                }
            });
        fbq('track', 'CompleteRegistration');
    }

    authenticateUser(): void {
        this.authenticationService
            .authenticateUser({
                enrollment_id: this.studentForm.get('email').value.trim(),
                password: this.studentForm.get('password').value.trim(),
            })
            .then((data) => {
                this.step = 8;
                this.userProfileService.setAuthToken(data.auth_token);
                this.userProfileService.setUserType(data.user_type);
                this.userProfileService.setAccountType(data.account_type);
            });
    }
}
