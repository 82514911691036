<div class="positioner">
    <div id="global-header">
        <div class="top">
            <div class="left-side">
                <ng-container *ngIf="goBackArrow">
                    <div class="back-arrow-container" (click)="goBack()">
                        <!-- <svg width="30" height="30" viewBox="0 0 30 30" [attr.color]="arrowColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.95 1.45L8.45 14.95" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                            <path d="M8.4499 14.95L21.9499 28.45" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                        </svg> -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" [attr.color]="arrowColor" viewBox="0 0 18 32" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.95 2.45L2.45 15.95L15.95 2.45Z" fill="#14004B"/>
                            <path d="M15.95 2.45L2.45 15.95" stroke="#14004B" stroke-width="4" stroke-linecap="round"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.45039 15.95L15.9504 29.45L2.45039 15.95Z" fill="#14004B"/>
                            <path d="M2.45039 15.95L15.9504 29.45" stroke="#14004B" stroke-width="4" stroke-linecap="round"/>
                        </svg>                    
                    </div>
                </ng-container>
                <ng-container *ngIf="logo">
                    <img class="logo mr-20" src="assets/images/icons/inverkids_logo_nav.png" alt="logo">
                </ng-container>
                <ng-container *ngIf="user_name != null">
                    <h2 [style.color]="textColor" class="user-name">{{user_name}}</h2>                   
                </ng-container>
            </div>
            <div class="middle" *ngIf="navbarTitle!=null">
                <h2 [style.color]="textColor">{{navbarTitle}}</h2>
            </div>
            <div class="right-side">
                <div class="profile-button-container" *ngIf="user && showProfileButton">
                    <img class="profile-button" src="{{assetsUrl}}assets/images/avatars/icon/{{user.avatar}}.svg" alt="perfil" (click)="goBack(['/', 'profile'])">
                </div>
                <div *ngIf="user_points != null" class="currecy-container">
                    <img class="coin" src="assets/images/icons/moneda.svg" alt="moneda">
                    <h2 [ngClass]="textColor">{{ user_points | number }}</h2>
                </div>
                <div *ngIf="user_exp != null" class="currecy-container">
                    <img class="coin" src="assets/images/icons/star.svg" alt="estrella">
                    <h2 [ngClass]="textColor">{{ user_exp | number }}</h2>
                </div>
                <div *ngIf="user_streak != null" class="currecy-container">
                    <img class="coin" src="assets/images/icons/fire.svg" alt="fire">
                    <h2 [ngClass]="textColor">{{ user_streak | number }}</h2>
                </div>
                <ng-container *ngIf="exitRoute != null">
                    <div class="cross-container" (click)="goTo(exitRoute)" >
                        <svg width="30" height="30" viewBox="0 0 30 30" [attr.color]="crossColor" xmlns="http://www.w3.org/2000/svg">
                            <rect x="2" y="26.7487" width="35" height="2" rx="1" transform="rotate(-45 2 26.7487)" fill="currentColor"/>
                            <rect x="3.41418" y="2" width="35" height="2" rx="1" transform="rotate(45 3.41418 2)" fill="currentColor"/>
                        </svg>                    
                    </div>
                </ng-container>

                <ng-container *ngIf="closeFunc != null">
                    <div class="cross-container" (click)="runCloseFunc()" >
                        <svg width="100%" height="100%" viewBox="0 0 30 30" [attr.color]="crossColor" xmlns="http://www.w3.org/2000/svg">
                            <rect x="2" y="26.7487" width="35" height="2" rx="1" transform="rotate(-45 2 26.7487)" fill="currentColor"/>
                            <rect x="3.41418" y="2" width="35" height="2" rx="1" transform="rotate(45 3.41418 2)" fill="currentColor"/>
                        </svg>                    
                    </div>
                </ng-container>

                <ng-container *ngIf="tabMenu != null">
                    <div class="tab-container">
                        <div class="tab" *ngFor="let tab of tabMenu; let index = index;" [ngClass]="{ 'selected': index === tabSelected }" (click)="selectTab(index)">
                            <p>{{ tab.title }}</p>
                        </div>
                    </div>
                </ng-container>
                
                <!-- <ng-container *ngIf="user && user.user_type === 'Student'">
                    <div *ngIf="showHitpoints" class="currecy-container">
                        <img class="hearth mr-20" src="assets/images/icons/corazon.svg" alt="corazon">
                        <h2 [ngClass]="textColor">{{ user.health | number }}</h2>
                    </div>
                </ng-container> -->
                <!-- <ng-container *ngIf="user && showPoints">
                    <h2 [ngClass]="textColor">${{ user.earnings | number }}</h2>
                </ng-container> -->
            </div>
        </div>
        <div class="bottom" *ngIf="showChildsNavbar || bottomNavbarOptions">
            <app-childs-navbar *ngIf="showChildsNavbar" [childs]="childs"></app-childs-navbar>
            <ng-container *ngIf="bottomNavbarOptions">
                <div class="mr-35" *ngFor="let option of bottomNavbarOptions">
                    <h2 [ngClass]="{ 'selected': option === optionSelected }" (click)="selectOption(option)">
                        {{ option }}</h2>
                </div>
            </ng-container>
        </div>
    </div>
</div>