<div class="fullscreen">
    <div class="app-login">
        <img src="assets/images/login/complete_bk.svg" class="complete_bk">
        <div class="form-container">
            <img src="assets/images/login/Inverkids_logo_white.svg" class="logo">
            <input class="password-input theme-text" placeholder="{{selected_lang == 'es'?'Contraseña':'Password'}}" (change)="setPassword($event)">
            <input class="password-input theme-text" placeholder="{{selected_lang == 'es'?'Confirmar contraseña':'Password confirm'}}" (change)="setPasswordConfirm($event)">
            <button class="login-button subtitle" (click)="sendResetPassword()">{{selected_lang == 'es'? 'Enviar':'Send'}}</button>
        </div>
    </div>
</div>