<div class="container">
    <div class="background-oval"></div>
    <div class="character">
        <dotlottie-wc src="https://assets.inverkids.mx/assets/lottie_animations/rosa_nube_sitio.lottie" 
        speed="1" 
        style="width: 135vw;
        height: auto;
        z-index: 0;
        left: -16%;
        top: -2%;
        position: absolute;
        margin-top: 5vw;"
        mode="forward" 
        loop autoplay></dotlottie-wc>
    </div>
</div>
<div class="curve">
    <img class= "curve4" src="assets/images/homepage/section4_curve.svg" alt="curva" style="width: 100%; z-index: 20;">
</div>   