// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
    production: true,
    // apiUrl: "http://localhost:3000/api",
    apiUrl: 'https://staging.inverkids.mx/api',
    // apiUrl: 'https://prod.inverkids.mx/api',
    conektaKey: "key_QQZtg0Z9b1UjEYN7nxa5eyE",
    // dashboardApiUrl: "http://localhost:3088/dashboard",
    dashboardApiUrl: "https://dashboard.inverkids.mx/dashboard",
    // dashboardApiUrl: "https://dashboard.prod.inverkids.mx/dashboard",
    // injectorApiUrl : "http://localhost:9000/",
    injectorApiUrl : "https://inverlingo.com/",
    assetsUrl: "https://assets.inverkids.mx/",
    plataform: "web"
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  