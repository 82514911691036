import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { environment } from '../../../environments/environment';
import { PaymentsService } from './payments.service';
import * as FormValidator from '../../shared/services/formValidaton.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedPopupComponent } from '../../shared/components/shared-popup/shared-popup.component';
import {
    FORM_ERROR,
    EMAIL_ERROR,
    EMAIL_MISSING,
    USER_NOT_FOUND,
    USER_NOT_VERIFIED,
    EMAIL_VERIFICATION_SENT,
    PAYMENT_SERVER_ERROR,
    PAYMENT_CREATION_ERROR,
    PAYMENT_PROGRESS,
    SERVER_ERROR,
} from './payments.dialogs';
import { UserProfileService } from '../../shared/services/userProfile.service';
import { Router } from '@angular/router';

declare var Conekta: any;
declare let fbq: Function;

@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit, AfterViewInit {
    paymentForm: FormGroup;
    userForm: FormGroup;
    conektaSuccessResponseHandler;
    conektaErrorResponseHandler;
    payment;
    planSelected = 'mensual';
    @ViewChild('conektaForm', { static: false }) conektaForm;
    isSubmitted = false;

    constructor(
        private paymentService: PaymentsService,
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
        private userProfileValidation: UserProfileService,
        private router: Router // se usa en el dialog, no lo borres
    ) {
        this.paymentForm = this.formBuilder.group({
            cardOwner: ['', FormValidator.FullNameValidator],
            cardNumber: ['', FormValidator.CardNumberValidator], // 4242424242424242
            cvcNumber: ['', FormValidator.CVCValidator], // 123
            expirationMonth: ['', FormValidator.ExpirationMonthValidator], // 12
            expirationYear: ['', FormValidator.ExpirationYearValidator], // 2020
        });

        this.userForm = this.formBuilder.group({
            email: ['', FormValidator.EmailValidator],
            email_confirmation: ['', FormValidator.EmailValidator],
            item: [this.planSelected, FormValidator.StreetsValidator],
        });
    }

    ngOnInit() {
        this.conektaSuccessResponseHandler = (token) => {
            this.payment = { ...this.userForm.value, token: token.id };
            fbq('track', 'Subscribe', { value: this.planSelected === 'mensual' ? 69.0 : 439.0, currency: 'MXN', predicted_ltv: '0.00' });
            this.paymentService.createPayment(this.payment).then(
                () => {
                    this.dialog.closeAll();
                    this.router.navigate(['/payment-success']);
                },
                (error) => {
                    console.log(error);
                    this.dialog.closeAll();
                    if (error.status === 400) {
                        this.dialog.open(SharedPopupComponent, PAYMENT_CREATION_ERROR(this));
                    } else if (error.status === 404) {
                        this.dialog.open(SharedPopupComponent, USER_NOT_FOUND(this));
                    } else {
                        this.dialog.open(SharedPopupComponent, SERVER_ERROR(this));
                    }
                }
            );
        };
        this.conektaErrorResponseHandler = (error) => {
            console.log(error);
            this.dialog.open(SharedPopupComponent, PAYMENT_SERVER_ERROR(this));
        };
    }

    ngAfterViewInit(): void {
        Conekta.setPublicKey(environment.conektaKey);
        Conekta.setLanguage('es');
    }

    pay(): void {
        this.isSubmitted = true;
        this.validateUserForm();
    }

    updateSelection(event): void {
        this.userForm.patchValue({ item: event.value });
        this.planSelected = event.value;
    }

    validateCard(): void {
        if (this.paymentForm.valid) {
            this.dialog.open(SharedPopupComponent, PAYMENT_PROGRESS(this));
            Conekta.Token.create(this.conektaForm.nativeElement, this.conektaSuccessResponseHandler, this.conektaErrorResponseHandler);
        } else {
            this.dialog.open(SharedPopupComponent, FORM_ERROR(this));
        }
    }

    validateUserForm(): void {
        if (this.userForm.valid) {
            if (this.userForm.get('email').value === this.userForm.get('email_confirmation').value) {
                this.userProfileValidation.checkEmailVerification(this.userForm.get('email').value).then(
                    () => {
                        this.validateCard();
                    },
                    (error) => {
                        console.log(error);
                        if (error.status === 400) {
                            this.dialog.open(SharedPopupComponent, USER_NOT_VERIFIED(this));
                        } else if (error.status === 404) {
                            this.dialog.open(SharedPopupComponent, USER_NOT_FOUND(this));
                        } else {
                            this.dialog.open(SharedPopupComponent, SERVER_ERROR(this));
                        }
                    }
                );
            } else {
                this.dialog.open(SharedPopupComponent, EMAIL_ERROR(this));
            }
        } else {
            this.dialog.open(SharedPopupComponent, EMAIL_MISSING(this));
        }
    }

    sendEmailVerification(): void {
        this.userProfileValidation.sendEmailVerification(this.userForm.get('email').value).then(
            () => {
                this.dialog.open(SharedPopupComponent, EMAIL_VERIFICATION_SENT(this));
            },
            (error) => {
                console.log(error);
                if (error.status === 404) {
                    this.dialog.open(SharedPopupComponent, USER_NOT_FOUND(this));
                } else {
                    this.dialog.open(SharedPopupComponent, SERVER_ERROR(this));
                }
            }
        );
    }
}
