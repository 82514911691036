import { Component, OnInit } from '@angular/core';

import { LoginComponent } from 'src/app/homepage/login/login.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-payment-success',
    templateUrl: './payment-success.component.html',
    styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {
    constructor(private dialog: MatDialog) {}

    ngOnInit() {}

    login(): void {
        this.dialog.open(LoginComponent, { width: '350px' });
    }
}
