import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class PinLoginService {
    private requestInProgress = false;

    constructor(private http: HttpClient) {}

    getStudents(teacher_enrollment_id, group_name, level): Promise<any> {
        const paramsData = {
            "teacher_enrollment_id": teacher_enrollment_id,
            "group_name": group_name,
            "level": level
          };
          const params = new HttpParams({ fromObject: paramsData });
          return this.http.get(`${environment.apiUrl}/students/get_teachers_students`, { params }).toPromise();
    }
}
