import { Component, OnInit } from "@angular/core";
import * as anime from 'animejs/lib/anime.min';
// import { HttpClient, HttpParams } from "@angular/common/http";
// import { environment } from "src/environments/environment";

@Component({
  selector: "app-section6",
  templateUrl: "./section6.component.html",
  styleUrls: ["./section6.component.scss"],
})
export class Section6Component implements OnInit {

  // Arreglo con los 17 nombres de las imágenes
  allImages = [
    'section6_carousel1.jpeg',
    'section6_carousel2.jpeg',
    'section6_carousel3.jpeg',
    'section6_carousel4.jpeg',
    'section6_carousel5.jpeg',
    'section6_carousel6.jpeg',
    'section6_carousel7.jpeg',
    'section6_carousel8.jpeg',
    'section6_carousel9.jpeg',
    'section6_carousel10.jpeg',
    'section6_carousel11.jpeg',
    'section6_carousel12.jpeg',
    'section6_carousel13.jpeg',
    'section6_carousel14.jpeg',
    'section6_carousel15.jpeg',
    'section6_carousel16.jpeg',
    'section6_carousel17.jpeg',
    'section6_carousel18.jpeg'
  ];

  // Cantidad de imágenes que muestras en cada "página" del carrusel
  imagesPerPage = 3;

  // Índice de la "página" actual (comenzamos en 0 = primera página)
  carouselFrame = 0;
  constructor() {}
  nextCarousel() {
    this.carouselFrame++;
    // Si te pasas de la última página, regresa a la primera (0)
    if (this.carouselFrame >= this.totalPages) {
      this.carouselFrame = 0;
    }
  }

  // Retrocede una "página" de 3 en 3
  prevCarousel() {
    this.carouselFrame--;
    // Si estás antes de la primera, ve a la última
    if (this.carouselFrame < 0) {
      this.carouselFrame = this.totalPages - 1;
    }
  }

  // Total de "páginas" o "sets" que habrá
  // 17 imágenes / 3 por página = 5.66 → 6 páginas
  get totalPages(): number {
    return Math.ceil(this.allImages.length / this.imagesPerPage);
  }

  // Obtiene el subarreglo de imágenes que se muestran
  // en la página actual
  get imagesToShow() {
    const startIndex = this.carouselFrame * this.imagesPerPage;
    return this.allImages.slice(startIndex, startIndex + this.imagesPerPage);
  }

  ngOnInit(): void {
    // Tu animación con anime.js:
    setTimeout(() => {
      anime({
        targets: '.floating-image',
        keyframes: this.generarKeyframesAleatoriosPequenos(5, 20),
        duration: 4000,
        loop: true,
        direction: 'alternate',
        easing: 'easeInOutSine',
        delay: anime.stagger(150)
      });
    }, 1);
  }
  private generarKeyframesAleatoriosPequenos(
    numKeyframes: number,
    range: number
  ): Array<{ translateX: number; translateY: number }> {
    const keyframes: { translateX: number; translateY: number }[] = [];

    for (let i = 0; i < numKeyframes; i++) {
      const randomX = (Math.random() - 0.5) * range * 2;
      const randomY = (Math.random() - 0.5) * range * 2;
      keyframes.push({
        translateX: randomX,
        translateY: randomY,
      });
    }

    keyframes.push(keyframes[0]);
    return keyframes;
  }

}
