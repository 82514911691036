<div *ngIf="pending_attitude_questions.length > 0" class="paq-popup-container">
  <div class="window">
    <div *ngFor="let aq of pending_attitude_questions" class="attitude_question">
      <h2>{{ aq.text_content[userLang] }}</h2>

      <div class="possible_answers">
        <button *ngFor="let pa of aq.possible_answers" class="possible_answer"
                [ngClass]="pa.selected ? 'selected': ''"
                (click)="selectThisPossibleAnswer(aq, pa)"
        >
          {{ pa.text_content[userLang] }}
        </button>
      </div>

      <div *ngFor="let error of getAttitudeAnswerErrors(aq)">
        <p class="small pink">{{ error.message[userLang] }}</p>
      </div>

      <button class="primary cta"
              (click)="answerAttitudeQuestion(aq)"
              [disabled]="ctaShouldBeDisabled(aq)"
      >
        <ng-container *ngIf="!aq.is_being_answered">{{ userLang === 'en' ? 'Send' : 'Enviar'}}</ng-container>
        <img *ngIf="aq.is_being_answered" src="../../../../assets/images/icons/loading.svg" class="animate-spin" />
      </button>

    </div>
  </div>
</div>
