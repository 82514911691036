import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BLOGS } from '../../../modules/extras/blog/pages/data';

@Component({
    selector: 'app-blog-list',
    templateUrl: './blog-list.component.html',
    styleUrls: ['./blog-list.component.scss'],
})
export class BlogListComponent implements OnInit {
    popularBlogs = BLOGS.slice(0, 3);

    constructor(private router: Router) {}

    ngOnInit() {}

    getBlog(selectedBlog: string) {
        this.router.navigate(['/blog', selectedBlog]);
    }
}
