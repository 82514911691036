import { PinLoginComponent } from './pin-login/pin-login.component';
import { AppLoginComponent } from './app-login/app-login.component';
import { AppLobbyComponent } from './app-lobby/app-lobby.component';
import { Routes } from '@angular/router';

export const TOOLS_ROUTES: Routes = [
    {
        path: 'pin-login/:teacher_enrollment_id',
        component: PinLoginComponent,
    },
    {
        path: 'pin-login/:teacher_enrollment_id/:group_name',
        component: PinLoginComponent,
    },
    {
        path: 'app-login',
        component: AppLoginComponent,
    },
    {
        path: 'app-login/:white_label',
        component: AppLoginComponent,
    },
    {
        path: 'app-lobby',
        component: AppLobbyComponent,
    },
];
