export function EMAIL_TAKEN(context) {
    const options = {
        width: '450px',
        data: {
            title: 'Lo sentimos',
            subtitle: 'El correo ya se encuentra en uso, por favor elige otro.',
            buttons: [
                {
                    text: 'Ok',
                    action: () => {
                        context.step = 5;
                        context.dialog.closeAll();
                    },
                },
            ],
        },
    };
    return options;
}

export function SCHOOL_REGISTRED(context) {
    const options = {
        width: '450px',
        data: {
            title: 'Tu información ha sido envida exitosamente',
            subtitle: 'Nos pondremos en contacto lo más pronto posible.',
            buttons: [
                {
                    text: 'Ok',
                    action: () => {
                        context.dialog.closeAll();
                    },
                },
            ],
        },
    };
    return options;
}

export function CARD_REGISTRED(context) {
    const options = {
        width: '450px',
        data: {
            title: 'Tu información ha sido envida exitosamente',
            subtitle: 'Nos pondremos en contacto lo más pronto posible.',
            buttons: [
                {
                    text: 'Ok',
                    action: () => {
                        context.dialog.closeAll();
                    },
                },
            ],
        },
    };
    return options;
}
