export function RESET_PIN(context) {
    const options = {
        width: '400px',
        data: {
            title: '¿Estás seguro que deseas restabelcer tu pin?',
            buttons: [
                {
                    text: 'Cancelar',
                    action: () => {
                        context.dialog.closeAll();
                    },
                },
                {
                    text: 'Ok',
                    action: () => {
                        context.resetPin();
                    },
                },
            ],
        },
    };
    return options;
}

export function RESET_PIN_SUCCESS(context) {
    const options = {
        width: '450px',
        data: {
            title: 'Éxito',
            subtitle: 'Hemos enviado un correo con las instrucciones para restablecer tu PIN.',
            buttons: [
                {
                    text: 'Ok',
                    action: () => {
                        context.dialog.closeAll();
                    },
                },
            ],
        },
    };
    return options;
}

export function WRONG_PIN(context) {
    const options = {
        width: '450px',
        data: {
            title: '¡Ups!',
            subtitle: 'Parece que tu PIN es incorrecto.',
            buttons: [
                {
                    text: 'Ok',
                    action: () => {
                        context.dialog.closeAll();
                    },
                },
            ],
        },
    };
    return options;
}
