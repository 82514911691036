    <section class="educacion-financiera">
        <h1>
            <span class="normal-text">Educación financiera </span>  
            <span class="bold">fácil y divertida</span></h1>
        <div class="contenedor">
            <div class="cards">
                <div class="img-container">
                    <img class="img1" src="assets/images/homepage/section4_bubble1.svg" alt="Plataforma Digital">
                </div>
                <h2 class="verde">Plataforma Digital</h2>
                <p>De 1ro a 9no grado en español e inglés en multiplataforma.</p>
            </div>
            <div class="cards">
                <div class="img-container">
                    <img class="img2" src="assets/images/homepage/section4_bubble2.svg" alt="Proyectos y eventos">
                </div>
                <h2 class="azul">Proyectos y eventos</h2>
                <p>Para desarrollar las competencias a través de la aplicación.</p>
            </div>
            <div class="cards">
                <div class="img-container">
                    <img class="img3" src="assets/images/homepage/section4_bubble3.svg" alt="Capacitación avanzada">
                </div>
                <h2 class="naranja">Capacitación avanzada</h2>
                <p>Para maestros en el uso de la plataforma y su contenido.</p>
            </div>
        </div>
    </section>