<div id="payments">
    <div class="main-container light-blue-bk">
        <div class="cart-container" (click)="goToCart()">
            <img class="cart clickable" src="assets/images/icons/carrito.svg" alt="carrito">
            <p class="caption ml-10 clickable">Mi carrito</p>
        </div>

        <h2 class="title">Finaliza tu pedido</h2>

        <div class="payment-container mb-40">
            <div class="left-container">
                <p class="description bold mb-10">Información de contacto</p>
                <form class="payment-form" [formGroup]="userForm" autocomplete="off">
                    <input class="payment-input" [ngClass]="{'error': (!userForm.get('email').valid && isSubmitted) }"
                        formControlName="email" type="email" placeholder="Correo electrónico">
                    <input class="payment-input"
                        [ngClass]="{'error': (!userForm.get('email_confirmation').valid && isSubmitted) }"
                        formControlName="email_confirmation" type="email" placeholder="Confirmar correo electrónico">
                    <div *ngIf="isAllDigital">
                        <input class="payment-input"
                            [ngClass]="{'error': (!userForm.get('name').valid && isSubmitted) }"
                            formControlName="name" type="text" placeholder="Nombre completo">
                    </div>
                    <div *ngIf="!isAllDigital">
                        <div class="divider"></div>
                        <p class="bold mb-20">Dirección de envío</p>
                        <input class="payment-input"
                            [ngClass]="{'error': (!userForm.get('full_name').valid && isSubmitted) }"
                            formControlName="full_name" type="text" placeholder="Nombre completo">
                        <input class="payment-input" [ngClass]="{'error': (!userForm.get('address').valid && isSubmitted) }"
                            formControlName="address" type="text" placeholder="Calle y número">
                        <div class="inputs-row">
                            <input class="payment-input medium"
                                [ngClass]="{'error': (!userForm.get('zip_code').valid && isSubmitted) }"
                                formControlName="zip_code" type="text" placeholder="Código Postal">
                            <input class="payment-input medium"
                                [ngClass]="{'error': (!userForm.get('city').valid && isSubmitted) }" formControlName="city"
                                type="text" placeholder="Ciudad">
                        </div>
                        <div class="inputs-row">
                            <input class="payment-input medium"
                                [ngClass]="{'error': (!userForm.get('state').valid && isSubmitted) }"
                                formControlName="state" type="text" placeholder="Estado">
                            <input class="payment-input medium"
                                [ngClass]="{'error': (!userForm.get('country').valid && isSubmitted) }"
                                formControlName="country" type="text" placeholder="País">
                        </div>
                        <input class="payment-input" [ngClass]="{'error': (!userForm.get('phone').valid && isSubmitted) }"
                            formControlName="phone" type="text" placeholder="Teléfono">
                    </div>
                </form>
                <div class="divider"></div>
                <p class="description bold mb-10">Detalles del pago</p>
                <form class="payment-form" [formGroup]="paymentForm" #conektaForm autocomplete="off">
                    <input class="payment-input"
                        [ngClass]="{'error': (!paymentForm.get('cardOwner').valid && isSubmitted) }"
                        placeholder="Nombre del titular" maxlength="60" formControlName="cardOwner"
                        data-conekta="card[name]" required>
                    <input class="payment-input"
                        [ngClass]="{'error': (!paymentForm.get('cardNumber').valid && isSubmitted) }"
                        placeholder="Número de la tarjeta" maxlength="60" formControlName="cardNumber"
                        data-conekta="card[number]" required>
                    <div class="inputs-row">
                        <input class="payment-input small"
                            [ngClass]="{'error': (!paymentForm.get('expirationMonth').valid && isSubmitted) }"
                            placeholder="MM" maxlength="2" formControlName="expirationMonth"
                            data-conekta="card[exp_month]" required>
                        <input class="payment-input small"
                            [ngClass]="{'error': (!paymentForm.get('expirationYear').valid && isSubmitted) }"
                            placeholder="AAAA" maxlength="4" formControlName="expirationYear"
                            data-conekta="card[exp_year]" required>
                        <input class="payment-input small"
                            [ngClass]="{'error': (!paymentForm.get('cvcNumber').valid && isSubmitted) }"
                            placeholder="CVC" maxlength="4" formControlName="cvcNumber" data-conekta="card[cvc]"
                            required>
                    </div>
                    <input type="hidden" name="conektaTokenId" id="conektaTokenId">
                    <div class="button-container mt-40">
                        <button class="tertiary" (click)="pay()">Pagar</button>
                    </div>
                </form>
            </div>
            <div class="right-container">
                <h3 class="mt-30">Resumen</h3>
                <div class="divider"></div>
                <div class="cart-list">
                    <div class="cart-item" *ngFor="let cartItem of cart">
                        <div class="row-container">
                            <p>{{ cartItem.product.name }}</p>
                            <p>${{ cartItem.product.price * cartItem.quantity | number }}</p>
                        </div>
                        <p class="small mt-5">Cantidad x{{ cartItem.quantity }}</p>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="row-container" >
                    <p>Subtotal</p>
                    <p>${{ total | number }}</p>
                </div>
                <div class="row-container mt-10" *ngIf="!isAllDigital">
                    <p class="shipping">Envío
                        <ng-container *ngIf="!shipping">(por calcular)</ng-container>
                    </p>
                    <p class="shipping">${{ shipping  | number }}</p>
                </div>
                <div class="row-container mt-10">
                    <p class="bold">Total a pagar</p>
                    <p class="bold">${{ total + shipping | number }}</p>
                </div>
                <div class="divider"></div>
                <p class="info caption small gray mb-20">El cobro se realiza en moneda nacional, ya incluye IVA.</p>
                <p class="info caption small gray mb-20">Si requieres factura solicítala a mariana@inverkids.mx.</p>
                <p class="info caption small gray mb-20">Si hay un error al procesar su pago, favor de verificar la información de su tarjeta o contactar a su banco para la aprobación de la transación.</p>
                <p *ngIf="isAllDigital" class="info caption small gray mb-20">Tu pedido es totalmente digital, así que no se cobrará el envío.</p>
                <p class="small caption gray">Aplican <a class="small"
                        [routerLink]="['/terminos-y-condiciones']">Terminos y condiciones</a>, y te invitamos a que
                    revises nuestras <a class="small" [routerLink]="['/politicas-de-privacidad']">Políticas de
                        Privacidad</a></p>
            </div>
        </div>
    </div>
</div>