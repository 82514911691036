<div id="terms-and-conditions">
    <h2 class="mb-5 mt-20">
        TERMINOS Y CONDICIONES
    </h2>

    <p>Este sitio web es operado por Inverkids México. En todo el sitio, los términos "nosotros", "nosotros" y "nuestro"
        se refieren a Inverkids. Inverkids ofrece este sitio web, que incluye toda la información, herramientas y
        servicios disponibles en este sitio para usted, el usuario, condicionado a su aceptación de todos los términos,
        condiciones, políticas y avisos que se indican aquí.</p>
    <p>Al visitar nuestro sitio y / o comprar algo de nosotros, participa en nuestro "Servicio" y acepta regirse por los
        siguientes términos y condiciones ("Términos del Servicio", "Términos"), incluidos esos términos y condiciones y
        políticas adicionales. Se hace referencia en este documento y / o está disponible por hipervínculo. Estos
        Términos de servicio se aplican a todos los usuarios del sitio, incluidos, entre otros, los usuarios que son
        navegadores, proveedores, clientes, comerciantes y / o contribuyentes de contenido.</p>
    <p>Lea estos Términos de servicio detenidamente antes de acceder o utilizar nuestro sitio web. Al acceder o utilizar
        cualquier parte del sitio, usted acepta estar sujeto a estos Términos de servicio. Si no está de acuerdo con
        todos los términos y condiciones de este acuerdo, entonces no puede acceder al sitio web ni utilizar ningún
        servicio. Si estos Términos de servicio se consideran una oferta, la aceptación se limita expresamente a estos
        Términos de servicio.</p>
    <p>Cualquier nueva característica o herramienta que se agregue a la tienda actual también estará sujeta a los
        Términos de Servicio. Puede revisar la versión más reciente de los Términos de servicio en cualquier momento en
        esta página. Nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte de estos Términos de
        servicio mediante la publicación de actualizaciones y / o cambios en nuestro sitio web. Es su responsabilidad
        revisar esta página periódicamente para ver si hay cambios. Su uso o acceso continuo al sitio web después de la
        publicación de cualquier cambio constituye la aceptación de dichos cambios.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 1 - TÉRMINOS DE LA TIENDA EN LÍNEA
    </h2>
    <p>Al aceptar estos Términos de servicio, usted declara que tiene al menos la mayoría de edad en su estado o
        provincia de residencia, o que es la mayoría de edad en su estado o provincia de residencia y nos ha dado su
        consentimiento para Permita que cualquiera de sus dependientes menores use este sitio.</p>
    <p>No puede usar nuestros productos para ningún fin ilegal o no autorizado, ni puede, en el uso del Servicio, violar
        las leyes de su jurisdicción (incluidas, entre otras, las leyes de derechos de autor).</p>
    <p>No debe transmitir ningún gusano o virus ni ningún código de naturaleza destructiva.</p>
    <p>El incumplimiento o violación de cualquiera de los Términos dará como resultado la terminación inmediata de sus
        Servicios.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 2 - CONDICIONES GENERALES
    </h2>
    <p>Nos reservamos el derecho de rechazar el servicio a cualquier persona por cualquier motivo en cualquier momento.
    </p>
    <p>Usted comprende que su contenido (sin incluir la información de la tarjeta de crédito) puede transferirse sin
        cifrar e implica (a) transmisiones a través de varias redes; y (b) cambios para cumplir y adaptarse a los
        requisitos técnicos de conexión de redes o dispositivos. La información de la tarjeta de crédito siempre se
        cifra durante la transferencia a través de redes.</p>
    <p>Acepta no reproducir, duplicar, copiar, vender, revender o explotar cualquier parte del Servicio, su uso o acceso
        al Servicio o cualquier contacto en el sitio web a través del cual se proporciona el servicio, sin el permiso
        expreso por escrito de nosotros.</p>
    <p>Los encabezados utilizados en este acuerdo se incluyen solo por conveniencia y no limitarán ni afectarán de otra
        manera estos Términos.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 3 - EXACTITUD, INTEGRIDAD Y OPORTUNIDAD DE INFORMACIÓN
    </h2>
    <p>No somos responsables si la información disponible en este sitio no es precisa, completa o actual. El material en
        este sitio se proporciona solo para información general y no se debe confiar o utilizar como la única base para
        tomar decisiones sin consultar fuentes de información primarias, más precisas, más completas o más oportunas.
        Cualquier confianza en el material de este sitio es bajo su propio riesgo.</p>
    <p>Este sitio puede contener cierta información histórica. La información histórica, necesariamente, no está
        actualizada y se proporciona solo para su referencia. Nos reservamos el derecho de modificar los contenidos de
        este sitio en cualquier momento, pero no tenemos la obligación de actualizar ninguna información en nuestro
        sitio. Usted acepta que es su responsabilidad monitorear los cambios en nuestro sitio.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 4 - MODIFICACIONES AL SERVICIO Y PRECIOS
    </h2>
    <p>Los precios de nuestros productos están sujetos a cambios sin previo aviso.</p>
    <p>En cualquier momento nos reservamos el derecho de modificar o interrumpir el Servicio (o cualquier parte o
        contenido del mismo) sin previo aviso en cualquier momento.</p>
    <p>No seremos responsables ante usted ni ante terceros por cualquier modificación, cambio de precio, suspensión o
        interrupción del Servicio.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 5 - PRODUCTOS O SERVICIOS (si corresponde)
    </h2>
    <p>Ciertos productos o servicios pueden estar disponibles exclusivamente en línea a través del sitio web. Estos
        productos o servicios pueden tener cantidades limitadas y están sujetos a devolución o cambio únicamente de
        acuerdo con nuestra Política de devolución.</p>
    <p>Hemos hecho todo lo posible para mostrar con la mayor precisión posible los colores e imágenes de nuestros
        productos que aparecen en la tienda. No podemos garantizar que la visualización de cualquier color del monitor
        de su computadora sea precisa.</p>
    <p>Nos reservamos el derecho, pero no estamos obligados, a limitar las ventas de nuestros productos o Servicios a
        cualquier persona, región geográfica o jurisdicción. Podemos ejercer este derecho en una base de caso por caso.
        Nos reservamos el derecho de limitar las cantidades de cualquier producto o servicio que ofrezcamos. Todas las
        descripciones de productos o precios de productos están sujetas a cambios en cualquier momento sin previo aviso,
        a nuestra entera discreción. Nos reservamos el derecho de descontinuar cualquier producto en cualquier momento.
        Cualquier oferta por cualquier producto o servicio realizado en este sitio es nula donde esté prohibida.</p>
    <p>No garantizamos que la calidad de los productos, servicios, información u otro material comprado u obtenido por
        usted cumpla con sus expectativas, o que se corrija cualquier error en el Servicio.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 6 - EXACTITUD DE LA INFORMACIÓN DE FACTURACIÓN Y DE LA CUENTA
    </h2>
    <p>Nos reservamos el derecho de rechazar cualquier pedido que usted realice con nosotros. Podemos, a nuestro
        exclusivo criterio, limitar o cancelar las cantidades compradas por persona, por hogar o por pedido. Estas
        restricciones pueden incluir pedidos realizados por o bajo la misma cuenta de cliente, la misma tarjeta de
        crédito y / o pedidos que utilizan la misma dirección de facturación y / o envío. En el caso de que realicemos
        un cambio o cancelemos un pedido, podemos intentar notificarlo comunicándonos con el correo electrónico y / o la
        dirección de facturación / número de teléfono proporcionado en el momento en que se realizó el pedido. Nos
        reservamos el derecho de limitar o prohibir los pedidos que, a nuestro exclusivo criterio, parecen ser
        realizados por distribuidores, revendedores o distribuidores.</p>
    <p>Usted acepta proporcionar información de compra y cuenta actual, completa y precisa para todas las compras
        realizadas en nuestra tienda. Acepta actualizar de inmediato su cuenta y otra información, incluida su dirección
        de correo electrónico y los números de las tarjetas de crédito y las fechas de vencimiento, para que podamos
        completar sus transacciones y contactarlo cuando sea necesario.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 7 - HERRAMIENTAS OPCIONALES
    </h2>
    <p>Es posible que le proporcionemos acceso a herramientas de terceros sobre las cuales no supervisamos ni tenemos
        ningún control ni entrada.</p>
    <p>Usted reconoce y acepta que proporcionamos acceso a dichas herramientas "tal como están" y "según estén
        disponibles" sin ninguna garantía, representación o condición de ningún tipo y sin ningún tipo de respaldo. No
        tendremos ninguna responsabilidad derivada de su uso de herramientas de terceros opcionales.</p>
    <p>Cualquier uso que usted haga de las herramientas opcionales ofrecidas a través del sitio es totalmente bajo su
        propio riesgo y discreción, y debe asegurarse de estar familiarizado y aprobar los términos en los cuales las
        herramientas son proporcionadas por el (los) tercero (s) proveedor (es) relevante (s).</p>
    <p>También podemos, en el futuro, ofrecer nuevos servicios y / o características a través del sitio web (incluido el
        lanzamiento de nuevas herramientas y recursos). Tales nuevas características y / o servicios también estarán
        sujetos a estos Términos de Servicio.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 8 - ENLACES DE TERCEROS
    </h2>
    <p>Ciertos contenidos, productos y servicios disponibles a través de nuestro Servicio pueden incluir materiales de
        terceros.</p>
    <p>Los enlaces de terceros en este sitio pueden dirigirlo a sitios web de terceros que no están afiliados con
        nosotros. No somos responsables de examinar o evaluar el contenido o la precisión, y no garantizamos y no
        seremos responsables de ningún material o sitio web de terceros, ni de ningún otro material, producto o servicio
        de terceros.</p>
    <p>No somos responsables de ningún daño o daño relacionado con la compra o el uso de bienes, servicios, recursos,
        contenido o cualquier otra transacción realizada en relación con sitios web de terceros. Revise detenidamente
        las políticas y prácticas de terceros y asegúrese de que las comprende antes de realizar cualquier transacción.
        Las quejas, reclamos, inquietudes o preguntas relacionadas con productos de terceros deben dirigirse al tercero.
    </p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 9 - COMENTARIOS DEL USUARIO, COMENTARIOS Y OTRAS PRESENTACIONES
    </h2>
    <p>Sí a petición nuestra, envía ciertos envíos específicos (por ejemplo, participaciones en el concurso) o, si no
        lo solicita, puede enviar ideas creativas, sugerencias, propuestas, planes u otros materiales, ya sea en línea,
        por correo electrónico, por correo postal o de otra manera. (colectivamente, 'comentarios'), usted acepta que,
        en cualquier momento, sin restricciones, podemos editar, copiar, publicar, distribuir, traducir y utilizar de
        cualquier otro modo cualquier comentario que nos envíe. Estamos y no estaremos bajo ninguna obligación (1) de
        mantener cualquier comentario en secreto; (2) para pagar una indemnización por cualquier comentario; o (3) para
        responder a cualquier comentario.</p>
    <p>Podemos, pero no tenemos la obligación de monitorear, editar o eliminar el contenido que determinemos a nuestra
        entera discreción que sea ilegal, ofensivo, amenazante, difamatorio, difamatorio, pornográfico, obsceno o de lo
        contrario objetable o que viole la propiedad intelectual de cualquier parte o estos Términos de servicio. .</p>
    <p>Usted acepta que sus comentarios no violarán ningún derecho de terceros, incluidos los derechos de autor, marca
        registrada, privacidad, personalidad u otro derecho personal o de propiedad. Además, acepta que sus comentarios
        no contendrán material difamatorio o ilegal, abusivo u obsceno, ni contendrán ningún virus informático u otro
        malware que pueda afectar de alguna manera el funcionamiento del Servicio o cualquier sitio web relacionado. No
        puede usar una dirección de correo electrónico falsa, pretender ser otra persona que no sea usted, o engañarnos
        a nosotros o a terceros en cuanto al origen de los comentarios. Usted es el único responsable de los comentarios
        que realice y de su exactitud. No asumimos ninguna responsabilidad ni asumimos ninguna responsabilidad por los
        comentarios publicados por usted o cualquier tercero.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 10 - INFORMACIÓN PERSONAL
    </h2>
    <p>Su envío de información personal a través de la tienda se rige por nuestra Política de privacidad. Para ver
        nuestra Política de Privacidad.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 11 - ERRORES, INACULTURAS Y OMISIONES
    </h2>
    <p>Ocasionalmente, puede haber información en nuestro sitio o en el Servicio que contenga errores tipográficos,
        inexactitudes u omisiones que puedan estar relacionadas con descripciones de productos, precios, promociones,
        ofertas, cargos de envío de productos, tiempos de tránsito y disponibilidad. Nos reservamos el derecho de
        corregir cualquier error, inexactitud u omisión, y de cambiar o actualizar la información o cancelar pedidos si
        alguna información en el Servicio o en cualquier sitio web relacionado es inexacta en cualquier momento sin
        previo aviso (incluso después de haber enviado su pedido) .</p>
    <p>No asumimos ninguna obligación de actualizar, enmendar o aclarar la información en el Servicio o en cualquier
        sitio web relacionado, incluyendo, entre otros, la información de precios, excepto según lo exija la ley. No se
        debe tomar ninguna actualización específica o fecha de actualización aplicada en el Servicio o en ningún sitio
        web relacionado, para indicar que toda la información en el Servicio o en cualquier sitio web relacionado ha
        sido modificada o actualizada.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 12 - USOS PROHIBIDOS
    </h2>
    <p>Además de otras prohibiciones según lo establecido en los Términos de servicio, se le prohíbe usar el sitio o su
        contenido: (a) para cualquier propósito ilegal; (b) para solicitar a otros que realicen o participen en actos
        ilegales; (c) violar cualquier reglamento, norma, ley, o ordenanza local, internacional, federal, provincial o
        estatal; (d) infringir o violar nuestros derechos de propiedad intelectual o los derechos de propiedad
        intelectual de terceros; (e) hostigar, abusar, insultar, dañar, difamar, calumniar, desacreditar, intimidar o
        discriminar por motivos de género, orientación sexual, religión, origen étnico, raza, edad, origen nacional o
        discapacidad; (f) presentar información falsa o engañosa; (g) para cargar o transmitir virus o cualquier otro
        tipo de código malicioso que se usará de cualquier manera que afecte la funcionalidad o el funcionamiento del
        Servicio o de cualquier sitio web relacionado, otros sitios web o Internet; (h) para recopilar o rastrear la
        información personal de otros; (i) spam, phish, pharm, pretexto, araña, rastreo o raspado; (j) para cualquier
        propósito obsceno o inmoral; o (k) para interferir o eludir las características de seguridad del Servicio o
        cualquier sitio web relacionado, otros sitios web o Internet. Nos reservamos el derecho de finalizar su uso del
        Servicio o de cualquier sitio web relacionado por violar cualquiera de los usos prohibidos.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 13 - EXENCIÓN DE RESPONSABILIDAD DE GARANTÍAS; LIMITACIÓN DE RESPONSABILIDAD
    </h2>
    <p>No garantizamos, representamos ni garantizamos que su uso de nuestro servicio sea ininterrumpido, oportuno,
        seguro o libre de errores.</p>
    <p>No garantizamos que los resultados que se puedan obtener del uso del servicio sean precisos o confiables.</p>
    <p>Usted acepta que de vez en cuando podemos eliminar el servicio por períodos de tiempo indefinidos o cancelar el
        servicio en cualquier momento, sin previo aviso.</p>
    <p>Usted acepta expresamente que su uso o la imposibilidad de usar el servicio es bajo su único riesgo. El servicio
        y todos los productos y servicios que se le entregan a través del servicio se brindan (a excepción de lo
        expresado expresamente por nosotros), tal como están y están disponibles para su uso, sin ninguna
        representación, garantía o condición de ningún tipo, ya sea expresa o implícita, incluidas todas las garantías o
        condiciones implícitas de comercialización, calidad comercial, idoneidad para un propósito particular,
        durabilidad, título y no infracción.</p>
    <p>En ningún caso Inverkids, nuestros directores, funcionarios, empleados, afiliados, agentes, contratistas,
        pasantes, proveedores, proveedores de servicios o licenciantes serán responsables de cualquier lesión, pérdida,
        reclamación o cualquier daño directo, indirecto, incidental, punitivo, especial o daños consecuenciales de
        cualquier tipo, incluidos, sin limitación, pérdida de beneficios, pérdida de ingresos, pérdida de datos, costos
        de reemplazo o daños similares, ya sea por contrato, responsabilidad extracontractual (incluida la negligencia),
        responsabilidad estricta o de otro tipo, derivados de su responsabilidad. el uso de cualquiera de los servicios
        o productos adquiridos utilizando el servicio, o para cualquier otra reclamación relacionada de alguna manera
        con su uso del servicio o cualquier producto, incluidos, entre otros, cualquier error u omisión en cualquier
        contenido, o cualquier pérdida o daño de cualquier tipo en el que se incurra como resultado del uso del servicio
        o del contenido (o producto) publicado, transmitido o puesto a disposición de otro modo a través del servicio,
        incluso si se informa de su posibilidad. Debido a que algunos estados o jurisdicciones no permiten la exclusión
        o limitación de responsabilidad por daños indirectos o incidentales, en dichos estados o jurisdicciones, nuestra
        responsabilidad se limitará al máximo permitido por la ley.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 14 - INDEMNIZACIÓN
    </h2>
    <p>Acepta indemnizar, defender y mantener inofensivos a Inverkids y nuestras matrices, subsidiarias, afiliadas,
        socios, funcionarios, directores, agentes, contratistas, otorgantes de licencias, proveedores de servicios,
        subcontratistas, proveedores, pasantes y empleados, inofensivos de cualquier reclamo o demanda, incluidos los
        razonables los honorarios de abogados, realizados por cualquier tercero debido a su incumplimiento de estos
        Términos de Servicio o los documentos que incorporan por referencia, o su violación de cualquier ley o los
        derechos de un tercero.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 15 - SEPARABILIDAD
    </h2>
    <p>En el caso de que se determine que cualquier disposición de estos Términos de Servicio es ilegal, nula o
        inejecutable, dicha disposición, sin embargo, será aplicable en la medida máxima permitida por la ley aplicable,
        y la parte no exigible se considerará separada de estos Términos de Servicio, dicha determinación no afectará la
        validez y exigibilidad de cualquier otra disposición restante.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 16 - TERMINACIÓN
    </h2>
    <p>Las obligaciones y responsabilidades de las partes incurridas antes de la fecha de terminación sobrevivirán a la
        terminación de este acuerdo para todos los propósitos.</p>
    <p>Estos Términos de Servicio son efectivos a menos y hasta que sean terminados por usted o por nosotros. Puede
        terminar estos Términos de Servicio en cualquier momento notificándonos que ya no desea utilizar nuestros
        Servicios o cuando deje de usar nuestro sitio.</p>
    <p>Sí a nuestro exclusivo criterio, usted no cumple, o sospechamos que no cumplió con algún término o disposición
        de estos Términos de servicio, también podemos rescindir este acuerdo en cualquier momento sin previo aviso y
        usted será responsable de todos los montos adeudados. hasta e incluyendo la fecha de terminación; y / o en
        consecuencia puede negarle el acceso a nuestros Servicios (o cualquier parte de los mismos).</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 17 - ACUERDO COMPLETO
    </h2>
    <p>El hecho de que no hayamos ejercido o hecho cumplir cualquier derecho o disposición de estos Términos de Servicio
        no constituirá una renuncia a dicho derecho o disposición.</p>
    <p>Estos Términos de Servicio y todas las políticas o reglas de operación publicadas por nosotros en este sitio o
        con respecto al Servicio constituyen el acuerdo y la comprensión completos entre usted y nosotros y rigen su uso
        del Servicio, sustituyendo cualquier acuerdo, comunicaciones y propuestas anteriores o actuales. , ya sea de
        forma oral o escrita, entre usted y nosotros (incluidas, entre otras, las versiones anteriores de los Términos
        de servicio).</p>
    <p>Cualquier ambigüedad en la interpretación de estos Términos de Servicio no se interpretará en contra de la parte
        redactora.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 18 - CAMBIOS A LOS TÉRMINOS DEL SERVICIO
    </h2>
    <p>Puede revisar la versión más reciente de los Términos de servicio en cualquier momento en esta página.</p>
    <p>Nos reservamos el derecho, a nuestro exclusivo criterio, de actualizar, cambiar o reemplazar cualquier parte de
        estos Términos de servicio mediante la publicación de actualizaciones y cambios en nuestro sitio web. Es su
        responsabilidad revisar periódicamente nuestro sitio web para ver si hay cambios. Su uso o acceso continuo a
        nuestro sitio web o al Servicio después de la publicación de cualquier cambio a estos Términos de Servicio
        constituye la aceptación de dichos cambios.</p>

    <h2 class="mb-5 mt-20">
        SECCIÓN 19 - INFORMACIÓN DE CONTACTO
    </h2>
    <p>Las preguntas sobre los Términos de Servicio deben enviarse a soporte@inverkids.mx.</p>

    <h2 class="mb-5 mt-20">
        TIENDA EN LINEA
    </h2>
    <p>Lo escrito aquí se extiende a tus compras en línea. Siempre y cuando tengas tu nota de compra digital los
        siguientes puntos se harán validos de acuerdo a sus requisitos.</p>

    <h2 class="mb-5 mt-20">
        MENSAJERÍA EN NUEVO LEÓN
    </h2>
    <p>En Inverkids nos esforzamos día a día para hacerte llegar tus productos en buen estado, por lo que nos encargamos
        en entregar personalmente en el estado de Nuevo León. En caso de que al momento de recibir el producto, se
        perciba algún daño, se podrá cambiar de inmediato.</p>
    <p>Al recibir y aceptar el producto por parte de nuestro equipo interno de mensajería damos por entendido que
        aceptaste la mercancía que se te entrego.</p>

    <h2 class="mb-5 mt-20">
        MENSAJERÍA FUERA DE NUEVO LEÓN
    </h2>
    <p>En Inverkids nos esforzamos día a día para hacerte llegar tus productos en buen estado y normalmente utilizamos
        según se requiera: plástico burbuja, papel kraft y suficiente relleno tipo cacahuate, cartón, bolsas de aire o
        plástico espuma. Con el fin de que te llegue en buen estado tu paquete.</p>
    <p>Inverkids no se hace responsable por los daños ocurridos durante el trayecto de entrega por parte de la
        mensajería. Una vez que nosotros les entregamos el paquete a la mensajería es responsabilidad de ellos que tu
        paquete llegue en las mismas condiciones que nosotros lo enviamos. Si al momento de la entrega recibiste el
        paquete y entregaste la firma al repartidor, sin revisar el contenido previamente, das por hecho que estas
        conforme con la entrega del paquete y su contenido. Entonces tanto ellos (servicios de mensajería) como nosotros
        (Inverkids) no podremos hacernos responsables. Por lo tanto toda responsabilidad y reclamo legal deben ir
        directamente hacia ellos en caso de que les rechaces un paquete dañado. En este último caso (cuando se rechaza
        un paquete) podríamos ayudarte en el proceso.</p>

    <h2 class="mb-5 mt-20">
        DEVOLUCIONES
    </h2>
    <p>No hacemos devoluciones por dinero, solo el cambio por otros artículos en venta. El cambio solo se garantiza bajo
        las siguientes condiciones:</p>
    <p>- Al entregar el producto en Nuevo León no hay devoluciones al recibir y aceptar el producto por parte de nuestro
        equipo interno de mensajería.</p>
    <p>- El periodo de cambio solo cubre los primeros 2 días hábiles a partir del día en el que se entregó el producto
        hablando en enviós fuera del estado de Nuevo León.</p>
    <p>- El producto debe estar intacto y en las mismas condiciones en las que se entregó.</p>
    <p>- En caso de que la compra se realizó fuera de la ciudad. Toda la responsabilidad de que el paquete llegue a
        nosotros es a cargo del comprador.</p>

    <p>Una vez aceptado el cambio te daremos las indicaciones para completar el cambio.</p>
    <p>El rango de lunes a sábado cuenta cómo días hábiles.</p>
</div>