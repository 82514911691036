import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedPopupComponent } from '../../shared/components/shared-popup/shared-popup.component';
import { MailerService } from '../../shared/services/mailer.service';
import { MatDialog } from '@angular/material/dialog';
import { SUBSCRIPTION_SUCCESS, WRONG_EMAIL } from '../home/home.dialogs';
import { PRODUCTS } from '../../../assets/store_files/products.data';

@Component({
    selector: 'app-store',
    templateUrl: './store.component.html',
    styleUrls: ['./store.component.scss'],
})
export class StoreComponent implements OnInit {
    email;
    products = PRODUCTS;
    storeProducts = PRODUCTS.filter(x => x.showOnStore);

    constructor(private router: Router, private mailerService: MailerService, private dialog: MatDialog) { }

    ngOnInit() { }

    subscribe(): void {
        if (this.email && this.email.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')) {
            this.mailerService.createNewsletterSubscription(this.email).then(
                () => this.dialog.open(SharedPopupComponent, SUBSCRIPTION_SUCCESS(this)),
                (error) => console.log(error)
            );
        } else {
            this.dialog.open(SharedPopupComponent, WRONG_EMAIL(this));
        }
    }

    selectProduct(productIndex): void {
        this.router.navigate([`tienda/producto/${productIndex}`]);
    }
}
