import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalHeaderComponent } from './global-header.component';
import { ChildsNavbarModule } from '../childs-navbar/childs-navbar.module';

@NgModule({
    declarations: [GlobalHeaderComponent],
    imports: [CommonModule, ChildsNavbarModule],
    exports: [GlobalHeaderComponent],
})
export class GlobalHeaderModule {}
