import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { UserProfileService } from '../../shared/services/userProfile.service';

import { AppLoginService } from './app-login.service';
import { SharedPopupComponent } from '../../shared/components/shared-popup/shared-popup.component';
import * as appDialogs from './app-login.dialogs';
import { UserCredentials } from '../../shared/models/user.model';
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ResetPasswordService } from '../../homepage/reset-password/reset-password.service';
import { show_parent_onboarding_key } from "../../modules/dashboard/student/student.component";
import { environment } from '../../../environments/environment';
import { run_process } from '../../shared/utils/progressive_offline_utils';
@Component({
    selector: 'app-app-login',
    templateUrl: './app-login.component.html',
    styleUrls: ['./app-login.component.scss'],
})
export class AppLoginComponent {
    pin = '';
    error;
    enrollmentId: string;
    forgot_enrollmentId: string;
    password: string;
    enrollmentIdError = false;
    passwordError = false;
    userCredentials = new UserCredentials();
    selected_lang = "es";
    forgot_pass = false;
    @ViewChild('pinInput', { static: false }) pinInput;

    constructor(private pinService: AppLoginService, private router: Router, private dialog: MatDialog,
        private userProfileService: UserProfileService,
        private activatedRoute: ActivatedRoute,
        private resetPasswordService: ResetPasswordService,
        private authService: AuthenticationService,
        ) {
    }

    goToLobby(): void {
        this.router.navigate(['/app-lobby']);
    }

    setEnrollmentId(event) {
        this.enrollmentId = event.target.value.trim().toLowerCase();
        event.target.value = this.enrollmentId
    }

    setForgotEnrollmentId(event) {
        this.forgot_enrollmentId = event.target.value.trim().toLowerCase();
        event.target.value = this.forgot_enrollmentId
    }

    setPassword(event) {
        this.pin = event.target.value.trim();
        event.target.value = this.pin
    }

    toggleForgot() {
        this.forgot_pass = !this.forgot_pass;
    }

    async cargarCatalogoDesdeAPI() {
        if ("serviceWorker" in navigator){
            try {
                console.log("Simulando carga de catalogo desde api");
                // QUESTION: deberiamos de gestionar la llamada a la api normal desde aqui o dejarlo tal cual esta y solo gestionar la llamada de SW?
                const registration: any = await navigator.serviceWorker.ready;
                try {
                console.log("Background Sync registered!");
                registration.active?.postMessage({
                    type: "ENVIRONMENT",
                    environment: environment,
                    authToken: this.userProfileService.getAuthToken(),
                    user_id: this.userProfileService.getUserId(),
                    label: this.userProfileService.getWhiteLabel()
                });
                // await registration.sync.register("update-catalogo");
                registration.active?.postMessage({
                    type: "update-catalog"
                });
                } catch (error){
                console.log("Background Sync could not be registered!", error);
                }
            } catch (error) {
                console.error("Error al cargar el catalogo desde la API:", error);
            }
        }else{
            console.log("Simulando carga de catalogo desde api, sin SW");
            run_process({
            data: {
                type: "ENVIRONMENT",
                environment: environment,
                authToken: this.userProfileService.getAuthToken(),
                user_id: this.userProfileService.getUserId(),
                label: this.userProfileService.getWhiteLabel()
            }
            })
            run_process({
            data: {
                type: "update-catalog"
            }
            })
        }
    }

    async downloadGradesProgress() {
        if ("serviceWorker" in navigator){
            try {
                console.log("Simulando carga de grades progress desde api");
                // QUESTION: deberiamos de gestionar la llamada a la api normal desde aqui o dejarlo tal cual esta y solo gestionar la llamada de SW?
                const registration: any = await navigator.serviceWorker.ready;
                try {
                  console.log("Background Sync registered!");
                  registration.active?.postMessage({
                    type: "ENVIRONMENT",
                    environment: environment,
                    authToken: this.userProfileService.getAuthToken(),
                    user_id: this.userProfileService.getUserId(),
                    label: this.userProfileService.getWhiteLabel()
                  });
                  // await registration.sync.register("update-grades-progress");
                  registration.active?.postMessage({
                      type: "update-grades-progress"
                  });
                } catch(error) {
                  console.log("Background Sync could not be registered!", error);
                }
              } catch (error) {
                console.error("Error al cargar el grades progress desde la API:", error);
              }
        }else{
            console.log("Simulando carga de grades desde api, sin SW");
            run_process({
            data: {
                type: "ENVIRONMENT",
                environment: environment,
                authToken: this.userProfileService.getAuthToken(),
                user_id: this.userProfileService.getUserId(),
                label: this.userProfileService.getWhiteLabel()
            }
            })
            run_process({
            data: {
                type: "update-grades-progress"
            }
            })
        }
    }

    login(): void {
        const botonIniciarSesion = (<HTMLButtonElement>document.getElementById("botonIniciarSesion"))
        this.enrollmentIdError = false;
        this.passwordError = false;

        if (this.enrollmentId === "") {
            this.enrollmentIdError = true;
        }

        if (this.pin === "") {
            this.passwordError = true;
        }

        if (this.pin && this.enrollmentId) {
            botonIniciarSesion.style.width = `${botonIniciarSesion.clientWidth}px`
            botonIniciarSesion.innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="20px" width="20px" style="animation: rotate 2s infinite linear;" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.917 7A6.002 6.002 0 0 0 2.083 7H1.071a7.002 7.002 0 0 1 13.858 0h-1.012z"></path></svg>';
            botonIniciarSesion.style.opacity = '0.5';
            this.userCredentials.enrollment_id = this.enrollmentId.trim();
            this.userCredentials.password = this.pin.trim();
            this.authenticateUser();
        }
    }

    sendEmail(): void {
        this.resetPasswordService.resetPasswordMail(this.forgot_enrollmentId.toLowerCase().trim()).then(
            (data) => {
                console.log("response: ", data);
            },
            (error) => {
                console.log("error: ", error);
            }
        );
    }

    authenticateUser(): void {
        this.authService.authenticateUser(this.userCredentials).then(
            async (user) => {
                this.userProfileService.setUserType(user.user_type);
                this.userProfileService.setUserRole(user.role);
                this.userProfileService.setUserId(user.id);
                this.userProfileService.setAuthToken(user.auth_token);
                this.userProfileService.setAccountType(user.account_type);
                this.userProfileService.removeParentToken();
                await this.userProfileService.getUserProfile();
                console.log(this.userProfileService.getLang());

                // here goes get catalog syncing
                if (navigator.onLine) {
                    this.cargarCatalogoDesdeAPI();
                    this.downloadGradesProgress();
                }

                if (user.user_type === 'Parent') {
                    this.userProfileService.setParentToken(user.auth_token);
                    this.router.navigate(['/users']);
                } else if (['Teacher', 'Coordinator', 'Director'].includes(user.user_type)) {
                    this.router.navigate(['/school']);
                } else {
                    this.router.navigate(['/dashboard']);
                }

                localStorage.setItem(show_parent_onboarding_key, 'true');
            },
            (error) => {
                const botonIniciarSesion = (<HTMLButtonElement>document.getElementById("botonIniciarSesion"))
                if (error.status === 400 || error.status === 404) {
                    this.error = error.error;
                    botonIniciarSesion.style.width = 'fit-content'
                    botonIniciarSesion.innerHTML = 'Iniciar Sesión';
                    botonIniciarSesion.style.opacity = '1';
                }
            }
        );
    }
}
